import React, { Component } from "react";
import Instructions from './Instructions';
import MyAccount from './MyAccount';
import AssesmentHome from './assesment/AssesmentHome';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Login from '../common/Login';
import Register from '../common/Register';
import Welcome from '../common/Welcome';
import {
    FIRST_NAME,
    LAST_NAME,
    AUTH_TOKEN,
    USER_NAME
  } from "../../constants/index";
  import { Image } from 'semantic-ui-react';
import { hist } from './../../App';

class PublicHome extends Component {

  render() {
    return (
      <div>
        {/* Begin Main */}
        <div id="spinneroverlay">
          <div class="spinner"></div>
        </div>
        {/* End Main */}

        {/* Begin Mobile Navbar */}
        {/* <div class="tm-mobile-header uk-hidden@m">
            <nav class="uk-navbar-container uk-navbar" uk-navbar="">
                <div class="uk-navbar-left">
                <Image className="ippf-logo" src={ require('../../assets/images/ippf-logo.png') } fluid />
                </div>
                <div class="uk-navbar-right">
                    <a class="uk-navbar-toggle" href="#tm-mobile" uk-toggle>
                        <div class="uk-navbar-toggle-icon">
                            <i class="far fa-bars icon-large uk-text-black"></i>
                        </div>
                    </a>
                </div>
            </nav>
            <div id="tm-mobile" class="uk-modal-full uk-modal" uk-modal>
                <div class="uk-modal-dialog uk-modal-body uk-text-center uk-flex" uk-height-viewport>
                    <button class="uk-modal-close-full uk-close uk-icon" type="button" uk-close></button>                     
                    <div class="uk-margin-auto-vertical uk-width-1-1">
                        <div class="uk-child-width-1-1" uk-grid>
                            <div>
                                <div class="uk-panel">
                                    <ul class="uk-nav uk-nav-primary uk-nav-center nav-white">
                                        <li>
                                            <a href="landing-home.html">Home 1</a>
                                        </li>
                                        <li>
                                            <a href="landing-blog.html">About RAT</a>
                                        </li>                                         
                                        <li class="uk-active">
                                            <a href="landing-about.html">Instructions</a> 
                                        </li>
                                        <li>
                                            <a href="landing-pricing.html">Contact Us</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div>
                                <div class="uk-panel widget-text" id="widget-text-1">
                                    <div class="textwidget">
                                        <p class="uk-text-center"><a class="uk-button uk-button-success uk-button-large" href="../main/Homepage.html"> My Account </a></p>
                                    </div>
                                </div>
                                <div class="uk-panel widget-text" id="widget-text-1">
                                    <div class="textwidget">
                                        <p class="uk-text-center"><a class="uk-button uk-button-success uk-button-large" href="../main/Homepage.html"> My Account </a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>             
        </div> */}
        {/* End Mobile NavBar */}

        {/* Begin NavBar */}
        <div class="tm-header uk-visible@m tm-header-transparent">
            <div media="768" animation="uk-animation-slide-top" cls-active="uk-navbar-sticky uk-nav-dark" sel-target=".uk-navbar-container" top=".tm-header ~ [class*=&quot;uk-section&quot;], .tm-header ~ * > [class*=&quot;uk-section&quot;]" cls-inactive="uk-navbar-transparent   uk-dark" class="uk-sticky uk-navbar-container">
                <div class="uk-container  uk-position-relative">
                    <nav class="uk-navbar uk-navbar-transparent">
                        
                        <div class="uk-navbar-left">
                        <Image className="ippf-logo" src={ require('../../assets/images/ippf-logo.png') } fluid />
                        </div>
                        <div class="uk-navbar-right">
                            <ul class="uk-navbar-nav toolbar-nav">
                                <li>
                                    <a href="">Home</a>
                                </li>
                                <li>
                                    <a href="">About RA Tool</a>
                                </li>                                 
                                <li>
                                    <a href="">Instructions</a> 
                                </li>
                                <li>
                                    <a href="">Contact Us{localStorage.LAST_NAME}</a>
                                </li>
                                
                                
                            </ul>
                            <a class="el-content uk-button uk-button-success uk-circle" href="/register"> Register </a>
                            {" "}
                        </div>
                    </nav>
                </div>
            </div>
        </div>
        {/* End NavBar */}
        
        {/* Begin Router */}
        
        <Router>
            <Route exact path="/" component={Login}/>
            <Route exact path="/register" component={Register}/>
            <Route exact path="/welcome" component={Welcome}/>
        </Router>

        {/* End Router */}

        {/* Begin Footer */}
            <div class="uk-section-small  uk-section-default">
            <hr class="uk-margin-remove" />
            <div class="uk-container uk-align-center uk-margin-remove-bottom uk-position-relative">
                <div class="uk-grid uk-margin-small" uk-grid>
                    <div class="uk-width-1-3@m uk-width-1-2@s uk-first-column">
                        <a href="pages-about.html" className=" display uk-link-heading uk-text-lead uk-text-bold">
                        <Image className="ippf-logo" src={ require('../../assets/images/ippf-logo.png') } fluid />
                        <Image className="IppfLogo" src={require('../../assets/images/IPPF.png')} fluid />
                        </a>
                        <hr/>
                        <div class="uk-width-xlarge tm-footer-description">
                            Vel alii zril maiorum ex, mea id sale eirsmod epicurei. Sit te possit senserit, eam alia veritus maluisset ei,
                        </div>
                    </div>
                    <div class="uk-width-expand@m uk-width-1-2@s">
                        
                    </div>
                    <div class="uk-width-expand@m uk-width-1-2@s">
                        {/* Middle Colum */}
                    </div>
                    <div class="uk-width-expand@m uk-width-1-2@s">
                    <ul class="uk-list tm-footer-list">
                            <li>
                                <a href="#">
                                    About Us
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    Contact Us
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    Privacy Policy
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    Terms & Conditions
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <hr/>
                <p class="uk-postion-absoult uk-margin-remove uk-position-bottom-right" style={styles.poweredBy} uk-tooltip="title: Visit Our Site; pos: top-center">
                    Powered By
                    <a href="#" class="uk-text-bold uk-link-reset">
                        {" "} Encyte
                    </a>
                </p>
                <div class="uk-margin-small" uk-grid>
                    <div class="uk-width-1-2@m uk-width-1-2@s uk-first-column">
                        <p class="uk-text-small">
                            <i class="far fa-copyright"></i>
                            (C) 2020
                            <span class="uk-text-bold"> IPPF</span>
                            . All rights reserved.</p>
                    </div>
                    <div class="uk-width-1-3@m uk-width-1-2@s">
                        
                    </div>
                </div>
            </div>
        </div>
        {/* End Footer */}
      </div>
    );
  }
}

const styles = {
    darkBack: {
        backgroundColor:'rgba(0, 0, 0, 0.52)',
        backgroundBlendMode:'color-burn',
    },
    poweredBy: {
        bottom: '8px',
        right: '60px',
    }
}

export default PublicHome;