import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import "semantic-ui-css/semantic.min.css";
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from "./store/store";
import { Provider } from "react-redux";
//import { createHTTPLink, createHttpLink } from "apollo-link-http";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";
import { persistCache } from "apollo-cache-persist";
import { ApolloClient } from "apollo-client";
import { ApolloProvider } from "react-apollo";
import { BrowserRouter, Route } from "react-router-dom";
import { AUTH_TOKEN } from './constants/index';
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

//UI CSS
import "./assets/css/main.css";
import './assets/css/admin.css';
import './assets/css/uikit.css';
import "./assets/css/fontawesome.css";
import './custom.css';

//UI JS
import '../node_modules/uikit/dist/js/uikit.js'
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

const httpLink = createHttpLink({
    // uri: "http://localhost:4000" //local

    uri: "http://165.22.67.128:4000/" //staging

    // uri: "https://ratback.encyteautomation.com:4000/" //live
    // http://3.16.196.110:4477/_admin
});

const cache = new InMemoryCache();
const authLink = setContext((_, { headers }) => {
    const token = localStorage.AUTH_TOKEN;
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        }
    }
});

// const defaultOptions = { 
//     watchQuery: {
//       fetchPolicy: 'no-cache',
//     },
//     query: {
//       fetchPolicy: 'no-cache',
//     },
//     mutate: {
//       fetchPolicy: 'no-cache',
//       errorPolicy: 'all'
//     }
//   }

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache,
    //defaultOptions
});

persistCache({
    cache: client.cache,
    storage: window.localStorage,
    debug: true,
});
// package.json:   "homepage": "/production/ippfrat-stag",
// /production/ippfrat-stag
// ""
ReactDOM.render(
    <BrowserRouter basename="">
        <ApolloProvider client={client}>
            <Provider store={store}>
                <I18nextProvider i18n={i18n}>
                    <App />
                </I18nextProvider>
            </Provider>
        </ApolloProvider>
    </BrowserRouter>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
