import React, { Component } from "react";
import TableLoader from "../../../../components/loaders/TableLoader";
import { GET_SUBMISSIONS_BY_SECTION } from "../../../../queries/SubmissionQueries";
import { SECTIONS_BY_USER } from "../../../../queries/SectionQueries";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { Label } from "semantic-ui-react";
import moment from "moment";
import {
  Button,
  Popup,
  Grid,
} from "semantic-ui-react";

class UserSubmissions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      submissions: {},
      questionList: [],
      sectionName: '',
    };
    this._getSubmissionData();
  }

  _getSubmissionData = () => {
    const userId = this._getUserFromUrl();
    const sectionId = this.props.match.params.sectionId;
    this.props.client
      .query({
        query: GET_SUBMISSIONS_BY_SECTION,
        variables: { userId, sectionId }
      })
      .then(result => {
        console.log(result);
        this.setState({
          questionList: Object.keys(
            result.data.getSubmissionForUser.submission
          ),
          //questionList: result.data.getSubmissionForUser.submission,
          submissions: result.data.getSubmissionForUser.submission,
          sectionName: result.data.getSubmissionForUser.sectionName,
        });
        console.log(this.state);
      })
      .catch(error => {
        console.log(error);
        this.setState({ loading: false });
      });
  };

  _getUserFromUrl = () => {
    const path = this.props.location.pathname;
    const comps = path.split("/");
    return comps[2];
  };

  _goToSectionList = () => {
    this.props.history.goBack();
  };

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

  render() {
    console.log(this.state.questionList)
    console.log(this.state.submissions)
    var secondaryJsonDisplay = {};
    var primaryJsonDisplay = {};
    var question_nu=0;

    this.state.questionList.map(question => (

      [this.state.submissions[question].secondaryJson ? (
        !this.isEmpty(this.state.submissions[question].secondaryJson) ? (
          secondaryJsonDisplay = this.state.submissions[question].secondaryJson
        ) : (null)
      ) : (
          null
        )
        ,

      this.state.submissions[question].primaryJson ? (
        !this.isEmpty(this.state.submissions[question].primaryJson) ? (
          primaryJsonDisplay = this.state.submissions[question].primaryJson
        ) : (null)
      ) : (
          null
        )]

    ))


    return (
      <div>
        {/* Begin Router */}
        <br />
        <h4 className="uk-heading-line">
          <span>Assessment Progress</span>
        </h4>

        <ul className="uk-breadcrumb">
          <li>
            <a onClick={this._goToSectionList}>Section List</a>
          </li>
          <li>
            <a href="#">{this.state.sectionName}</a>
          </li>
          {/* <li className="uk-disabled">
            <a>Disabled</a>
          </li>
          <li>
            <span>Active</span>
          </li> */}
        </ul>
        <div className="uk-overflow-auto">
          {/* Submission List comes here */}
          <div className="uk-background-default uk-position-relative uk-box-shadow-medium">
            <h5 className="uk-padding-small uk-margin-remove uk-text-bold  uk-text-left">
              {" "}
              MA Responses{" "}
            </h5>
            {/* <p className="uk-position-top-right uk-position-small">
              3 Questions Pending
            </p> */}
            <hr className="uk-margin-remove" />

            {/* {console.log(this.state.questionList)} */}

            {/* Begin Single Question */}
            {this.state.questionList.map(question => (
              <div key={question}>
                <div className="uk-grid uk-padding-small">
                  <div className="uk-width-expand">
                    {/* <div className="uk-align-right">
                      <Label circular color="green"></Label>
                    </div> */}
                    <h3 className="uk-margin-top">
                      {" "}
                      {/* {++question_nu})  */}
                      {this.state.submissions[question].primaryQuestion}{" "}
                    </h3>

                    <h5 className="uk-margin-small uk-text-muted">
                      {" "}

                      Answer:  {typeof (this.state.submissions[question].primaryAnswer) === 'boolean' ? (
                        this.state.submissions[question].primaryAnswer ? 'YES' : 'NO'
                      ) : (

                          this.state.submissions[question].primaryArray ? (
                            this.state.submissions[question].primaryArray.length > 0 ? (
                              //console.log(index.submission[question].secondaryArray)

                              this.state.submissions[question].primaryArray.map((ans, i) => {

                                return [<br />, Object.keys(ans).map((key, index) => (
                                  //console.log(key +"--"+ans[key])
                                  <div> {key.split(/(?=[A-Z])/).join(" ").replace(/^\w/, c => c.toUpperCase())}: {ans[key]} </div>
                                ))]

                              })
                            ) : (
                                null
                              )
                          ) : (
                              // this.state.submissions[question].primaryJson || !this.isEmpty(this.state.submissions[question].primaryJson) ? (
                              //   Object.keys(this.state.submissions[question].primaryJson).map(function (key) {
                              //     return <div> {key}  :  {this.state.submissions[question].primaryJson[key]} </div>
                              //   })
                              // ) : (
                              //     this.state.submissions[question].primaryAnswer
                              //   )

                              this.state.submissions[question].primaryJson ? (
                                !this.isEmpty(this.state.submissions[question].primaryJson) ? (
                                  Object.keys(this.state.submissions[question].primaryJson).map(function (key) {
                                    return <div> {key.split(/(?=[A-Z])/).join(" ").replace(/^\w/, c => c.toUpperCase())}: {primaryJsonDisplay[key]}</div>
                                  })
                                ) : (null)
                              ) : (
                                  this.state.submissions[question].primaryAnswer
                                )

                            )

                        )}
                    </h5>

                    <p>
                      {console.log(this.state.submissions[question].secondaryJson)}
                      {" "}
                      Secondary Answer:  {typeof (this.state.submissions[question].secondaryAnswer) === 'boolean' ? (
                        this.state.submissions[question].secondaryAnswer ? 'YES' : 'NO'
                      ) :(this.state.submissions[question].secondaryAnswer)}
                      {(

                          this.state.submissions[question].secondaryArray ? (
                            this.state.submissions[question].secondaryArray.length > 0 ? (
                              //console.log(index.submission[question].secondaryArray)

                              this.state.submissions[question].secondaryArray.map((ans, i) => {

                                return [<br />, Object.keys(ans).map((key, index) => (
                                  //console.log(key +"--"+ans[key])
                                  <div> {key.split(/(?=[A-Z])/).join(" ").replace(/^\w/, c => c.toUpperCase())}: {ans[key]} </div>
                                ))]

                              })
                            ) : (
                                null
                              )
                          ) : (
                              //console.log(this.state.submissions[question].secondaryJson)

                              this.state.submissions[question].secondaryJson ? (
                                !this.isEmpty(this.state.submissions[question].secondaryJson) ? (
                                  Object.keys(this.state.submissions[question].secondaryJson).map(function (key) {
                                    return <div> {key.split(/(?=[A-Z])/).join(" ").replace(/^\w/, c => c.toUpperCase())}: {secondaryJsonDisplay[key]}</div>
                                  })
                                ) : (null)
                              ) : (
                                null
                                  // this.state.submissions[question].secondaryAnswer
                                )

                            )
                        )}
                    </p>

                    <div>
                      {this.state.submissions[question].fileUrl ? (
                        this.state.submissions[question].fileUrl != "" ? (
                          // <img src={index.submission[question].fileUrl} style={{ width: "300px" }} />

                          <div className="file-upload-group" >
                            <Popup
                              trigger={<Button className="file-icon" circular icon="attach" />}
                              flowing
                              hoverable
                            >
                              <Grid centered divided columns={1}>
                                <Grid.Column textAlign="center">
                                  <Button circular icon='download' onClick={() => { window.open(this.state.submissions[question].fileUrl, '_blank'); }} />

                                </Grid.Column>
                              </Grid>
                            </Popup>
                          </div>

                        ) : (
                            <p><i className="fas fa-file"></i> Attachments: No Documents given</p>
                          )
                      ) : (
                          <p><i className="fas fa-file"></i> Attachments: No Documents given</p>
                        )}
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            ))}

            {/* End Single Question */}
          </div>
        </div>

        {/* End Router */}
      </div>
    );
  }
}

export default withRouter(withApollo(UserSubmissions));
