import React, { Component } from "react";
import { GET_ALL_SUBMMISSIONS } from "../../queries/SubmissionQueries";
import { withApollo } from "react-apollo";
import {
  Button,
  Icon,
  Popup,
  Grid,
  Accordion
} from "semantic-ui-react";


class Progress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 1,
      loading: true,
      sections: []
    };
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  loadResults = () => {
    console.log("Querying submissions");
    this.props.client
      .query({
        query: GET_ALL_SUBMMISSIONS,
        fetchPolicy: 'network-only'
      })
      .then(result => {
        result.data.getAllSubmissionsByUser.map(section => {
          console.log(section);
        });

        this.setState({
          loading: false,
          sections: result.data.getAllSubmissionsByUser
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({
          loading: false
        });
      });
  };

  componentDidMount() {
    this.setState({ loading: true });
    this.loadResults();
  }

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }
  capitalize = function (text) {
    return text.replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase(); });
  };
  render() {
    const { activeIndex, sections } = this.state;
    return (
      <div>
        <h2 className="uk-text-bold uk-text-center">Submissions</h2>
        <p className="uk-text-muted uk-margin-remove-top uk-margin-small-bottom uk-text-center">
          You can view your submissions here. Please visit the <button onClick={() => { window.location.href = '/assessment' }} class="uk-button uk-button-text verticalAlign"> Assesment Page </button> to edit or continue the assesment.
              </p>
        <Accordion fluid styled>
          {/* Begin Section Main */}
          {sections.map((index, section) => (
            <div key={index.section.id}>

              <Accordion.Title
                active={activeIndex === index}
                index={index}
                onClick={this.handleClick}
              >
                <Icon name="dropdown" />
                {index.section.sectionNumber} {"  "} {index.section.title}
              </Accordion.Title>
              <Accordion.Content active={activeIndex === index}>
                {
                  Object.keys(index.submission).map(question => (
                    <div>
                      <p class="uk-text-small uk-text-muted uk-margin-remove-bottom uk-margin-top">Primary Question</p>
                      <p class="uk-text-emphasis uk-margin-remove">{index.submission[question].primaryQuestion}</p>
                      <p class="uk-text-primary uk-margin-remove-top">
                        Answer:  {typeof (index.submission[question].primaryAnswer) === 'boolean' ? (
                          index.submission[question].primaryAnswer ? 'YES' : 'NO'
                        ) : (

                            index.submission[question].primaryArray ? (
                              index.submission[question].primaryArray.length > 0 ? (
                                //console.log(index.submission[question].secondaryArray)

                                index.submission[question].primaryArray.map((ans, i) => {

                                  return [<br />, Object.keys(ans).map((key, index) => (
                                    //console.log(key +"--"+ans[key])
                                    <div> {key.split(/(?=[A-Z])/).join(" ").replace(/^\w/, c => c.toUpperCase())}: {ans[key]} </div>
                                  ))]

                                })
                              ) : (
                                  null
                                )
                            ) : (
                                index.submission[question].primaryJson || !this.isEmpty(index.submission[question].primaryJson) ? (
                                  Object.keys(index.submission[question].primaryJson).map(function (key) {
                                    return <div> {key.split(/(?=[A-Z])/).join(" ").replace(/^\w/, c => c.toUpperCase())}:  {index.submission[question].primaryJson[key]} </div>
                                  })
                                ) : (
                                    index.submission[question].primaryAnswer
                                  )
                              )

                          )}
                      </p>

                      <p class="uk-text-small uk-text-muted uk-margin-remove-bottom uk-margin-top">Secondary Question</p>
                      <p class="uk-text-emphasis uk-margin-remove">{index.submission[question].secondaryQuestion}</p>
                      <p class="uk-text-primary uk-margin-remove-top">
                        Answer: {typeof (index.submission[question].secondaryAnswer) === 'boolean' ? (
                          index.submission[question].secondaryAnswer ? 'YES' : 'NO'
                        ) : (index.submission[question].secondaryAnswer)}
                        
                        
                        
                        {(

                            index.submission[question].secondaryArray ? (
                              index.submission[question].secondaryArray.length > 0 ? (
                                //console.log(index.submission[question].secondaryArray)

                                index.submission[question].secondaryArray.map((ans, i) => {

                                  return [<br />, Object.keys(ans).map((key, index) => (

                                    <div> {key.split(/(?=[A-Z])/).join(" ").replace(/^\w/, c => c.toUpperCase())}: {ans[key]}</div>
                                  ))]

                                })
                              ) : (
                                  null
                                )
                            ) : (
                                index.submission[question].secondaryJson || !this.isEmpty(index.submission[question].secondaryJson) ? (
                                  Object.keys(index.submission[question].secondaryJson).map(function (key) {
                                    return <div> {key.split(/(?=[A-Z])/).join(" ").replace(/^\w/, c => c.toUpperCase())}:  {index.submission[question].secondaryJson[key]} </div>
                                  })
                                ) : (
                                  null
                                    // index.submission[question].secondaryAnswer
                                  )
                              )


                          )}
                      </p>
                      <div>
                        {index.submission[question].fileUrl ? (
                          index.submission[question].fileUrl != "" ? (
                            // <img src={index.submission[question].fileUrl} style={{ width: "300px" }} />

                            <div className="file-upload-group" >
                              <Popup
                                trigger={<Button className="file-icon" circular icon="attach" />}
                                flowing
                                hoverable
                              >
                                <Grid centered divided columns={1}>
                                  <Grid.Column textAlign="center">
                                    <Button circular icon='download' onClick={() => { window.open(index.submission[question].fileUrl, '_blank'); }} />

                                  </Grid.Column>
                                </Grid>
                              </Popup>
                            </div>

                          ) : (
                              null
                            )
                        ) : (
                            null
                          )}
                      </div>
                      <hr />
                    </div>
                  ))
                }

              </Accordion.Content>
            </div>
          ))}
          {/* End Section Main */}
        </Accordion>
        {/* Begin Spinner */}
        {this.state.loading ? <div class="spinner"></div> : ""}
        {/* End Spinner */}
      </div>
    );
  }
}

export default withApollo(Progress);
