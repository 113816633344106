import gql from "graphql-tag";
import GraphQLJSON, { GraphQLJSONObject } from "graphql-type-json";

export const USER_LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        id
        email
        firstName
        lastName
        userType
        isActive
      }
    }
  }
`;

export const GET_GENERIC_USER_LIST = gql`
  query {
    getUserList {
      id
      firstName
      lastName
      email
      createdAt
    }
  }
`;

export const GENERIC_PROFILE = gql`
  query GetGenericProfile($userId: String) {
    getUserFullProfile(userId: $userId) {
      general {
        fullName
        country
        region
        year
      }
      user {
        firstName
        lastName
        email
        id
        createdAt
      }
    }
  }
`;

export const GET_MY_PROFILE = gql`
  query {
  getMyProfile{
    general {
        fullName
        country
        region
        year
      }
      user {
        firstName
        lastName
        email
        id
        createdAt
      }
  }
}
`

export const GET_USER_PROFILE = gql`
  query GetUserProfile($userId: String!) {
    getUserProfile(userId: $userId) {
      general {
        fullName
        country
        region
        year
      }
      user {
        firstName
        lastName
        email
        id
        createdAt
      }
    }
  }
`;

export const GET_MA_PROFILE_LIST = gql`
  query GetMyProfile($isActive: Boolean) {
    getMAUserList(isActive: $isActive) {
      user {
        id
        firstName
        lastName
        email
        gender
        isActive
        createdAt
        updatedAt
      }
      general {
        id
        updatedAt
        status
        fullName
        country
        region
        year
        isCompleted
        contactPersonFullName
        contactPersonEmail
        contactPersonSkype
      }
    }
  }
`;

export const ADD_MA_USER = gql`
  mutation CreateMA(
    $firstName: String!,
    $lastName: String,
    $email: String!,
    $password: String!,
    $fullName: String,
    $country: String,
    $region: String,
    $year: Int,
    $languagePreference: String,
  ) {
    addMAUser(
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      fullName: $fullName
      country: $country
      region: $region
      year: $year
      languagePreference: $languagePreference
    ) {
      user {
        firstName
        lastName
        email
      }
      general {
        fullName
        region
        country
        year
      }
    }
  }
`;


export const EDIT_USER = gql`
  mutation EditUser(
    $userId: String!,
    $firstName: String,
    $lastName: String,
    $email: String,
    $password: String,
    $isActive: Boolean,
    $fullName: String,
    $country: String,
    $region: String,
    $year: Int,
    $contactPersonFullName: String,
    $contactPersonEmail: String,
    $contactPersonSkype: String,
    $languagePreference: String,
  ) {
    editUser(
      userId: $userId
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      isActive: $isActive
      fullName: $fullName
      country: $country
      region: $region
      year: $year
      contactPersonFullName: $contactPersonFullName
      contactPersonEmail: $contactPersonEmail
      contactPersonSkype: $contactPersonSkype
      languagePreference: $languagePreference
    ) {
      user {
        firstName
        lastName
        email
        languagePreference
      }
      general {
        fullName
      }
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser(
    $userID: String!
  ) {
    deleteUser(
      userID: $userID
    ) {
      id
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation RequestResetPassword($email: String!) {
    resetPasswordRequest(email: $email) {
      status
      userId
      reason
      userEmail
      userName
      psscode
      languagePreference
    }
  }
`;

export const VERIFY_PASSCODE = gql`
  query VerifyPasscode($passcode: Int!, $userId: String!) {
    checkPasscode(passcode: $passcode, userId: $userId) {
      status
      userId
      reason
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation UpdatePassword($userID: String!, $password: String!) {
    updatePassword(userID: $userID, password: $password) {
      token
      user {
        id
        email
        firstName
        lastName
        userType
        isActive
      }
    }
  }
`;