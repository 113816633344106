import React, { Component } from "react";
import { Form, Dropdown, Message, Button } from "semantic-ui-react";
import { connect } from 'react-redux';
import { withApollo } from "react-apollo";
import { withRouter } from 'react-router-dom';
import { setFormStatus, setSuccessStatus } from '../../actions/user-actions';
import { ADD_MA_USER } from "../../queries/UserQueries";
import ReactPasswordStrength from 'react-password-strength';
import {
  FIRST_NAME,
  LAST_NAME,
  AUTH_TOKEN,
  USER_NAME
} from "../../constants/index";
import { _generate_email } from '../../middleware';
import zxcvbn from 'zxcvbn';
import { withNamespaces } from "react-i18next";

var countryOptions = [];
// central office ma name = International Planned Parenthood Federation
const countryList = {
  rg1: [
    { "key": "ctr1", "value": "Benin", "text": "Benin", "ma": "Association Béninoise pour la Promotion de la Famille" },
    { "key": "ctr2", "value": "Botswana", "text": "Botswana", "ma": "Botswana Family Welfare Association" },
    { "key": "ctr3", "value": "Burkina Faso", "text": "Burkina Faso", "ma": "Association Burkinabé pour le Bien-Etre Familial" },
    { "key": "ctr4", "value": "Burundi", "text": "Burundi", "ma": "Association Burundaise pour le Bien-Etre Familial" },
    { "key": "ctr5", "value": "Cameroon", "text": "Cameroon", "ma": "Cameroon National Association for Family Welfare" },
    { "key": "ctr6", "value": "Cape Verde", "text": "Cape Verde", "ma": "Associação Caboverdiana para a Proteção da Familia" },
    { "key": "ctr7", "value": "Central African Republic", "text": "Central African Republic", "ma": "Association Centrafricaine pour le Bien-Etre Familial" },
    { "key": "ctr8", "value": "Chad", "text": "Chad", "ma": "Association Tchadienne pour le Bien-Etre Familial" },
    { "key": "ctr9", "value": "Comoros", "text": "Comoros", "ma": "Association Comorienne pour le Bien-Etre de la Famille" },
    { "key": "ctr10", "value": "Republic of the Congo", "text": "Republic of the Congo", "ma": "Association Congolaise pour le Bien-Etre Familial" },
    { "key": "ctr11", "value": "Democratic Republic of the Congo", "text": "Democratic Republic of the Congo", "ma": "Association pour le Bien-Etre Familial/Naissances Désirables" },
    { "key": "ctr12", "value": "Cote d’Ivoire", "text": "Cote d’Ivoire", "ma": "Association Ivoirienne pour le Bien-Etre Familial" },
    { "key": "ctr13", "value": "Ethiopia", "text": "Ethiopia", "ma": "Family Guidance Association of Ethiopia" },
    { "key": "ctr14", "value": "Ghana", "text": "Ghana", "ma": "Planned Parenthood Association of Ghana" },
    { "key": "ctr15", "value": "Guinea-Bissau", "text": "Guinea-Bissau", "ma": "Associação Guineense para o Bem Estar Familiar" },
    { "key": "ctr16", "value": "Guinea-Conakry", "text": "Guinea-Conakry", "ma": "Association Guinéenne pour le Bien-Etre Familial" },
    { "key": "ctr17", "value": "Kenya", "text": "Kenya", "ma": "Family Health Options Kenya" },
    { "key": "ctr18", "value": "Lesotho", "text": "Lesotho", "ma": "Lesotho Planned Parenthood Association" },
    { "key": "ctr19", "value": "Liberia", "text": "Liberia", "ma": "Planned Parenthood Association of Liberia" },
    { "key": "ctr20", "value": "Madagascar", "text": "Madagascar", "ma": "Fianakaviana Sambatra" },
    { "key": "ctr21", "value": "Malawi", "text": "Malawi", "ma": "Family Planning Association of Malaw" },
    { "key": "ctr22", "value": "Mali", "text": "Mali", "ma": "Association Malienne pour la Protection et la Promotion de la Famille" },
    { "key": "ctr23", "value": "Mozambique", "text": "Mozambique", "ma": "Associação Moçambicana para Desenvolvimento da Família" },
    { "key": "ctr24", "value": "Namibia", "text": "Namibia", "ma": "Namibia Planned Parenthood Association" },
    { "key": "ctr25", "value": "Niger", "text": "Niger", "ma": "Association Nigérienne pour le Bien-Etre Familial" },
    { "key": "ctr26", "value": "Nigeria", "text": "Nigeria", "ma": "Planned Parenthood Federation of Nigeria" },
    { "key": "ctr27", "value": "Rwanda", "text": "Rwanda", "ma": "Association Rwandaise pour le Bien-Etre Familial" },
    { "key": "ctr28", "value": "Senegal", "text": "Senegal", "ma": "Association Sénégalaise pour le Bien-Étre Familial" },
    { "key": "ctr29", "value": "Sierra Leone", "text": "Sierra Leone", "ma": "Planned Parenthood Association of Sierra Leone" },
    { "key": "ctr30", "value": "Swaziland", "text": "Swaziland", "ma": "Family Life Association of Swaziland" },
    { "key": "ctr31", "value": "Tanzania", "text": "Tanzania", "ma": "Uzazi na Malezi Bora Tanzania" },
    { "key": "ctr32", "value": "Togo", "text": "Togo", "ma": "Association Togolaise pour le Bien-Etre Familial" },
    { "key": "ctr33", "value": "Uganda", "text": "Uganda", "ma": "Reproductive Health Uganda" },
    { "key": "ctr34", "value": "Zambia", "text": "Zambia", "ma": "Planned Parenthood Association of Zambia" },
    { "key": "ctr35", "value": "Angola", "text": "Angola", "ma": "Associação Angolana para o Bem Estar da Familia" },
    { "key": "ctr36", "value": "Mauritius", "text": "Mauritius", "ma": "Mauritius Family Planning & Welfare Association" },
    { "key": "ctr37", "value": "Republic of South Africa", "text": "Republic of South Africa", "ma": "Partners in Sexual Health" },
    { "key": "ctr38", "value": "Sao Tome & Principe", "text": "Sao Tome & Principe", "ma": "Associação Santomense para Promoção Familiar" },
    { "key": "ctr39", "value": "Seychelles", "text": "Seychelles", "ma": "Alliance of Solidarity for the Family" },
    { "key": "ctr40", "value": "South Sudan", "text": "South Sudan", "ma": "Reproductive Health Association of South Sudan" },
    { "key": "ctr41", "value": "Zimbabwe", "text": "Zimbabwe", "ma": "Zimbabwe National Family Planning Council" }
  ],
  rg2: [
    { "key": "ctr42", "value": "Algeria", "text": "Algeria", "ma": "Association Algérienne pour la Planification Familiale" },
    { "key": "ctr43", "value": "Bahrain", "text": "Bahrain", "ma": "Bahrain Reproductive Health Association" },
    { "key": "ctr44", "value": "Djibouti", "text": "Djibouti", "ma": "Association Djiboutienne pour l'Equilibre et la Promotion de la Famille" },
    { "key": "ctr45", "value": "Egypt", "text": "Egypt", "ma": "Egyptian Family Planning Association" },
    { "key": "ctr46", "value": "Jordan", "text": "Jordan", "ma": "Ahel Al Jabal Association" },
    { "key": "ctr47", "value": "Lebanon", "text": "Lebanon", "ma": "Lebanese Association for Family Health" },
    { "key": "ctr48", "value": "Mauritania", "text": "Mauritania", "ma": "Association Mauritanienne pour la Promotion de la Famille" },
    { "key": "ctr49", "value": "Morocco", "text": "Morocco", "ma": "Association Marocaine de Planification Familiale" },
    { "key": "ctr50", "value": "Palestine", "text": "Palestine", "ma": "Palestinian Family Planning and Protection Association" },
    { "key": "ctr51", "value": "Somaliland", "text": "Somaliland", "ma": "Somaliland Family Health Association" },
    { "key": "ctr52", "value": "Sudan", "text": "Sudan", "ma": "Sudan Family Planning Association" },
    { "key": "ctr53", "value": "Syria", "text": "Syria", "ma": "Syrian Family Planning Association" },
    { "key": "ctr54", "value": "Tunisia", "text": "Tunisia", "ma": "Association Tunisienne de la Santé de la Reproduction" },
    { "key": "ctr55", "value": "Iraq", "text": "Iraq", "ma": "Iraqi Reproductive Health & Family Planning Association" },
    { "key": "ctr56", "value": "Yemen", "text": "Yemen", "ma": "Yemeni Association for Reproductive Health" }
  ],
  rg3: [
    { "key": "ctr57", "value": "Cambodia", "text": "Cambodia", "ma": "Reproductive Health Association of Cambodia" },
    { "key": "ctr58", "value": "China", "text": "China", "ma": "China Family Planning Association" },
    { "key": "ctr59", "value": "Cook Islands", "text": "Cook Islands", "ma": "Cook Islands Family Welfare Association" },
    { "key": "ctr60", "value": "Fiji", "text": "Fiji", "ma": "Reproductive & Family Health Association of Fiji" },
    { "key": "ctr61", "value": "Hong Kong", "text": "Hong Kong", "ma": "Family Planning Association of Hong Kong" },
    { "key": "ctr62", "value": "Indonesia", "text": "Indonesia", "ma": "The Indonesian Planned Parenthood Association" },
    { "key": "ctr63", "value": "Japan", "text": "Japan", "ma": "Japan Family Planning Association, Inc." },
    { "key": "ctr64", "value": "Kiribati", "text": "Kiribati", "ma": "Kiribati Family Health Association" },
    { "key": "ctr65", "value": "Democratic People's Republic of Korea", "text": "Democratic People's Republic of Korea", "ma": "Korean Family Planning & Maternal Child Health Association of DPRK" },
    { "key": "ctr66", "value": "Republic of Korea", "text": "Republic of Korea", "ma": "Korea Population, Health and Welfare Association" },
    { "key": "ctr67", "value": "Malaysia", "text": "Malaysia", "ma": "Federation of Reproductive Health Associations, Malaysia" },
    { "key": "ctr68", "value": "Mongolia", "text": "Mongolia", "ma": "Mongolian Family Welfare Association" },
    { "key": "ctr69", "value": "New Zealand", "text": "New Zealand", "ma": "New Zealand Family Planning" },
    { "key": "ctr70", "value": "Philippines", "text": "Philippines", "ma": "The Family Planning Organization of the Philippines, Inc." },
    { "key": "ctr71", "value": "Samoa", "text": "Samoa", "ma": "Samoa Family Health Association" },
    { "key": "ctr72", "value": "Solomon Islands", "text": "Solomon Islands", "ma": "Solomon Islands Planned Parenthood Association" },
    { "key": "ctr73", "value": "Thailand", "text": "Thailand", "ma": "Planned Parenthood Association of Thailand" },
    { "key": "ctr74", "value": "Tonga", "text": "Tonga", "ma": "Tonga Family Health Association" },
    { "key": "ctr75", "value": "Tuvalu", "text": "Tuvalu", "ma": "Tuvalu Family Health Association" },
    { "key": "ctr76", "value": "Vanuatu", "text": "Vanuatu", "ma": "Vanuatu Family Health Association" },
    { "key": "ctr77", "value": "Vietnam", "text": "Vietnam", "ma": "Vietnam Family Planning Association" },
    { "key": "ctr78", "value": "Australia", "text": "Australia", "ma": "Family Planning Alliance Australia" },
    { "key": "ctr79", "value": "Laos", "text": "Laos", "ma": "Promotion of Family Health Association of Lao PDR" },
    { "key": "ctr80", "value": "Myanmar", "text": "Myanmar", "ma": "Myanmar Maternal and Child Welfare Association" },
    { "key": "ctr81", "value": "Papua New Guinea", "text": "Papua New Guinea", "ma": "Papua New Guinea Family Health Association" }
  ],
  rg4: [
    { "key": "ctr82", "value": "Albania", "text": "Albania", "ma": "Qêndra pêr Popullsinë dhe Zhvillimin (Center for Population and Development)" },
    { "key": "ctr83", "value": "Austria", "text": "Austria", "ma": "Österreichische Gesellschaft für Familienplanung" },
    { "key": "ctr84", "value": "Belgium", "text": "Belgium", "ma": "Belgische Federatie voor Seksuele en Reproductieve Gezondheid en Rechten (BFSRGR) / Fédération Belge pour la Santé et les Droits Sexuels et Reproductifs (FBSDSR)" },
    { "key": "ctr85", "value": "Bosnia and Herzegovina", "text": "Bosnia and Herzegovina", "ma": "Association XY" },
    { "key": "ctr86", "value": "Bulgaria", "text": "Bulgaria", "ma": "Bulgarian Family Planning and Sexual Health Association" },
    { "key": "ctr87", "value": "Cyprus", "text": "Cyprus", "ma": "Cyprus Family Planning Association" },
    { "key": "ctr88", "value": "Denmark", "text": "Denmark", "ma": "Sex & Samfund - The Danish Family Planning Association" },
    { "key": "ctr89", "value": "Estonia", "text": "Estonia", "ma": "Eesti Seksuaaltervise Liit / Estonian Sexual Health Association" },
    { "key": "ctr90", "value": "Finland", "text": "Finland", "ma": "Väestöliitto" },
    { "key": "ctr91", "value": "France", "text": "France", "ma": "Mouvement Français pour le Planning Familial" },
    { "key": "ctr92", "value": "Georgia", "text": "Georgia", "ma": "Association HERA XXI" },
    { "key": "ctr93", "value": "Germany", "text": "Germany", "ma": "PRO FAMILIA Bundesverband" },
    { "key": "ctr94", "value": "Ireland", "text": "Ireland", "ma": "Irish Family Planning Association" },
    { "key": "ctr95", "value": "Israel", "text": "Israel", "ma": "Israel Family Planning Association" },
    { "key": "ctr96", "value": "Kazakhstan", "text": "Kazakhstan", "ma": "Kazakhstan Association on Sexual and Reproductive Health" },
    { "key": "ctr97", "value": "Kyrgyzstan", "text": "Kyrgyzstan", "ma": "Reproductive Health Alliance of Kyrgyzstan" },
    { "key": "ctr98", "value": "Latvia", "text": "Latvia", "ma": "Papardes Zieds" },
    { "key": "ctr99", "value": "Lithuania", "text": "Lithuania", "ma": "Seimos Planavimo ir Seksualines Sveikatos Asociacija" },
    { "key": "ctr100", "value": "Netherlands", "text": "Netherlands", "ma": "Rutgers" },
    { "key": "ctr101", "value": "Norway", "text": "Norway", "ma": "Sex og Politikk" },
    { "key": "ctr102", "value": "Portugal", "text": "Portugal", "ma": "Associação Para o Planeamento da Família" },
    { "key": "ctr103", "value": "Republic of Macedonia", "text": "Republic of Macedonia", "ma": "Health Education and Research Association" },
    { "key": "ctr104", "value": "Republic of Serbia", "text": "Republic of Serbia", "ma": "Serbian Association for Sexual and Reproductive Rights" },
    { "key": "ctr105", "value": "Romania", "text": "Romania", "ma": "Societatea de Educatie Contraceptiva si Sexuala" },
    { "key": "ctr106", "value": "Slovak Republic", "text": "Slovak Republic", "ma": "Slovenská spolocnost pre plánované rodicovstvo a výchovu k rodi." },
    { "key": "ctr107", "value": "Spain", "text": "Spain", "ma": "Federación de Planificación Familiar de España" },
    { "key": "ctr108", "value": "Sweden", "text": "Sweden", "ma": "Riksförbundet för Sexuell Upplysning" },
    { "key": "ctr109", "value": "Switzerland", "text": "Switzerland", "ma": "SANTÉ SEXUELLE Suisse" },
    { "key": "ctr110", "value": "Tajikistan", "text": "Tajikistan", "ma": "Tajik Family Planning Alliance" },
    { "key": "ctr111", "value": "Ukraine", "text": "Ukraine", "ma": "NGO Women Health and Family Planning" },
    { "key": "ctr112", "value": "Greece", "text": "Greece", "ma": "Positive Voice" },
    { "key": "ctr113", "value": "Hungary", "text": "Hungary", "ma": "Patent Association Against Patriarchy" },
    { "key": "ctr114", "value": "Italy", "text": "Italy", "ma": "Associazione Italiana per l’Educazione Demografica" },
    { "key": "ctr115", "value": "Luxembourg", "text": "Luxembourg", "ma": "Mouvement Luxembourgeois pour le Planning Familial et l'Education Sexuelle asbl" },
    { "key": "ctr116", "value": "Montenegro", "text": "Montenegro", "ma": "CAZAS" }
  ],
  rg5: [
    { "key": "ctr117", "value": "Afghanistan", "text": "Afghanistan", "ma": "Afghan Family Guidance Association" },
    { "key": "ctr118", "value": "Bangladesh", "text": "Bangladesh", "ma": "Family Planning Association of Bangladesh" },
    { "key": "ctr119", "value": "India", "text": "India", "ma": "Family Planning Association of India" },
    { "key": "ctr120", "value": "Iran", "text": "Iran", "ma": "Family Health Association of Iran" },
    { "key": "ctr121", "value": "Maldives", "text": "Maldives", "ma": "Society for Health Education" },
    { "key": "ctr122", "value": "Nepal", "text": "Nepal", "ma": "Family Planning Association of Nepal" },
    { "key": "ctr123", "value": "Pakistan", "text": "Pakistan", "ma": "Rahnuma-Family Planning Association of Pakistan" },
    { "key": "ctr124", "value": "Sri Lanka", "text": "Sri Lanka", "ma": "Family Planning Association of Sri Lanka" },
    { "key": "ctr125", "value": "Bhutan", "text": "Bhutan", "ma": "Respect Educate Nurture Empower Women" },
  ],
  rg6: [
    { "key": "ctr126", "value": "Barbados", "text": "Barbados" },
    { "key": "ctr127", "value": "Caribbean Family Planning Affiliation", "text": "Caribbean Family Planning Affiliation" },
    { "key": "ctr128", "value": "Colombia", "text": "Colombia" },
    { "key": "ctr129", "value": "El Salvador", "text": "El Salvador" },
    { "key": "ctr130", "value": "Jamaica", "text": "Jamaica" },
    { "key": "ctr131", "value": "Mexico", "text": "Mexico" },
    { "key": "ctr132", "value": "Peru", "text": "Peru" },
    { "key": "ctr133", "value": "Suriname", "text": "Suriname" },
    { "key": "ctr134", "value": "Trinidad and Tobago", "text": "Trinidad and Tobago" },
    { "key": "ctr135", "value": "United States of America", "text": "United States of America" },
    { "key": "ctr136", "value": "Anguilla", "text": "Anguilla" },
    { "key": "ctr137", "value": "Antigua", "text": "Antigua" },
    { "key": "ctr138", "value": "Aruba", "text": "Aruba" },
    { "key": "ctr139", "value": "Bahamas", "text": "Bahamas" },
    { "key": "ctr140", "value": "Bermuda", "text": "Bermuda" },
    { "key": "ctr141", "value": "Curacao", "text": "Curacao" },
    { "key": "ctr142", "value": "Dominica", "text": "Dominica" },
    { "key": "ctr143", "value": "Grenada", "text": "Grenada" },
    { "key": "ctr144", "value": "Guadeloupe", "text": "Guadeloupe" },
    { "key": "ctr145", "value": "Martinique", "text": "Martinique" },
    { "key": "ctr146", "value": "St. Lucia", "text": "St. Lucia" },
    { "key": "ctr147", "value": "St. Vincent", "text": "St. Vincent" }
  ]
}

// rg6: [
//   { "key": "ctr126", "value": "Barbados", "text": "Barbados", "ma": "The Barbados Family Planning Association" },
//   { "key": "ctr127", "value": "Belize", "text": "Belize", "ma": "Belize Family Life Association" },
//   { "key": "ctr128", "value": "Bolivia", "text": "Bolivia", "ma": "Centro de Investigación, Educación y Servicios" },
//   { "key": "ctr129", "value": "Caribbean Family Planning Affiliation", "text": "Caribbean Family Planning Affiliation", "ma": "Caribbean Family Planning Affiliation Ltd" },
//   { "key": "ctr130", "value": "Chile", "text": "Chile", "ma": "Asociación Chilena de Protección de la Familia" },
//   { "key": "ctr131", "value": "Colombia", "text": "Colombia", "ma": "Asociación Pro-Bienestar de la Familia Colombiana" },
//   { "key": "ctr132", "value": "Costa Rica", "text": "Costa Rica", "ma": "Asociación Demográfica Costarricense" },
//   { "key": "ctr133", "value": "Dominican Republic", "text": "Dominican Republic", "ma": "Asociación Dominicana Pro-Bienestar de la Familia" },
//   { "key": "ctr134", "value": "Ecuador", "text": "Ecuador", "ma": "Centro Ecuatoriano para la Promoción y Acción de la Mujer de Guayaquil, Ecuador" },
//   { "key": "ctr135", "value": "El Salvador", "text": "El Salvador", "ma": "Asociación Demográfica Salvadoreña/Pro-Familia" },
//   { "key": "ctr136", "value": "Guatemala", "text": "Guatemala", "ma": "Asociación Pro-Bienestar de la Familia de Guatemala" },
//   { "key": "ctr137", "value": "Guyana", "text": "Guyana", "ma": "Guyana Responsible Parenthood Association" },
//   { "key": "ctr138", "value": "Haiti", "text": "Haiti", "ma": "Association pour la Promotion de la Famille Haïtienne" },
//   { "key": "ctr139", "value": "Honduras", "text": "Honduras", "ma": "Asociación Hondureña de Planificación de Familia" },
//   { "key": "ctr140", "value": "Jamaica", "text": "Jamaica", "ma": "Jamaica Family Planning Association" },
//   { "key": "ctr141", "value": "Mexico", "text": "Mexico", "ma": "Fundación Mexicana para la Planeación Familiar, A.C." },
//   { "key": "ctr142", "value": "Panama", "text": "Panama", "ma": "Asociación Panameña para el Planeamiento de la Familia" },
//   { "key": "ctr143", "value": "Paraguay", "text": "Paraguay", "ma": "El Centro Paraguayo de Estudios de Población" },
//   { "key": "ctr144", "value": "Peru", "text": "Peru", "ma": "Instituto Peruano de Paternidad Responsable" },
//   { "key": "ctr145", "value": "Puerto Rico", "text": "Puerto Rico", "ma": "PROFAMILIAS" },
//   { "key": "ctr146", "value": "Suriname", "text": "Suriname", "ma": "Stichting Lobi" },
//   { "key": "ctr147", "value": "Trinidad and Tobago", "text": "Trinidad and Tobago", "ma": "Family Planning Association of Trinidad and Tobago" },
//   { "key": "ctr148", "value": "United States of America", "text": "United States of America", "ma": "Planned Parenthood Federation of America" },
//   { "key": "ctr149", "value": "Uruguay", "text": "Uruguay", "ma": "Iniciativas Sanitarias" },
//   { "key": "ctr150", "value": "Venezuela", "text": "Venezuela", "ma": "Asociacion Civil de Planificacion Familiar" },
//   { "key": "ctr151", "value": "Anguilla", "text": "Anguilla", "ma": "Anguilla Family Planning Association-The Primary Health Care" },
//   { "key": "ctr152", "value": "Antigua", "text": "Antigua", "ma": "Antigua Planned Parenthood Association" },
//   { "key": "ctr153", "value": "Aruba", "text": "Aruba", "ma": "Foundation for the Promotion of Responsible Parenthood (Aruba)" },
//   { "key": "ctr154", "value": "Bahamas", "text": "Bahamas", "ma": "Bahamas Sexual Health and Rights Association" },
//   { "key": "ctr155", "value": "Bermuda", "text": "Bermuda", "ma": "Teen Services" },
//   { "key": "ctr156", "value": "Curacao", "text": "Curacao", "ma": "Foundation for the Promotion of Responsible Parenthood (FPRP)" },
//   { "key": "ctr157", "value": "Dominica", "text": "Dominica", "ma": "Dominica Planned Parenthood Association" },
//   { "key": "ctr158", "value": "Grenada", "text": "Grenada", "ma": "Grenada Planned Parenthood Association" },
//   { "key": "ctr159", "value": "Guadeloupe", "text": "Guadeloupe", "ma": "Association Guadeloupéenne pour le Planning Familial" },
//   { "key": "ctr160", "value": "Martinique", "text": "Martinique", "ma": "Association Martiniquaise pour I'Information et l'Orientation Familiales" },
//   { "key": "ctr161", "value": "St. Lucia", "text": "St. Lucia", "ma": "Saint Lucia Planned Parenthood Association" },
//   { "key": "ctr162", "value": "St. Vincent", "text": "St. Vincent", "ma": "St. Vincent Planned Parenthood Association" },
//   { "key": "ctr163", "value": "Argentina", "text": "Argentina", "ma": "Fundación Huésped" },
//   { "key": "ctr164", "value": "Brazil", "text": "Brazil", "ma": "Ciudadania Estudo Pesquisa Informaçao e Açao" },
//   { "key": "ctr165", "value": "Canada", "text": "Canada", "ma": "Action Canada for Sexual Health and Rights" },
//   { "key": "ctr166", "value": "Chile", "text": "Chile", "ma": "Movimiento por la Interrupción Legal del Embarazo" },
//   { "key": "ctr167", "value": "Haiti", "text": "Haiti", "ma": "Haiti Adolescent Girls Network" },
//   { "key": "ctr168", "value": "Cuba", "text": "Cuba", "ma": "Sociedad Cientifica Cubana Para el Desarrollo de la Familia" }
// ]

const regionOption = [
  { key: "rg1", value: "Africa", text: "Africa" },
  { key: "rg6", value: "Americas and the Caribbean", text: "Americas and the Caribbean" },
  { key: "rg2", value: "Arab World", text: "Arab World" },
  { key: "rg3", value: "East and Southeast Asia and Oceania", text: "East and Southeast Asia and Oceania" },
  { key: "rg4", value: "European Network", text: "European Network" },
  { key: "rg5", value: "South Asia", text: "South Asia" }
];

const yearOptions = [
  { key: "1940", value: 1940, text: "1940" },
  { key: "1941", value: 1941, text: "1941" },
  { key: "1942", value: 1942, text: "1942" },
  { key: "1943", value: 1943, text: "1943" },
  { key: "1944", value: 1944, text: "1944" },
  { key: "1945", value: 1945, text: "1945" },
  { key: "1946", value: 1946, text: "1946" },
  { key: "1947", value: 1947, text: "1947" },
  { key: "1948", value: 1948, text: "1948" },
  { key: "1949", value: 1949, text: "1949" },
  { key: "1950", value: 1950, text: "1950" },
  { key: "1951", value: 1951, text: "1951" },
  { key: "1952", value: 1952, text: "1952" },
  { key: "1953", value: 1953, text: "1953" },
  { key: "1954", value: 1954, text: "1954" },
  { key: "1955", value: 1955, text: "1955" },
  { key: "1956", value: 1956, text: "1956" },
  { key: "1957", value: 1957, text: "1957" },
  { key: "1958", value: 1958, text: "1958" },
  { key: "1959", value: 1959, text: "1959" },
  { key: "1960", value: 1960, text: "1960" },
  { key: "1961", value: 1961, text: "1961" },
  { key: "1962", value: 1962, text: "1962" },
  { key: "1963", value: 1963, text: "1963" },
  { key: "1964", value: 1964, text: "1964" },
  { key: "1965", value: 1965, text: "1965" },
  { key: "1966", value: 1966, text: "1966" },
  { key: "1967", value: 1967, text: "1967" },
  { key: "1968", value: 1968, text: "1968" },
  { key: "1969", value: 1969, text: "1969" },
  { key: "1970", value: 1970, text: "1970" },
  { key: "1971", value: 1971, text: "1971" },
  { key: "1972", value: 1972, text: "1972" },
  { key: "1973", value: 1973, text: "1973" },
  { key: "1974", value: 1974, text: "1974" },
  { key: "1975", value: 1975, text: "1975" },
  { key: "1976", value: 1976, text: "1976" },
  { key: "1977", value: 1977, text: "1977" },
  { key: "1978", value: 1978, text: "1978" },
  { key: "1979", value: 1979, text: "1979" },
  { key: "1980", value: 1980, text: "1980" },
  { key: "1981", value: 1981, text: "1981" },
  { key: "1982", value: 1982, text: "1982" },
  { key: "1983", value: 1983, text: "1983" },
  { key: "1984", value: 1984, text: "1984" },
  { key: "1985", value: 1985, text: "1985" },
  { key: "1986", value: 1986, text: "1986" },
  { key: "1987", value: 1987, text: "1987" },
  { key: "1988", value: 1988, text: "1988" },
  { key: "1989", value: 1989, text: "1989" },
  { key: "1990", value: 1990, text: "1990" },
  { key: "1991", value: 1991, text: "1991" },
  { key: "1992", value: 1992, text: "1992" },
  { key: "1993", value: 1993, text: "1993" },
  { key: "1994", value: 1994, text: "1994" },
  { key: "1995", value: 1995, text: "1995" },
  { key: "1996", value: 1996, text: "1996" },
  { key: "1997", value: 1997, text: "1997" },
  { key: "1998", value: 1998, text: "1998" },
  { key: "1999", value: 1999, text: "1999" },
  { key: "2000", value: 2000, text: "2000" },
  { key: "2001", value: 2001, text: "2001" },
  { key: "2002", value: 2002, text: "2002" },
  { key: "2003", value: 2003, text: "2003" },
  { key: "2004", value: 2004, text: "2004" },
  { key: "2005", value: 2005, text: "2005" },
  { key: "2006", value: 2006, text: "2006" },
  { key: "2007", value: 2007, text: "2007" },
  { key: "2008", value: 2008, text: "2008" },
  { key: "2009", value: 2009, text: "2009" },
  { key: "2010", value: 2010, text: "2010" },
  { key: "2011", value: 2011, text: "2011" },
  { key: "2012", value: 2012, text: "2012" },
  { key: "2013", value: 2013, text: "2013" },
  { key: "2014", value: 2014, text: "2014" },
  { key: "2015", value: 2015, text: "2015" },
  { key: "2016", value: 2016, text: "2016" },
  { key: "2017", value: 2017, text: "2017" },
  { key: "2018", value: 2018, text: "2018" },
  { key: "2019", value: 2019, text: "2019" }
];
const mapStateToProps = (state, ownProps) => {
  return {
    formErrorStatus: state.custom_reducer.formErrorStatus,
    formSuccessState: state.custom_reducer.formSuccessState,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setFormStatus: (status) => {
      dispatch(setFormStatus(status))
    },
    setSuccessStatus: (status) => {
      dispatch(setSuccessStatus(status))
    }
  }
}
class Register extends Component {
  constructor(props) {
    super(props);
    const { minStrength = 3, thresholdLength = 7 } = props;

    this.minStrength = typeof minStrength === 'number'
      ? Math.max(Math.min(minStrength, 4), 0)
      : 3;

    this.thresholdLength = typeof thresholdLength === 'number'
      ? Math.max(thresholdLength, 7)
      : 7;
    this.state = {
      firstName: '',
      lastName: '',
      email: "",
      maFullName: '',
      password: "",
      confPassword: '',
      country: '',
      region: '',
      regionId: null,
      year: '',
      loading: false,
      user_category: 0,
      strength: 0,
      minStrength: 3,
      thresholdLength: 7,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleSubmit = () => {
    this.setState({ loading: true });
    const { firstName, lastName, email, maFullName, password, confPassword, country, region, regionId, year, user_category } = this.state;
    const { t, i18n } = this.props;
    if (user_category == 2 && region === "") {
      this.setState({ loading: false });
      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message: localStorage.RAT_LANG == 'ar' ? (
          <div style={{ paddingRight: "15px" }}><span>{t("Please make sure you have filled out all required fields.")}</span></div>
        ) : (
          <div><span>{t("Please make sure you have filled out all required fields.")}</span></div>
        )
      });
      return;
    }

    if (password !== confPassword) {
      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message: localStorage.RAT_LANG == 'ar' ? (
          <div style={{ paddingRight: "15px" }}>
            <span>{t("Passwords do not match!")}</span>
          </div>
        ) : (
          <div>
            <span>{t("Passwords do not match!")}</span>
          </div>
        )
      });
      this.setState({ loading: false });
      return;
    }

    ////Password_length

    if(this.state.password.length <= this.state.thresholdLength ){
      this.props.setFormStatus({
          status: true,
          title: "Oops!",
          message: 
        localStorage.RAT_LANG=="ar" ? (
            <div style={{paddingRight:"20px"}}> { this.props.t('password_validity') } </div>
          ):(
            <div> { this.props.t('password_validity') } </div>
          )
          
                 
      });
      this.setState({ loading: false });
      return;
  }
  /////

    if (firstName.trim().length < 2 || lastName.trim().length < 2) {
      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message: localStorage.RAT_LANG == 'ar' ? (
          <div style={{ paddingRight: "15px" }}><span>{t("You are using 1 character for the name! Minimum 2 characters required.")}</span></div>
        ) : (
          <div><span>{t("You are using 1 character for the name! Minimum 2 characters required.")}</span></div>
        )
      });
      this.setState({ loading: false });
      return;
    }
    if (!firstName == '' && !lastName == '' && !email == '' && !maFullName == '' && !password == '' && !confPassword == '') {
      this._createUser();
    } else {
      console.log('no');
      this.setState({
        loading: false,
      });
      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message: localStorage.RAT_LANG == 'ar' ? (
          <div style={{ paddingRight: "15px" }} ><span>{t("Please make sure you have filled out all required fields.")}</span></div>
        ) : (
          <div><span>{t("Please make sure you have filled out all required fields.")}</span></div>
        )
      });
    }
    // this._createUser();
  };

  onUserCategoryChanged = event => {
    this.setState({
      user_category: event.target.value,
      country: '',
      region: '',
      year: '',
      maFullName: ''
    });
    switch (event.target.value) {
      case "1":
        this.setState({
          maFullName: "International Planned Parenthood Federation",
        });
        break;
      case "2":
        this.setState({
          maFullName: "NA",
        });
        break;
      default:
        break;
    }

  }

  handleChange = event => {
    // console.log(event)
    this.setState({ [event.target.id]: event.target.value });
  };
  passwordChange = (event) => {
    this.setState({
      password: event.target.value,
      strength: zxcvbn(event.target.value).score,
    })
  };

  _handleYearChange = (e, { value }) => this.setState({ year: value });

  _handleRegionChange = async (e, { value }) => {
    countryOptions = [];
    const selectedOption = regionOption.filter(function (item) {
      return item.value == value;
    });
    this.setState({ region: value });
    const key = selectedOption[0].key;
    countryOptions = countryList[key];
    countryOptions.sort((a, b) => (a['text'] || "").toString().localeCompare((b['text'] || "").toString()));
    countryOptions.push({ "key": "ctr170", "value": "Other", "text": "Other", "ma": "Other" });
  };

  _handleCountryChange = (e, { value }) => {
    const allcountry = [...countryList.rg1, ...countryList.rg2, ...countryList.rg3, ...countryList.rg4, ...countryList.rg5, ...countryList.rg6];
    allcountry.push({ "key": "ctr170", "value": "Other", "text": "Other" });
    const selectedOption = allcountry.filter(function (item) {
      return item.value == value;
    });
    if (selectedOption[0] !== undefined) {
      this.setState({ maFullName: selectedOption[0].ma });
    } else {
      this.setState({ maFullName: "Other" });
    }

    this.setState({ country: value });
  };

  _createUser = async () => {

    this.props.setFormStatus({ status: false, title: '', message: '' });
    this.props.setSuccessStatus({ status: false, title: '', message: '' });
    const { t, i18n } = this.props;
    this.props.client.mutate({
      mutation: ADD_MA_USER,
      variables: {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        password: this.state.password,
        fullName: this.state.maFullName,
        country: this.state.country === '' ? 'NA' : this.state.country,
        region: this.state.region === '' ? 'NA' : this.state.region,
        year: parseInt(this.state.year),
        languagePreference: localStorage.RAT_LANG,
      }
    }).then(result => {
      const ADDED_USER = result.data.addMAUser;
      const FULL_NAME = ADDED_USER.general.fullName;
      const REGION = ADDED_USER.general.region;
      const COUNTRY = ADDED_USER.general.country;
      const YEAR = ADDED_USER.general.year === null ? "NA" : ADDED_USER.general.year;
      const FIRST_NAME = ADDED_USER.user.firstName;
      const LAST_NAME = ADDED_USER.user.lastName;
      const USER_EMAIL = ADDED_USER.user.email;
      const LABEL_USER = (FULL_NAME === "International Planned Parenthood Federation") ? "Organization" : (FULL_NAME === "NA") ? "Regional Office Name" : "Full name of the Member Association (MA)";
      const personalizations = {
        "personalizations": [
          {
            "to": [
              {
                "email": USER_EMAIL,
                "name": FULL_NAME
              }
            ],
            "bcc": [
              // {
              //   "email": "manjula@fpasrilanka.org",
              //   "name": "Manjula Chathuranga Liyanage"
              // },
              // {
              //   "email": "chameera.lakshitha212@gmail.com",
              //   "name": "Lakshitha"
              // },
              // {
              //   "email": "rishi@fpasrilanka.org",
              //   "name": "Rishikeshan Thiyagaraja"
              // }
            ],
            "dynamic_template_data": {
              "name": FIRST_NAME
            },
            "subject": "SE Readiness Assessment Tool – Your request is under review"
          }
        ],
        // COMMENT KEY - C-01/06/2021
        // "template_id": "d-e77bb2495cf146928dc71d3a074a2b6d"
        "template_id": localStorage.RAT_LANG === 'en' ? "d-e77bb2495cf146928dc71d3a074a2b6d"
          : localStorage.RAT_LANG === 'ar' ? "d-d15113272f6248b4a0ce7cb6fcf6b32f"
            : localStorage.RAT_LANG === 'fr' ? "d-430c89ad6d494f48882f4f80d8b682c4"
              : localStorage.RAT_LANG === 'sp' ? "d-f15b9a1c24f6434bb3881a54f62e3531"
                : "d-e77bb2495cf146928dc71d3a074a2b6d"
      };
      const new_user_personalizations = {
        "personalizations": [
          {
            "to": [
              // {
              //   "email": "rishi@fpasrilanka.org",
              //   "name": "Rishikeshan Thiyagaraja"
              // }
            ],
            "cc": [
              // {
              //   "email": "manjula@fpasrilanka.org",
              //   "name": "Manjula Chathuranga Liyanage"
              // },
              // {
              //   "email": "chameera.lakshitha212@gmail.com",
              //   "name": "Lakshitha"
              // }
            ],
            "subject": "SE Readiness Assessment Tool – A new user has signed up – Review required"
          }
        ],
        "content": [
          {
            "type": "text/html",
            "value": "<h2>Hello, Admin</h2><p>A new user has signed up for IPPF's Social Enterprise Readiness Assessment Tool.</p><p>User details as follows -</p><b>First Name: </b>" + FIRST_NAME + "<br><b>Last Name: </b>" + LAST_NAME + "<br><b>" + LABEL_USER + ": </b>" + FULL_NAME + "<br><b>Official Email: </b>" + USER_EMAIL + "<br><b>IPPF Region: </b>" + REGION + "<br><b>Country: </b>" + COUNTRY + "<br><b>Year of establishment: </b>" + YEAR + "<br><p><a href='https://ra.seippf.org/users'>Click here</a> here to approve/decline the request.</p><p style='color::#888888'><i>Note: This is a system generated notice. Please do not reply.</i></p>"
          }
        ]
      };
      try {
        const user_data = _generate_email('https://ra.seippf.org/mail/mail.php', new_user_personalizations);
        const data = _generate_email('https://ra.seippf.org/mail/mail.php', personalizations);
        this.setState({
          loading: false,
        })
        this.props.setSuccessStatus({
          status: true,
          title: "",
          message:
            <span>{t("Thank you for registering to access the Readiness Assessment Tool. IPPF’s Social Enterprise Hub will review your request and revert soon. Do check your Email for notifications related to this account creation.")}</span>
        });
        this.props.history.push('/welcome');
      } catch (error) {
        console.error(error);
      }
    }).catch(error => {
      console.log(error);
      if (error) {
        this.setState({
          loading: false,
        });
        this.props.setFormStatus({
          status: true,
          title: "Oops!",
          message: localStorage.RAT_LANG == 'ar' ? (
            <div style={{paddingRight:"15px"}}><span>{t("A User ID already exists for this email address.")}</span></div>
          ) : (
            <div><span>{t("A User ID already exists for this email address.")}</span></div>
          )
        });
      }
    });
  }

  render() {
    const { firstName, lastName, email, maFullName, password, confPassword, country, region, regionId, year, user_category, strength } = this.state;
    const { formErrorStatus, formSuccessState, validator, children, t, i18n, ...restProps } = this.props;
    const passwordLength = password.length;
    const passwordStrong = strength >= this.minStrength;
    const passwordLong = passwordLength > this.thresholdLength;

    const counterClass = ['badge badge-pill', passwordLong ? passwordStrong ? 'badge-success' : 'badge-warning' : 'badge-danger'].join(' ').trim();
    const strengthClass = ['strength-meter mt-2', passwordLength > 0 ? 'visible' : 'invisible'].join(' ').trim();
    return (
      <div
        uk-height-viewport="offset-top: true; offset-bottom: true"
        className="uk-flex uk-flex-middle"
        class="uk-margin"
        uk-scrollspy="target: > .reg-holder; cls:uk-animation-slide-bottom-small; delay: 200"
        style={{ backgroundColor: "#fff" }}
      >
        <div className="uk-width-1-2@m uk-width-1-2@s uk-margin-auto reg-holder">
          <div className="uk-padding extra-top-padding">
            <Form success error onSubmit={this.handleSubmit}>
              <h2 className="uk-text-bold uk-text-center">{t("Create Your Account")}</h2>
              <p className="uk-text-muted uk-margin-remove-top uk-margin-small-bottom uk-text-center">
                {t("Please complete all the fields below and click on")} <b>‘{t("Register")}’</b>
              </p>
              <p className="uk-text-muted uk-margin-remove-top uk-margin-small-bottom uk-text-center">
                {t("Keep a note of the password you enter")}.
              </p>
              <div className="uk-form-label uk-margin-medium-top">{t("User Category")}<i style={styles.icon} className="icon red">*</i></div>
              <div className="uk-inline">
                <label style={{ paddingRight: "15px" }}> <input required className="uk-radio" onChange={this.onUserCategoryChanged} type="radio" id="user_category" name="user_category" value="1" /> {t("Central Office")} </label>
                <label style={{ paddingRight: "15px" }}> <input className="uk-radio" onChange={this.onUserCategoryChanged} type="radio" id="user_category" name="user_category" value="2" /> {t("Regional Office")} </label>
                <label> <input className="uk-radio" onChange={this.onUserCategoryChanged} type="radio" id="user_category" name="user_category" value="3" /> {t("Member Association")} </label>
              </div>
              {(user_category === "2" || user_category === "3") ? (
                <div>
                  <div className="uk-form-label">{t("IPPF Region")}<i style={styles.icon} className="icon red">*</i></div>
                  <div className="uk-margin-bottom">
                    <Dropdown
                      onChange={this._handleRegionChange}
                      id="region"
                      name="region"
                      selection
                      value={this.state.region}
                      placeholder={t("Select your IPPF Region")}
                      options={regionOption}
                      style={{ width: "350px" }}
                    />
                  </div>
                </div>
              ) : ("")}
              {(user_category === "3") ? (
                <div>
                  <div className="uk-form-label">{t("Country")}<i style={styles.icon} className="icon red">*</i></div>
                  <div className="uk-margin-bottom">
                    <Dropdown
                      onChange={this._handleCountryChange}
                      name="country"
                      id="country"
                      selection
                      value={this.state.country}
                      placeholder={t("Select your country")}
                      options={countryOptions}
                      style={{ width: "350px" }}
                    />
                  </div>
                </div>
              ) : ("")}

              {(user_category === "3") ? (
                <div>
                  <div className="uk-form-label">{t("Year of establishment")}</div>
                  <div className="uk-margin-bottom">
                    <Dropdown
                      onChange={this._handleYearChange}
                      options={yearOptions}
                      placeholder={t("Select Year")}
                      selection
                      value={this.state.year}
                      style={{ width: "350px" }}
                    />
                  </div>
                </div>
              ) : ("")}
              <div className="uk-form-label">{t("First Name")}<i style={styles.icon} className="icon red">*</i></div>
              <div>
                <input
                  name="firstName"
                  id="firstName"
                  value={this.state.firstName}
                  className="uk-input uk-form-width-large"
                  placeholder=""
                  type="text"
                  onChange={this.handleChange}
                  required
                // minlength="2"
                />
              </div>

              <div className="uk-form-label">{t("Last Name")}<i style={styles.icon} className="icon red">*</i></div>
              <div>
                <input
                  name="lastName"
                  id="lastName"
                  value={this.state.lastName}
                  className="uk-input uk-form-width-large"
                  placeholder=""
                  type="text"
                  onChange={this.handleChange}
                  required
                // minlength="2"
                />
              </div>
              {(user_category === "1") ? (
                <div>
                  <div className="uk-form-label">{t("Organization")}<i style={styles.icon} className="icon red">*</i></div>
                  <div>
                    <input
                      name="maFullName"
                      id="maFullName"
                      value={this.state.maFullName}
                      className="uk-input uk-form-width-large"
                      placeholder=""
                      type="text"
                      onChange={this.handleChange}
                      required
                      readonly="true"
                    />
                  </div>
                </div>
              ) : ("")}
              {(user_category === "3") ? (
                <div>
                  <div className="uk-form-label">{t("Full name of the Member Association (MA)")}<i style={styles.icon} className="icon red">*</i></div>
                  <div>
                    <input
                      name="maFullName"
                      id="maFullName"
                      value={this.state.maFullName}
                      className="uk-input uk-form-width-large"
                      placeholder=""
                      type="text"
                      onChange={this.handleChange}
                      required
                    />
                  </div>
                </div>
              ) : ("")}
              <div className="uk-form-label">{t("Official Email Address")}<i style={styles.icon} className="icon red">*</i></div>
              <div>
                <input
                  name="email"
                  id="email"
                  value={this.state.email}
                  className="uk-input uk-form-width-large"
                  placeholder=""
                  type="email"
                  onChange={this.handleChange}
                  required
                />
              </div>

              <div className="uk-form-label">{t("Password")}<i style={styles.icon} className="icon red">*</i></div>
              <Form.Field {...restProps} validator={this.validatePasswordStrong}  >
                <span style={{ fontSize: "14px" }} className="d-block form-hint">{t("To conform with our Strong Password policy, you are required to use a sufficiently strong password. Password must be more than 7 characters.")}</span>
                {children}
                <div className={strengthClass}>
                  <div className="strength-meter-fill" data-strength={strength}></div>
                </div>
              </Form.Field>
              <div className="uk-margin-bottom">
                <input
                  name="password"
                  id="password"
                  value={this.state.password}
                  className="uk-input uk-form-width-large"
                  placeholder={t("Password")}
                  type="password"
                  onChange={this.passwordChange}
                  required
                />
              </div>
              <div className="position-absolute password-count mx-3">
                <span className={counterClass}>{passwordLength ? passwordLong ? `${this.thresholdLength}+` : passwordLength : ''}</span>
              </div>

              <div className="uk-form-label">{t("Confirm Password")}<i style={styles.icon} className="icon red">*</i></div>
              <div className="uk-margin-bottom">
                <input
                  label="Confirm Password"
                  name="confPassword"
                  id="confPassword"
                  value={this.state.confPassword}
                  className="uk-input uk-form-width-large"
                  placeholder={t("Confirm Password")}
                  type="password"
                  onChange={this.handleChange}
                  required
                />
              </div>


              <div></div>
              <input
                className="uk-button uk-button-success uk-width-1-1  uk-margin"
                type="submit"
                value={t("Register")}
                loading={this.state.loading}
                disabled={this.state.loading ? (true) : (false)}
              // disabled={!firstName || !lastName || !email || !maFullName || !password || !confPassword || !country || !region || !year }          
              />

              {(formErrorStatus.status) ? (
                <div className="danger_alert">{formErrorStatus.message}</div>
                // <Message
                //   error
                //   content={formErrorStatus.message}
                // />
              ) : ((formSuccessState.status) ? (
                <div className="success_alert">{formSuccessState.message}</div>
                // <Message
                //   success
                //   content={formSuccessState.message}
                // />
              ) : (''))}
            </Form>
            <p>
              {t("Already Registered?")} <button onClick={() => { window.location.href = '/' }} class="uk-button align uk-button-text verticalAlign"> {t("Log in Here")} </button>
            </p>
            {this.state.loading ? (<div class="spinner"></div>) : ("")}
          </div>

        </div>
      </div>
    );
  }
}
const styles = {
  icon: {
    width: "0px"
  }
};

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(Register))));
