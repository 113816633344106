import React, { Component } from "react";
import SectionLoader from "../../../../generic/assesment/SectionLoader";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import { GET_SECTION_TWO } from "../../../../../queries/SectionQueries";
import {
    SUBMIT_SECTION_1,
    GET_SECTION_1,
    GET_EDIT_SECTION_SUBMISSION,
    UPDATE_SECTION_1,
    GET_ALL_EDIT_SUBMMISSIONS,
    GET_USER_SCORE,
    GET_SUBMISSION
} from "../../../../../queries/SubmissionQueries";
import { setFormStatus, setSuccessStatus } from '../../../../../actions/user-actions';
import { connect } from 'react-redux';
import { Form, Button, TextArea, Message, Progress } from "semantic-ui-react";
import sectionOneQuestions from '../../../../questions/section1_questions.json';
import moment from "moment";
import { GENERIC_PROFILE } from "../../../../../queries/UserQueries";
import { withNamespaces } from "react-i18next";

const mapStateToProps = (state, ownProps) => {
    return {
        formErrorStatus: state.custom_reducer.formErrorStatus,
        formSuccessState: state.custom_reducer.formSuccessState,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setFormStatus: (status) => {
            dispatch(setFormStatus(status))
        },
        setSuccessStatus: (status) => {
            dispatch(setSuccessStatus(status))
        }
    }
}

class ViewSection2 extends Component {
    constructor(props) {
        super(props);
        this.props.setFormStatus({ status: false, title: '', message: '' });
        this.props.setSuccessStatus({ status: false, title: '', message: '' });
        const proObj = this.props.location.pathname.split('/');
        this.state = {
            submissionId: this.props.location.state.submissionId,
            submissionType: this.props.location.state.submissionType,
            assessmentName: this.props.location.state.assessmentName,
            userId: proObj[2],
            loading: true,
            spinner: false,
            saveNContinue: false,
            sectionEmpty: true,
            section: {
                id: "",
                sectionNumber: "",
                title: "",
                subTitle: "",
                description: "",
                sequence: "",
                createdAt: "",
                updatedAt: ""
            },
            // question1: {
            //     showFollowupQuestion: false,
            //     primaryQuestion:
            //         "Does your domestic law allow NGOs to carry out income generating activities (to support the social mission)?",
            //     primaryAnswer: false,
            //     secondaryQuestion: "If Yes, what kind of provisions are in place?",
            //     secondaryAnswer: "",
            //     weightFactor: "0.4",
            //     uploads: [],
            //     validated: true,
            //     isScore: false,
            //     feedBack: "",
            //     score: "",
            // },
            // question2: {
            //     showFollowupQuestion: false,
            //     primaryQuestion:
            //         "Does the constitution of your organization place explicit constraints or controls on income generating activities?",
            //     primaryAnswer: "",
            //     secondaryQuestion: "",
            //     secondaryAnswer: "",
            //     weightFactor: "-0.4",
            //     uploads: [],
            //     validated: false,
            //     isScore: true,
            //     feedBack: "",
            //     score: "",
            // },
            // question3: {
            //     showFollowupQuestion: false,
            //     primaryQuestion:
            //         "Does the constitution of your organization need to be amended to allow income generating activities?",
            //     primaryAnswer: "",
            //     secondaryQuestion: "",
            //     secondaryAnswer: "",
            //     weightFactor: "-0.4",
            //     uploads: [],
            //     validated: false,
            //     isScore: true,
            //     feedBack: "",
            //     score: "",
            // },
            // question4: {
            //     showFollowupQuestion: false,
            //     primaryQuestion:
            //         "Is the Governing Board of your organization representative of multi-disciplinary fields?",
            //     primaryAnswer: false,
            //     secondaryQuestion: "If Yes, add all the applicable Board Members details",
            //     secondaryAnswer: "",
            //     secondaryJson: {},
            //     secondaryArray: [{ fullName: "", professionalTitle: "", roleOnTheBoard: "", typeOfExpertise: "" }],
            //     weightFactor: "0.2",
            //     uploads: [],
            //     validated: true,
            //     isScore: false,
            //     feedBack: "",
            //     score: "",
            // },
            // question5: {
            //     showFollowupQuestion: false,
            //     primaryQuestion:
            //         "Does at least one Board member have knowledge of business management?",
            //     primaryAnswer: false,
            //     secondaryQuestion: "If Yes, specify the exact advisory capacity of the Board Members who have business management knowledge and any evidence of their business experience (including any academic qualification)?",
            //     secondaryAnswer: "",
            //     secondaryJson: {},
            //     weightFactor: "0.2",
            //     uploads: [],
            //     validated: true,
            //     isScore: false,
            //     feedBack: "",
            //     score: "",
            // },
            // question6: {
            //     showFollowupQuestion: false,
            //     primaryQuestion:
            //         "Is there consensus or a documented agreement (i.e. meeting minutes) between your organization’s management and Board on the need to be self-sufficient financially (sustainability)?",
            //     primaryAnswer: false,
            //     secondaryQuestion: "If Yes, does your organization have a Resource Mobilization plan/strategy?",
            //     secondaryAnswer: "",
            //     secondaryJson: {},
            //     weightFactor: "0.4",
            //     uploads: [],
            //     validated: true,
            //     isScore: false,
            //     feedBack: "",
            //     score: "",
            // },
            ...sectionOneQuestions,
            multiquestions: [{ fullName: "", professionalTitle: "", roleOnTheBoard: "", typeOfExpertise: "" }],
            sectionFeedBack: "",
            validation_arr: [],
            progress: 0,
            result_status: "Not Ready",
            result_color: "red",
            score: 0,
            user: {
                firstName: "...",
                lastName: "...",
                email: "...",
                id: "...",
                createdAt: "...",
            },
            general: {
                fullName: "...",
                country: "...",
                region: "...",
                year: "...",
            },
        };
        props.setFormStatus({ status: false, title: "", message: "" });
        props.setSuccessStatus({ status: false, title: "", message: "" });
        this._getSection2();
        this._getSection1Submissions();

        this.get_all_submissions().then(submission => {
            submission.map(item => {
                Object.keys(item.submission).map(key => {
                    if (item.submission[key]['validated'] !== undefined && item.submission[key]['validated'] === true) {
                        this.state.validation_arr.push(item.submission[key]['validated']);
                    }
                })
            });

            const percent_progress = parseFloat((this.state.validation_arr.length / 48) * 100).toFixed(0)
            this.setState({
                progress: percent_progress
            });
        });
        this.get_submission().then(result => {
            this.setState({ score: result.finalScore, result_status: result.seReadinessStatus, result_color: result.scoreDescriptionColor, loading: false });
        }).catch(error => {
            console.log(error);
            this.setState({ loading: false });
        });
        // this.get_user_score().then(result => {

        //     const high_impact = result['0.4']['weightedValue'] === "NaN" ? parseFloat(0) : parseFloat(result['0.4']['weightedValue']);
        //     const tot_others = (result['0.3']['weightedValue'] === "NaN" ? parseFloat(0) : parseFloat(result['0.3']['weightedValue'])) + (result['0.2']['weightedValue'] === "NaN" ? parseFloat(0) : parseFloat(result['0.2']['weightedValue'])) + (result['0.1']['weightedValue'] === "NaN" ? parseFloat(0) : parseFloat(result['0.1']['weightedValue']));
        //     const tot_marks = tot_others + high_impact;
        //     this.setState({ score: tot_marks });
        //     if (high_impact === 40 && tot_others >= 20) {
        //         this.setState({ result_status: "Ready", result_color: "RGB(146,208,80)", loading: false });
        //     } else if (high_impact > 0 && tot_marks >= 60) {
        //         this.setState({ result_status: "Partially Ready.Additional development required in certain areas.", result_color: "RGB(255,192,0)", loading: false });
        //     }
        // }).catch(error => {
        //     console.log(error);
        //     this.setState({ loading: false });
        // });
        this._getGenericProfile();
    }
    _getGenericProfile = () => {
        const { userId } = this.state;
        this.props.client.query({
            query: GENERIC_PROFILE,
            variables: { userId: userId }
        }).then(result => {
            console.log(result)
            this.setState({
                user: { ...result.data.getUserFullProfile.user },
                general: { ...result.data.getUserFullProfile.general },
                loading: false,
            })
        }).catch(error => {
            console.log(error);
            this.setState({ loading: false });
        });
    }
    get_user_score = async () => {
        const { userId, submissionId } = this.state
        const result = await this.props.client.query({
            query: GET_USER_SCORE,
            variables: { userId: userId, submissionId: submissionId },
            fetchPolicy: 'network-only'
        });
        return result.data.getUserScore;
    };
    get_submission = async () => {
        const { submissionId, submissionType } = this.state
        const result = await this.props.client.query({
            query: GET_SUBMISSION,
            variables: { submissionId: submissionId, submissionType: submissionType },
            fetchPolicy: 'network-only'
        });
        return result.data.getSubmission;
    };

    get_all_submissions = async () => {
        const { submissionId, submissionType } = this.state;
        console.log(submissionId)
        const result = await this.props.client.query({
            query: GET_ALL_EDIT_SUBMMISSIONS,
            variables: { submissionId: submissionId },
            fetchPolicy: 'network-only'
        });
        return result.data.getAllEditSubmissionsByUser;
    };


    componentDidMount() {
        window.scrollTo(0, 0)
        setTimeout(() => {
            this.setState({
                loading: false
            });
        }, 2000);
    }

    _getSection2 = () => {
        this.props.client
            .query({
                query: GET_SECTION_TWO
            })
            .then(result => {

                this.setState({
                    section: { ...result.data.getSection1 }
                });
            })
            .catch(error => {
                console.log(error);
            });
    };

    _getSection1Submissions = () => {
        const { submissionId, submissionType } = this.state;
        this.props.client
            .query({
                query: GET_EDIT_SECTION_SUBMISSION,
                variables: { sectionId: "ck09iwgjk6ftg0b53chrw6bkf", submissionId: submissionId },
                fetchPolicy: 'network-only'
            })
            .then(result => {
                console.log(result);
                if (
                    result.data.getEditSectionSubmission === null ||
                    result.data.getEditSectionSubmission.length === 0
                ) {
                    console.log("there is nothing here");
                } else {
                    this.setState({
                        question1: result.data.getEditSectionSubmission.submission.question1 ? { ...result.data.getEditSectionSubmission.submission.question1, weightFactor: this.state.question1.weightFactor } : this.state.question1,
                        question2: result.data.getEditSectionSubmission.submission.question2 ? { ...result.data.getEditSectionSubmission.submission.question2, weightFactor: this.state.question2.weightFactor } : this.state.question2,
                        question3: result.data.getEditSectionSubmission.submission.question3 ? { ...result.data.getEditSectionSubmission.submission.question3, weightFactor: this.state.question3.weightFactor } : this.state.question3,
                        question4: result.data.getEditSectionSubmission.submission.question4 ? { ...result.data.getEditSectionSubmission.submission.question4, weightFactor: this.state.question4.weightFactor } : this.state.question4,
                        question5: result.data.getEditSectionSubmission.submission.question5 ? { ...result.data.getEditSectionSubmission.submission.question5, weightFactor: this.state.question5.weightFactor } : this.state.question5,
                        question6: result.data.getEditSectionSubmission.submission.question6 ? { ...result.data.getEditSectionSubmission.submission.question6, weightFactor: this.state.question6.weightFactor } : this.state.question6,
                        sectionFeedBack: result.data.getEditSectionSubmission.submission.sectionFeedBack ? result.data.getEditSectionSubmission.submission.sectionFeedBack : this.state.sectionFeedBack,
                        sectionEmpty: false,
                    });
                }
                console.log(this.state);
            })
            .catch(error => {
                console.log(error);
            });
    };

    _goBack = () => {
        //this.props.history.goBack();
        const { submissionId, submissionType, userId, assessmentName } = this.state;
        this.props.history.push({ pathname: '/users/'.concat(userId, '/view/section1'), state: { submissionId: submissionId, submissionType: submissionType, assessmentName: assessmentName } });
        // window.location.href = "/assessment/new/section1";
    };

    // Question 1 Stuff

    _question1Followup = state => {
        state
            ? this.setState({
                question1: {
                    ...this.state.question1,
                    showFollowupQuestion: true,
                    primaryAnswer: true,
                    validated: this.state.question1.secondaryAnswer.trim() === "" ? false : true

                }
            })
            : this.setState({
                question1: {
                    ...this.state.question1,
                    showFollowupQuestion: false,
                    primaryAnswer: false,
                    validated: true
                }
            });
    };

    _question1Onchange = event => {
        this.setState({
            question1: {
                ...this.state.question1,
                [event.target.id]: event.target.value,
                validated: (event.target.value === "" && this.state.question1.primaryAnswer === true) ? false : true
            }
        });
    };

    _composeQ1Answers = () => {
        const answers = {
            primaryQuestion:
                "Does your domestic laws allow income generating activities by NGOs?",
            primaryAnswer: this.state.question1.showFollowupQuestion,
            hasFollowup: this.state.question1.showFollowupQuestion,
            secondaryQuestion: "If Yes, what kind of provisions are in place?",
            secondaryAnswer: this.state.question1.secondaryAnswer,
            uploads: []
        };
        const submission = {
            question1: answers
        };
        this.setState({
            question1: answers
        });
        return submission;
    };

    // Question2 stuff
    _question2Onchange = event => {
        this.setState({
            question2: {
                ...this.state.question2,
                primaryAnswer: event.target.value,
                validated: event.target.value.trim() === "" ? false : true
            }
        });
    };

    // Question3 stuff
    _question3Onchange = event => {
        this.setState({
            question3: {
                ...this.state.question3,
                primaryAnswer: event.target.value,
                validated: event.target.value.trim() === "" ? false : true
            }
        });
    };

    // Question 4 Stuff

    _setupQuestion4 = () => { };

    _question4Followup = state => {
        let fields = this.state.question4.secondaryArray;
        const toArray = fields.reduce((acc, obj) => [...acc, Object.values(obj).map(y => y.trim())], []);
        const singleArr = [].concat(...toArray);
        const emptyStrings = singleArr.filter(str => str.trim().length <= 0);

        state
            ? this.setState({
                question4: { ...this.state.question4, showFollowupQuestion: true, primaryAnswer: true, validated: emptyStrings.length === 0 ? true : false }
            })
            : this.setState({
                question4: { ...this.state.question4, showFollowupQuestion: false, primaryAnswer: false, validated: true }
            });
    };

    _question4jsonOnchange = event => {
        this.setState({
            question4: {
                ...this.state.question4,
                secondaryJson: {
                    ...this.state.question4.secondaryJson,
                    [event.target.id]: event.target.value
                }
            }
        });
    };

    _question4Onchange = event => {
        this.setState({
            question4: {
                ...this.state.question4,
                [event.target.id]: event.target.value
            }
        });
    };

    // Question 5 stuff
    _question5Followup = state => {
        state
            ? this.setState({
                question5: {
                    ...this.state.question5,
                    showFollowupQuestion: true,
                    primaryAnswer: true,
                    validated: this.state.question5.secondaryAnswer.trim() === "" ? false : true
                }
            })
            : this.setState({
                question5: {
                    ...this.state.question5,
                    showFollowupQuestion: false,
                    primaryAnswer: false,
                    validated: true
                }
            });
    };

    _question5Onchange = event => {
        this.setState({
            question5: {
                ...this.state.question5,
                [event.target.id]: event.target.value
            }
        });
    };

    _question5TextOnchange = event => {
        this.setState({
            question5: {
                ...this.state.question5,
                secondaryAnswer: event.target.value,
                validated: event.target.value.trim() === "" ? false : true
            }
        });
    };

    // Question 6 stuff
    _question6Followup = state => {
        state
            ? this.setState({
                question6: {
                    ...this.state.question6,
                    showFollowupQuestion: true,
                    primaryAnswer: true,
                    // validated: false
                }
            })
            : this.setState({
                question6: {
                    ...this.state.question6,
                    showFollowupQuestion: false,
                    primaryAnswer: false,
                    // validated: true
                }
            });
    };

    _question6SecondaryFollowup = state => {
        state
            ? this.setState({
                question6: { ...this.state.question6, secondaryAnswer: true }
            })
            : this.setState({
                question6: { ...this.state.question6, secondaryAnswer: false }
            });
    };

    _next = () => {
        const { submissionId, submissionType, userId, assessmentName } = this.state;
        this.props.history.push({ pathname: '/users/'.concat(userId, '/view/section3'), state: { submissionId: submissionId, submissionType: submissionType, assessmentName: assessmentName } });
    };

    isEmpty(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    _handleMultiChange(i, e) {
        const { name, value } = e.target;
        let fields = this.state.question4.secondaryArray;
        fields[i][name] = value;

        const toArray = fields.reduce((acc, obj) => [...acc, Object.values(obj).map(y => y.trim())], []);
        const singleArr = [].concat(...toArray);
        const emptyStrings = singleArr.filter(str => str.trim().length <= 0);
        // const isEmpty = emptyStrings.length === 0 ? true : false;
        // // console.log(isEmpty);


        this.setState({
            question4: {
                ...this.state.question4,
                validated: emptyStrings.length === 0 ? true : false
            }
        });

        this.setState({ fields });
    }

    _addMoreClick() {
        let fields = this.state.question4.secondaryArray;
        fields.push({ fullName: "", professionalTitle: "", roleOnTheBoard: "", typeOfExpertise: "" });
        this.setState({
            question4: {
                ...this.state.question4,
                validated: false
            }
        });
        this.setState(fields);
    }

    _removeMoreClick(i) {

        let secondaryArray = this.state.question4.secondaryArray;
        secondaryArray.splice(i, 1);

        const toArray = secondaryArray.reduce((acc, obj) => [...acc, Object.values(obj).map(y => y.trim())], []);
        const singleArr = [].concat(...toArray);
        const emptyStrings = singleArr.filter(str => str.trim().length <= 0);

        this.setState({
            question4: {
                ...this.state.question4,
                validated: emptyStrings.length === 0 ? true : false
            }
        });
        this.setState({ secondaryArray });
    }

    _createMuliQuestion() {
        const { t, i18n } = this.props
        if (this.state.question4.secondaryArray != undefined) {
            return this.state.question4.secondaryArray.map((el, i) => (
                <div key={i}>
                    <br /><br />
                    <div className="ms-Grid">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm6 ms-lg10">
                                <Form.Field>
                                    <label>{t("Full Name")}</label>
                                    <TextArea
                                        onChange={this._handleMultiChange.bind(this, i)}
                                        name="fullName"
                                        id="fullName"
                                        label="Full Name"
                                        value={el.fullName || ''}
                                        placeholder={t("Full Name")}
                                        type="text"
                                        readOnly
                                    />
                                    {/* <input disabled type="text" id='fullName' name='fullName' label="Full Name" placeholder={t("Full Name")} value={el.fullName || ''} onChange={this._handleMultiChange.bind(this, i)} /> */}
                                </Form.Field>
                                <Form.Field>
                                    <label>{t("Professional Title")}</label>
                                    <TextArea
                                        onChange={this._handleMultiChange.bind(this, i)}
                                        name="professionalTitle"
                                        id="professionalTitle"
                                        label="Professional Title"
                                        value={el.professionalTitle || ''}
                                        placeholder={t("Professional Title")}
                                        type="text"
                                        readOnly
                                    />
                                    {/* <input disabled type="text" id='professionalTitle' name='professionalTitle' label="Professional Title" placeholder={t("Professional Title")} value={el.professionalTitle || ''} onChange={this._handleMultiChange.bind(this, i)} /> */}
                                </Form.Field>
                                <Form.Field>
                                    <label>{t("Role on the Board")}</label>
                                    <TextArea
                                        onChange={this._handleMultiChange.bind(this, i)}
                                        name="roleOnTheBoard"
                                        id="roleOnTheBoard"
                                        label="Role on the Board"
                                        value={el.roleOnTheBoard || ''} 
                                        className="text-area-height uk-input uk-form-width-large"
                                        placeholder={t("Role on the Board")} 
                                        type="text"
                                        readOnly
                                    />
                                    {/* <input disabled type="text" id='roleOnTheBoard' name='roleOnTheBoard' label="Role on the Board" placeholder={t("Role on the Board")} value={el.roleOnTheBoard || ''} onChange={this._handleMultiChange.bind(this, i)} /> */}
                                </Form.Field>
                                <Form.Field>
                                    <label>{t("Type of Expertise")}</label>
                                    <TextArea
                                        onChange={this._handleMultiChange.bind(this, i)}
                                        name="typeOfExpertise"
                                        id="typeOfExpertise"
                                        label="Type of Expertise"
                                        value={el.typeOfExpertise || ''}
                                        className="text-area-height uk-input uk-form-width-large"
                                        placeholder={t("Type of Expertise")}
                                        type="text"
                                        readOnly
                                    />
                                    {/* <input disabled type="text" id='typeOfExpertise' name='typeOfExpertise' label="Type of Expertise" placeholder={t("Type of Expertise")} value={el.typeOfExpertise || ''} onChange={this._handleMultiChange.bind(this, i)} /> */}
                                </Form.Field>
                            </div>

                            <br />

                            {this.state.question4.secondaryArray.length <= 1 ? (
                                null
                            ) : (
                                    null
                                    // <div className="ms-Grid-col ms-sm6 ms-lg2 uk-text-right">
                                    //     <i class="fas fa-trash-alt multiple_Field_Remove"  aria-hidden="true" onClick={this._removeMoreClick.bind(this, i)}></i>                                      
                                    // </div>
                                )}

                        </div>
                    </div>
                    <hr className="multiple_Field_Seperator" />
                </div>

            ))

        } else {
            let fields = this.state.question4;
            fields["secondaryArray"] = [{ fullName: "", professionalTitle: "", roleOnTheBoard: "", typeOfExpertise: "" }];
            this.setState(fields)
        }
    }
    _pagi = (path) => {
        const { submissionId, submissionType, userId, assessmentName } = this.state;
        this.props.history.push({ pathname: '/users/'.concat(userId, '/view/', path), state: { submissionId: submissionId, submissionType: submissionType, assessmentName: assessmentName } });
    }

    render() {
        const { formErrorStatus, formSuccessState, t, i18n } = this.props;
        const { progress, result_color, result_status, score } = this.state
        return (
            <div style={{ backgroundColor: "#fff" }} class="remove-disabled">
                <div>
                    <div class="uk-background-muted uk-margin-top uk-padding" style={{ paddingBottom: "15px", paddingTop: "15px" }}>
                        <div class="uk-grid" uk-grid>
                            <div class="uk-width-expand@m">
                                <form class=" uk-flex-inline uk-flex-middle uk-text-small">
                                    <h4 class="uk-margin-remove-bottom uk-margin-right">
                                        {" "}
                                        {this.state.general.fullName}
                                    </h4>
                                </form>
                                <h6 class="uk-margin-remove-top uk-text-left">
                                    {" "}
                                    {this.state.general.country}, {this.state.general.region}
                                </h6>
                            </div>
                            <div class="uk-width-auto@m uk-text-small">
                                <p class="uk-text-right uk-margin-remove-bottom"> {t("Submitted by")}: <strong> {this.state.user.firstName} {this.state.user.lastName} </strong></p>
                                <p class="uk-text-right uk-margin-remove-top uk-margin-remove-bottom"> {t("Assessment name")}: <strong>{this.state.assessmentName} </strong></p>
                                <p class="uk-text-right uk-margin-remove-top uk-margin-remove-bottom">{t("Account created on")}: {moment(this.state.user.createdAt).format('DD-MMMM-YYYY')}</p>
                            </div>
                        </div>
                    </div>
                    {/* <div class="uk-background-muted" style={{ paddingRight: "40px", paddingBottom: "20px" }}>
                        <div class="uk-width-auto@m uk-text-small">

                        </div>
                    </div> */}
                </div>
                <div class="uk-background-muted" style={{ paddingRight: "40px", paddingBottom: "15px" }}>
                    <div class="uk-width-auto@m uk-text-small">
                        <p class="uk-text-right uk-margin-remove-top" style={{ color: result_color }}><b>{t(result_status)}: {score}</b></p>

                    </div>
                </div>
                {this.state.loading ? (
                    <SectionLoader />
                ) : (
                        <div>
                            {/* Begin Dark Header */}
                            <div className="uk-section-default uk-section" style={{ paddingTop: "10px" }}>
                                <div className="uk-container-small uk-margin-auto" style={{ marginTop: "10px" }}>
                                    <h3 className=" uk-text-light">
                                        {" "}
                                        {t("Section")} {this.state.section.sectionNumber}{" "}
                                        {/* {this.state.section.title}{" "} */}
                                    </h3>
                                </div>
                                {/* <hr /> */}
                                <div
                                    className="uk-container-small uk-margin-auto uk-margin-small-top uk-padding-small paper-read"
                                    uk-scrollspy="target: > .paper; cls:uk-animation-slide-bottom-small; delay: 200"
                                >
                                    <div className="paper uk-link-reset sectionTwo  " style={{ backgroundColor: "#008C95" }}>
                                        <div className="uk-grid uk-flex-middle">
                                            <div className="uk-width-1-6 uk-flex-first uk-text-center">
                                                <i className="far fa-comment-alt icon-xxlarge"></i>
                                            </div>
                                            <div className="uk-width-5-6">
                                                <h4 className="uk-margin-remove" style={{ color: "#ffffff" }}>
                                                    {" "}
                                                    {t(this.state.section.title)}{" "}
                                                </h4>
                                                {/* <p className="uk-margin-remove">
                          {" "}
                          {this.state.section.description}{" "}
                        </p> */}
                                            </div>
                                        </div>
                                    </div>
                                    <Progress style={{ margin: 0 }} percent={progress} progress success size='medium' />
                                    <Form success error className="uk-margin">
                                        {/* Begin Question Group */}
                                        {/* question1 */}
                                        <div className="uk-link-reset paper uk-margin s1q1">
                                            <h4>
                                                {" "}
                                                <b>1)</b> {t("Does your domestic law allow NGOs to carry out income generating activities (to support the social mission)?")}{" "}
                                            </h4>
                                            {localStorage.RAT_LANG=='ar'? (
                                                 <Button.Group
                                                 size="large"
                                                 onChange={this._question1Onchange}
                                                 className="buttons_arabic_style"
                                             >
                                                
                                                
                                                 <Button disabled readOnly positive={this.state.question1.showFollowupQuestion ? false : true} onClick={() => this._question1Followup(false)}>
                                                     {t("NO")}
                                                 </Button>
                                                 {/* <Button.Or /> */}
                                                 <div class="or" data-text={localStorage.RAT_LANG=='sp'? "o" : localStorage.RAT_LANG=='fr'? "ou" : localStorage.RAT_LANG=='ar'? "أو": "or"}></div>
                                                 <Button disabled readOnly positive={this.state.question1.showFollowupQuestion ? true : false} onClick={() => this._question1Followup(true)}>
                                                     {t("YES")}
                                                 </Button>
                                             </Button.Group>
                                            ):(
                                                <Button.Group
                                                size="large"
                                                onChange={this._question1Onchange}
                                                // className={localStorage.RAT_LANG=='ar'? "buttons_arabic_style": "" } 
                                            >
                                                <Button disabled readOnly positive={this.state.question1.showFollowupQuestion ? true : false} onClick={() => this._question1Followup(true)}>
                                                    {t("YES")}
                                                </Button>
                                                {/* <Button.Or /> */}
                                                <div class="or" data-text={localStorage.RAT_LANG=='sp'? "o" : localStorage.RAT_LANG=='fr'? "ou" : localStorage.RAT_LANG=='ar'? "أو": "or"}></div>
                                                <Button disabled readOnly positive={this.state.question1.showFollowupQuestion ? false : true} onClick={() => this._question1Followup(false)}>
                                                    {t("NO")}
                                                </Button>
                                            </Button.Group>
                                            )}
                                           
                                            {this.state.question1.showFollowupQuestion ? (
                                                <div>
                                                    <div className="uk-form-label">
                                                        {t("If Yes, what kind of provisions are in place?")}
                                                    </div>
                                                    <div className="uk-margin">
                                                        <span className="uk-form-icon">
                                                            <i className="far fa-User icon-medium"></i>
                                                        </span>

                                                        <TextArea
                                                            onChange={this._question1Onchange}
                                                            name="secondaryAnswer"
                                                            id="secondaryAnswer"
                                                            value={this.state.question1.secondaryAnswer}
                                                            className="text-area-height uk-input uk-form-width-large"
                                                            placeholder={t("Describe here")}
                                                            type="text"
                                                            disabled
                                                            readOnly
                                                        />
                                                    </div>
                                                </div>
                                            ) : (
                                                    ""
                                                )}

                                            {this.state.question1.score && this.state.question1.score !== "" ? (
                                                <p><span style={{ color: "#eaaa00", fontWeight: "bold" }}>{t("Score given")}: </span> <span className="uk-text-muted">{this.state.question1.score}</span></p>
                                            ) : (
                                                    ""
                                                )}
                                            {this.state.question1.feedBack && this.state.question1.feedBack !== "" ? (
                                                <p><span style={{ color: "#eaaa00", fontWeight: "bold" }}>{t("Comments/Feedback from SE Hub")}:</span> <div className="uk-text-muted" dangerouslySetInnerHTML={{ __html: this.state.question1.feedBack.replace(/(?:\r\n|\r|\n)/g, '<br />') }}></div> </p>
                                            ) : (
                                                    ""
                                                )}
                                        </div>

                                        {/* End Question Group */}
                                        {/* Begin Question 2 */}
                                        <div className="uk-link-reset paper uk-margin">
                                            <h4 className="uk-margin-remove">
                                                {" "}
                                                <b>2)</b> {t("Does the constitution of your organization place explicit constraints or controls on income generating activities?")}
                                            </h4>
                                            <p class="uk-text-muted uk-margin-remove-top uk-margin-small-bottom">{t("Please answer and support the response with additional details.")}</p>
                                            <TextArea
                                                onChange={this._question2Onchange}
                                                name="q2primaryAnswer"
                                                id="q2primaryAnswer"
                                                value={this.state.question2.primaryAnswer}
                                                className="text-area-height uk-input uk-form-width-large"
                                                placeholder={t("Describe here")}
                                                type="text"
                                                disabled
                                                readOnly
                                            />
                                            {this.state.question2.score && this.state.question2.score !== "" ? (
                                                <p><span style={{ color: "#eaaa00", fontWeight: "bold" }}>{t("Score given")}: </span> <span className="uk-text-muted">{this.state.question2.score}</span></p>
                                            ) : (
                                                    ""
                                                )}
                                            {this.state.question2.feedBack && this.state.question2.feedBack !== "" ? (
                                                <p><span style={{ color: "#eaaa00", fontWeight: "bold" }}>{t("Comments/Feedback from SE Hub")}:</span> <div className="uk-text-muted" dangerouslySetInnerHTML={{ __html: this.state.question2.feedBack.replace(/(?:\r\n|\r|\n)/g, '<br />') }}></div> </p>
                                            ) : (
                                                    ""
                                                )}
                                        </div>
                                        {/* End Question 2 */}
                                        {/* Begin Question 3 */}
                                        <div
                                            href="help-post-read.html"
                                            className="uk-link-reset paper uk-margin-remove-bottom"
                                        >
                                            <h4 className="uk-margin-remove">
                                                {" "}
                                                <b>3)</b> {t("Does the constitution of your organization need to be amended to allow income generating activities?")}
                                            </h4>
                                            <p class="uk-text-muted uk-margin-remove-top uk-margin-small-bottom">{t("Please answer and support the response with additional details.")}</p>
                                            <p className="uk-margin-remove">
                                                {" "}
                                                <TextArea
                                                    onChange={this._question3Onchange}
                                                    name="q3primaryAnswer"
                                                    id="q3primaryAnswer"
                                                    value={this.state.question3.primaryAnswer}
                                                    className="text-area-height uk-input uk-form-width-large"
                                                    placeholder={t("Describe here")}
                                                    type="text"
                                                    disabled
                                                    readOnly
                                                />
                                            </p>
                                            {this.state.question3.score && this.state.question3.score !== "" ? (
                                                <p><span style={{ color: "#eaaa00", fontWeight: "bold" }}>{t("Score given")}: </span> <span className="uk-text-muted">{this.state.question3.score}</span></p>
                                            ) : (
                                                    ""
                                                )}
                                            {this.state.question3.feedBack && this.state.question3.feedBack !== "" ? (
                                                <p><span style={{ color: "#eaaa00", fontWeight: "bold" }}>{t("Comments/Feedback from SE Hub")}:</span> <div className="uk-text-muted" dangerouslySetInnerHTML={{ __html: this.state.question3.feedBack.replace(/(?:\r\n|\r|\n)/g, '<br />') }}></div> </p>
                                            ) : (
                                                    ""
                                                )}
                                        </div>
                                        {/* End Question 3 */}

                                        {/* Begin Question 4 */}
                                        <div
                                            href="help-post-read.html"
                                            className="uk-link-reset paper uk-margin"
                                        >
                                            <h4 className="uk-margin-remove">
                                                {" "}
                                                <b>4)</b> {t("Is the Governing Board of your organization representative of multi-disciplinary fields?")}
                                            </h4>
                                            {localStorage.RAT_LANG=='ar'? (
                                                 <Button.Group
                                                 size="large"
                                                 onChange={this._question1Onchange}
                                                 className="buttons_arabic_style"
                                             >
                                                
                                                
                                                 <Button disabled positive={this.state.question4.showFollowupQuestion ? false : true} onClick={() => this._question4Followup(false)}>
                                                     {t("NO")}
                                                 </Button>
                                                 {/* <Button.Or /> */}
                                                 <div class="or" data-text={localStorage.RAT_LANG=='sp'? "o" : localStorage.RAT_LANG=='fr'? "ou" : localStorage.RAT_LANG=='ar'? "أو": "or"}></div>
                                                 <Button disabled positive={this.state.question4.showFollowupQuestion ? true : false} onClick={() => this._question4Followup(true)}>
                                                     {t("YES")}
                                                 </Button>
                                             </Button.Group>
                                            ):(
                                                <Button.Group
                                                size="large"
                                                onChange={this._question1Onchange}
                                                // className={localStorage.RAT_LANG=='ar'? "buttons_arabic_style": "" } 
                                            >
                                                <Button disabled positive={this.state.question4.showFollowupQuestion ? true : false} onClick={() => this._question4Followup(true)}>
                                                    {t("YES")}
                                                </Button>
                                                {/* <Button.Or /> */}
                                                <div class="or" data-text={localStorage.RAT_LANG=='sp'? "o" : localStorage.RAT_LANG=='fr'? "ou" : localStorage.RAT_LANG=='ar'? "أو": "or"}></div>
                                                <Button disabled positive={this.state.question4.showFollowupQuestion ? false : true} onClick={() => this._question4Followup(false)}>
                                                    {t("NO")}
                                                </Button>
                                            </Button.Group>
                                            )}
                                           
                                            {this.state.question4.showFollowupQuestion ? (
                                                <div className="uk-margin">
                                                    <div class="uk-form-label">{t("If Yes, add all the applicable Board Members details")}</div>
                                                    <div>
                                                        {this._createMuliQuestion()}
                                                        <div className="uk-text-right"><button disabled onClick={this._addMoreClick.bind(this)} className="uk-button uk-button-big uk-button-primary multiple_Field_Plus" type="button">{t("Add details for another Board Member")}</button></div>
                                                    </div>
                                                </div>
                                            ) : (
                                                    " "
                                                )}

                                            {this.state.question4.score && this.state.question4.score !== "" ? (
                                                <p><span style={{ color: "#eaaa00", fontWeight: "bold" }}>{t("Score given")}: </span> <span className="uk-text-muted">{this.state.question4.score}</span></p>
                                            ) : (
                                                    ""
                                                )}
                                            {this.state.question4.feedBack && this.state.question4.feedBack !== "" ? (
                                                <p><span style={{ color: "#eaaa00", fontWeight: "bold" }}>{t("Comments/Feedback from SE Hub")}:</span> <div className="uk-text-muted" dangerouslySetInnerHTML={{ __html: this.state.question4.feedBack.replace(/(?:\r\n|\r|\n)/g, '<br />') }}></div> </p>
                                            ) : (
                                                    ""
                                                )}
                                        </div>
                                        {/* End Question 4 */}

                                        {/* Begin Question 5 */}
                                        <div
                                            href="help-post-read.html"
                                            className="uk-link-reset paper uk-margin-remove-bottom"
                                        >
                                            <h4 className="uk-margin-remove">
                                                <b>5)</b>{" "}
                                                {t("Does at least one Board member have knowledge of business management?")}
                                            </h4>
                                            {localStorage.RAT_LANG=='ar'? (
                                                 <Button.Group
                                                 size="large"
                                                 onChange={this._question5Onchange}
                                                 className= "buttons_arabic_style"
                                             >
                                                 
                                                
                                                 <Button disabled positive={this.state.question5.showFollowupQuestion ? false : true} onClick={() => this._question5Followup(false)}>
                                                     {t("NO")}
                                                 </Button>
                                                 {/* <Button.Or /> */}
                                                 <div class="or" data-text={localStorage.RAT_LANG=='sp'? "o" : localStorage.RAT_LANG=='fr'? "ou" : localStorage.RAT_LANG=='ar'? "أو": "or"}></div>
                                                 <Button disabled positive={this.state.question5.showFollowupQuestion ? true : false} onClick={() => this._question5Followup(true)}>
                                                     {t("YES")}
                                                 </Button>
                                             </Button.Group>
                                            ):(
                                                <Button.Group
                                                size="large"
                                                onChange={this._question5Onchange}
                                                // className={localStorage.RAT_LANG=='ar'? "buttons_arabic_style": "" } 
                                            >
                                                <Button disabled positive={this.state.question5.showFollowupQuestion ? true : false} onClick={() => this._question5Followup(true)}>
                                                    {t("YES")}
                                                </Button>
                                                {/* <Button.Or /> */}
                                                <div class="or" data-text={localStorage.RAT_LANG=='sp'? "o" : localStorage.RAT_LANG=='fr'? "ou" : localStorage.RAT_LANG=='ar'? "أو": "or"}></div>
                                                <Button disabled positive={this.state.question5.showFollowupQuestion ? false : true} onClick={() => this._question5Followup(false)}>
                                                    {t("NO")}
                                                </Button>
                                            </Button.Group>
                                            )}
                                           
                                            {this.state.question5.showFollowupQuestion ? (
                                                <div>
                                                    <div className="uk-form-label">
                                                        {t("If Yes, specify the exact advisory capacity of the Board Members who have business management knowledge and any evidence of their business experience (including any academic qualification)?")}
                                                    </div>
                                                    <div className="uk-margin">
                                                        <span className="uk-form-icon">
                                                            <i className="far fa-User icon-medium"></i>
                                                        </span>
                                                        <TextArea
                                                            onChange={this._question5TextOnchange}
                                                            name="q5secondaryAnswer"
                                                            id="q5secondaryAnswer"
                                                            value={this.state.question5.secondaryAnswer}
                                                            className="text-area-height uk-input uk-form-width-large"
                                                            placeholder={t("Describe here")}
                                                            type="text"
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                            ) : (
                                                    " "
                                                )}

                                            {this.state.question5.score && this.state.question5.score !== "" ? (
                                                <p><span style={{ color: "#eaaa00", fontWeight: "bold" }}>{t("Score given")}: </span> <span className="uk-text-muted">{this.state.question5.score}</span></p>
                                            ) : (
                                                    ""
                                                )}
                                            {this.state.question5.feedBack && this.state.question5.feedBack !== "" ? (
                                                <p><span style={{ color: "#eaaa00", fontWeight: "bold" }}>{t("Comments/Feedback from SE Hub")}:</span> <div className="uk-text-muted" dangerouslySetInnerHTML={{ __html: this.state.question5.feedBack.replace(/(?:\r\n|\r|\n)/g, '<br />') }}></div> </p>
                                            ) : (
                                                    ""
                                                )}
                                        </div>

                                        {/* End Question5 */}

                                        {/* Begin Question 6 */}

                                        <div
                                            href="help-post-read.html"
                                            className="uk-link-reset paper uk-margin"
                                        >
                                            <h4 className="uk-margin-remove">
                                                <b>6)</b>{" "}
                                                {t("Is there consensus or a documented agreement (i.e. meeting minutes) between your organization’s management and Board on the need to be self-sufficient financially (sustainability)?")}
                                            </h4>
                                            {localStorage.RAT_LANG=='ar'? (
                                                  <Button.Group
                                                  size="large"
                                                  onChange={this._question6Onchange}
                                                  className="buttons_arabic_style"
                                              >
                                                 
                                                  
                                                  <Button disabled positive={this.state.question6.showFollowupQuestion ? false : true} onClick={() => this._question6Followup(false)}>
                                                      {t("NO")}
                                                  </Button>
                                                  {/* <Button.Or /> */}
                                                  <div class="or" data-text={localStorage.RAT_LANG=='sp'? "o" : localStorage.RAT_LANG=='fr'? "ou" : localStorage.RAT_LANG=='ar'? "أو": "or"}></div>
                                                  <Button disabled positive={this.state.question6.showFollowupQuestion ? true : false} onClick={() => this._question6Followup(true)}>
                                                      {t("YES")}
                                                  </Button>
                                              </Button.Group>
                                            ):(
                                                <Button.Group
                                                size="large"
                                                onChange={this._question6Onchange}
                                                // className={localStorage.RAT_LANG=='ar'? "buttons_arabic_style": "" } 
                                            >
                                                <Button disabled positive={this.state.question6.showFollowupQuestion ? true : false} onClick={() => this._question6Followup(true)}>
                                                    {t("YES")}
                                                </Button>
                                                {/* <Button.Or /> */}
                                                <div class="or" data-text={localStorage.RAT_LANG=='sp'? "o" : localStorage.RAT_LANG=='fr'? "ou" : localStorage.RAT_LANG=='ar'? "أو": "or"}></div>
                                                <Button disabled positive={this.state.question6.showFollowupQuestion ? false : true} onClick={() => this._question6Followup(false)}>
                                                    {t("NO")}
                                                </Button>
                                            </Button.Group>
                                            )}
                                          
                                            {this.state.question6.showFollowupQuestion ? (
                                                <div>
                                                    <div className="uk-form-label">
                                                        {t("If Yes, does your organization have a Resource Mobilization plan/strategy?")}
                                                    </div>
                                                    <div className="uk-margin">
                                                        <span className="uk-form-icon">
                                                            <i className="far fa-User icon-medium"></i>
                                                        </span>
                                                        {localStorage.RAT_LANG=='ar'? (
                                                            <Button.Group size="large" className="buttons_arabic_style">
                                                          
                                                            
                                                            <Button
                                                                disabled
                                                                positive={this.state.question6.secondaryAnswer ? false : true}
                                                                onClick={() =>
                                                                    this._question6SecondaryFollowup(false)
                                                                }
                                                            >
                                                                {t("NO")}
                                                            </Button>
                                                            {/* <Button.Or /> */}
                                                            <div class="or" data-text={localStorage.RAT_LANG=='sp'? "o" : localStorage.RAT_LANG=='fr'? "ou" : localStorage.RAT_LANG=='ar'? "أو": "or"}></div>
                                                            <Button
                                                                disabled
                                                                positive={this.state.question6.secondaryAnswer ? true : false}
                                                                onClick={() =>
                                                                    this._question6SecondaryFollowup(true)
                                                                }
                                                            >
                                                                {t("YES")}
                                                            </Button>
                                                        </Button.Group>
                                                        ):(
                                                            <Button.Group size="large" >
                                                            <Button
                                                                disabled
                                                                positive={this.state.question6.secondaryAnswer ? true : false}
                                                                onClick={() =>
                                                                    this._question6SecondaryFollowup(true)
                                                                }
                                                            >
                                                                {t("YES")}
                                                            </Button>
                                                            {/* <Button.Or /> */}
                                                            <div class="or" data-text={localStorage.RAT_LANG=='sp'? "o" : localStorage.RAT_LANG=='fr'? "ou" : localStorage.RAT_LANG=='ar'? "أو": "or"}></div>
                                                            <Button
                                                                disabled
                                                                positive={this.state.question6.secondaryAnswer ? false : true}
                                                                onClick={() =>
                                                                    this._question6SecondaryFollowup(false)
                                                                }
                                                            >
                                                                {t("NO")}
                                                            </Button>
                                                        </Button.Group>
                                                        )}
                                                        
                                                    </div>
                                                </div>
                                            ) : (
                                                    ""
                                                )}

                                            {this.state.question6.score && this.state.question6.score !== "" ? (
                                                <p><span style={{ color: "#eaaa00", fontWeight: "bold" }}>{t("Score given")}: </span> <span className="uk-text-muted">{this.state.question6.score}</span></p>
                                            ) : (
                                                    ""
                                                )}
                                            {this.state.question6.feedBack && this.state.question6.feedBack !== "" ? (
                                                <p><span style={{ color: "#eaaa00", fontWeight: "bold" }}>{t("Comments/Feedback from SE Hub")}:</span> <div className="uk-text-muted" dangerouslySetInnerHTML={{ __html: this.state.question6.feedBack.replace(/(?:\r\n|\r|\n)/g, '<br />') }}></div> </p>
                                            ) : (
                                                    ""
                                                )}
                                        </div>

                                        {/* End Question 6 */}
                                        {this.state.sectionFeedBack && this.state.sectionFeedBack !== "" ? (
                                            <div
                                                href="help-post-read.html"
                                                className="uk-link-reset paper uk-margin-remove-bottom"
                                            >
                                                <h4 style={{ color: "#eaaa00", fontWeight: "bold" }}>
                                                    {" "}
                                                    {t("Overall section comments/feedback from SE Hub")}
                                                </h4>
                                                <div className="uk-text-muted" dangerouslySetInnerHTML={{ __html: this.state.sectionFeedBack.replace(/(?:\r\n|\r|\n)/g, '<br />') }}></div>

                                            </div>
                                        ) : (
                                                ""
                                            )}

                                        <div className="uk-margin">
                                            {(formErrorStatus.status) ? (
                                                <div className="danger_alert">{t(formErrorStatus.message)}</div>
                                            ) : ((formSuccessState.status) ? (
                                                <div className="success_alert">{t(formSuccessState.message)}</div>
                                            ) : (''))}
                                        </div>
                                        {/* <div className="uk-grid uk-margin"> */}
                                        <div className="uk-text-center uk-grid uk-grid-small uk-child-width-1-6 uk-margin-small quick-nav" uk-grid>
                                            <div>
                                                <div className="uk-card">
                                                    <li><a className={localStorage.RAT_LANG=="fr"? "fr_MA_contact uk-button uk-button-default uk-button-small uk-width-1-1": localStorage.RAT_LANG=='ar'? "Ar_MA_contact uk-button uk-button-default uk-button-small uk-width-1-1": localStorage.RAT_LANG=="sp"? "uk-button uk-button-default uk-button-small uk-width-1-1 sp_MA_contact": "uk-button uk-button-default uk-button-small uk-width-1-1"} onClick={(e) => this._pagi("section1")} href="javascript:void(0)">{t("MA Contact")}</a></li>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="uk-card">
                                                    <li className="uk-active"><a className="uk-button uk-button-default uk-button-small uk-width-1-1" onClick={(e) => this._pagi("section2")} href="javascript:void(0)">{t("Operations")}</a></li>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="uk-card">
                                                    <li><a className="uk-button uk-button-default uk-button-small uk-width-1-1" onClick={(e) => this._pagi("section3")} href="javascript:void(0)">{t("Financial")}</a></li>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="uk-card">
                                                    <li><a className="uk-button uk-button-default uk-button-small uk-width-1-1" onClick={(e) => this._pagi("section4")} href="javascript:void(0)">{t("HR")}</a></li>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="uk-card">
                                                    <li><a className="uk-button uk-button-default uk-button-small uk-width-1-1" onClick={(e) => this._pagi("section5")} href="javascript:void(0)">{t("Resources")}</a></li>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="uk-card">
                                                    <li><a className="uk-button uk-button-default uk-button-small uk-width-1-1" onClick={(e) => this._pagi("section6")} href="javascript:void(0)">{t("Other")}</a></li>
                                                </div>
                                            </div>
                                        </div>

                                        {/* </div> */}

                                        <hr />
                                        <div className="uk-grid uk-text-center button_div">
                                            <div className="uk-width-1-3@m">
                                                <Button
                                                    basic
                                                    className="outline-button blueButton"
                                                    onClick={this._goBack}
                                                >
                                                    {t("Go Back")}
                                                </Button>
                                            </div>

                                            <div className="uk-width-expand@m">

                                            </div>

                                            <div className="uk-width-1-3@m">
                                                <div>
                                                    <Button
                                                        onClick={this._next}
                                                        className="next-button purpleButton"
                                                        loading={this.state.saveNContinue}
                                                        basic
                                                    >
                                                        {t("Next")}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    )}
            </div>
        );
    }
}

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(ViewSection2))));