import React, { Component } from "react";
import Reg from './Reg';
import AboutImage from '../../assets/images/backgrounds/banner.jpg';
import Login from '../common/Login';
import { Button, Modal } from 'semantic-ui-react'
import ForgotPassword from '../common/ForgotPassword'
import { withNamespaces } from "react-i18next";
import PropTypes from "prop-types";

class Instructions extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }



    render() {
        const { t, i18n } = this.props;
        return (
            <div className="containerStyle ">
                {/* Begin Dark Header */}


                <div className="uk-section-default" uk-scrollspy="{&quot;target&quot;:&quot;[uk-scrollspy-class]&quot;,&quot;cls&quot;:&quot;uk-animation-slide-top-small&quot;,&quot;delay&quot;:false}" tm-header-transparent="dark" tm-header-transparent-placeholder="">
                    {localStorage.RAT_LANG == 'ar' ? (
                        <div className="uk-grid" data-uk-grid-match="{target:'.equal-height'}">
                             <div className="uk-width-1-3@m uk-width-1-1@s uk-position-relative login_side_order Main-banner-login-sec">
                                <div className="equal-height">
                                    <Login />
                                </div>

                            </div>

                            <div className="uk-width-2-3@m uk-width-1-1@s uk-position-relative">
                                <div className="equal-height uk-background-cover uk-background-norepeat" style={styles.darkBack}>
                                    <div className="uk-width-1-1@m" style={{ position: "absolute", bottom: 0 }}>
                                        {localStorage.RAT_LANG == "sp" ? (
                                            <h2 className=" uk-animation-slide-top-small uk-text-white uk-padding ">
                                                {t("Social Enterprise")}<br /> {t("Readiness Assessment Tool")}
                                            </h2>
                                        ) : (
                                            localStorage.RAT_LANG == "ar" ?
                                                (
                                                    <h1 style={{ direction: "rtl", paddingRight: "80px" }} className=" uk-animation-slide-top-small uk-text-white uk-padding ">
                                                        {t("Social Enterprise")}<br /> {t("Readiness Assessment Tool")}
                                                    </h1>
                                                ) : (
                                                    <h1 style={{textAlign:"left"}} className=" uk-animation-slide-top-small uk-text-white uk-padding ">
                                                        {t("Social Enterprise")}<br /> {t("Readiness Assessment Tool")}
                                                    </h1>
                                                )

                                        )
                                        }

                                    </div>
                                </div>
                            </div>
                           
                        </div>
                    ) : (
                        <div className="uk-grid" data-uk-grid-match="{target:'.equal-height'}">
                            <div className="uk-width-2-3@m uk-width-1-1@s uk-position-relative">
                                {/* <div class="uk-overlay-primary uk-position-cover"></div> */}
                                <div className="equal-height uk-background-cover uk-background-norepeat" style={styles.darkBack}>
                                    <div className="uk-width-1-1@m" style={{ position: "absolute", bottom: 0 }}>
                                        {localStorage.RAT_LANG == "sp" ? (
                                            <h2 className=" uk-animation-slide-top-small uk-text-white uk-padding ">
                                                {t("Social Enterprise")}<br /> {t("Readiness Assessment Tool")}
                                            </h2>
                                        ) : (
                                            localStorage.RAT_LANG == "ar" ?
                                                (
                                                    <h1 style={{ direction: "rtl", paddingRight: "80px" }} className=" uk-animation-slide-top-small uk-text-white uk-padding ">
                                                        {t("Social Enterprise")}<br /> {t("Readiness Assessment Tool")}
                                                    </h1>
                                                ) : (
                                                    <h1  style={{textAlign:"left"}} className=" uk-animation-slide-top-small uk-text-white uk-padding ">
                                                        {t("Social Enterprise")}<br /> {t("Readiness Assessment Tool")}
                                                    </h1>
                                                )

                                        )
                                        }

                                    </div>
                                </div>
                            </div>
                            <div className="uk-width-1-3@m uk-width-1-1@s uk-position-relative login_side_order Main-banner-login-sec">
                                <div className="equal-height">
                                    <Login />
                                </div>

                            </div>
                        </div>
                    )}


                </div>

                <div className="uk-section-default uk-section impact_container" id="aboutus_section" style={{ paddingTop: "30px" }}>
                    <div className="uk-margin uk-container">
                        <div className="uk-child-width-1-2@m uk-grid-match uk-margin uk-grid" uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 200">
                            <div>
                                <h4 style={{ color: "#151F6D" }} className={localStorage.RAT_LANG=='ar'? "uk-text-right":"uk-text-left" } >{t("International Planned Parenthood Federation")} <br />({t("IPPF")}) </h4>
                            </div>

                            <div>
                                <h4 style={{ color: "#151F6D" }} className={localStorage.RAT_LANG=='ar'? "uk-text-right": "uk-text-left"} >{t("Social Enterprise Acceleration Programme")} <br />({t("SEAP")})</h4>
                            </div>
                        </div>
                        <div className="uk-child-width-1-2@m uk-grid-match uk-margin uk-grid" uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 200">
                            <div>
                                <p style={styles.Align}><a href="https://www.ippf.org" target="_blank">{t("IPPF")}</a> {t("is a global federation working to promote Sexual and Reproductive Health and Rights for all. It was first formed in 1952 in Bombay (present-day Mumbai) at the 3rd International Conference on Planned Parenthood. 67 years later, the Federation has")} <a href="https://www.ippf.org/about-us/member-associations" target="_blank">{t("130+ Member Associations")}</a> {t("(MA) working in 145 countries, with another 26 Partners working in 21 countries, totaling 166 countries.  The IPPF Secretariat comprises of the Central Office in London and six Regional Offices, working out of seven global locations")}.</p>
                            </div>
                            <div>
                                <p style={styles.Align}>{t("In 2015, IPPF established the")} <a href="https://www.ippf.org/our-approach/programmes/social-enterprise-acceleration-programme" target="_blank">{t("Social Enterprise Acceleration Programme")}</a> {t("(SEAP) with the long term aim of strengthening the capacity of its MAs to apply entrepreneurial best practices in the health sector while delivering social value and improving lives. The Programme supports the establishment and growth of social enterprises to increase and diversify the MAs funding base and provide greater sustainability")}.</p>
                            </div>
                        </div>
                        <div className="uk-child-width-1-2@m uk-grid-match uk-margin uk-grid">
                            <div>
                                <h4 className="uk-text-left-medium uk-margin-small">{t("Social Enterprise in the IPPF Strategic Framework")}</h4>
                            </div>
                            <div>
                                <h4 className="uk-text-left-medium uk-margin-small">{t("IPPF Social Enterprise Hub")}</h4>
                            </div>
                        </div>
                        <div className="uk-child-width-1-2@m uk-grid-match uk-margin uk-grid">
                            <div>
                                <p className={localStorage.RAT_LANG=='sp'? ("Sp_para"):("")}>{t("IPPF recognises social enterprise as the use of entrepreneurial methods to generate a surplus income, which is used to finance social development, service delivery and humanitarian activities that enable the organisation to fulfil its social mission")}.</p>
                                <p style={{ textAlign: "justify", marginTop: "0px" }}>{t("The Federation’s commitment to social enterprise is aligned with Outcome 4 of IPPF’s")} <a href="https://www.ippf.org/about-us/strategy" target="_blank">{t("Strategic Framework")}</a> {t("and Priority Objective 7, which aims to “enhance operational effectiveness and double national and global income”. Demand for SRH services, including contraception, is growing. The role of social enterprise is increasingly recognised by donors and impact investors as an effective way to provide goods and services")}. </p>
                            </div>

                            <div>
                                <p style={styles.Align}>{t("Since January 2018")} <a href="http://www.fpasrilanka.org/" target="_blank">{t("The Family Planning Association of Sri Lanka (FPASL)")}</a> {t("has functioned as IPPF’s Social Enterprise Hub (SE Hub). The appointment of FPASL as the SE Hub was done with a long term view and belief that SEAP's potential and impact will be maximised if delivered in the context of a well-established MA with significant expertise and a successful track record in social enterprise")}.</p>
                                <p style={{ textAlign: "justify", marginTop: "0px" }}>{t("The SE Hub is located within FPASL’s Head Office, with a dedicated two member team who are supported by the organization’s Social Marketing Unit, Communications Officer and Senior Management Team. The team works closely with IPPF CO and the six regional offices to ensure the successful implementation of SEAP")}.</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* End Dark Header */}
            </div>
        );
    }
}
const styles = {
    darkBack: {
        // backgroundColor:'rgba(0, 0, 0, 0.52)',
        backgroundImage: `url(${AboutImage})`,
        backgroundBlendMode: 'color-burn',
        width: '100%',
        height: '100%',
        // backgroundSize: 'cover',
        // backgroundPosition: 'center',
        // backgroundRepeat: 'no-repeat'
    },
    poweredBy: {
        bottom: '8px',
        right: '60px',

    },
    graduationIcon: {
        fontSize: '60px',
    }
}

export default withNamespaces()(Instructions);
