import React, { Component } from "react";
import { Form } from "semantic-ui-react";
import userImage from '../../../assets/images/avatures/avature.jpg';
import SectionList from './SectionList';
import AddSection from './AddSection';
import SingleSection from './SingleSection';
import AddQuestion from './AddQuestion';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { logoutUser } from '../../../services/CacheOperations';

class Sections extends Component {

    logout(){
        logoutUser();
        window.location.href = '/';
    }

  render() {
    const { match } = this.props;
    return (
        <div className="admin-content"> 
            <nav className="uk-navbar"> 
                <div className="uk-navbar-left"> 
                    <ul className="uk-navbar-nav"> 
                        <li className="uk-active"> 
                            <a href="/">Dashboard</a> 
                        </li>                         
                        <li> 
                            <a href="/sections"> Sections</a>
                        </li>                         
                    </ul>                     
                </div>                 
                <div className="uk-navbar-right"> 
                    
                    <a href="#"> 
                    <img src={userImage} alt="" class="uk-border-circle user-profile-tiny" />
                    </a>                     
                    <div uk-dropdown="pos: top-right ;mode : click ;animation: uk-animation-slide-bottom-small" className="uk-dropdown uk-padding-small angle-top-right uk-dropdown-bottom-right" > 
                        <p className="uk-margin-remove-bottom uk-margin-small-top uk-text-bold"> Hamse Mohamoud  </p>                                          
                        <ul className="uk-nav uk-dropdown-nav"> 
                            <li> 
                                <a href=""> <i className="fas fa-user uk-margin-small-right"></i> Profile</a> 
                            </li>                                             
                            <li> 
                               <a href=""> <i className="fas fa-cog uk-margin-small-right"></i> Setting</a>                                 </li>                                             
                            <li className="uk-nav-divider"></li>                                             
                            <li> 
                                <a onClick={()=>this.logout()}> <i className="fas fa-sign-out-alt uk-margin-small-right"></i> Log out</a> 
                            </li>                                             
                        </ul>                                         
                    </div>                     
                </div>                 
            </nav>
            <Route exact path={`${match.url}`} component={SectionList} /> 
            <Route exact path={`${match.url}/new`} component={AddSection} />
            <Route exact path={`${match.url}/:someid`} component={SingleSection} />
            <Route exact path={`${match.url}/:someid/new`} component={AddQuestion}/>
        </div>
    );
  }
}

export default withRouter(Sections);
