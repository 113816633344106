import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { setFormStatus, setSuccessStatus } from '../../../../../actions/user-actions'
import { withApollo } from 'react-apollo';
import { Form, Select, Dropdown, Button, Message, Modal, Image } from "semantic-ui-react";
import { ADD_SUBMISSION, GET_GENERAL_INFO, GET_EDIT_GENERAL_INFO, UPDATE_SUBMISSION, GET_VIEW_GENERAL_INFO, GET_USER_SCORE, GET_SUBMISSION } from '../../../../../queries/SubmissionQueries';
import { GET_MY_PROFILE, GET_USER_PROFILE, GENERIC_PROFILE } from "../../../../../queries/UserQueries";
import navigation_img from '../../../../../assets/images/navigation_flow.jpg';
//import firebase from '../../../firebase/firebase';
import { checkReference } from '../../../../../firebase/FileServices';
import {
    USER_NAME
} from "../../../../../constants/index";
import { async } from "@firebase/util";
import moment from "moment";
import { withNamespaces } from "react-i18next";


var countryOptions = [];
const countryList = {
    rg1: [
        { "key": "ctr1", "value": "Benin", "text": "Benin" },
        { "key": "ctr2", "value": "Botswana", "text": "Botswana" },
        { "key": "ctr3", "value": "Burkina Faso", "text": "Burkina Faso" },
        { "key": "ctr4", "value": "Burundi", "text": "Burundi" },
        { "key": "ctr5", "value": "Cameroon", "text": "Cameroon" },
        { "key": "ctr6", "value": "Cape Verde", "text": "Cape Verde" },
        { "key": "ctr7", "value": "Central African Republic", "text": "Central African Republic" },
        { "key": "ctr8", "value": "Chad", "text": "Chad" },
        { "key": "ctr9", "value": "Comoros", "text": "Comoros" },
        { "key": "ctr10", "value": "Republic of the Congo", "text": "Republic of the Congo" },
        { "key": "ctr11", "value": "Democratic Republic of the Congo", "text": "Democratic Republic of the Congo" },
        { "key": "ctr12", "value": "Cote d’Ivoire", "text": "Cote d’Ivoire" },
        { "key": "ctr13", "value": "Ethiopia", "text": "Ethiopia" },
        { "key": "ctr14", "value": "Ghana", "text": "Ghana" },
        { "key": "ctr15", "value": "Guinea-Bissau", "text": "Guinea-Bissau" },
        { "key": "ctr16", "value": "Guinea-Conakry", "text": "Guinea-Conakry" },
        { "key": "ctr17", "value": "Kenya", "text": "Kenya" },
        { "key": "ctr18", "value": "Lesotho", "text": "Lesotho" },
        { "key": "ctr19", "value": "Liberia", "text": "Liberia" },
        { "key": "ctr20", "value": "Madagascar", "text": "Madagascar" },
        { "key": "ctr21", "value": "Malawi", "text": "Malawi" },
        { "key": "ctr22", "value": "Mali", "text": "Mali" },
        { "key": "ctr23", "value": "Mozambique", "text": "Mozambique" },
        { "key": "ctr24", "value": "Namibia", "text": "Namibia" },
        { "key": "ctr25", "value": "Niger", "text": "Niger" },
        { "key": "ctr26", "value": "Nigeria", "text": "Nigeria" },
        { "key": "ctr27", "value": "Rwanda", "text": "Rwanda" },
        { "key": "ctr28", "value": "Senegal", "text": "Senegal" },
        { "key": "ctr29", "value": "Sierra Leone", "text": "Sierra Leone" },
        { "key": "ctr30", "value": "Swaziland", "text": "Swaziland" },
        { "key": "ctr31", "value": "Tanzania", "text": "Tanzania" },
        { "key": "ctr32", "value": "Togo", "text": "Togo" },
        { "key": "ctr33", "value": "Uganda", "text": "Uganda" },
        { "key": "ctr34", "value": "Zambia", "text": "Zambia" },
        { "key": "ctr35", "value": "Angola", "text": "Angola" },
        { "key": "ctr36", "value": "Mauritius", "text": "Mauritius" },
        { "key": "ctr37", "value": "Republic of South Africa", "text": "Republic of South Africa" },
        { "key": "ctr38", "value": "Sao Tome & Principe", "text": "Sao Tome & Principe" },
        { "key": "ctr39", "value": "Seychelles", "text": "Seychelles" },
        { "key": "ctr40", "value": "South Sudan", "text": "South Sudan" },
        { "key": "ctr41", "value": "Zimbabwe", "text": "Zimbabwe" }
    ],
    rg2: [
        { "key": "ctr42", "value": "Algeria", "text": "Algeria" },
        { "key": "ctr43", "value": "Bahrain", "text": "Bahrain" },
        { "key": "ctr44", "value": "Djibouti", "text": "Djibouti" },
        { "key": "ctr45", "value": "Egypt", "text": "Egypt" },
        { "key": "ctr46", "value": "Jordan", "text": "Jordan" },
        { "key": "ctr47", "value": "Lebanon", "text": "Lebanon" },
        { "key": "ctr48", "value": "Mauritania", "text": "Mauritania" },
        { "key": "ctr49", "value": "Morocco", "text": "Morocco" },
        { "key": "ctr50", "value": "Palestine", "text": "Palestine" },
        { "key": "ctr51", "value": "Somaliland", "text": "Somaliland" },
        { "key": "ctr52", "value": "Sudan", "text": "Sudan" },
        { "key": "ctr53", "value": "Syria", "text": "Syria" },
        { "key": "ctr54", "value": "Tunisia", "text": "Tunisia" },
        { "key": "ctr55", "value": "Iraq", "text": "Iraq" },
        { "key": "ctr56", "value": "Yemen", "text": "Yemen" }
    ],
    rg3: [
        { "key": "ctr57", "value": "Cambodia", "text": "Cambodia" },
        { "key": "ctr58", "value": "China", "text": "China" },
        { "key": "ctr59", "value": "Cook Islands", "text": "Cook Islands" },
        { "key": "ctr60", "value": "Fiji", "text": "Fiji" },
        { "key": "ctr61", "value": "Hong Kong", "text": "Hong Kong" },
        { "key": "ctr62", "value": "Indonesia", "text": "Indonesia" },
        { "key": "ctr63", "value": "Japan", "text": "Japan" },
        { "key": "ctr64", "value": "Kiribati", "text": "Kiribati" },
        { "key": "ctr65", "value": "Democratic People's Republic of Korea", "text": "Democratic People's Republic of Korea" },
        { "key": "ctr66", "value": "Republic of Korea", "text": "Republic of Korea" },
        { "key": "ctr67", "value": "Malaysia", "text": "Malaysia" },
        { "key": "ctr68", "value": "Mongolia", "text": "Mongolia" },
        { "key": "ctr69", "value": "New Zealand", "text": "New Zealand" },
        { "key": "ctr70", "value": "Philippines", "text": "Philippines" },
        { "key": "ctr71", "value": "Samoa", "text": "Samoa" },
        { "key": "ctr72", "value": "Solomon Islands", "text": "Solomon Islands" },
        { "key": "ctr73", "value": "Thailand", "text": "Thailand" },
        { "key": "ctr74", "value": "Tonga", "text": "Tonga" },
        { "key": "ctr75", "value": "Tuvalu", "text": "Tuvalu" },
        { "key": "ctr76", "value": "Vanuatu", "text": "Vanuatu" },
        { "key": "ctr77", "value": "Vietnam", "text": "Vietnam" },
        { "key": "ctr78", "value": "Australia", "text": "Australia" },
        { "key": "ctr79", "value": "Laos", "text": "Laos" },
        { "key": "ctr80", "value": "Myanmar", "text": "Myanmar" },
        { "key": "ctr81", "value": "Papua New Guinea", "text": "Papua New Guinea" }
    ],
    rg4: [
        { "key": "ctr82", "value": "Albania", "text": "Albania" },
        { "key": "ctr83", "value": "Austria", "text": "Austria" },
        { "key": "ctr84", "value": "Belgium", "text": "Belgium" },
        { "key": "ctr85", "value": "Bosnia and Herzegovina", "text": "Bosnia and Herzegovina" },
        { "key": "ctr86", "value": "Bulgaria", "text": "Bulgaria" },
        { "key": "ctr87", "value": "Cyprus", "text": "Cyprus" },
        { "key": "ctr88", "value": "Denmark", "text": "Denmark" },
        { "key": "ctr89", "value": "Estonia", "text": "Estonia" },
        { "key": "ctr90", "value": "Finland", "text": "Finland" },
        { "key": "ctr91", "value": "France", "text": "France" },
        { "key": "ctr92", "value": "Georgia", "text": "Georgia" },
        { "key": "ctr93", "value": "Germany", "text": "Germany" },
        { "key": "ctr94", "value": "Ireland", "text": "Ireland" },
        { "key": "ctr95", "value": "Israel", "text": "Israel" },
        { "key": "ctr96", "value": "Kazakhstan", "text": "Kazakhstan" },
        { "key": "ctr97", "value": "Kyrgyzstan", "text": "Kyrgyzstan" },
        { "key": "ctr98", "value": "Latvia", "text": "Latvia" },
        { "key": "ctr99", "value": "Lithuania", "text": "Lithuania" },
        { "key": "ctr100", "value": "Netherlands", "text": "Netherlands" },
        { "key": "ctr101", "value": "Norway", "text": "Norway" },
        { "key": "ctr102", "value": "Portugal", "text": "Portugal" },
        { "key": "ctr103", "value": "Republic of Macedonia", "text": "Republic of Macedonia" },
        { "key": "ctr104", "value": "Republic of Serbia", "text": "Republic of Serbia" },
        { "key": "ctr105", "value": "Romania", "text": "Romania" },
        { "key": "ctr106", "value": "Slovak Republic", "text": "Slovak Republic" },
        { "key": "ctr107", "value": "Spain", "text": "Spain" },
        { "key": "ctr108", "value": "Sweden", "text": "Sweden" },
        { "key": "ctr109", "value": "Switzerland", "text": "Switzerland" },
        { "key": "ctr110", "value": "Tajikistan", "text": "Tajikistan" },
        { "key": "ctr111", "value": "Ukraine", "text": "Ukraine" },
        { "key": "ctr112", "value": "Greece", "text": "Greece" },
        { "key": "ctr113", "value": "Hungary", "text": "Hungary" },
        { "key": "ctr114", "value": "Italy", "text": "Italy" },
        { "key": "ctr115", "value": "Luxembourg", "text": "Luxembourg" },
        { "key": "ctr116", "value": "Montenegro", "text": "Montenegro" }
    ],
    rg5: [
        { "key": "ctr117", "value": "Afghanistan", "text": "Afghanistan" },
        { "key": "ctr118", "value": "Bangladesh", "text": "Bangladesh" },
        { "key": "ctr119", "value": "India", "text": "India" },
        { "key": "ctr120", "value": "Iran", "text": "Iran" },
        { "key": "ctr121", "value": "Maldives", "text": "Maldives" },
        { "key": "ctr122", "value": "Nepal", "text": "Nepal" },
        { "key": "ctr123", "value": "Pakistan", "text": "Pakistan" },
        { "key": "ctr124", "value": "Sri Lanka", "text": "Sri Lanka" },
        { "key": "ctr125", "value": "Bhutan", "text": "Bhutan" },
    ],
    rg6: [
        { "key": "ctr126", "value": "Barbados", "text": "Barbados" },
        { "key": "ctr127", "value": "Caribbean Family Planning Affiliation", "text": "Caribbean Family Planning Affiliation" },
        { "key": "ctr128", "value": "Colombia", "text": "Colombia" },
        { "key": "ctr129", "value": "El Salvador", "text": "El Salvador" },
        { "key": "ctr130", "value": "Jamaica", "text": "Jamaica" },
        { "key": "ctr131", "value": "Mexico", "text": "Mexico" },
        { "key": "ctr132", "value": "Peru", "text": "Peru" },
        { "key": "ctr133", "value": "Suriname", "text": "Suriname" },
        { "key": "ctr134", "value": "Trinidad and Tobago", "text": "Trinidad and Tobago" },
        { "key": "ctr135", "value": "United States of America", "text": "United States of America" },
        { "key": "ctr136", "value": "Anguilla", "text": "Anguilla" },
        { "key": "ctr137", "value": "Antigua", "text": "Antigua" },
        { "key": "ctr138", "value": "Aruba", "text": "Aruba" },
        { "key": "ctr139", "value": "Bahamas", "text": "Bahamas" },
        { "key": "ctr140", "value": "Bermuda", "text": "Bermuda" },
        { "key": "ctr141", "value": "Curacao", "text": "Curacao" },
        { "key": "ctr142", "value": "Dominica", "text": "Dominica" },
        { "key": "ctr143", "value": "Grenada", "text": "Grenada" },
        { "key": "ctr144", "value": "Guadeloupe", "text": "Guadeloupe" },
        { "key": "ctr145", "value": "Martinique", "text": "Martinique" },
        { "key": "ctr146", "value": "St. Lucia", "text": "St. Lucia" },
        { "key": "ctr147", "value": "St. Vincent", "text": "St. Vincent" }
    ]
}

// rg6: [
//     { "key": "ctr126", "value": "Barbados", "text": "Barbados" },
//     { "key": "ctr127", "value": "Belize", "text": "Belize" },
//     { "key": "ctr128", "value": "Bolivia", "text": "Bolivia" },
//     { "key": "ctr129", "value": "Caribbean Family Planning Affiliation", "text": "Caribbean Family Planning Affiliation" },
//     { "key": "ctr130", "value": "Chile", "text": "Chile" },
//     { "key": "ctr131", "value": "Colombia", "text": "Colombia" },
//     { "key": "ctr132", "value": "Costa Rica", "text": "Costa Rica" },
//     { "key": "ctr133", "value": "Dominican Republic", "text": "Dominican Republic" },
//     { "key": "ctr134", "value": "Ecuador", "text": "Ecuador" },
//     { "key": "ctr135", "value": "El Salvador", "text": "El Salvador" },
//     { "key": "ctr136", "value": "Guatemala", "text": "Guatemala" },
//     { "key": "ctr137", "value": "Guyana", "text": "Guyana" },
//     { "key": "ctr138", "value": "Haiti", "text": "Haiti" },
//     { "key": "ctr139", "value": "Honduras", "text": "Honduras" },
//     { "key": "ctr140", "value": "Jamaica", "text": "Jamaica" },
//     { "key": "ctr141", "value": "Mexico", "text": "Mexico" },
//     { "key": "ctr142", "value": "Panama", "text": "Panama" },
//     { "key": "ctr143", "value": "Paraguay", "text": "Paraguay" },
//     { "key": "ctr144", "value": "Peru", "text": "Peru" },
//     { "key": "ctr145", "value": "Puerto Rico", "text": "Puerto Rico" },
//     { "key": "ctr146", "value": "Suriname", "text": "Suriname" },
//     { "key": "ctr147", "value": "Trinidad and Tobago", "text": "Trinidad and Tobago" },
//     { "key": "ctr148", "value": "United States of America", "text": "United States of America" },
//     { "key": "ctr149", "value": "Uruguay", "text": "Uruguay" },
//     { "key": "ctr150", "value": "Venezuela", "text": "Venezuela" },
//     { "key": "ctr151", "value": "Anguilla", "text": "Anguilla" },
//     { "key": "ctr152", "value": "Antigua", "text": "Antigua" },
//     { "key": "ctr153", "value": "Aruba", "text": "Aruba" },
//     { "key": "ctr154", "value": "Bahamas", "text": "Bahamas" },
//     { "key": "ctr155", "value": "Bermuda", "text": "Bermuda" },
//     { "key": "ctr156", "value": "Curacao", "text": "Curacao" },
//     { "key": "ctr157", "value": "Dominica", "text": "Dominica" },
//     { "key": "ctr158", "value": "Grenada", "text": "Grenada" },
//     { "key": "ctr159", "value": "Guadeloupe", "text": "Guadeloupe" },
//     { "key": "ctr160", "value": "Martinique", "text": "Martinique" },
//     { "key": "ctr161", "value": "St. Lucia", "text": "St. Lucia" },
//     { "key": "ctr162", "value": "St. Vincent", "text": "St. Vincent" },
//     { "key": "ctr163", "value": "Argentina", "text": "Argentina" },
//     { "key": "ctr164", "value": "Brazil", "text": "Brazil" },
//     { "key": "ctr165", "value": "Canada", "text": "Canada" },
//     { "key": "ctr166", "value": "Chile", "text": "Chile" },
//     { "key": "ctr167", "value": "Haiti", "text": "Haiti" },
//     { "key": "ctr168", "value": "Cuba", "text": "Cuba" }
// ]

const regionOption = [
    { key: "rg1", value: "Africa", text: "Africa" },
    { key: "rg6", value: "Americas and the Caribbean", text: "Americas and the Caribbean" },
    { key: "rg2", value: "Arab World", text: "Arab World" },
    { key: "rg3", value: "East and Southeast Asia and Oceania", text: "East and Southeast Asia and Oceania" },
    { key: "rg4", value: "European Network", text: "European Network" },
    { key: "rg5", value: "South Asia", text: "South Asia" }
];
const yearOptions = [
    { key: "1940", value: 1940, text: "1940" },
    { key: "1941", value: 1941, text: "1941" },
    { key: "1942", value: 1942, text: "1942" },
    { key: "1943", value: 1943, text: "1943" },
    { key: "1944", value: 1944, text: "1944" },
    { key: "1945", value: 1945, text: "1945" },
    { key: "1946", value: 1946, text: "1946" },
    { key: "1947", value: 1947, text: "1947" },
    { key: "1948", value: 1948, text: "1948" },
    { key: "1949", value: 1949, text: "1949" },
    { key: "1950", value: 1950, text: "1950" },
    { key: "1951", value: 1951, text: "1951" },
    { key: "1952", value: 1952, text: "1952" },
    { key: "1953", value: 1953, text: "1953" },
    { key: "1954", value: 1954, text: "1954" },
    { key: "1955", value: 1955, text: "1955" },
    { key: "1956", value: 1956, text: "1956" },
    { key: "1957", value: 1957, text: "1957" },
    { key: "1958", value: 1958, text: "1958" },
    { key: "1959", value: 1959, text: "1959" },
    { key: "1960", value: 1960, text: "1960" },
    { key: "1961", value: 1961, text: "1961" },
    { key: "1962", value: 1962, text: "1962" },
    { key: "1963", value: 1963, text: "1963" },
    { key: "1964", value: 1964, text: "1964" },
    { key: "1965", value: 1965, text: "1965" },
    { key: "1966", value: 1966, text: "1966" },
    { key: "1967", value: 1967, text: "1967" },
    { key: "1968", value: 1968, text: "1968" },
    { key: "1969", value: 1969, text: "1969" },
    { key: "1970", value: 1970, text: "1970" },
    { key: "1971", value: 1971, text: "1971" },
    { key: "1972", value: 1972, text: "1972" },
    { key: "1973", value: 1973, text: "1973" },
    { key: "1974", value: 1974, text: "1974" },
    { key: "1975", value: 1975, text: "1975" },
    { key: "1976", value: 1976, text: "1976" },
    { key: "1977", value: 1977, text: "1977" },
    { key: "1978", value: 1978, text: "1978" },
    { key: "1979", value: 1979, text: "1979" },
    { key: "1980", value: 1980, text: "1980" },
    { key: "1981", value: 1981, text: "1981" },
    { key: "1982", value: 1982, text: "1982" },
    { key: "1983", value: 1983, text: "1983" },
    { key: "1984", value: 1984, text: "1984" },
    { key: "1985", value: 1985, text: "1985" },
    { key: "1986", value: 1986, text: "1986" },
    { key: "1987", value: 1987, text: "1987" },
    { key: "1988", value: 1988, text: "1988" },
    { key: "1989", value: 1989, text: "1989" },
    { key: "1990", value: 1990, text: "1990" },
    { key: "1991", value: 1991, text: "1991" },
    { key: "1992", value: 1992, text: "1992" },
    { key: "1993", value: 1993, text: "1993" },
    { key: "1994", value: 1994, text: "1994" },
    { key: "1995", value: 1995, text: "1995" },
    { key: "1996", value: 1996, text: "1996" },
    { key: "1997", value: 1997, text: "1997" },
    { key: "1998", value: 1998, text: "1998" },
    { key: "1999", value: 1999, text: "1999" },
    { key: "2000", value: 2000, text: "2000" },
    { key: "2001", value: 2001, text: "2001" },
    { key: "2002", value: 2002, text: "2002" },
    { key: "2003", value: 2003, text: "2003" },
    { key: "2004", value: 2004, text: "2004" },
    { key: "2005", value: 2005, text: "2005" },
    { key: "2006", value: 2006, text: "2006" },
    { key: "2007", value: 2007, text: "2007" },
    { key: "2008", value: 2008, text: "2008" },
    { key: "2009", value: 2009, text: "2009" },
    { key: "2010", value: 2010, text: "2010" },
    { key: "2011", value: 2011, text: "2011" },
    { key: "2012", value: 2012, text: "2012" },
    { key: "2013", value: 2013, text: "2013" },
    { key: "2014", value: 2014, text: "2014" },
    { key: "2015", value: 2015, text: "2015" },
    { key: "2016", value: 2016, text: "2016" },
    { key: "2017", value: 2017, text: "2017" },
    { key: "2018", value: 2018, text: "2018" },
    { key: "2019", value: 2019, text: "2019" }
];
const mapStateToProps = (state, ownProps) => {
    return {
        formErrorStatus: state.custom_reducer.formErrorStatus,
        formSuccessState: state.custom_reducer.formSuccessState,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setFormStatus: (status) => {
            dispatch(setFormStatus(status))
        },
        setSuccessStatus: (status) => {
            dispatch(setSuccessStatus(status))
        }
    }
}
class ViewSection1 extends Component {
    constructor(props) {
        super(props);
        // const proObj = this.props.location.pathname.split('/');
        this.state = {
            userId: this.props.location.state.userId,
            open: true,
            firstName: "",
            lastName: "",
            fullName: "",
            country: "",
            region: "",
            year: '',
            contactPersonFullName: "",
            contactPersonEmail: "",
            contactPersonSkype: "",
            loading: true,
            sectionEmpty: true,
            size: "small",
            imageStatus: "loading",
            assessmentName: "",
            submissionId: this.props.location.state.submissionId,
            submissionType: this.props.location.state.submissionType,
            assessmentName: this.props.location.state.assessmentName,
            result_status: "Not Ready",
            result_color: "red",
            score: 0,
            user: {
                firstName: "...",
                lastName: "...",
                email: "...",
                id: "...",
                createdAt: "...",
            },
            general: {
                fullName: "...",
                country: "...",
                region: "...",
                year: "...",
            }
        };
        props.setFormStatus({ status: false, title: "", message: "" });
        props.setSuccessStatus({ status: false, title: "", message: "" });
        this._checkGeneralInfo();
        this.checkRef();
        this.get_submission().then(result => {
            this.setState({ score: result.finalScore, result_status: result.seReadinessStatus, result_color: result.scoreDescriptionColor, loading: false });
        }).catch(error => {
            console.log(error);
            this.setState({ loading: false });
        });

        // this.get_user_score().then(result => {
        //     console.log(result)

        //     const high_impact = result['0.4']['weightedValue'] === "NaN" ? parseFloat(0) : parseFloat(result['0.4']['weightedValue']);
        //     const tot_others = (result['0.3']['weightedValue'] === "NaN" ? parseFloat(0) : parseFloat(result['0.3']['weightedValue'])) + (result['0.2']['weightedValue'] === "NaN" ? parseFloat(0) : parseFloat(result['0.2']['weightedValue'])) + (result['0.1']['weightedValue'] === "NaN" ? parseFloat(0) : parseFloat(result['0.1']['weightedValue']));
        //     const tot_marks = tot_others + high_impact;
        //     this.setState({ score: tot_marks });
        //     if (high_impact === 40 && tot_others >= 20) {
        //         this.setState({ result_status: "Ready", result_color: "RGB(146,208,80)", loading: false });
        //     } else if (high_impact > 0 && tot_marks >= 60) {
        //         this.setState({ result_status: "Partially Ready.Additional development required in certain areas.", result_color: "RGB(255,192,0)", loading: false });
        //     }
        // }).catch(error => {
        //     console.log(error);
        //     this.setState({ loading: false });
        // });
        this._getGenericProfile();
    }

    _getGenericProfile = () => {
        const { userId } = this.state;
        this.props.client.query({
            query: GENERIC_PROFILE,
            variables: { userId: userId }
        }).then(result => {
            this.setState({
                user: { ...result.data.getUserFullProfile.user },
                general: { ...result.data.getUserFullProfile.general },
                loading: false,
            })
        }).catch(error => {
            console.log(error);
            this.setState({ loading: false });
        });
    }

    get_user_score = async () => {
        const { userId, submissionId } = this.state
        const result = await this.props.client.query({
            query: GET_USER_SCORE,
            variables: { userId: userId, submissionId: submissionId },
            fetchPolicy: 'network-only'
        });
        return result.data.getUserScore;
    };

    get_submission = async () => {
        const { submissionId, submissionType } = this.state
        const result = await this.props.client.query({
            query: GET_SUBMISSION,
            variables: { submissionId: submissionId, submissionType: submissionType },
            fetchPolicy: 'network-only'
        });
        return result.data.getSubmission;
    };

    checkRef = async () => {
        const result = await checkReference(localStorage.USER_NAME);
        console.log('Checking Reference for user ' + result);
    }

    handleImageLoaded() {
        console.log('Loaded');
        this.open();
        this.setState({ imageStatus: "loaded" });
    }

    handleImageErrored() {
        this.setState({ imageStatus: "failed to load" });
    }

    _checkGeneralInfo = () => {
        const { submissionId, submissionType, userId } = this.state;
        this.props.client.query({
            query: GET_VIEW_GENERAL_INFO,
            variables: { submissionId: submissionId, submissionType: submissionType, userId: userId },
            fetchPolicy: 'network-only'
        }).then(result => {
            console.log(result.data);
            if (result.data.getViewGeneralInfo === null || result.data.getViewGeneralInfo.length === 0) {
                console.log('there is nothing here');
            } else {
                // Apply values 
                if (result.data.getViewGeneralInfo[0].region !== "NA") {
                    const selectedOption = regionOption.filter(function (item) {
                        return item.value == result.data.getViewGeneralInfo[0].region;
                    });
                    console.log(selectedOption)
                    const key = selectedOption[0].key;
                    countryOptions = countryList[key];
                }

                countryOptions.sort((a, b) => (a['text'] || "").toString().localeCompare((b['text'] || "").toString()));
                this.props.client.query({
                    query: GET_USER_PROFILE,
                    variables: { userId: userId },
                    fetchPolicy: 'network-only'
                }).then(result => {
                    console.log(result);
                    this.setState({
                        // loading: false,
                        firstName: result.data.getUserProfile.user.firstName,
                        lastName: result.data.getUserProfile.user.lastName,

                    })
                }).catch(error => {
                    this.setState({
                        // loading: false,
                    })
                });

                this.setState({
                    fullName: result.data.getViewGeneralInfo[0].fullName,
                    country: result.data.getViewGeneralInfo[0].country === "NA" ? "" : result.data.getViewGeneralInfo[0].country,
                    region: result.data.getViewGeneralInfo[0].region === "NA" ? "" : result.data.getViewGeneralInfo[0].region,
                    year: result.data.getViewGeneralInfo[0].year,
                    contactPersonFullName: result.data.getViewGeneralInfo[0].contactPersonFullName,
                    contactPersonEmail: result.data.getViewGeneralInfo[0].contactPersonEmail,
                    contactPersonSkype: result.data.getViewGeneralInfo[0].contactPersonSkype,
                    sectionEmpty: false,
                    assessmentName: result.data.getViewGeneralInfo[0].assessmentName
                })
            }
            this.setState({ loading: false, });
        }).catch(error => {
            console.log(error)
            console.log('there was an error calling general info');
            this.setState({ loading: false, });
        })
    }


    _next = (flag) => {
        const { submissionId, submissionType, userId, assessmentName } = this.state;
        this.props.history.push({ pathname: "/submissions/view/section2", state: { submissionId: submissionId, submissionType: submissionType, userId: userId, assessmentName: assessmentName } });
        // this.props.history.push({ pathname: '/users/'.concat(userId, '/view/section2'), state: { submissionId: submissionId, submissionType: submissionType } });
    };


    showContent(response) {
        console.log(response);
    }

    _handleChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    };

    _handleYearChange = (e, { value }) => this.setState({ year: value });

    _handleRegionChange = (e, { value }) => {
        countryOptions = [];
        const selectedOption = regionOption.filter(function (item) {
            return item.value == value;
        });
        this.setState({ region: value });
        const key = selectedOption[0].key;
        countryOptions = countryList[key];
    }

    _handleCountryChange = (e, { value }) => this.setState({ country: value });

    componentDidMount() {
        window.scrollTo(0, 0)
    }



    close = () => this.setState({ open: false })

    open = () => this.setState({ open: true })

    _goBack = () => {
        const { submissionId, submissionType, userId, assessmentName } = this.state;
        this.props.history.push({ pathname: "/submissions" });
        // this.props.history.goBack();
        // window.location.href = "/assessment";
    };
    _pagi = (path) => {
        const { submissionId, submissionType, userId, assessmentName } = this.state;
        this.props.history.push({ pathname: '/submissions/view/'.concat(path), state: { submissionId: submissionId, submissionType: submissionType, userId: userId, assessmentName: assessmentName } });
    }

    render() {
        const { formErrorStatus, formSuccessState, t, i18n } = this.props;
        const { open, size, result_color, result_status, score } = this.state

        return (
            <div style={{ backgroundColor: "#fff" }} class="remove-disabled">

                {/* Begin Dark Header */}
                <div>
                    <div class="uk-background-muted uk-margin-top uk-padding" style={{ paddingBottom: "15px", paddingTop: "15px" }}>
                        <div class="uk-grid" uk-grid>
                            <div class="uk-width-expand@m">
                                <form class=" uk-flex-inline uk-flex-middle uk-text-small">
                                    <h4 class="uk-margin-remove-bottom uk-margin-right">
                                        {" "}
                                        {this.state.general.fullName}
                                    </h4>
                                </form>
                                <h6 class="uk-margin-remove-top uk-text-left">
                                    {" "}
                                    {this.state.general.country}, {this.state.general.region}
                                </h6>
                            </div>
                            <div class="uk-width-auto@m uk-text-small">
                                <p class="uk-text-right uk-margin-remove-bottom"> {t("Submitted by")}: <strong> {this.state.user.firstName} {this.state.user.lastName} </strong> </p>
                                <p class="uk-text-right uk-margin-remove-top uk-margin-remove-bottom"> {t("Assessment name")}: <strong>{this.state.assessmentName} </strong></p>
                                <p class="uk-text-right uk-margin-remove-top uk-margin-remove-bottom">{t("Account created on")}: {moment(this.state.user.createdAt).format('DD-MMMM-YYYY')}</p>
                            </div>
                        </div>
                    </div>
                    {/* <div class="uk-background-muted" style={{ paddingRight: "40px", paddingBottom: "20px" }}>
                        <div class="uk-width-auto@m uk-text-small">

                        </div>
                    </div> */}
                </div>

                <div class="uk-background-muted" style={{ paddingRight: "40px", paddingBottom: "15px" }}>
                    <div class="uk-width-auto@m uk-text-small">
                        <p class="uk-text-right uk-margin-remove-top" style={{ color: result_color }}><b>{t(result_status)}: {score}</b></p>

                    </div>
                </div>


                <div className="uk-section-default uk-section" style={{ paddingTop: "10px" }}>
                    {/* <div className="uk-container-small uk-margin-auto">
                    </div> */}
                    <Form success error className="formDisabled">
                        <div
                            className="uk-container-small uk-margin-auto uk-margin-small-top uk-padding-small paper-read"
                            uk-scrollspy="target: > .paper; cls:uk-animation-slide-bottom-small; delay: 200"
                        >
                            <div className="paper uk-link-reset " style={{ backgroundColor: "#686c6d" }}>
                                <div className="uk-grid uk-flex-middle">
                                    <div className="uk-width-1-6 uk-flex-first uk-text-center">
                                        <i className="far fa-comment-alt icon-xxlarge"></i>
                                    </div>
                                    <div className="uk-width-5-6">
                                        <h4 className="uk-margin-remove" style={{ color: "#ffffff" }}>
                                            {" "}
                                            {t("General Details")}{" "}
                                        </h4>
                                        {/* <p className="uk-margin-remove">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                    </p> */}

                                    </div>
                                </div>
                            </div>
                            <div className="uk-link-reset paper uk-margin-remove-bottom">
                                {/* General Details */}
                                <div
                                    id="register"
                                    className="animation: uk-animation-slide-left-medium"
                                >
                                    <p className="uk-text-muted uk-margin-remove-top uk-margin-small-bottom">
                                        {" "}
                                    </p>
                                    {/* <div className="uk-form-label uk-margin-medium-top">User Category<i style={styles.icon} className="icon red">*</i></div>
                  <div className="uk-inline">
                    <label style={{ paddingRight: "15px" }}> <input checked required readOnly disabled className="uk-radio"  type="radio" id="user_category" name="user_category" value="1" /> Central Office </label>
                    <label style={{ paddingRight: "15px" }}> <input className="uk-radio" type="radio" id="user_category" name="user_category" value="2" /> Regional Office </label>
                    <label> <input className="uk-radio"  type="radio" id="user_category" name="user_category" value="3" /> Member Association </label>
                  </div> */}

                                    <div className="uk-form-label">{t("User Category")}<i style={styles.icon} className="icon red">*</i></div>
                                    <div>
                                        <input
                                            name="firstName"
                                            id="firstName"
                                            value={(this.state.fullName === "International Planned Parenthood Federation") ? (
                                                "Central Office"
                                            ) : (this.state.fullName === "NA") ? ("Regional Office") : ("Member Association")}
                                            className="uk-input uk-form-width-large"
                                            placeholder=""
                                            type="text"
                                            onChange={this._handleChange}
                                            // required
                                            readOnly
                                            disabled
                                        />
                                    </div>

                                    <div className="uk-form-label">{t("First Name")}<i style={styles.icon} className="icon red">*</i></div>
                                    <div>
                                        <input
                                            name="firstName"
                                            id="firstName"
                                            value={this.state.firstName}
                                            className="uk-input uk-form-width-large"
                                            placeholder=""
                                            type="text"
                                            onChange={this._handleChange}
                                            // required
                                            readOnly
                                            disabled
                                        />
                                    </div>

                                    <div className="uk-form-label">{t("Last Name")}<i style={styles.icon} className="icon red">*</i></div>
                                    <div>
                                        <input
                                            name="lastName"
                                            id="lastName"
                                            value={this.state.lastName}
                                            className="uk-input uk-form-width-large"
                                            placeholder=""
                                            type="text"
                                            onChange={this._handleChange}
                                            // required
                                            readOnly
                                            disabled
                                        />
                                    </div>
                                    <div className="uk-form-label">
                                        {(this.state.fullName === "International Planned Parenthood Federation") ? (
                                            t("Organization")
                                        ) : (this.state.fullName === "NA") ? t("Regional Office Name") : t("Full name of the Member Association (MA)")}
                                        <i style={styles.icon} className="icon red">*</i>
                                    </div>
                                    <div>
                                        {/* <span className="uk-form-icon">
                      <i className="far fa-User icon-medium"></i>
                    </span> */}
                                        <input
                                            onChange={this._handleChange}
                                            name="fullName"
                                            id="fullName"
                                            value={this.state.fullName}
                                            className="uk-input uk-form-width-large"
                                            placeholder={t("Full name")}
                                            type="text"
                                            // required
                                            readOnly
                                            disabled="disabled"
                                        />
                                    </div>
                                    <div className="uk-form-label">{t("IPPF Region")}<i style={styles.icon} className="icon red">*</i></div>
                                    <div>
                                        {/* <span className="uk-form-icon">
                      <i className="far fa-envelope icon-medium"></i>
                    </span> */}
                                        {/* <Dropdown
                      placeholder="Select your IPPF Region"
                      onChange={this._handleRegionChange}
                      id="region"
                      name="region"
                      selection
                      value={this.state.region}
                      options={regionOption}
                      required
                      disabled
                      readOnly

                    /> */}

                                        <input
                                            name="region"
                                            id="region"
                                            value={this.state.region === "" ? "NA" : this.state.region}
                                            className="uk-input uk-form-width-large"
                                            // placeholder="Full name"
                                            type="text"
                                            // required
                                            readOnly
                                            disabled="disabled"
                                        />
                                    </div>
                                    <div className="uk-form-label">{t("Country")}<i style={styles.icon} className="icon red">*</i></div>
                                    <div>
                                        {/* <span className="uk-form-icon">
                      <i className="far fa-envelope icon-medium"></i>
                    </span> */}
                                        {/* <Dropdown
                      placeholder="Select your country"
                      onChange={this._handleCountryChange}
                      name="country"
                      id="country"
                      selection
                      value={this.state.country}
                      options={countryOptions}
                      required
                      disabled
                      readOnly
                    /> */}

                                        <input
                                            name="country"
                                            id="country"
                                            value={this.state.country === "" ? "NA" : this.state.country}
                                            className="uk-input uk-form-width-large"
                                            // placeholder="Full name"
                                            type="text"
                                            // required
                                            readOnly
                                            disabled="disabled"
                                        />
                                    </div>


                                    <div className="uk-form-label">{t("Year of establishment")}</div>
                                    <div>
                                        <input
                                            name="country"
                                            id="country"
                                            value={this.state.year === 0 || this.state.year === "" ? "NA" : this.state.year}
                                            className="uk-input uk-form-width-large"
                                            // placeholder="Full name"
                                            type="text"
                                            // required
                                            readOnly
                                            disabled="disabled"
                                        />
                                    </div>
                                    <hr />
                                    <div className="uk-form-label"><b>{t("For this Assessment - provide an assessment name and contact person details")}</b></div>

                                    <div className="uk-margin"></div>

                                    <div className="uk-form-label">{t("Assessment name")}<i style={styles.icon} className="icon red">*</i></div>
                                    <div>
                                        <span className="uk-form-icon">
                                            <i className="far fa-User icon-medium"></i>
                                        </span>
                                        <input
                                            onChange={this._handleChange}
                                            name="assessmentName"
                                            id="assessmentName"
                                            value={this.state.assessmentName}
                                            className="uk-input uk-form-width-large"
                                            placeholder={t("Assessment name")}
                                            type="text"
                                            required
                                            minLength="2"
                                            disabled="disabled"
                                        />
                                    </div>

                                    <div className="uk-form-label">{t("Full name")}<i style={styles.icon} className="icon red">*</i></div>
                                    <div>
                                        <span className="uk-form-icon">
                                            <i className="far fa-User icon-medium"></i>
                                        </span>
                                        <input
                                            onChange={this._handleChange}
                                            name="contactPersonFullName"
                                            id="contactPersonFullName"
                                            value={this.state.contactPersonFullName}
                                            className="uk-input uk-form-width-large"
                                            placeholder={t("Full name")}
                                            type="text"
                                            required
                                            minLength="2"
                                            disabled="disabled"
                                        />
                                    </div>

                                    <div className="uk-form-label">{t("Official Email Address")}<i style={styles.icon} className="icon red">*</i></div>
                                    <div>
                                        <span className="uk-form-icon">
                                            <i className="far fa-User icon-medium"></i>
                                        </span>
                                        <input
                                            onChange={this._handleChange}
                                            name="contactPersonEmail"
                                            id="contactPersonEmail"
                                            value={this.state.contactPersonEmail}
                                            className="uk-input uk-form-width-large"
                                            placeholder={t("Official Email Address")}
                                            type="email"
                                            required
                                            disabled="disabled"
                                        />
                                    </div>

                                    <div className="uk-form-label">{t("Skype ID")}</div>
                                    <div>
                                        <span className="uk-form-icon">
                                            <i className="far fa-User icon-medium"></i>
                                        </span>
                                        <input
                                            onChange={this._handleChange}
                                            name="contactPersonSkype"
                                            id="contactPersonSkype"
                                            value={this.state.contactPersonSkype}
                                            className="uk-input uk-form-width-large"
                                            placeholder={t("Skype ID")}
                                            type="text"
                                            disabled="disabled"
                                        // required
                                        />
                                    </div>

                                    <div className="uk-margin"></div>
                                    {(formErrorStatus.status) ? (
                                        <div className="danger_alert">{t(formErrorStatus.message)}</div>
                                    ) : ((formSuccessState.status) ? (
                                        <div className="success_alert">{t(formSuccessState.message)}</div>
                                    ) : (''))}
                                </div>
                                {/* General Details */}
                            </div>

                            <div className="uk-text-center uk-grid uk-grid-small uk-child-width-1-6 uk-margin-small quick-nav" uk-grid>
                                <div>
                                    <div className="uk-card">
                                        <li className="uk-active"><a className={localStorage.RAT_LANG=="fr"? "uk-button uk-button-default uk-button-small uk-width-1-1 fr_MA_contact" : localStorage.RAT_LANG=='ar'? "Ar_MA_contact uk-button uk-button-default uk-button-small uk-width-1-1": localStorage.RAT_LANG=="sp"? "uk-button uk-button-default uk-button-small uk-width-1-1 sp_MA_contact":"uk-button uk-button-default uk-button-small uk-width-1-1"}  onClick={(e) => this._pagi("section1")} href="javascript:void(0)">{t("MA Contact")}</a></li>
                                    </div>
                                </div>
                                <div>
                                    <div className="uk-card">
                                        <li><a className="uk-button uk-button-default uk-button-small uk-width-1-1" onClick={(e) => this._pagi("section2")} href="javascript:void(0)">{t("Operations")}</a></li>
                                    </div>
                                </div>
                                <div>
                                    <div className="uk-card">
                                        <li><a className="uk-button uk-button-default uk-button-small uk-width-1-1" onClick={(e) => this._pagi("section3")} href="javascript:void(0)">{t("Financial")}</a></li>
                                    </div>
                                </div>
                                <div>
                                    <div className="uk-card">
                                        <li><a className="uk-button uk-button-default uk-button-small uk-width-1-1" onClick={(e) => this._pagi("section4")} href="javascript:void(0)">{t("HR")}</a></li>
                                    </div>
                                </div>
                                <div>
                                    <div className="uk-card">
                                        <li><a className="uk-button uk-button-default uk-button-small uk-width-1-1" onClick={(e) => this._pagi("section5")} href="javascript:void(0)">{t("Resources")}</a></li>
                                    </div>
                                </div>
                                <div>
                                    <div className="uk-card">
                                        <li><a className="uk-button uk-button-default uk-button-small uk-width-1-1" onClick={(e) => this._pagi("section6")} href="javascript:void(0)">{t("Other")}</a></li>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="uk-grid uk-text-center button_div">
                                <div className="uk-width-1-3@m">
                                    <Button
                                        basic
                                        className="outline-button blueButton"
                                        onClick={this._goBack}>
                                        {t("Go Back")}
                                    </Button>
                                </div>


                                <div className="uk-width-expand@m">

                                </div>

                                <div className="uk-width-1-3@m">
                                    <div>
                                        <Button
                                            type="submit"
                                            onClick={(e) => this._next(true)}
                                            className="next-button purpleButton"
                                            loading={this.state.loading}
                                            basic
                                        >
                                            {t("Next")}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        );
    }
}

const styles = {
    stickyStyle: {
        zIndex: "980"
    },
    icon: {
        width: "0px"
    },
    navigation_flow: {
        backgroundImage: `url(${navigation_img})`,
        // backgroundImage: URL("../../../assets/images/navigation_flow.jpg")
    }
};

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(ViewSection1)))); 
