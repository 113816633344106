import React, { Component } from "react";
import SectionLoader from "../../../../generic/assesment/SectionLoader";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import {
    Form,
    Button,
    TextArea,
    Input,
    Label,
    Loader,
    Message,
    Icon,
    Popup,
    Grid,
    Header,
    Confirm,
    Progress
} from "semantic-ui-react";
import { GET_SECTION_II } from "../../../../../queries/SectionQueries";
import {
    GET_SECTION_1,
    SUBMIT_SECTION_1,
    UPDATE_SECTION_1,
    GET_EDIT_SECTION_SUBMISSION,
    GET_ALL_EDIT_SUBMMISSIONS,
    GET_USER_SCORE,
    GET_SUBMISSION
} from "../../../../../queries/SubmissionQueries";

import { USER_NAME } from "../../../../../constants/index";

import { uploadFile, deleteFile } from "../../../../../firebase/FileServices";
import { Promise } from "q";
import { setFormStatus, setSuccessStatus } from '../../../../../actions/user-actions';
import { connect } from 'react-redux';
import sectionTwoQuestions from '../../../../questions/section2_questions.json';
import moment from "moment";
import { GENERIC_PROFILE } from "../../../../../queries/UserQueries";
import { withNamespaces } from "react-i18next";

const mapStateToProps = (state, ownProps) => {
    return {
        formErrorStatus: state.custom_reducer.formErrorStatus,
        formSuccessState: state.custom_reducer.formSuccessState,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setFormStatus: (status) => {
            dispatch(setFormStatus(status))
        },
        setSuccessStatus: (status) => {
            dispatch(setSuccessStatus(status))
        }
    }
}

class ViewSection3 extends Component {
    constructor(props) {
        super(props);

        this.props.setFormStatus({ status: false, title: '', message: '' });
        this.props.setSuccessStatus({ status: false, title: '', message: '' });
        const proObj = this.props.location.pathname.split('/');
        this.state = {
            submissionId: this.props.location.state.submissionId,
            submissionType: this.props.location.state.submissionType,
            assessmentName: this.props.location.state.assessmentName,
            userId: proObj[2],
            loading: true,
            spinner: false,
            saveNContinue: false,
            sectionEmpty: true,
            open: false,
            confirmAction: false,
            section: {
                id: "",
                sectionNumber: "",
                title: "",
                subTitle: "",
                description: "",
                sequence: "",
                createdAt: "",
                updatedAt: ""
            },
            // question1: {
            //     showFollowupQuestion: false,
            //     primaryQuestion:
            //         "Will your current donor(s) be supportive if your organization adopts income generating activities for delivering SRHR products and services?",
            //     primaryAnswer: false,
            //     secondaryQuestion: "If No, explain the concerns.",
            //     secondaryAnswer: "",
            //     weightFactor: "0.4",
            //     uploads: [],
            //     validated: false,
            //     isScore: false,
            //     feedBack: "",
            //     score: "",
            // },
            // question2: {
            //     showFollowupQuestion: true,
            //     primaryQuestion:
            //         "Does your organization issue an annual financial statement, monthly income and expenditure statement and (if applicable) any profit and loss report(s)?",
            //     primaryAnswer: false,
            //     secondaryQuestion: "If No, specify the reasons.",
            //     secondaryAnswer: "",
            //     weightFactor: "0.2",
            //     uploads: [],
            //     validated: false,
            //     isScore: false,
            //     feedBack: "",
            //     score: "",
            // },
            // question3: {
            //     showFollowupQuestion: false,
            //     primaryQuestion:
            //         "Does your organization charge clients for any activities/services rendered?",
            //     primaryAnswer: false,
            //     secondaryQuestion: "If Yes, specify the details.",
            //     secondaryAnswer: "",
            //     weightFactor: "0.1",
            //     uploads: [],
            //     validated: true,
            //     isScore: false,
            //     feedBack: "",
            //     score: "",
            // },
            // question4: {
            //     showFollowupQuestion: false,
            //     primaryQuestion: "Does your organization have a procurement policy?",
            //     primaryAnswer: false,
            //     secondaryQuestion:
            //         "If Yes, please attach a copy of the policy. If No, specify the reasons.",
            //     secondaryAnswer: "",
            //     uploads: [],
            //     fileUrl: null,
            //     fileRef: null,
            //     tempFile: "",
            //     weightFactor: "0.1",
            //     isUploading: false,
            //     validated: false,
            //     isScore: false,
            //     feedBack: "",
            //     score: "",
            // },
            // question5: {
            //     showFollowupQuestion: false,
            //     primaryQuestion:
            //         "Does your organization have a staff remuneration policy and salary scales?",
            //     primaryAnswer: false,
            //     secondaryQuestion:
            //         "If Yes, please attach the policy and salary scale guidelines. If No, specify the reasons.",
            //     secondaryAnswer: "",
            //     uploads: [],
            //     fileUrl: null,
            //     fileRef: null,
            //     tempFile: "",
            //     weightFactor: "0.2",
            //     isUploading: false,
            //     validated: false,
            //     isScore: false,
            //     feedBack: "",
            //     score: "",
            // },
            // question6: {
            //     showFollowupQuestion: false,
            //     primaryQuestion:
            //         "Is your organization audited by an external auditor?",
            //     primaryAnswer: false,
            //     secondaryQuestion:
            //         "If Yes, specify the type of audits and frequency. If No, specify the reasons why not audited.",
            //     secondaryAnswer: "",
            //     weightFactor: "0.1",
            //     uploads: [],
            //     validated: false,
            //     isScore: false,
            //     feedBack: "",
            //     score: "",
            // },
            // question7: {
            //     showFollowupQuestion: true,
            //     primaryQuestion:
            //         "As a statutory obligation, in what matters do the Board members get involved as signatories?",
            //     primaryAnswer: "",
            //     secondaryQuestion: "",
            //     secondaryAnswer: "",
            //     weightFactor: "-0.1",
            //     uploads: [],
            //     validated: false,
            //     isScore: true,
            //     feedBack: "",
            //     score: "",
            // },
            // question8: {
            //     showFollowupQuestion: true,
            //     primaryQuestion:
            //         "Which financial institutions does your organization engage with (banks, savings societies, etc.)?",
            //     primaryAnswer: "",
            //     secondaryQuestion: "",
            //     secondaryAnswer: "",
            //     weightFactor: "-0.1",
            //     uploads: [],
            //     validated: false,
            //     isScore: true,
            //     feedBack: "",
            //     score: "",
            // },
            // question9: {
            //     showFollowupQuestion: false,
            //     primaryQuestion:
            //         "Can your organization borrow working capital from the bank to finance the social enterprise/business idea?",
            //     primaryAnswer: false,
            //     secondaryQuestion: "",
            //     secondaryAnswer: "",
            //     weightFactor: "0.4",
            //     uploads: [],
            //     validated: true,
            //     isScore: false,
            //     feedBack: "",
            //     score: "",
            // },
            // question10: {
            //     showFollowupQuestion: true,
            //     primaryQuestion:
            //         "What is the USD value of your Annual Programme Budget (APB) for the ongoing year?",
            //     primaryAnswer: "",
            //     secondaryQuestion: "",
            //     secondaryAnswer: "",
            //     weightFactor: "-0.2",
            //     uploads: [],
            //     validated: false,
            //     isScore: true,
            //     feedBack: "",
            //     score: "",
            // },
            // question11: {
            //     showFollowupQuestion: true,
            //     primaryQuestion:
            //         "What is the USD value of the IPPF core grant (if applicable) for the ongoing year?",
            //     primaryAnswer: "",
            //     secondaryQuestion: "",
            //     secondaryAnswer: "",
            //     weightFactor: "-0.2",
            //     uploads: [],
            //     validated: false,
            //     isScore: true,
            //     feedBack: "",
            //     score: "",
            // },
            // question12: {
            //     showFollowupQuestion: true,
            //     primaryQuestion:
            //         "What is the USD value of your (local) self-generated income for the last financial year?",
            //     primaryAnswer: "",
            //     secondaryQuestion: "",
            //     secondaryAnswer: "",
            //     weightFactor: "-0.4",
            //     uploads: [],
            //     validated: false,
            //     isScore: true,
            //     feedBack: "",
            //     score: "",
            // },
            ...sectionTwoQuestions,
            sectionFeedBack: "",
            validation_arr: [],
            progress: 0,
            result_status: "Not Ready",
            result_color: "red",
            score: 0,
            user: {
                firstName: "...",
                lastName: "...",
                email: "...",
                id: "...",
                createdAt: "...",
            },
            general: {
                fullName: "...",
                country: "...",
                region: "...",
                year: "...",
            },
        };
        props.setFormStatus({ status: false, title: "", message: "" });
        props.setSuccessStatus({ status: false, title: "", message: "" });
        this._getSection3();
        this._getSection3Submissions();
        this.get_all_submissions().then(submission => {
            submission.map(item => {
                Object.keys(item.submission).map(key => {
                    if (item.submission[key]['validated'] !== undefined && item.submission[key]['validated'] === true) {
                        this.state.validation_arr.push(item.submission[key]['validated']);
                    }
                })
            });
            console.log(this.state.validation_arr)
            const percent_progress = parseFloat((this.state.validation_arr.length / 48) * 100).toFixed(0)
            this.setState({
                progress: percent_progress
            });
        });
        this.get_submission().then(result => {
            this.setState({ score: result.finalScore, result_status: result.seReadinessStatus, result_color: result.scoreDescriptionColor, loading: false });
        }).catch(error => {
            console.log(error);
            this.setState({ loading: false });
        });
        // this.get_user_score().then(result => {

        //     const high_impact = result['0.4']['weightedValue'] === "NaN" ? parseFloat(0) : parseFloat(result['0.4']['weightedValue']);
        //     const tot_others = (result['0.3']['weightedValue'] === "NaN" ? parseFloat(0) : parseFloat(result['0.3']['weightedValue'])) + (result['0.2']['weightedValue'] === "NaN" ? parseFloat(0) : parseFloat(result['0.2']['weightedValue'])) + (result['0.1']['weightedValue'] === "NaN" ? parseFloat(0) : parseFloat(result['0.1']['weightedValue']));
        //     const tot_marks = tot_others + high_impact;
        //     this.setState({ score: tot_marks });
        //     if (high_impact === 40 && tot_others >= 20) {
        //         this.setState({ result_status: "Ready", result_color: "RGB(146,208,80)", loading: false });
        //     } else if (high_impact > 0 && tot_marks >= 60) {
        //         this.setState({ result_status: "Partially Ready.Additional development required in certain areas.", result_color: "RGB(255,192,0)", loading: false });
        //     }
        // }).catch(error => {
        //     console.log(error);
        //     this.setState({ loading: false });
        // });
        this._getGenericProfile();
    }
    _getGenericProfile = () => {
        const { userId } = this.state;
        this.props.client.query({
            query: GENERIC_PROFILE,
            variables: { userId: userId }
        }).then(result => {
            console.log(result)
            this.setState({
                user: { ...result.data.getUserFullProfile.user },
                general: { ...result.data.getUserFullProfile.general },
                loading: false,
            })
        }).catch(error => {
            console.log(error);
            this.setState({ loading: false });
        });
    }
    get_user_score = async () => {
        const { userId, submissionId } = this.state
        const result = await this.props.client.query({
            query: GET_USER_SCORE,
            variables: { userId: userId, submissionId: submissionId },
            fetchPolicy: 'network-only'
        });
        return result.data.getUserScore;
    };
    get_submission = async () => {
        const { submissionId, submissionType } = this.state
        const result = await this.props.client.query({
            query: GET_SUBMISSION,
            variables: { submissionId: submissionId, submissionType: submissionType },
            fetchPolicy: 'network-only'
        });
        return result.data.getSubmission;
    };
    get_all_submissions = async () => {
        const { submissionId, submissionType } = this.state;
        console.log(submissionId)
        const result = await this.props.client.query({
            query: GET_ALL_EDIT_SUBMMISSIONS,
            variables: { submissionId: submissionId },
            fetchPolicy: 'network-only'
        });
        return result.data.getAllEditSubmissionsByUser;
    };
    componentDidMount() {
        window.scrollTo(0, 0)
        setTimeout(() => {
            this.setState({
                loading: false
            });
        }, 2000);
    }
    _goBack = () => {
        const { submissionId, submissionType, userId, assessmentName } = this.state;
        this.props.history.push({ pathname: '/users/'.concat(userId, '/view/section2'), state: { submissionId: submissionId, submissionType: submissionType, assessmentName: assessmentName } });
        // this.props.history.goBack();
    };

    _next = () => {
        const { submissionId, submissionType, userId, assessmentName } = this.state;
        this.props.history.push({ pathname: '/users/'.concat(userId, '/view/section4'), state: { submissionId: submissionId, submissionType: submissionType, assessmentName: assessmentName } });
    };

    _getSection3 = () => {
        this.props.client
            .query({
                query: GET_SECTION_II
            })
            .then(result => {
                this.setState({
                    section: { ...result.data.getSection1 }
                });
            })
            .catch(error => {
                console.log(error);
            });
    };

    _getSection3Submissions = () => {
        const { submissionId, submissionType } = this.state;
        this.props.client
            .query({
                query: GET_EDIT_SECTION_SUBMISSION,
                variables: { sectionId: "ck0xh4o71pbyx0b17v0fmtjb4", submissionId: submissionId },
                fetchPolicy: 'network-only'
            })
            .then(result => {
                console.log(result);
                if (result.data.getEditSectionSubmission === null || result.data.getEditSectionSubmission.length === 0) {
                    console.log('there is nothing here');
                } else {
                    // Apply values 
                    this.setState({
                        question1: result.data.getEditSectionSubmission.submission.question1
                            ? { ...result.data.getEditSectionSubmission.submission.question1, weightFactor: this.state.question1.weightFactor }
                            : this.state.question1,
                        question2: result.data.getEditSectionSubmission.submission.question2
                            ? { ...result.data.getEditSectionSubmission.submission.question2, weightFactor: this.state.question2.weightFactor }
                            : this.state.question2,
                        question3: result.data.getEditSectionSubmission.submission.question3
                            ? { ...result.data.getEditSectionSubmission.submission.question3, weightFactor: this.state.question3.weightFactor }
                            : this.state.question3,
                        question4: result.data.getEditSectionSubmission.submission.question4
                            ? { ...result.data.getEditSectionSubmission.submission.question4, weightFactor: this.state.question4.weightFactor }
                            : this.state.question4,
                        question5: result.data.getEditSectionSubmission.submission.question5
                            ? { ...result.data.getEditSectionSubmission.submission.question5, weightFactor: this.state.question5.weightFactor }
                            : this.state.question5,
                        question6: result.data.getEditSectionSubmission.submission.question6
                            ? { ...result.data.getEditSectionSubmission.submission.question6, weightFactor: this.state.question6.weightFactor }
                            : this.state.question6,
                        question7: result.data.getEditSectionSubmission.submission.question7
                            ? { ...result.data.getEditSectionSubmission.submission.question7, weightFactor: this.state.question7.weightFactor }
                            : this.state.question7,
                        question8: result.data.getEditSectionSubmission.submission.question8
                            ? { ...result.data.getEditSectionSubmission.submission.question8, weightFactor: this.state.question8.weightFactor }
                            : this.state.question8,
                        question9: result.data.getEditSectionSubmission.submission.question9
                            ? { ...result.data.getEditSectionSubmission.submission.question9, weightFactor: this.state.question9.weightFactor }
                            : this.state.question9,
                        question10: result.data.getEditSectionSubmission.submission.question10
                            ? { ...result.data.getEditSectionSubmission.submission.question10, weightFactor: this.state.question10.weightFactor }
                            : this.state.question10,
                        question11: result.data.getEditSectionSubmission.submission.question11
                            ? { ...result.data.getEditSectionSubmission.submission.question11, weightFactor: this.state.question11.weightFactor }
                            : this.state.question11,
                        question12: result.data.getEditSectionSubmission.submission.question12
                            ? { ...result.data.getEditSectionSubmission.submission.question12, weightFactor: this.state.question12.weightFactor }
                            : this.state.question12,
                        sectionFeedBack: result.data.getEditSectionSubmission.submission.sectionFeedBack ? result.data.getEditSectionSubmission.submission.sectionFeedBack : this.state.sectionFeedBack,
                        sectionEmpty: false
                    });
                }
            })
            .catch(error => {
                console.log(error);
            });
    };

    // Question1 Stuff
    _question1Followup = state => {
        state
            ? this.setState({
                question1: {
                    ...this.state.question1,
                    showFollowupQuestion: true,
                    primaryAnswer: true,
                    validated: true
                }
            })
            : this.setState({
                question1: {
                    ...this.state.question1,
                    showFollowupQuestion: false,
                    primaryAnswer: false,
                    validated: this.state.question1.secondaryAnswer.trim() === "" ? false : true
                }
            });
    };

    _question1Onchange = event => {
        this.setState({
            question1: {
                ...this.state.question1,
                [event.target.id]: event.target.value,
                validated: event.target.value.trim() === "" ? false : true
            }
        });
    };

    // Question 2 stuff
    _question2Followup = state => {
        state
            ? this.setState({
                question2: {
                    ...this.state.question2,
                    showFollowupQuestion: false,
                    primaryAnswer: true,
                    validated: true
                }
            })
            : this.setState({
                question2: {
                    ...this.state.question2,
                    showFollowupQuestion: true,
                    primaryAnswer: false,
                    validated: this.state.question2.secondaryAnswer.trim() === "" ? false : true
                }
            });
    };

    _question2Onchange = event => {
        this.setState({
            question2: {
                ...this.state.question2,
                secondaryAnswer: event.target.value,
                validated: event.target.value.trim() === "" ? false : true
            }
        });
    };

    // Question 3 stuff
    _question3Followup = state => {
        state
            ? this.setState({
                question3: {
                    ...this.state.question3,
                    showFollowupQuestion: true,
                    primaryAnswer: true,
                    validated: this.state.question3.secondaryAnswer.trim() === "" ? false : true
                }
            })
            : this.setState({
                question3: {
                    ...this.state.question3,
                    showFollowupQuestion: false,
                    primaryAnswer: false,
                    validated: true
                }
            });
    };

    _question3Onchange = event => {
        this.setState({
            question3: {
                ...this.state.question3,
                secondaryAnswer: event.target.value,
                validated: event.target.value.trim() === "" ? false : true
            }
        });
    };

    //Question 4 stuff
    _question4Followup = state => {
        state
            ? this.setState({
                question4: {
                    ...this.state.question4,
                    showFollowupQuestion: true,
                    primaryAnswer: true,
                    validated: (this.state.question4.fileUrl === null || this.state.question4.fileUrl === undefined) ? false : true
                }
            })
            : this.setState({
                question4: {
                    ...this.state.question4,
                    showFollowupQuestion: false,
                    primaryAnswer: false,
                    validated: this.state.question4.secondaryAnswer.trim() === "" ? false : true
                }
            });
    };
    _question4FileOnchange = event => {
        this.setState({
            question4: {
                ...this.state.question4,
                tempFile: event.target.files[0],
                isUploading: true,
            }
        });
        console.log(event.target.files[0]);
        console.log(this.state);
        this._startUpload(event.target.files[0]);
        this.setState({
            isUploading: true
        });
    };

    _startUpload = async file => {
        const result = await uploadFile(file, localStorage.USER_NAME);
        if (result.status) {
            this.setState({
                isUploading: false,
                question4: {
                    ...this.state.question4,
                    fileRef: 'uploads/' + localStorage.USER_NAME + '/' + file.name,
                    fileUrl: result.url,
                    isUploading: false,
                    validated: true
                }
            });
        }
    };

    _deleteFile = async (path) => {
        const deleteRef = await deleteFile(path);
        this.setState({
            question4: {
                ...this.state.question4,
                validated: false
            }
        });
        return deleteRef;
    }

    _question4Onchange = event => {
        this.setState({
            question4: {
                ...this.state.question4,
                secondaryAnswer: event.target.value,
                validated: event.target.value.trim() === "" ? false : true
            }
        });
    };

    // Question 5 stuff
    _question5Followup = state => {
        state
            ? this.setState({
                question5: {
                    ...this.state.question5,
                    showFollowupQuestion: true,
                    primaryAnswer: true,
                    validated: (this.state.question5.fileUrl === null || this.state.question5.fileUrl === undefined) ? false : true
                }
            })
            : this.setState({
                question5: {
                    ...this.state.question5,
                    showFollowupQuestion: false,
                    primaryAnswer: false,
                    validated: this.state.question5.secondaryAnswer.trim() === "" ? false : true
                }
            });
    };


    _question5Onchange = event => {
        this.setState({
            question5: {
                ...this.state.question5,
                secondaryAnswer: event.target.value,
                validated: event.target.value.trim() === "" ? false : true
            }
        });
    };

    _question5FileOnchange = event => {
        this.setState({
            question5: {
                ...this.state.question5,
                tempFile: event.target.files[0],
                isUploading: true,
            }
        });
        console.log(event.target.files[0]);
        console.log(this.state);
        this._startUpload5(event.target.files[0]);
        this.setState({
            isUploading: true
        });
    };

    _startUpload5 = async file => {
        const result = await uploadFile(file, localStorage.USER_NAME);
        if (result.status) {
            console.log(result.url);
            this.setState({
                isUploading: false,
                question5: {
                    ...this.state.question5,
                    fileRef: 'uploads/' + localStorage.USER_NAME + '/' + file.name,
                    fileUrl: result.url,
                    isUploading: false,
                    validated: true
                }
            });
        }
    };

    _deleteFile5 = async (path) => {
        const deleteRef = await deleteFile(this.state.question5.fileRef);
        this.setState({
            question5: {
                ...this.state.question5,
                validated: false
            }
        });
        return deleteRef;
    }


    //Question 6 stuff
    _question6Followup = state => {
        state
            ? this.setState({
                question6: {
                    ...this.state.question6,
                    showFollowupQuestion: true,
                    primaryAnswer: true,
                    validated: this.state.question6.secondaryAnswer.trim() === "" ? false : true
                }
            })
            : this.setState({
                question6: {
                    ...this.state.question6,
                    showFollowupQuestion: false,
                    primaryAnswer: false,
                    validated: this.state.question6.secondaryAnswer.trim() === "" ? false : true
                }
            });
    };
    _question6Onchange = event => {
        this.setState({
            question6: {
                ...this.state.question6,
                secondaryAnswer: event.target.value,
                validated: event.target.value.trim() === "" ? false : true
            }
        });
    };

    //Question 7 Stuff
    _question7Onchange = event => {
        this.setState({
            question7: {
                ...this.state.question7,
                primaryAnswer: event.target.value,
                validated: event.target.value.trim() === "" ? false : true
            }
        });
    };

    // Question 8 stuff
    _question8Onchange = event => {
        this.setState({
            question8: {
                ...this.state.question8,
                primaryAnswer: event.target.value,
                validated: event.target.value.trim() === "" ? false : true
            }
        });
    };

    // Question 9 stuff

    _question9Followup = state => {
        state
            ? this.setState({
                question9: {
                    ...this.state.question9,
                    showFollowupQuestion: true,
                    primaryAnswer: true
                }
            })
            : this.setState({
                question9: {
                    ...this.state.question9,
                    showFollowupQuestion: false,
                    primaryAnswer: false
                }
            });
    };

    // Question 10 stuff
    _qeustion10OnChange = event => {
        if (event.target.value < 0) {
            event.preventDefault();
        } else {
            this.setState({
                question10: {
                    ...this.state.question10,
                    primaryAnswer: event.target.value,
                    validated: event.target.value.trim() === "" ? false : true
                }
            });
        }
    };

    //Question 11 stuff
    _qeustion11OnChange = event => {
        if (event.target.value < 0) {
            event.preventDefault();
        } else {
            this.setState({
                question11: {
                    ...this.state.question11,
                    primaryAnswer: event.target.value,
                    validated: event.target.value.trim() === "" ? false : true
                }
            });
        }
    };
    validateQty = event => {
        console.log('s');
        return false;
    }

    _qeustion12OnChange = event => {
        if (event.target.value < 0) {
            event.preventDefault();
        } else {
            this.setState({
                question12: {
                    ...this.state.question12,
                    primaryAnswer: event.target.value,
                    validated: event.target.value.trim() === "" ? false : true
                }
            });
        }
    };
    show = () => this.setState({ open: true })
    handleConfirm = () => this.setState({ confirmAction: true, open: false })
    handleCancel = () => this.setState({ confirmAction: false, open: false })
    _pagi = (path) => {
        const { submissionId, submissionType, userId, assessmentName } = this.state;
        this.props.history.push({ pathname: '/users/'.concat(userId, '/view/', path), state: { submissionId: submissionId, submissionType: submissionType, assessmentName: assessmentName } });
    }
    render() {
        const { formErrorStatus, formSuccessState, t, i18n } = this.props;
        const { open, confirmAction, progress, result_color, result_status, score } = this.state
        return (
            <div style={{ backgroundColor: "#fff" }} class="remove-disabled">
                <div>
                    <div class="uk-background-muted uk-margin-top uk-padding" style={{ paddingBottom: "15px", paddingTop: "15px" }}>
                        <div class="uk-grid" uk-grid>
                            <div class="uk-width-expand@m">
                                <form class=" uk-flex-inline uk-flex-middle uk-text-small">
                                    <h4 class="uk-margin-remove-bottom uk-margin-right">
                                        {" "}
                                        {this.state.general.fullName}
                                    </h4>
                                </form>
                                <h6 class="uk-margin-remove-top uk-text-left">
                                    {" "}
                                    {this.state.general.country}, {this.state.general.region}
                                </h6>
                            </div>
                            <div class="uk-width-auto@m uk-text-small">
                                <p class="uk-text-right uk-margin-remove-bottom"> {t("Submitted by")}: <strong> {this.state.user.firstName} {this.state.user.lastName} </strong></p>
                                <p class="uk-text-right uk-margin-remove-top uk-margin-remove-bottom"> {t("Assessment name")}: <strong>{this.state.assessmentName} </strong></p>
                                <p class="uk-text-right uk-margin-remove-top uk-margin-remove-bottom">{t("Account created on")}: {moment(this.state.user.createdAt).format('DD-MMMM-YYYY')}</p>
                            </div>
                        </div>
                    </div>
                    {/* <div class="uk-background-muted" style={{ paddingRight: "40px", paddingBottom: "20px" }}>
                        <div class="uk-width-auto@m uk-text-small">

                        </div>
                    </div> */}
                </div>
                <div class="uk-background-muted" style={{ paddingRight: "40px", paddingBottom: "15px" }}>
                    <div class="uk-width-auto@m uk-text-small">
                        <p class="uk-text-right uk-margin-remove-top" style={{ color: result_color }}><b>{t(result_status)}: {score}</b></p>

                    </div>
                </div>
                {this.state.loading ? (
                    <SectionLoader />
                ) : (
                    <div>
                        {/* Begin Dark Header */}
                        <div className="uk-section-default uk-section" style={{ paddingTop: "10px" }}>
                            <div className="uk-container-small uk-margin-auto" style={{ marginTop: "10px" }}>
                                <h3 className=" uk-text-light">
                                    {" "}
                                    {t("Section")} {this.state.section.sectionNumber}{" "}
                                    {/* {this.state.section.title}{" "} */}
                                </h3>
                            </div>
                            {/* <hr /> */}

                            <div
                                className="uk-container-small uk-margin-auto uk-margin-small-top uk-padding-small paper-read"
                                uk-scrollspy="target: > .paper; cls:uk-animation-slide-bottom-small; delay: 200"
                            >
                                <div className="paper uk-link-reset" style={{ backgroundColor: "#84329B" }} >
                                    <div className="uk-grid uk-flex-middle">
                                        <div className="uk-width-1-6 uk-flex-first uk-text-center">
                                            <i className="far fa-comment-alt icon-xxlarge"></i>
                                        </div>
                                        <div className="uk-width-5-6">
                                            <h4 className="uk-margin-remove" style={{ color: "#ffffff" }} >
                                                {" "}
                                                {t(this.state.section.title)}{" "}
                                            </h4>
                                            {/* <p className="uk-margin-remove">
                          {" "}
                          {this.state.section.description}{" "}
                        </p> */}
                                        </div>
                                    </div>
                                </div>
                                <Progress style={{ margin: 0 }} percent={progress} progress success size='medium' />
                                <Form success error className="uk-margin">
                                    {/* Begin Question 1 */}
                                    <div className="uk-link-reset paper uk-margin s1q1">
                                        <h4>
                                            <b>7)</b>{" "}
                                            {t("Will your current donor(s) be supportive if your organization adopts income generating activities for delivering SRHR products and services?")}{" "}
                                        </h4>
                                        {localStorage.RAT_LANG == 'ar' ? (
                                            <Button.Group size="large" className="buttons_arabic_style">
                                              
                                                <Button disabled positive={this.state.question1.showFollowupQuestion ? false : true} onClick={() => this._question1Followup(false)}>
                                                    {t("NO")}
                                                </Button>
                                                {/* <Button.Or /> */}
                                                <div class="or" data-text={localStorage.RAT_LANG=='sp'? "o" : localStorage.RAT_LANG=='fr'? "ou" : localStorage.RAT_LANG=='ar'? "أو": "or"}></div>
                                                <Button disabled positive={this.state.question1.showFollowupQuestion ? true : false} onClick={() => this._question1Followup(true)}>
                                                    {t("YES")}
                                                </Button>
                                            </Button.Group>
                                        ) : (
                                            <Button.Group size="large">
                                                <Button disabled positive={this.state.question1.showFollowupQuestion ? true : false} onClick={() => this._question1Followup(true)}>
                                                    {t("YES")}
                                                </Button>
                                                {/* <Button.Or /> */}
                                                <div class="or" data-text={localStorage.RAT_LANG=='sp'? "o" : localStorage.RAT_LANG=='fr'? "ou" : localStorage.RAT_LANG=='ar'? "أو": "or"}></div>
                                                <Button disabled positive={this.state.question1.showFollowupQuestion ? false : true} onClick={() => this._question1Followup(false)}>
                                                    {t("NO")}
                                                </Button>
                                            </Button.Group>
                                        )}

                                        {this.state.question1.showFollowupQuestion ? (
                                            ""
                                        ) : (
                                            <div>
                                                <div className="uk-form-label">
                                                    {t("If No, explain the concerns.")}
                                                </div>
                                                <div className="uk-margin">
                                                    <span className="uk-form-icon">
                                                        <i className="far fa-User icon-medium"></i>
                                                    </span>

                                                    <TextArea
                                                        onChange={this._question1Onchange}
                                                        name="secondaryAnswer"
                                                        id="secondaryAnswer"
                                                        value={this.state.question1.secondaryAnswer}
                                                        className="text-area-height uk-input uk-form-width-large"
                                                        placeholder={t("Describe here")}
                                                        type="text"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        {this.state.question1.score && this.state.question1.score !== "" ? (
                                            <p><span style={{ color: "#eaaa00", fontWeight: "bold" }}>{t("Score given")}: </span> <span className="uk-text-muted">{this.state.question1.score}</span></p>
                                        ) : (
                                            ""
                                        )}
                                        {this.state.question1.feedBack && this.state.question1.feedBack !== "" ? (
                                            <p><span style={{ color: "#eaaa00", fontWeight: "bold" }}>{t("Comments/Feedback from SE Hub")}:</span> <div className="uk-text-muted" dangerouslySetInnerHTML={{ __html: this.state.question1.feedBack.replace(/(?:\r\n|\r|\n)/g, '<br />') }}></div> </p>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    {/* End Question 1 */}

                                    {/* Begin Question2 */}
                                    <div className="uk-link-reset paper uk-margin s1q1">
                                        <h4>
                                            <b>8)</b>{" "}
                                            {t("Does your organization issue an annual financial statement, monthly income and expenditure statement and (if applicable) any profit and loss report(s)?")}{" "}
                                        </h4>
                                        {localStorage.RAT_LANG=='ar'? (
                                             <Button.Group size="large" className="buttons_arabic_style">
                                            
                                             
                                             <Button disabled positive={this.state.question2.showFollowupQuestion ? true : false} onClick={() => this._question2Followup(false)}>
                                                 {t("NO")}
                                             </Button>
                                             {/* <Button.Or /> */}
                                             <div class="or" data-text={localStorage.RAT_LANG=='sp'? "o" : localStorage.RAT_LANG=='fr'? "ou" : localStorage.RAT_LANG=='ar'? "أو": "or"}></div>
                                             <Button disabled positive={this.state.question2.showFollowupQuestion ? false : true} onClick={() => this._question2Followup(true)}>
                                                 {t("YES")}
                                             </Button>
                                         </Button.Group>
                                        ):(
                                            <Button.Group size="large">
                                            <Button disabled positive={this.state.question2.showFollowupQuestion ? false : true} onClick={() => this._question2Followup(true)}>
                                                {t("YES")}
                                            </Button>
                                            {/* <Button.Or /> */}
                                            <div class="or" data-text={localStorage.RAT_LANG=='sp'? "o" : localStorage.RAT_LANG=='fr'? "ou" : localStorage.RAT_LANG=='ar'? "أو": "or"}></div>
                                            <Button disabled positive={this.state.question2.showFollowupQuestion ? true : false} onClick={() => this._question2Followup(false)}>
                                                {t("NO")}
                                            </Button>
                                        </Button.Group>
                                        )}
                                       
                                        {this.state.question2.showFollowupQuestion ? (
                                            <div>
                                                <div className="uk-form-label">
                                                    {t("If No, specify the reasons.")}
                                                </div>
                                                <div className="uk-margin">
                                                    <span className="uk-form-icon">
                                                        <i className="far fa-User icon-medium"></i>
                                                    </span>

                                                    <TextArea
                                                        onChange={this._question2Onchange}
                                                        name="q2secondaryAnswer"
                                                        id="q2secondaryAnswer"
                                                        value={this.state.question2.secondaryAnswer}
                                                        className="text-area-height uk-input uk-form-width-large"
                                                        placeholder={t("Describe here")}
                                                        type="text"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        {this.state.question2.score && this.state.question2.score !== "" ? (
                                            <p><span style={{ color: "#eaaa00", fontWeight: "bold" }}>{t("Score given")}: </span> <span className="uk-text-muted">{this.state.question2.score}</span></p>
                                        ) : (
                                            ""
                                        )}
                                        {this.state.question2.feedBack && this.state.question2.feedBack !== "" ? (
                                            <p><span style={{ color: "#eaaa00", fontWeight: "bold" }}>{t("Comments/Feedback from SE Hub")}:</span> <div className="uk-text-muted" dangerouslySetInnerHTML={{ __html: this.state.question2.feedBack.replace(/(?:\r\n|\r|\n)/g, '<br />') }}></div> </p>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    {/* End Question 2 */}

                                    {/* Begin Question 3 */}

                                    <div className="uk-link-reset paper uk-margin s1q1">
                                        <h4>
                                            <b>9)</b>{" "}
                                            {t("Does your organization charge clients for any activities/services rendered?")}{" "}
                                        </h4>
                                        {localStorage.RAT_LANG=='ar'? (
                                             <Button.Group size="large" className="buttons_arabic_style">
                                            
                                            
                                             <Button disabled positive={this.state.question3.showFollowupQuestion ? false : true} onClick={() => this._question3Followup(false)}>
                                                 {t("NO")}
                                             </Button>
                                             {/* <Button.Or /> */}
                                             <div class="or" data-text={localStorage.RAT_LANG=='sp'? "o" : localStorage.RAT_LANG=='fr'? "ou" : localStorage.RAT_LANG=='ar'? "أو": "or"}></div>
                                             <Button disabled positive={this.state.question3.showFollowupQuestion ? true : false} onClick={() => this._question3Followup(true)}>
                                                 {t("YES")}
                                             </Button>
                                         </Button.Group>
                                        ):(
                                            <Button.Group size="large">
                                            <Button disabled positive={this.state.question3.showFollowupQuestion ? true : false} onClick={() => this._question3Followup(true)}>
                                                {t("YES")}
                                            </Button>
                                            {/* <Button.Or /> */}
                                            <div class="or" data-text={localStorage.RAT_LANG=='sp'? "o" : localStorage.RAT_LANG=='fr'? "ou" : localStorage.RAT_LANG=='ar'? "أو": "or"}></div>
                                            <Button disabled positive={this.state.question3.showFollowupQuestion ? false : true} onClick={() => this._question3Followup(false)}>
                                                {t("NO")}
                                            </Button>
                                        </Button.Group>
                                        )}
                                       
                                        {this.state.question3.showFollowupQuestion ? (
                                            <div>
                                                <div className="uk-form-label">
                                                    {t("If Yes, specify the details.")}
                                                </div>
                                                <div className="uk-margin">
                                                    <span className="uk-form-icon">
                                                        <i className="far fa-User icon-medium"></i>
                                                    </span>

                                                    <TextArea
                                                        onChange={this._question3Onchange}
                                                        name="q3secondaryAnswer"
                                                        id="q3secondaryAnswer"
                                                        value={this.state.question3.secondaryAnswer}
                                                        className="text-area-height uk-input uk-form-width-large"
                                                        placeholder={t("Describe here")}
                                                        type="text"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        {this.state.question3.score && this.state.question3.score !== "" ? (
                                            <p><span style={{ color: "#eaaa00", fontWeight: "bold" }}>{t("Score given")}: </span> <span className="uk-text-muted">{this.state.question3.score}</span></p>
                                        ) : (
                                            ""
                                        )}
                                        {this.state.question3.feedBack && this.state.question3.feedBack !== "" ? (
                                            <p><span style={{ color: "#eaaa00", fontWeight: "bold" }}>{t("Comments/Feedback from SE Hub")}:</span> <div className="uk-text-muted" dangerouslySetInnerHTML={{ __html: this.state.question3.feedBack.replace(/(?:\r\n|\r|\n)/g, '<br />') }}></div> </p>
                                        ) : (
                                            ""
                                        )}
                                    </div>

                                    {/* End Question 3 */}

                                    {/* Begin Question 4 */}
                                    <div className="uk-link-reset paper uk-margin s1q1">
                                        <h4><b>10)</b> {t("Does your organization have a procurement policy?")} </h4>
                                        {localStorage.RAT_LANG=='ar'? (
                                            <Button.Group size="large" className="buttons_arabic_style">
                                           
                                           
                                            <Button disabled positive={this.state.question4.showFollowupQuestion ? false : true} onClick={() => this._question4Followup(false)}>
                                                {t("NO")}
                                            </Button>
                                            {/* <Button.Or /> */}
                                            <div class="or" data-text={localStorage.RAT_LANG=='sp'? "o" : localStorage.RAT_LANG=='fr'? "ou" : localStorage.RAT_LANG=='ar'? "أو": "or"}></div>
                                            <Button disabled positive={this.state.question4.showFollowupQuestion ? true : false} onClick={() => this._question4Followup(true)}>
                                                {t("YES")}
                                            </Button>
                                        </Button.Group>
                                        ):(
                                            <Button.Group size="large" >
                                            <Button disabled positive={this.state.question4.showFollowupQuestion ? true : false} onClick={() => this._question4Followup(true)}>
                                                {t("YES")}
                                            </Button>
                                            {/* <Button.Or /> */}
                                            <div class="or" data-text={localStorage.RAT_LANG=='sp'? "o" : localStorage.RAT_LANG=='fr'? "ou" : localStorage.RAT_LANG=='ar'? "أو": "or"}></div>
                                            <Button disabled positive={this.state.question4.showFollowupQuestion ? false : true} onClick={() => this._question4Followup(false)}>
                                                {t("NO")}
                                            </Button>
                                        </Button.Group>
                                        )}
                                        
                                        {this.state.question4.showFollowupQuestion ? (
                                            <div>
                                                <div className="uk-form-label">
                                                    {t("If Yes, please attach a copy of the policy.")}
                                                </div>
                                                <div className="uk-margin">
                                                    <span className="uk-form-icon">
                                                        <i className="far fa-User icon-medium"></i>
                                                    </span>

                                                    {/* File Component */}
                                                    {this.state.question4.fileUrl !== null && this.state.question4.fileUrl !== undefined ?
                                                        (
                                                            <div className="file-upload-group" >
                                                                <Popup
                                                                    trigger={<Button className="file-icon" circular icon="attach" />}
                                                                    flowing
                                                                    hoverable
                                                                >
                                                                    <Grid centered divided columns={2}>
                                                                        <Grid.Column textAlign="center">
                                                                            <Button circular icon='download' onClick={() => { window.open(this.state.question4.fileUrl, '_blank'); }} />

                                                                        </Grid.Column>
                                                                        <Grid.Column textAlign="center">
                                                                            <Button disabled circular icon='trash alternate outline' onClick={() => {
                                                                                this._deleteFile(this.state.question4.fileRef).then(result => {
                                                                                    // console.log(result);
                                                                                    this.setState({
                                                                                        question4: {
                                                                                            ...this.state.question4,
                                                                                            fileUrl: null,
                                                                                            fileRef: null,
                                                                                        }
                                                                                    });
                                                                                });
                                                                            }} />

                                                                        </Grid.Column>
                                                                    </Grid>
                                                                </Popup>
                                                            </div>
                                                        ) : (
                                                            <Input
                                                                onChange={this._question4FileOnchange}
                                                                name="q4upload"
                                                                id="q4upload"
                                                                value={this.state.question4.uploads}
                                                                className="text-area-height uk-input uk-form-width-large"
                                                                placeholder={t("Upload here")}
                                                                type="file"
                                                                disabled
                                                            />
                                                        )
                                                    }

                                                    {/* File Component */}


                                                </div>
                                                {this.state.question4.isUploading ? (
                                                    <Message icon>
                                                        <Icon name="circle notched" loading />
                                                        <Message.Content>
                                                            <Message.Header>{t("Just a moment…")}</Message.Header>
                                                            {t("We are uploading your document.")}
                                                        </Message.Content>
                                                    </Message>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        ) : (
                                            <div>
                                                <div className="uk-form-label">
                                                    {t("If No, specify the reasons.")}
                                                </div>
                                                <div className="uk-margin">
                                                    <span className="uk-form-icon">
                                                        <i className="far fa-User icon-medium"></i>
                                                    </span>

                                                    <TextArea
                                                        onChange={this._question4Onchange}
                                                        name="q4secondaryAnswer"
                                                        id="q4secondaryAnswer"
                                                        value={this.state.question4.secondaryAnswer}
                                                        className="text-area-height uk-input uk-form-width-large"
                                                        placeholder={t("Describe here")}
                                                        type="text"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        {this.state.question4.score && this.state.question4.score !== "" ? (
                                            <p><span style={{ color: "#eaaa00", fontWeight: "bold" }}>{t("Score given")}: </span> <span className="uk-text-muted">{this.state.question4.score}</span></p>
                                        ) : (
                                            ""
                                        )}
                                        {this.state.question4.feedBack && this.state.question4.feedBack !== "" ? (
                                            <p><span style={{ color: "#eaaa00", fontWeight: "bold" }}>{t("Comments/Feedback from SE Hub")}:</span> <div className="uk-text-muted" dangerouslySetInnerHTML={{ __html: this.state.question4.feedBack.replace(/(?:\r\n|\r|\n)/g, '<br />') }}></div> </p>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    {/* End Question 4 */}

                                    {/* Begin Question 5 */}
                                    <div className="uk-link-reset paper uk-margin s1q1">
                                        <h4><b>11)</b> {t("Does your organization have a staff remuneration policy and salary scales?")}{" "} </h4>
                                        {localStorage.RAT_LANG=='ar'? (
                                             <Button.Group size="large" className="buttons_arabic_style" >
                                            
                                           
                                             <Button disabled positive={this.state.question5.showFollowupQuestion ? false : true} onClick={() => this._question5Followup(false)}>
                                                 {t("NO")}
                                             </Button>
                                             {/* <Button.Or /> */}
                                             <div class="or" data-text={localStorage.RAT_LANG=='sp'? "o" : localStorage.RAT_LANG=='fr'? "ou" : localStorage.RAT_LANG=='ar'? "أو": "or"}></div>
                                             <Button disabled positive={this.state.question5.showFollowupQuestion ? true : false} onClick={() => this._question5Followup(true)}>
                                                 {t("YES")}
                                             </Button>
                                         </Button.Group>
                                        ):(
                                            <Button.Group size="large" >
                                            <Button disabled positive={this.state.question5.showFollowupQuestion ? true : false} onClick={() => this._question5Followup(true)}>
                                                {t("YES")}
                                            </Button>
                                            {/* <Button.Or /> */}
                                            <div class="or" data-text={localStorage.RAT_LANG=='sp'? "o" : localStorage.RAT_LANG=='fr'? "ou" : localStorage.RAT_LANG=='ar'? "أو": "or"}></div>
                                            <Button disabled positive={this.state.question5.showFollowupQuestion ? false : true} onClick={() => this._question5Followup(false)}>
                                                {t("NO")}
                                            </Button>
                                        </Button.Group>
                                        )}
                                       
                                        {this.state.question5.showFollowupQuestion ? (
                                            <div>
                                                <div className="uk-form-label">
                                                    {t("If Yes, please attach the policy and salary scale guidelines.")}
                                                </div>
                                                <div className="uk-margin">
                                                    <span className="uk-form-icon">
                                                        <i className="far fa-User icon-medium"></i>
                                                    </span>

                                                    {/* File Component */}
                                                    {this.state.question5.fileUrl !== null && this.state.question5.fileUrl !== undefined ?
                                                        (
                                                            <div className="file-upload-group" >
                                                                <Popup
                                                                    trigger={<Button className="file-icon" circular icon="attach" />}
                                                                    flowing
                                                                    hoverable
                                                                >
                                                                    <Grid centered divided columns={2}>
                                                                        <Grid.Column textAlign="center">
                                                                            <Button circular icon='download' onClick={() => { window.open(this.state.question5.fileUrl, '_blank'); }} />

                                                                        </Grid.Column>
                                                                        <Grid.Column textAlign="center">
                                                                            <Button disabled circular icon='trash alternate outline' onClick={() => {
                                                                                this._deleteFile5(this.state.question5.fileRef).then(result => {
                                                                                    this.setState({
                                                                                        question5: {
                                                                                            ...this.state.question5,
                                                                                            fileUrl: null,
                                                                                            fileRef: null,
                                                                                        }
                                                                                    });
                                                                                });
                                                                                // this.setState({
                                                                                //   question4: {
                                                                                //     ...this.state.question4,
                                                                                //     fileRef: null,
                                                                                //     fileUrl: null,
                                                                                //   }
                                                                                // })
                                                                            }} />

                                                                        </Grid.Column>
                                                                    </Grid>
                                                                </Popup>
                                                            </div>
                                                        ) : (
                                                            <Input
                                                                onChange={this._question5FileOnchange}
                                                                name="q5upload"
                                                                id="q5upload"
                                                                value={this.state.question5.uploads}
                                                                className="text-area-height uk-input uk-form-width-large"
                                                                placeholder={t("Upload here")}
                                                                type="file"
                                                                disabled
                                                            />
                                                        )
                                                    }

                                                    {/* File Component */}


                                                </div>
                                                {this.state.question5.isUploading ? (
                                                    <Message icon>
                                                        <Icon name="circle notched" loading />
                                                        <Message.Content>
                                                            <Message.Header>{t("Just a moment…")}</Message.Header>
                                                            {t("We are uploading your document.")}
                                                        </Message.Content>
                                                    </Message>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        ) : (
                                            <div>
                                                <div className="uk-form-label">
                                                    {t("If No, specify the reasons.")}
                                                </div>
                                                <div className="uk-margin">
                                                    <span className="uk-form-icon">
                                                        <i className="far fa-User icon-medium"></i>
                                                    </span>

                                                    <TextArea
                                                        onChange={this._question5Onchange}
                                                        name="q5secondaryAnswer"
                                                        id="q5secondaryAnswer"
                                                        value={this.state.question5.secondaryAnswer}
                                                        className="text-area-height uk-input uk-form-width-large"
                                                        placeholder={t("Describe here")}
                                                        type="text"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        {this.state.question5.score && this.state.question5.score !== "" ? (
                                            <p><span style={{ color: "#eaaa00", fontWeight: "bold" }}>{t("Score given")}: </span> <span className="uk-text-muted">{this.state.question5.score}</span></p>
                                        ) : (
                                            ""
                                        )}
                                        {this.state.question5.feedBack && this.state.question5.feedBack !== "" ? (
                                            <p><span style={{ color: "#eaaa00", fontWeight: "bold" }}>{t("Comments/Feedback from SE Hub")}:</span> <div className="uk-text-muted" dangerouslySetInnerHTML={{ __html: this.state.question5.feedBack.replace(/(?:\r\n|\r|\n)/g, '<br />') }}></div> </p>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    {/* End Question 5 */}

                                    {/* Begin Question 6 */}
                                    <div className="uk-link-reset paper uk-margin s1q1">
                                        <h4>
                                            <b>12)</b>{" "}
                                            {t("Is your organization audited by an external auditor?")}{" "}
                                        </h4>
                                        {localStorage.RAT_LANG=='ar'? (
                                             <Button.Group size="large" className="buttons_arabic_style">
                                            
                                            
                                             <Button disabled positive={this.state.question6.showFollowupQuestion ? false : true} onClick={() => this._question6Followup(false)}>
                                                 {t("NO")}
                                             </Button>
                                             {/* <Button.Or /> */}
                                             <div class="or" data-text={localStorage.RAT_LANG=='sp'? "o" : localStorage.RAT_LANG=='fr'? "ou" : localStorage.RAT_LANG=='ar'? "أو": "or"}></div>
                                             <Button disabled positive={this.state.question6.showFollowupQuestion ? true : false} onClick={() => this._question6Followup(true)}>
                                                 {t("YES")}
                                             </Button>
                                         </Button.Group>
                                        ):(
                                            <Button.Group size="large" >
                                            <Button disabled positive={this.state.question6.showFollowupQuestion ? true : false} onClick={() => this._question6Followup(true)}>
                                                {t("YES")}
                                            </Button>
                                            {/* <Button.Or /> */}
                                            <div class="or" data-text={localStorage.RAT_LANG=='sp'? "o" : localStorage.RAT_LANG=='fr'? "ou" : localStorage.RAT_LANG=='ar'? "أو": "or"}></div>
                                            <Button disabled positive={this.state.question6.showFollowupQuestion ? false : true} onClick={() => this._question6Followup(false)}>
                                                {t("NO")}
                                            </Button>
                                        </Button.Group>
                                        )}
                                       
                                        {this.state.question6.showFollowupQuestion ? (
                                            <div>
                                                <div className="uk-form-label">
                                                    {t("If Yes, specify the type of audits and frequency.")}
                                                </div>
                                                <div className="uk-margin">
                                                    <span className="uk-form-icon">
                                                        <i className="far fa-User icon-medium"></i>
                                                    </span>

                                                    <TextArea
                                                        onChange={this._question6Onchange}
                                                        name="q6secondaryAnswer"
                                                        id="q6secondaryAnswer"
                                                        value={this.state.question6.secondaryAnswer}
                                                        className="text-area-height uk-input uk-form-width-large"
                                                        placeholder={t("Describe here")}
                                                        type="text"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                <div className="uk-form-label">
                                                    {t("If No, specify the reasons why not audited.")}
                                                </div>
                                                <div className="uk-margin">
                                                    <span className="uk-form-icon">
                                                        <i className="far fa-User icon-medium"></i>
                                                    </span>

                                                    <TextArea
                                                        onChange={this._question6Onchange}
                                                        name="q6secondaryAnswer"
                                                        id="q6secondaryAnswer"
                                                        value={this.state.question6.secondaryAnswer}
                                                        className="text-area-height uk-input uk-form-width-large"
                                                        placeholder={t("Describe here")}
                                                        type="text"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        {this.state.question6.score && this.state.question6.score !== "" ? (
                                            <p><span style={{ color: "#eaaa00", fontWeight: "bold" }}>{t("Score given")}: </span> <span className="uk-text-muted">{this.state.question6.score}</span></p>
                                        ) : (
                                            ""
                                        )}
                                        {this.state.question6.feedBack && this.state.question6.feedBack !== "" ? (
                                            <p><span style={{ color: "#eaaa00", fontWeight: "bold" }}>{t("Comments/Feedback from SE Hub")}:</span> <div className="uk-text-muted" dangerouslySetInnerHTML={{ __html: this.state.question6.feedBack.replace(/(?:\r\n|\r|\n)/g, '<br />') }}></div> </p>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    {/* End Question 6 */}

                                    {/* Begin Question 7 */}
                                    <div className="uk-link-reset paper uk-margin s1q1">
                                        <h4>
                                            <b>13)</b>{" "}
                                            {t("As a statutory obligation, in what matters do the Board members get involved as signatories?")}{" "}
                                        </h4>
                                        <p class="uk-text-muted uk-margin-remove-top uk-margin-small-bottom">{t("Please answer and support the response with additional details.")}</p>
                                        <div>
                                            <div className="uk-form-label"></div>
                                            <div className="uk-margin">
                                                <span className="uk-form-icon">
                                                    <i className="far fa-User icon-medium"></i>
                                                </span>

                                                <TextArea
                                                    onChange={this._question7Onchange}
                                                    name="q6primaryAnswer"
                                                    id="q6primaryAnswer"
                                                    value={this.state.question7.primaryAnswer}
                                                    className="text-area-height uk-input uk-form-width-large"
                                                    placeholder={t("Describe here")}
                                                    type="text"
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        {this.state.question7.score && this.state.question7.score !== "" ? (
                                            <p><span style={{ color: "#eaaa00", fontWeight: "bold" }}>{t("Score given")}: </span> <span className="uk-text-muted">{this.state.question7.score}</span></p>
                                        ) : (
                                            ""
                                        )}
                                        {this.state.question7.feedBack && this.state.question7.feedBack !== "" ? (
                                            <p><span style={{ color: "#eaaa00", fontWeight: "bold" }}>{t("Comments/Feedback from SE Hub")}:</span> <div className="uk-text-muted" dangerouslySetInnerHTML={{ __html: this.state.question7.feedBack.replace(/(?:\r\n|\r|\n)/g, '<br />') }}></div> </p>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    {/* End Question 7 */}

                                    {/* Begin Question 8 */}
                                    <div className="uk-link-reset paper uk-margin s1q1">
                                        <h4>
                                            <b>14)</b>{" "}
                                            {t("Which financial institutions does your organization engage with (banks, savings societies, etc.)?")}{" "}
                                        </h4>

                                        <div>
                                            <div className="uk-form-label"></div>
                                            <div className="uk-margin">
                                                <span className="uk-form-icon">
                                                    <i className="far fa-User icon-medium"></i>
                                                </span>
                                                <TextArea
                                                    onChange={this._question8Onchange}
                                                    name="q8primaryAnswer"
                                                    id="q8primaryAnswer"
                                                    value={this.state.question8.primaryAnswer}
                                                    className="text-area-height uk-input uk-form-width-large"
                                                    placeholder={t("Specify the types and names of the financial institutions.")}
                                                    type="text"
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        {this.state.question8.score && this.state.question8.score !== "" ? (
                                            <p><span style={{ color: "#eaaa00", fontWeight: "bold" }}>{t("Score given")}: </span> <span className="uk-text-muted">{this.state.question8.score}</span></p>
                                        ) : (
                                            ""
                                        )}
                                        {this.state.question8.feedBack && this.state.question8.feedBack !== "" ? (
                                            <p><span style={{ color: "#eaaa00", fontWeight: "bold" }}>{t("Comments/Feedback from SE Hub")}:</span> <div className="uk-text-muted" dangerouslySetInnerHTML={{ __html: this.state.question8.feedBack.replace(/(?:\r\n|\r|\n)/g, '<br />') }}></div> </p>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    {/* End Question 8 */}

                                    {/* Begin Question 9 */}
                                    <div className="uk-link-reset paper uk-margin s1q1">
                                        <h4>
                                            <b>15)</b>{" "}
                                            {t("Can your organization borrow working capital from the bank to finance the social enterprise/business idea?")}{" "}
                                        </h4>
                                        <div>
                                            <div className="uk-form-label"></div>
                                            <div className="uk-margin">
                                                <span className="uk-form-icon">
                                                    <i className="far fa-User icon-medium"></i>
                                                </span>
                                                {localStorage.RAT_LANG=='ar'? (
                                                     <Button.Group size="large" className="buttons_arabic_style" >
                                                    
                                                    
                                                     <Button
                                                         disabled
                                                         positive={this.state.question9.showFollowupQuestion ? false : true}
                                                         onClick={() => this._question9Followup(false)}
                                                     >
                                                         {t("NO")}
                                                     </Button>
                                                     {/* <Button.Or /> */}
                                                     <div class="or" data-text={localStorage.RAT_LANG=='sp'? "o" : localStorage.RAT_LANG=='fr'? "ou" : localStorage.RAT_LANG=='ar'? "أو": "or"}></div>
                                                     <Button disabled positive={this.state.question9.showFollowupQuestion ? true : false} onClick={() => this._question9Followup(true)}>
                                                         {t("YES")}
                                                     </Button>
                                                 </Button.Group>
                                                ):(
                                                    <Button.Group size="large" >
                                                    <Button disabled positive={this.state.question9.showFollowupQuestion ? true : false} onClick={() => this._question9Followup(true)}>
                                                        {t("YES")}
                                                    </Button>
                                                    {/* <Button.Or /> */}
                                                    <div class="or" data-text={localStorage.RAT_LANG=='sp'? "o" : localStorage.RAT_LANG=='fr'? "ou" : localStorage.RAT_LANG=='ar'? "أو": "or"}></div>
                                                    <Button
                                                        disabled
                                                        positive={this.state.question9.showFollowupQuestion ? false : true}
                                                        onClick={() => this._question9Followup(false)}
                                                    >
                                                        {t("NO")}
                                                    </Button>
                                                </Button.Group>
                                                )}
                                               
                                            </div>
                                        </div>
                                        {this.state.question9.score && this.state.question9.score !== "" ? (
                                            <p><span style={{ color: "#eaaa00", fontWeight: "bold" }}>{t("Score given")}: </span> <span className="uk-text-muted">{this.state.question9.score}</span></p>
                                        ) : (
                                            ""
                                        )}
                                        {this.state.question9.feedBack && this.state.question9.feedBack !== "" ? (
                                            <p><span style={{ color: "#eaaa00", fontWeight: "bold" }}>{t("Comments/Feedback from SE Hub")}:</span> <div className="uk-text-muted" dangerouslySetInnerHTML={{ __html: this.state.question9.feedBack.replace(/(?:\r\n|\r|\n)/g, '<br />') }}></div> </p>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    {/* End Question 9 */}

                                    {/* Begin Question 10 */}
                                    <div className="uk-link-reset paper uk-margin s1q1">
                                        <h4>
                                            <b>16)</b>{" "}
                                            {t("What is the USD value of your Annual Programme Budget (APB) for the ongoing year?")}{" "}
                                        </h4>
                                        <div>
                                            <div className="uk-form-label"></div>
                                            <div className="uk-margin">
                                                <span className="uk-form-icon">
                                                    <i className="far fa-User icon-medium"></i>
                                                </span>

                                                <Input
                                                    labelPosition={localStorage.RAT_LANG == 'ar' ? "left" : "right"}
                                                    type="number"
                                                    placeholder={t("Amount")}
                                                    id="q10primaryAnswer"
                                                    name="q10primaryAnswer"
                                                    value={this.state.question10.primaryAnswer}
                                                    onChange={this._qeustion10OnChange}
                                                    className={localStorage.RAT_LANG=='ar'? "input_arabic":""}
                                                    disabled
                                                    onKeyDown={e => {
                                                        if (/[\+\-\.\,]/.test(e.key) || e.key.toUpperCase() === "E") {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                >
                                                    <Label  className={localStorage.RAT_LANG=='ar'? "label_arabic":""} basic>$</Label>
                                                    <input />
                                                    <Label>.00</Label>
                                                </Input>
                                            </div>
                                        </div>
                                        {this.state.question10.score && this.state.question10.score !== "" ? (
                                            <p><span style={{ color: "#eaaa00", fontWeight: "bold" }}>{t("Score given")}: </span> <span className="uk-text-muted">{this.state.question10.score}</span></p>
                                        ) : (
                                            ""
                                        )}
                                        {this.state.question10.feedBack && this.state.question10.feedBack !== "" ? (
                                            <p><span style={{ color: "#eaaa00", fontWeight: "bold" }}>{t("Comments/Feedback from SE Hub")}:</span> <div className="uk-text-muted" dangerouslySetInnerHTML={{ __html: this.state.question10.feedBack.replace(/(?:\r\n|\r|\n)/g, '<br />') }}></div> </p>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    {/* End Question 10 */}

                                    {/* Begin Quesion 11 */}
                                    <div className="uk-link-reset paper uk-margin s1q1">
                                        <h4>
                                            <b>17)</b>{" "}
                                            {t("What is the USD value of the IPPF core grant (if applicable) for the ongoing year?")}{" "}
                                        </h4>
                                        <div>
                                            <div className="uk-form-label"></div>
                                            <div className="uk-margin">
                                                <span className="uk-form-icon">
                                                    <i className="far fa-User icon-medium"></i>
                                                </span>

                                                <Input
                                                    labelPosition={localStorage.RAT_LANG == 'ar' ? "left" : "right"}
                                                    type="number"
                                                    placeholder={t("Amount")}
                                                    id="q11primaryAnswer"
                                                    name="q11primaryAnswer"
                                                    value={this.state.question11.primaryAnswer}
                                                    onChange={this._qeustion11OnChange}
                                                    className={localStorage.RAT_LANG=='ar'? "input_arabic":""}
                                                    disabled
                                                    onKeyDown={e => {
                                                        if (/[\+\-\.\,]/.test(e.key) || e.key.toUpperCase() === "E") {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                >
                                                    <Label  className={localStorage.RAT_LANG=='ar'? "label_arabic":""} basic>$</Label>
                                                    <input />
                                                    <Label>.00</Label>
                                                </Input>
                                            </div>
                                        </div>
                                        {this.state.question11.score && this.state.question11.score !== "" ? (
                                            <p><span style={{ color: "#eaaa00", fontWeight: "bold" }}>{t("Score given")}: </span> <span className="uk-text-muted">{this.state.question11.score}</span></p>
                                        ) : (
                                            ""
                                        )}
                                        {this.state.question11.feedBack && this.state.question11.feedBack !== "" ? (
                                            <p><span style={{ color: "#eaaa00", fontWeight: "bold" }}>{t("Comments/Feedback from SE Hub")}:</span> <div className="uk-text-muted" dangerouslySetInnerHTML={{ __html: this.state.question11.feedBack.replace(/(?:\r\n|\r|\n)/g, '<br />') }}></div> </p>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    {/* End Question 11 */}

                                    {/* Begin Question 12 */}
                                    <div className="uk-link-reset paper uk-margin s1q1">
                                        <h4>
                                            <b>18)</b>{" "}
                                            {t("What is the USD value of your (local) self-generated income for the last financial year?")}{" "}
                                        </h4>
                                        <div>
                                            <div className="uk-form-label"></div>
                                            <div className="uk-margin">
                                                <span className="uk-form-icon">
                                                    <i className="far fa-User icon-medium"></i>
                                                </span>

                                                <Input
                                                    labelPosition={localStorage.RAT_LANG == 'ar' ? "left" : "right"}
                                                    type="number"
                                                    placeholder={t("Amount")}
                                                    id="q12primaryAnswer"
                                                    name="q12primaryAnswer"
                                                    value={this.state.question12.primaryAnswer}
                                                    onChange={this._qeustion12OnChange}
                                                    className={localStorage.RAT_LANG=='ar'? "input_arabic":""}
                                                    disabled
                                                    onKeyDown={e => {
                                                        if (/[\+\-\.\,]/.test(e.key) || e.key.toUpperCase() === "E") {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                >
                                                    <Label  className={localStorage.RAT_LANG=='ar'? "label_arabic":""} basic>$</Label>
                                                    <input />
                                                    <Label>.00</Label>
                                                </Input>
                                            </div>
                                        </div>

                                        {this.state.question12.score && this.state.question12.score !== "" ? (
                                            <p><span style={{ color: "#eaaa00", fontWeight: "bold" }}>{t("Score given")}: </span> <span className="uk-text-muted">{this.state.question12.score}</span></p>
                                        ) : (
                                            ""
                                        )}
                                        {this.state.question12.feedBack && this.state.question12.feedBack !== "" ? (
                                            <p><span style={{ color: "#eaaa00", fontWeight: "bold" }}>{t("Comments/Feedback from SE Hub")}:</span> <div className="uk-text-muted" dangerouslySetInnerHTML={{ __html: this.state.question12.feedBack.replace(/(?:\r\n|\r|\n)/g, '<br />') }}></div> </p>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    {/* End Question 12 */}
                                    {this.state.sectionFeedBack && this.state.sectionFeedBack !== "" ? (
                                        <div
                                            href="help-post-read.html"
                                            className="uk-link-reset paper uk-margin-remove-bottom"
                                        >
                                            <h4 style={{ color: "#eaaa00", fontWeight: "bold" }}>
                                                {" "}
                                                {t("Overall section comments/feedback from SE Hub")}
                                            </h4>
                                            <div className="uk-text-muted" dangerouslySetInnerHTML={{ __html: this.state.sectionFeedBack.replace(/(?:\r\n|\r|\n)/g, '<br />') }}></div>

                                        </div>
                                    ) : (
                                        ""
                                    )}

                                    <div className="uk-margin">
                                        {(formErrorStatus.status) ? (
                                            <div className="danger_alert">{t(formErrorStatus.message)}</div>
                                        ) : ((formSuccessState.status) ? (
                                            <div className="success_alert">{t(formSuccessState.message)}</div>
                                        ) : (''))}
                                    </div>

                                    <div className="uk-text-center uk-grid uk-grid-small uk-child-width-1-6 uk-margin-small quick-nav" uk-grid>
                                        <div>
                                            <div className="uk-card">
                                                <li><a className={localStorage.RAT_LANG == "fr" ? "fr_MA_contact uk-button uk-button-default uk-button-small uk-width-1-1" : localStorage.RAT_LANG == 'ar' ? "Ar_MA_contact uk-button uk-button-default uk-button-small uk-width-1-1" : localStorage.RAT_LANG=="sp"? "uk-button uk-button-default uk-button-small uk-width-1-1 sp_MA_contact":"uk-button uk-button-default uk-button-small uk-width-1-1"} onClick={(e) => this._pagi("section1")} href="javascript:void(0)">{t("MA Contact")}</a></li>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="uk-card">
                                                <li><a className="uk-button uk-button-default uk-button-small uk-width-1-1" onClick={(e) => this._pagi("section2")} href="javascript:void(0)">{t("Operations")}</a></li>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="uk-card">
                                                <li className="uk-active"><a className="uk-button uk-button-default uk-button-small uk-width-1-1" onClick={(e) => this._pagi("section3")} href="javascript:void(0)">{t("Financial")}</a></li>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="uk-card">
                                                <li><a className="uk-button uk-button-default uk-button-small uk-width-1-1" onClick={(e) => this._pagi("section4")} href="javascript:void(0)">{t("HR")}</a></li>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="uk-card">
                                                <li><a className="uk-button uk-button-default uk-button-small uk-width-1-1" onClick={(e) => this._pagi("section5")} href="javascript:void(0)">{t("Resources")}</a></li>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="uk-card">
                                                <li><a className="uk-button uk-button-default uk-button-small uk-width-1-1" onClick={(e) => this._pagi("section6")} href="javascript:void(0)">{t("Other")}</a></li>
                                            </div>
                                        </div>
                                    </div>

                                    <hr />
                                    {/* Begin Footer */}
                                    <div className="uk-grid uk-text-center button_div">
                                        <div className="uk-width-1-3@m">
                                            <Button
                                                basic
                                                className="outline-button blueButton"
                                                onClick={this._goBack}
                                            >
                                                {t("Go Back")}
                                            </Button>
                                        </div>

                                        <div className="uk-width-expand@m">

                                        </div>

                                        <div className="uk-width-1-3@m">
                                            <div>
                                                <Button
                                                    onClick={this._next}
                                                    className="next-button purpleButton"
                                                    loading={this.state.saveNContinue}
                                                    basic
                                                >
                                                    {t("Next")}
                                                </Button>
                                            </div>

                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}


export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(ViewSection3))));
