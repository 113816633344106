import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import Navbar from '../navbar/navbar';

//images
import Video from '../../assets/images/icons/Video.png';
import Students from '../../assets/images/icons/students.png';
import Discussion from '../../assets/images/icons/Discussion.png';




class Main_banner extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <div>

                <ul className="uk-switcher switcher-container">
                    <li>
                        <div className="topic1 hero-bg">
                            <div className="uk-grid">
                                <div className="uk-width-1-2@m">
                                    <h1 className="uk-animation-fade"> Web Development    </h1>
                                    <p> Maecenas vel dolor sit amet ligula interdum tempor id eu ipsum. <br /> Suspendisse pharetra risus ut metus  </p>
                                    <div className="uk-visible@m uk-animation-slide-bottom-small uk-grid" >
                                        <div className="uk-width-1-3@m">
                                            <img src={Video} className="img-small" />
                                            <span className="uk-text-middle uk-text-white">17 Courses </span>
                                        </div>
                                        <div className="uk-width-1-3@m">
                                            <img src={Students} className="img-small" />
                                            <span className="uk-text-middle uk-text-white">12 + Students </span>
                                        </div>
                                        <div className="uk-width-1-3@m">
                                            <img src={Discussion} className="img-small" />
                                            <span className="uk-text-middle uk-text-white">17 Discussion </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-width-1-2@m uk-visible@m">
                                    <img src="../../assets/images/courses/course-catagory-hero.jpg" alt="" className="uk-align-right img-xxlarge" />
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="topic2 hero-bg">
                            <div className="uk-grid">
                                <div className="uk-width-1-2@m">
                                    <h1 className="uk-animation-fade"> Mobile Apps Courses  </h1>
                                    <p> Maecenas vel dolor sit amet ligula interdum tempor id eu ipsum. <br /> Suspendisse pharetra risus ut metus  </p>
                                    <div className="uk-visible@m uk-animation-slide-bottom-small uk-grid" >
                                        <div className="uk-width-1-3@m">
                                            <img src="../../assets/images/icons/Video.png" className="img-small" />
                                            <span className="uk-text-middle uk-text-white">17 Courses </span>
                                        </div>
                                        <div className="uk-width-1-3@m">
                                            <img src="../../assets/images/icons/students.png" className="img-small" />
                                            <span className="uk-text-middle uk-text-white">12 + Students </span>
                                        </div>
                                        <div className="uk-width-1-3@m">
                                            <img src="../../assets/images/icons/Discussion.png" className="img-small" />
                                            <span className="uk-text-middle uk-text-white">17 Discussion </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-width-1-2@m uk-visible@m">
                                    <img src="../../assets/images/courses/course-catagory-hero.jpg" alt="" className="uk-align-right img-xxlarge" />
                                </div>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div className="topic3 hero-bg">
                            <h1> Game development courses </h1>
                            <h6> 30 Courses available </h6>
                        </div>
                    </li>
                    <li>
                        <div className="topic4 hero-bg">
                            <h1>  Software Training courses </h1>
                            <h6> 30 Courses available </h6>
                        </div>
                    </li>
                    <li>
                        <div className="topic6 hero-bg">
                            <h1>  Development Tools </h1>
                            <h6> 30 Courses available </h6>
                        </div>
                    </li>
                    <li>
                        <div className="topic7 hero-bg">
                            <h1> Ecommerce courses </h1>
                            <h6> 30 Courses available </h6>
                        </div>
                    </li>
                </ul>

                <button className="uk-button uk-hidden@m  mobile-catagory-button" type="button" uk-toggle="target: #tabs-moible; cls: tabs-moible"> Open subcatagory </button>

                <ul className="uk-subnav uk-subnav-pill tabs-moible-hidden" uk-switcher="connect: .switcher-container" id="tabs-moible">
                    <li className="uk-active">
                        <a href="#"> Web development </a>
                    </li>
                    <li>
                        <a href="#">Mobile Apps </a>
                    </li>
                    <li>
                        <a href="#">Game development </a>
                    </li>
                    <li>
                        <a href="#">Software  </a>
                    </li>
                    <li>
                        <a href="#">Development Tools </a>
                    </li>
                    <li>
                        <a href="#">Ecommerce </a>
                    </li>
                </ul>

                
            </div>
        );
    }

}

export default Main_banner; 