import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import Navbar from '../navbar/navbar';
import Header from '../header/header';
import Main_banner from '../main_banner/main_banner';
import Footer from '../footer/footer';

class Home_container extends React.Component {
    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e) {

    }

    render() {

        return (
            <ul className="uk-switcher switcher-container">
                <li>

                    <div className="uk-container">
                        <div className="uk-clearfix boundary-align">
                            <div className="uk-float-left section-heading none-border">
                                <h2>Browse Web development courses</h2>
                                <p>Adipisici elit, sed eiusmod tempor incidunt ut labore et</p>
                            </div>
                            <div className="uk-float-right">
                                <a href="#" className="uk-link-reset uk-margin-small-right uk-text-small uk-text-uppercase" uk-tooltip="title: Course Filter; pos: top-right" uk-toggle="target: #Course-Filter ; animation: uk-animation-fade; queued: true">   FILTER </a>
                                <a href="#" className="uk-link-reset uk-margin-small-right" uk-tooltip="title: Course card; pos: top-right"> <i className="fas fa-th-large"></i> </a>
                                <a href="Course-list.html" className="uk-margin-small-right" uk-tooltip="title:Course list; pos: top-right"><i className="fas fa-th-list"></i> </a>
                                <span className="uk-text-small uk-text-uppercase"> Sort by :</span>
                                <button className="uk-button uk-button-default uk-background-default uk-button-small" type="button" uk-tooltip="title: Sort; pos: top-right"> Newest  </button>
                                <div uk-dropdown="pos: top-right ;mode : click" className="uk-dropdown  uk-dropdown-top-right"
                                //style="left: -141px; top: -267px;"
                                >
                                    <ul className="uk-nav uk-dropdown-nav">
                                        <li className="uk-active">
                                            <a href="#">Best Courses</a>
                                        </li>
                                        <li>
                                            <a href="#">Newest Courses</a>
                                        </li>
                                        <li>
                                            <a href="#">Most Courses takes </a>
                                        </li>
                                        <li>
                                            <a href="#">Oldest Courses</a>
                                        </li>
                                        <li className="uk-nav-divider"></li>
                                        <li>
                                            <a href="#">trending Courses</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div id="Course-Filter" hidden className="uk-margin-top uk-margin-large-bottom uk-position-relative">
                            <h3 className="uk-visible@s uk-text-uppercase"> FILTER </h3>
                            <div className="uk-position-top-right">
                                <button className="uk-button uk-button-white" uk-toggle="target: #Course-Filter ; animation: uk-animation-fade; queued: true"> Cancel </button>
                                <button className="uk-button uk-button-grey"> Apply </button>
                            </div>
                            <hr className="uk-margin-small" />
                            <div className="uk-child-width-1-4@m uk-child-width-1-2" uk-grid>
                                <div>
                                    <b> Topic </b>
                                    <div className="uk-flex uk-flex-column">
                                        <label>
                                            <input className="uk-checkbox uk-margin-small-right" type="checkbox" checked="checked" />
                                            <span className="checkmark uk-text-small"> Web Design </span>
                                            <span className="uk-text-muted uk-margin-small-left uk-text-small"> 3 </span>
                                        </label>
                                        <label>
                                            <input className="uk-checkbox uk-margin-small-right" type="checkbox" />
                                            <span className="checkmark uk-text-small"> HTML </span>
                                            <span className="uk-text-muted uk-margin-small-left uk-text-small"> 3 </span>
                                        </label>
                                        <label>
                                            <input className="uk-checkbox uk-margin-small-right" type="checkbox" />
                                            <span className="checkmark uk-text-small"> Javascript </span>
                                            <span className="uk-text-muted uk-margin-small-left uk-text-small"> 3 </span>
                                        </label>
                                        <label>
                                            <input className="uk-checkbox uk-margin-small-right" type="checkbox" />
                                            <span className="checkmark uk-text-small"> PHP </span>
                                            <span className="uk-text-muted uk-margin-small-left uk-text-small"> 3 </span>
                                        </label>
                                    </div>
                                </div>
                                <div>
                                    <b> Level </b>
                                    <div className="uk-flex uk-flex-column">
                                        <label>
                                            <input className="uk-checkbox uk-margin-small-right" type="checkbox" />
                                            <span className="checkmark uk-text-small"> all Levels </span>
                                            <span className="uk-text-muted uk-margin-small-left uk-text-small"> 10 </span>
                                        </label>
                                        <label>
                                            <input className="uk-checkbox uk-margin-small-right" type="checkbox" />
                                            <span className="checkmark uk-text-small"> Beginner </span>
                                            <span className="uk-text-muted uk-margin-small-left uk-text-small"> 4 </span>
                                        </label>
                                        <label>
                                            <input className="uk-checkbox uk-margin-small-right" type="checkbox" />
                                            <span className="checkmark uk-text-small"> Intermediate </span>
                                            <span className="uk-text-muted uk-margin-small-left uk-text-small"> 2 </span>
                                        </label>
                                        <label>
                                            <input className="uk-checkbox uk-margin-small-right" type="checkbox" />
                                            <span className="checkmark uk-text-small"> Expert </span>
                                            <span className="uk-text-muted uk-margin-small-left uk-text-small"> 3 </span>
                                        </label>
                                    </div>
                                </div>
                                <div>
                                    <b> Language </b>
                                    <div className="uk-flex uk-flex-column">
                                        <label>
                                            <input className="uk-checkbox uk-margin-small-right" type="checkbox" />
                                            <span className="checkmark uk-text-small"> English </span>
                                            <span className="uk-text-muted uk-margin-small-left uk-text-small"> 2 </span>
                                        </label>
                                        <label>
                                            <input className="uk-checkbox uk-margin-small-right" type="checkbox" />
                                            <span className="checkmark uk-text-small"> Arabic </span>
                                            <span className="uk-text-muted uk-margin-small-left uk-text-small"> 3 </span>
                                        </label>
                                        <label>
                                            <input className="uk-checkbox uk-margin-small-right" type="checkbox" />
                                            <span className="checkmark uk-text-small"> Español </span>
                                            <span className="uk-text-muted uk-margin-small-left uk-text-small"> 5 </span>
                                        </label>
                                    </div>
                                </div>
                                <div>
                                    <b> Price </b>
                                    <div className="uk-flex uk-flex-column">
                                        <label>
                                            <input className="uk-checkbox uk-margin-small-right" type="checkbox" />
                                            <span className="checkmark uk-text-small"> Paid </span>
                                            <span className="uk-text-muted uk-margin-small-left uk-text-small"> 2 </span>
                                        </label>
                                        <label>
                                            <input className="uk-checkbox uk-margin-small-right" type="checkbox" />
                                            <span className="checkmark uk-text-small"> Free </span>
                                            <span className="uk-text-muted uk-margin-small-left uk-text-small"> 5 </span>
                                        </label>
                                    </div>
                                </div>
                                <div>
                                    <b> Features </b>
                                    <div className="uk-flex uk-flex-column">
                                        <label>
                                            <input className="uk-checkbox uk-margin-small-right" type="checkbox" />
                                            <span className="checkmark uk-text-small"> Captions </span>
                                            <span className="uk-text-muted uk-margin-small-left uk-text-small"> 3 </span>
                                        </label>
                                        <label>
                                            <input className="uk-checkbox uk-margin-small-right" type="checkbox" />
                                            <span className="checkmark uk-text-small"> Quizzes </span>
                                            <span className="uk-text-muted uk-margin-small-left uk-text-small"> 4 </span>
                                        </label>
                                    </div>
                                </div>
                                <div>
                                    <b> Ratings </b>
                                    <div className="uk-flex uk-flex-column">
                                        <label>
                                            <input className="uk-checkbox uk-margin-small-right" type="checkbox" />
                                            <span className="checkmark uk-text-small">  <i className="fas fa-star icon-small icon-rate"></i>  <i className="fas fa-star icon-small icon-rate"></i>  <i className="fas fa-star icon-small icon-rate"></i>  <i className="fas fa-star icon-small icon-rate"></i>  <i className="fas fa-star icon-small icon-rate"></i>
                                                5    </span>
                                            <span className="uk-text-muted uk-margin-small-left uk-text-small"> 3 </span>
                                        </label>
                                        <label>
                                            <input className="uk-checkbox uk-margin-small-right" type="checkbox" />
                                            <span className="checkmark uk-text-small">  <i className="fas fa-star icon-small icon-rate"></i>  <i className="fas fa-star icon-small icon-rate"></i>  <i className="fas fa-star icon-small icon-rate"></i>  <i className="fas fa-star icon-small icon-rate"></i>  <i className="far fa-star icon-small icon-rate"></i>
                                                4.0 & up  </span>
                                            <span className="uk-text-muted uk-margin-small-left uk-text-small"> 2 </span>
                                        </label>
                                        <label>
                                            <input className="uk-checkbox uk-margin-small-right" type="checkbox" />
                                            <span className="checkmark uk-text-small">  <i className="fas fa-star icon-small icon-rate"></i>  <i className="fas fa-star icon-small icon-rate"></i>  <i className="fas fa-star icon-small icon-rate"></i>  <i className="far fa-star icon-small icon-rate"></i>  <i className="far fa-star icon-small icon-rate"></i>
                                                3.0 & up  </span>
                                            <span className="uk-text-muted uk-margin-small-left uk-text-small"> 5 </span>
                                        </label>
                                        <label>
                                            <input className="uk-checkbox uk-margin-small-right" type="checkbox" />
                                            <span className="checkmark uk-text-small">  <i className="fas fa-star icon-small icon-rate"></i>  <i className="fas fa-star icon-small icon-rate"></i>  <i className="far fa-star icon-small icon-rate"></i>  <i className="far fa-star icon-small icon-rate"></i>  <i className="far fa-star icon-small icon-rate"></i>
                                                2.0 & up  </span>
                                            <span className="uk-text-muted uk-margin-small-left uk-text-small"> 3 </span>
                                        </label>
                                    </div>
                                </div>
                                <div>
                                    <b> Duration </b>
                                    <div className="uk-flex uk-flex-column">
                                        <label>
                                            <input className="uk-checkbox uk-margin-small-right" type="checkbox" />
                                            <span className="checkmark uk-text-small">   0-2 Hours </span>
                                            <span className="uk-text-muted uk-margin-small-left uk-text-small"> 3 </span>
                                        </label>
                                        <label>
                                            <input className="uk-checkbox uk-margin-small-right" type="checkbox" />
                                            <span className="checkmark uk-text-small"> 3-6 Hours  </span>
                                            <span className="uk-text-muted uk-margin-small-left uk-text-small"> 3 </span>
                                        </label>
                                        <label>
                                            <input className="uk-checkbox uk-margin-small-right" type="checkbox" />
                                            <span className="checkmark uk-text-small"> 7-16 Hours </span>
                                            <span className="uk-text-muted uk-margin-small-left uk-text-small"> 3 </span>
                                        </label>
                                        <label>
                                            <input className="uk-checkbox uk-margin-small-right" type="checkbox" />
                                            <span className="checkmark uk-text-small"> 17+ Hours  </span>
                                            <span className="uk-text-muted uk-margin-small-left uk-text-small"> 3 </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="uk-child-width-1-2@s uk-child-width-1-3@m uk-grid-match uk-margin" uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 200" uk-grid>

                            <div>
                                <div className="uk-card-default uk-card-hover uk-card-small Course-card uk-inline-clip uk-transition-toggle" tabindex="0">
                                    <div className="uk-transition-slide-right-small uk-position-top-right uk-padding-small uk-position-z-index">
                                        <a className="uk-button course-badge" href="#"> <i className="fas fa-heart icon-medium"></i> </a>
                                    </div>
                                    <div className="uk-transition-slide-right-medium uk-position-top-right uk-padding-small uk-margin-medium-right">
                                        <a className="uk-button uk-margin-small-right course-badge" href="#"> <i className="far fa-check-square icon-medium"></i> </a>
                                    </div>
                                    <a href="Course-intro-one.html" className="uk-link-reset">
                                        <img src="../../assets/images/courses/course-10.jpg" className="course-img" />
                                        <div className="uk-card-body">
                                            <h4>Vue.js The Complete Guide</h4>
                                            <p> Lorem ipsum dolor sit amet tempor  consectetur adipiscing elit, sed do eiusmod tempor ... </p>
                                            <hr className="uk-margin-remove-top" />
                                            <div className="uk-clearfix">
                                                <div className="uk-float-left">
                                                    <a className="Course-tags uk-margin-small-right" href="Course-all-tags.html"> JavaScript </a>
                                                    <div uk-drop="pos: top-left;animation: uk-animation-slide-bottom-medium" className="uk-drop">
                                                        <div className="uk-card uk-card-body uk-card-default Course-tooltip-dark anglie-left-bottom-dark">
                                                            <span>Lorem ipsum dolor sit amet tempor  consectetur adipiscing elit  sed do eiusmod tempor incididunt </span>
                                                        </div>
                                                    </div>
                                                    <a className="Course-tags" href="Course-all-tags.html"> Beginner </a>
                                                    <div uk-drop="pos: top-center;animation: uk-animation-slide-bottom-small"
                                                        //style="width:auto !important" 
                                                        className="uk-drop">
                                                        <div className="uk-card uk-padding-small uk-card-default Course-tooltip-dark anglie-center-bottom-dark">
                                                            <span> Lorem ipsum dolor </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="uk-float-right">
                                                    <a className="Course-tags Course-tags-more" href="Browse_all_webdevelopment.html"> </a>
                                                    <div uk-drop="pos: top-right;animation: uk-animation-slide-bottom-medium" className="uk-drop">
                                                        <div className="uk-card uk-card-body uk-card-default Course-tooltip-dark anglie-right-bottom-dark">
                                                            <span>Lorem ipsum dolor sit <a href="#">consectetur</a> </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div>
                                <div className="uk-card-default uk-card-hover  uk-card-small Course-card uk-inline-clip uk-transition-toggle" tabindex="0">
                                    <div className="uk-transition-slide-right-small uk-position-top-right uk-padding-small uk-position-z-index">
                                        <a className="uk-button course-badge" href="#"> <i className="fas fa-heart icon-medium"></i> </a>
                                    </div>
                                    <div className="uk-transition-slide-right-medium uk-position-top-right uk-padding-small uk-margin-medium-right">
                                        <a className="uk-button uk-margin-small-right course-badge" href="#"> <i className="far fa-check-square icon-medium"></i> </a>
                                    </div>
                                    <a href="Course-intro-one.html" className="uk-link-reset">
                                        <img src="../../assets/images/courses/course-7.png" className="course-img" />
                                        <div className="uk-card-body">
                                            <h4>Bootstrap Introduction</h4>
                                            <p> Lorem ipsum dolor sit amet tempor consectetur adipiscing elit, sed do eiusmod tempor ... </p>
                                            <hr className="uk-margin-remove-top" />
                                            <a className="Course-tags uk-margin-small-right tags-active" href="Course-all-tags.html"> Bootstrap </a>
                                            <a className="Course-tags" href="Course-all-tags.html"> Beginner </a>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div>
                                <div className="uk-card-default uk-card-hover  uk-card-small Course-card uk-inline-clip uk-transition-toggle" tabindex="0">
                                    <div className="uk-transition-slide-left-small uk-position-top-left uk-padding-small uk-position-z-index">
                                        <a className="uk-button course-badge" href="#"> <i className="fas fa-heart icon-medium"></i> </a>
                                    </div>
                                    <div className="uk-transition-slide-left-medium uk-position-top-left uk-padding-small uk-margin-medium-left">
                                        <a className="uk-button uk-margin-small-left course-badge" href="#"> <i className="far fa-check-square icon-medium"></i> </a>
                                    </div>
                                    <a href="Course-intro-one.html" className="uk-link-reset">
                                        <img src="../../assets/images/courses/course-1.jpg" className="course-img" />
                                        <div className="uk-card-body">
                                            <h4>Complete web development</h4>
                                            <p> Lorem ipsum dolor sit amet tempor  consectetur adipiscing elit, sed do eiusmod tempor ... </p>
                                            <hr className="uk-margin-remove-top" />
                                            <div className="uk-clearfix">
                                                <div className="uk-float-left">
                                                    <a className="Course-tags uk-margin-small-right" href="Course-all-tags.html"> web Development </a>
                                                    <div uk-drop="pos: top-left;animation: uk-animation-slide-bottom-medium">
                                                        <div className="uk-card uk-card-body uk-card-default Course-tooltip anglie-left-bottom">
                                                            <span>Lorem ipsum dolor sit amet tempor  consectetur adipiscing elit  sed do eiusmod tempor incididunt </span>
                                                        </div>
                                                    </div>
                                                    <a className="Course-tags" href="Course-all-tags.html"> Beginners </a>
                                                    <div uk-drop="pos: top-center;animation: uk-animation-slide-bottom-small uk-width-auto">
                                                        <div className="uk-card uk-padding-small uk-card-default Course-tooltip anglie-center-bottom">
                                                            <span> Lorem ipsum dolor </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="uk-float-right">
                                                    <a className="Course-tags Course-tags-more" href="Browse_all_webdevelopment.html"> </a>
                                                    <div uk-drop="pos: top-right;animation: uk-animation-slide-bottom-medium" className="uk-drop">
                                                        <div className="uk-card uk-card-body uk-card-default Course-tooltip anglie-right-bottom">
                                                            <span>Lorem ipsum dolor sit <a href="#">consectetur</a> </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div>
                                <div className="uk-card-default uk-card-hover  uk-card-small Course-card uk-inline-clip uk-transition-toggle" tabindex="0">
                                    <div className="uk-transition-slide-right-small uk-position-top-right uk-padding-small uk-position-z-index">
                                        <a className="uk-button course-badge" href="#"> <i className="fas fa-heart icon-medium"></i> </a>
                                    </div>
                                    <div className="uk-transition-slide-right-medium uk-position-top-right uk-padding-small uk-margin-medium-right">
                                        <a className="uk-button uk-margin-small-right course-badge" href="#"> <i className="far fa-check-square icon-medium"></i> </a>
                                    </div>
                                    <a href="Course-intro-one.html" className="uk-link-reset">
                                        <img src="../../assets/images/courses/course-9.jpg" className="course-img" />
                                        <div className="uk-card-body">
                                            <h4>Beginning JavaScript </h4>
                                            <p> Lorem ipsum dolor sit amet tempor  consectetur adipiscing elit, sed do eiusmod tempor ... </p>
                                            <hr className="uk-margin-remove-top" />
                                            <a className="Course-tags uk-margin-small-right" href="#"> JavaScript </a>
                                            <div uk-drop="pos: top-left;animation: uk-animation-slide-bottom-medium">
                                                <div className="uk-card uk-card-body uk-card-default Course-tooltip-dark">
                                                    <span>Lorem ipsum dolor sit amet tempor  consectetur adipiscing elit  sed do eiusmod tempor incididunt </span>
                                                </div>
                                            </div>
                                            <a className="Course-tags" href="Course-all-tags.html"> Beginner </a>
                                            <div uk-drop="pos: top-center;animation: uk-animation-slide-bottom-small uk-width-auto">
                                                <div className="uk-card uk-padding-small uk-card-default Course-tooltip-dark">
                                                    <span> Lorem ipsum dolor </span>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div>
                                <div className="uk-card-default uk-card-hover  uk-card-small Course-card uk-inline-clip">
                                    <a href="Course-intro-one.html" className="uk-link-reset">
                                        <img src="../../assets/images/courses/course-8.jpg" className="course-img" />
                                        <div className="uk-card-body">
                                            <h4>Beginning Bython</h4>
                                            <p> Lorem ipsum dolor sit amet tempor consectetur adipiscing elit, sed do eiusmod tempor ... </p>
                                            <hr className="uk-margin-remove-top" />
                                            <a className="Course-tags uk-margin-small-right" href="#"> JavaScript </a>
                                            <div uk-drop="pos: top-left;animation: uk-animation-slide-bottom-medium">
                                                <div className="uk-card uk-card-body uk-card-default Course-tooltip">
                                                    <span>Lorem ipsum dolor sit amet tempor  consectetur adipiscing elit  sed do eiusmod tempor incididunt </span>
                                                </div>
                                            </div>
                                            <a className="Course-tags" href="Course-all-tags.html"> Beginner </a>
                                            <div uk-drop="pos: top-center;animation: uk-animation-slide-bottom-small uk-width-auto">
                                                <div className="uk-card uk-padding-small uk-card-default Course-tooltip ">
                                                    <span> Lorem ipsum dolor </span>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div>
                                <div className="uk-card-default uk-card-hover  uk-card-small Course-card  scale-up-medium uk-inline-clip uk-transition-toggle" tabindex="0">
                                    <div className="uk-transition-slide-right-small uk-position-top-right uk-padding-small uk-position-z-index">
                                        <a className="uk-button course-badge" href="#"> <i className="fas fa-heart icon-medium"></i> </a>
                                    </div>
                                    <div className="uk-transition-slide-right-medium uk-position-top-right uk-padding-small uk-margin-medium-right">
                                        <a className="uk-button uk-margin-small-right course-badge" href="#"> <i className="far fa-check-square icon-medium"></i> </a>
                                    </div>
                                    <a href="Course-intro-one.html" className="uk-link-reset">
                                        <img src="../../assets/images/courses/course-2.jpg" className="course-img" />
                                        <div className="uk-card-body">
                                            <h4> How to Create A Website</h4>
                                            <p> Lorem ipsum dolor sit amet tempor consectetur adipiscing elit, sed do eiusmod tempor ... </p>
                                            <hr className="uk-margin-remove-top" />
                                            <a className="Course-tags uk-margin-small-right tags-active" href="Course-all-tags.html"> Web Development </a>
                                            <a className="Course-tags" href="Course-all-tags.html"> Beginner </a>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div>
                                <div className="uk-card-default uk-card-hover  uk-card-small Course-card uk-inline-clip">
                                    <a href="Course-intro-one.html" className="uk-link-reset">
                                        <img src="../../assets/images/courses/course-5.jpg" className="course-img" />
                                        <div className="uk-card-body">
                                            <h4>Build your site from Scratch</h4>
                                            <p> Lorem ipsum dolor sit amet tempor consectetur adipiscing elit, sed do eiusmod tempor ... </p>
                                            <hr className="uk-margin-remove-top" />
                                            <a className="Course-tags uk-margin-small-right tags-active" href="Course-all-tags.html"> JavaScript </a>
                                            <a className="Course-tags" href="Course-all-tags.html"> Beginner </a>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div>
                                <div className="uk-card-default uk-card-hover  uk-card-small Course-card  scale-up-medium uk-inline-clip uk-transition-toggle" tabindex="0">
                                    <div className="uk-transition-slide-right-small uk-position-top-right uk-padding-small uk-position-z-index">
                                        <a className="uk-button course-badge" href="#"> <i className="fas fa-heart icon-medium"></i> </a>
                                    </div>
                                    <div className="uk-transition-slide-right-medium uk-position-top-right uk-padding-small uk-margin-medium-right">
                                        <a className="uk-button uk-margin-small-right course-badge" href="#"> <i className="far fa-check-square icon-medium"></i> </a>
                                    </div>
                                    <a href="Course-intro-one.html" className="uk-link-reset">
                                        <img src="../../assets/images/courses/course-3.jpg" className="course-img" />
                                        <div className="uk-card-body">
                                            <h4>Web Design for Beginners </h4>
                                            <p> Lorem ipsum dolor sit amet tempor consectetur adipiscing elit, sed do eiusmod tempor ... </p>
                                            <hr className="uk-margin-remove-top" />
                                            <a className="Course-tags uk-margin-small-right tags-active" href="#"> JavaScript </a>
                                            <a className="Course-tags" href="#"> Beginner </a>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div>
                                <div className="uk-card-default uk-card-hover  uk-card-small Course-card uk-inline-clip">
                                    <a href="Course-intro-one.html" className="uk-link-reset">
                                        <img src="../../assets/images/courses/course-4.jpg" className="course-img" />
                                        <div className="uk-card-body">
                                            <h4>The Ultimate HTML Developer</h4>
                                            <p> Lorem ipsum dolor sit amet tempor consectetur adipiscing elit, sed do eiusmod tempor ... </p>
                                            <hr className="uk-margin-remove-top" />
                                            <a className="Course-tags uk-margin-small-right tags-active" href="#"> JavaScript </a>
                                            <a className="Course-tags" href="#"> Beginner </a>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <a href="Browse-courses-single-page.html" className="uk-align-center uk-text-center uk-margin-large uk-h4 uk-link-heading"> Browse more Courses </a>
                    </div>
                </li>
                <li>

                    <div className="uk-container">
                        <div className="uk-clearfix boundary-align">
                            <div className="uk-float-left section-heading none-border">
                                <h2>Browse Mobile Apps courses</h2>
                                <p>Adipisici elit, sed eiusmod tempor incidunt ut labore et</p>
                            </div>
                            <div className="uk-float-right">
                                <a href="#" className="uk-link-reset uk-margin-small-right" uk-tooltip="title: list; pos: top-right"> <i className="fas fa-th-large"></i> </a>
                                <a href="Course-list.html" className="uk-margin-small-right" uk-tooltip="title: list; pos: top-right"><i className="fas fa-th-list"></i> </a>
                                <span className="uk-text-small"> Sort by :</span>
                                <button className="uk-button uk-button-default uk-background-default uk-button-small" type="button" uk-tooltip="title: Sort; pos: top-right"> Newest  </button>
                                <div uk-dropdown="pos: top-right ;mode : click" className="uk-dropdown  uk-dropdown-top-right"
                                //style="left: -141px; top: -267px;"
                                >
                                    <ul className="uk-nav uk-dropdown-nav">
                                        <li className="uk-active">
                                            <a href="#">Best Courses</a>
                                        </li>
                                        <li>
                                            <a href="#">Newest Courses</a>
                                        </li>
                                        <li>
                                            <a href="#">Most Courses takes </a>
                                        </li>
                                        <li>
                                            <a href="#">Oldest Courses</a>
                                        </li>
                                        <li className="uk-nav-divider"></li>
                                        <li>
                                            <a href="#">trending Courses</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="uk-child-width-1-2@s uk-child-width-1-3@m uk-grid-match uk-margin" uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 200" uk-grid>
                            <div>
                                <div className="uk-card-default uk-card-hover  uk-card-small Course-card uk-inline-clip">
                                    <a href="Course-intro-one.html" className="uk-link-reset">
                                        <img src="../../assets/images/courses/course-1.jpg" className="course-img" />
                                        <div className="uk-card-body">
                                            <h4>Build from Scratch</h4>
                                            <p> Lorem ipsum dolor sit amet tempor consectetur adipiscing elit, sed do eiusmod tempor ... </p>
                                            <hr className="uk-margin-remove-top" />
                                            <a className="Course-tags uk-margin-small-right tags-active" href="Course-all-tags.html"> JavaScript </a>
                                            <a className="Course-tags" href="Course-all-tags.html"> Beginner </a>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div>
                                <div className="uk-card-default uk-card-hover  uk-card-small Course-card uk-inline-clip">
                                    <a href="Course-intro-one.html" className="uk-link-reset">
                                        <img src="../../assets/images/courses/course-2.jpg" className="course-img" />
                                        <div className="uk-card-body">
                                            <h4> How to Create A Website</h4>
                                            <p> Lorem ipsum dolor sit amet tempor consectetur adipiscing elit, sed do eiusmod tempor ... </p>
                                            <hr className="uk-margin-remove-top" />
                                            <a className="Course-tags uk-margin-small-right tags-active" href="Course-all-tags.html"> JavaScript </a>
                                            <a className="Course-tags" href="Course-all-tags.html"> Beginner </a>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div>
                                <div className="uk-card-default uk-card-hover  uk-card-small Course-card uk-inline-clip">
                                    <a href="Course-intro-one.html" className="uk-link-reset">
                                        <img src="../../assets/images/courses/course-3.jpg" className="course-img" />
                                        <div className="uk-card-body">
                                            <h4>The Ultimate HTML Developer</h4>
                                            <p> Lorem ipsum dolor sit amet tempor consectetur adipiscing elit, sed do eiusmod tempor ... </p>
                                            <hr className="uk-margin-remove-top" />
                                            <a className="Course-tags uk-margin-small-right tags-active" href="Course-all-tags.html"> JavaScript </a>
                                            <a className="Course-tags" href="Course-all-tags.html"> Beginner </a>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div>
                                <div className="uk-card-default uk-card-hover  uk-card-small Course-card uk-inline-clip">
                                    <a href="Course-intro-one.html" className="uk-link-reset">
                                        <img src="../../assets/images/courses/course-5.jpg" className="course-img" />
                                        <div className="uk-card-body">
                                            <h4>Build from Scratch</h4>
                                            <p> Lorem ipsum dolor sit amet tempor consectetur adipiscing elit, sed do eiusmod tempor ... </p>
                                            <hr className="uk-margin-remove-top" />
                                            <a className="Course-tags uk-margin-small-right tags-active" href="Course-all-tags.html"> JavaScript </a>
                                            <a className="Course-tags" href="Course-all-tags.html"> Beginner </a>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div>
                                <div className="uk-card-default uk-card-hover  uk-card-small Course-card uk-inline-clip">
                                    <a href="Course-intro-one.html" className="uk-link-reset">
                                        <img src="../../assets/images/courses/course-8.jpg" className="course-img" />
                                        <div className="uk-card-body">
                                            <h4>Beginning Bython</h4>
                                            <p> Lorem ipsum dolor sit amet tempor consectetur adipiscing elit, sed do eiusmod tempor ... </p>
                                            <hr className="uk-margin-remove-top" />
                                            <a className="Course-tags uk-margin-small-right tags-active" href="Course-all-tags.html"> JavaScript </a>
                                            <a className="Course-tags" href="Course-all-tags.html"> Beginner </a>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div>
                                <div className="uk-card-default uk-card-hover  uk-card-small Course-card uk-inline-clip">
                                    <a href="Course-intro-one.html" className="uk-link-reset">
                                        <img src="../../assets/images/courses/course-4.jpg" className="course-img" />
                                        <div className="uk-card-body">
                                            <h4>The Ultimate HTML Developer</h4>
                                            <p> Lorem ipsum dolor sit amet tempor consectetur adipiscing elit, sed do eiusmod tempor ... </p>
                                            <hr className="uk-margin-remove-top" />
                                            <a className="Course-tags uk-margin-small-right tags-active" href="Course-all-tags.html"> JavaScript </a>
                                            <a className="Course-tags" href="Course-all-tags.html"> Beginner </a>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <a href="Browse-courses-single-page.html" className="uk-align-center uk-text-center uk-margin-large uk-h4 uk-link-heading"> Browse more Courses </a>
                    </div>
                </li>
                <li>

                    <div className="uk-container">
                        <div className="uk-clearfix boundary-align">
                            <div className="uk-float-left section-heading none-border">
                                <h2>Browse Game development courses</h2>
                                <p>Adipisici elit, sed eiusmod tempor incidunt ut labore et</p>
                            </div>
                            <div className="uk-float-right">
                                <a href="#" className="uk-link-reset uk-margin-small-right" uk-tooltip="title: list; pos: top-right"> <i className="fas fa-th-large"></i> </a>
                                <a href="Course-list.html" className="uk-margin-small-right" uk-tooltip="title: list; pos: top-right"><i className="fas fa-th-list"></i> </a>
                                <span className="uk-text-small"> Sort by :</span>
                                <button className="uk-button uk-button-default uk-background-default uk-button-small" type="button" uk-tooltip="title: Sort; pos: top-right"> Newest  </button>
                                <div uk-dropdown="pos: top-right ;mode : click" className="uk-dropdown  uk-dropdown-top-right"
                                //style="left: -141px; top: -267px;"
                                >
                                    <ul className="uk-nav uk-dropdown-nav">
                                        <li className="uk-active">
                                            <a href="#">Best Courses</a>
                                        </li>
                                        <li>
                                            <a href="#">Newest Courses</a>
                                        </li>
                                        <li>
                                            <a href="#">Most Courses takes </a>
                                        </li>
                                        <li>
                                            <a href="#">Oldest Courses</a>
                                        </li>
                                        <li className="uk-nav-divider"></li>
                                        <li>
                                            <a href="#">trending Courses</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="uk-child-width-1-2@s uk-child-width-1-3@m uk-grid-match uk-margin" uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 200" uk-grid>

                            <div>
                                <div className="uk-card-default uk-card-hover  uk-card-small Course-card uk-inline-clip">
                                    <a href="Course-intro-one.html" className="uk-link-reset">
                                        <img src="../../assets/images/courses/course-1.jpg" className="course-img" />
                                        <div className="uk-card-body">
                                            <h4>Build from Scratch</h4>
                                            <p> Lorem ipsum dolor sit amet tempor consectetur adipiscing elit, sed do eiusmod tempor ... </p>
                                            <hr className="uk-margin-remove-top" />
                                            <a className="Course-tags uk-margin-small-right tags-active" href="Course-all-tags.html"> JavaScript </a>
                                            <a className="Course-tags" href="Course-all-tags.html"> Beginner </a>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div>
                                <div className="uk-card-default uk-card-hover  uk-card-small Course-card uk-inline-clip">
                                    <a href="Course-intro-one.html" className="uk-link-reset">
                                        <img src="../../assets/images/courses/course-2.jpg" className="course-img" />
                                        <div className="uk-card-body">
                                            <h4> How to Create A Website</h4>
                                            <p> Lorem ipsum dolor sit amet tempor consectetur adipiscing elit, sed do eiusmod tempor ... </p>
                                            <hr className="uk-margin-remove-top" />
                                            <a className="Course-tags uk-margin-small-right tags-active" href="Course-all-tags.html"> JavaScript </a>
                                            <a className="Course-tags" href="Course-all-tags.html"> Beginner </a>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div>
                                <div className="uk-card-default uk-card-hover  uk-card-small Course-card uk-inline-clip">
                                    <a href="Course-intro-one.html" className="uk-link-reset">
                                        <img src="../../assets/images/courses/course-3.jpg" className="course-img" />
                                        <div className="uk-card-body">
                                            <h4>The Ultimate HTML Developer</h4>
                                            <p> Lorem ipsum dolor sit amet tempor consectetur adipiscing elit, sed do eiusmod tempor ... </p>
                                            <hr className="uk-margin-remove-top" />
                                            <a className="Course-tags uk-margin-small-right tags-active" href="Course-all-tags.html"> JavaScript </a>
                                            <a className="Course-tags" href="Course-all-tags.html"> Beginner </a>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div>
                                <div className="uk-card-default uk-card-hover  uk-card-small Course-card uk-inline-clip">
                                    <a href="Course-intro-one.html" className="uk-link-reset">
                                        <img src="../../assets/images/courses/course-5.jpg" className="course-img" />
                                        <div className="uk-card-body">
                                            <h4>Build from Scratch</h4>
                                            <p> Lorem ipsum dolor sit amet tempor consectetur adipiscing elit, sed do eiusmod tempor ... </p>
                                            <hr className="uk-margin-remove-top" />
                                            <a className="Course-tags uk-margin-small-right tags-active" href="Course-all-tags.html"> JavaScript </a>
                                            <a className="Course-tags" href="Course-all-tags.html"> Beginner </a>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div>
                                <div className="uk-card-default uk-card-hover  uk-card-small Course-card uk-inline-clip">
                                    <a href="Course-intro-one.html" className="uk-link-reset">
                                        <img src="../../assets/images/courses/course-8.jpg" className="course-img" />
                                        <div className="uk-card-body">
                                            <h4>Beginning Bython</h4>
                                            <p> Lorem ipsum dolor sit amet tempor consectetur adipiscing elit, sed do eiusmod tempor ... </p>
                                            <hr className="uk-margin-remove-top" />
                                            <a className="Course-tags uk-margin-small-right tags-active" href="Course-all-tags.html"> JavaScript </a>
                                            <a className="Course-tags" href="Course-all-tags.html"> Beginner </a>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div>
                                <div className="uk-card-default uk-card-hover  uk-card-small Course-card uk-inline-clip">
                                    <a href="Course-intro-one.html" className="uk-link-reset">
                                        <img src="../../assets/images/courses/course-4.jpg" className="course-img" />
                                        <div className="uk-card-body">
                                            <h4>The Ultimate HTML Developer</h4>
                                            <p> Lorem ipsum dolor sit amet tempor consectetur adipiscing elit, sed do eiusmod tempor ... </p>
                                            <hr className="uk-margin-remove-top" />
                                            <a className="Course-tags uk-margin-small-right tags-active" href="Course-all-tags.html"> JavaScript </a>
                                            <a className="Course-tags" href="Course-all-tags.html"> Beginner </a>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <a href="Browse-courses-single-page.html" className="uk-align-center uk-text-center uk-margin-large uk-h4 uk-link-heading"> Browse more Courses </a>
                    </div>
                </li>
                <li>

                    <div className="uk-container">
                        <div className="uk-clearfix boundary-align">
                            <div className="uk-float-left section-heading none-border">
                                <h2>Browse Software courses</h2>
                                <p>Adipisici elit, sed eiusmod tempor incidunt ut labore et</p>
                            </div>
                            <div className="uk-float-right">
                                <a href="#" className="uk-link-reset uk-margin-small-right" uk-tooltip="title: list; pos: top-right"> <i className="fas fa-th-large"></i> </a>
                                <a href="Course-list.html" className="uk-margin-small-right" uk-tooltip="title: list; pos: top-right"><i className="fas fa-th-list"></i> </a>
                                <span className="uk-text-small"> Sort by :</span>
                                <button className="uk-button uk-button-default uk-background-default uk-button-small" type="button" uk-tooltip="title: Sort; pos: top-right"> Newest  </button>
                                <div uk-dropdown="pos: top-right ;mode : click" className="uk-dropdown  uk-dropdown-top-right"
                                //style="left: -141px; top: -267px;"
                                >
                                    <ul className="uk-nav uk-dropdown-nav">
                                        <li className="uk-active">
                                            <a href="#">Best Courses</a>
                                        </li>
                                        <li>
                                            <a href="#">Newest Courses</a>
                                        </li>
                                        <li>
                                            <a href="#">Most Courses takes </a>
                                        </li>
                                        <li>
                                            <a href="#">Oldest Courses</a>
                                        </li>
                                        <li className="uk-nav-divider"></li>
                                        <li>
                                            <a href="#">trending Courses</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="uk-child-width-1-2@s uk-child-width-1-3@m uk-grid-match uk-margin" uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 200" uk-grid>

                            <div>
                                <div className="uk-card-default uk-card-hover  uk-card-small Course-card uk-inline-clip">
                                    <a href="Course-intro-one.html" className="uk-link-reset">
                                        <img src="../../assets/images/courses/course-1.jpg" className="course-img" />
                                        <div className="uk-card-body">
                                            <h4>Build from Scratch</h4>
                                            <p> Lorem ipsum dolor sit amet tempor consectetur adipiscing elit, sed do eiusmod tempor ... </p>
                                            <hr className="uk-margin-remove-top" />
                                            <a className="Course-tags uk-margin-small-right tags-active" href="Course-all-tags.html"> JavaScript </a>
                                            <a className="Course-tags" href="Course-all-tags.html"> Beginner </a>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div>
                                <div className="uk-card-default uk-card-hover  uk-card-small Course-card uk-inline-clip">
                                    <a href="Course-intro-one.html" className="uk-link-reset">
                                        <img src="../../assets/images/courses/course-2.jpg" className="course-img" />
                                        <div className="uk-card-body">
                                            <h4> How to Create A Website</h4>
                                            <p> Lorem ipsum dolor sit amet tempor consectetur adipiscing elit, sed do eiusmod tempor ... </p>
                                            <hr className="uk-margin-remove-top" />
                                            <a className="Course-tags uk-margin-small-right tags-active" href="Course-all-tags.html"> JavaScript </a>
                                            <a className="Course-tags" href="Course-all-tags.html"> Beginner </a>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div>
                                <div className="uk-card-default uk-card-hover  uk-card-small Course-card uk-inline-clip">
                                    <a href="Course-intro-one.html" className="uk-link-reset">
                                        <img src="../../assets/images/courses/course-3.jpg" className="course-img" />
                                        <div className="uk-card-body">
                                            <h4>The Ultimate HTML Developer</h4>
                                            <p> Lorem ipsum dolor sit amet tempor consectetur adipiscing elit, sed do eiusmod tempor ... </p>
                                            <hr className="uk-margin-remove-top" />
                                            <a className="Course-tags uk-margin-small-right tags-active" href="Course-all-tags.html"> JavaScript </a>
                                            <a className="Course-tags" href="Course-all-tags.html"> Beginner </a>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div>
                                <div className="uk-card-default uk-card-hover  uk-card-small Course-card uk-inline-clip">
                                    <a href="Course-intro-one.html" className="uk-link-reset">
                                        <img src="../../assets/images/courses/course-5.jpg" className="course-img" />
                                        <div className="uk-card-body">
                                            <h4>Build from Scratch</h4>
                                            <p> Lorem ipsum dolor sit amet tempor consectetur adipiscing elit, sed do eiusmod tempor ... </p>
                                            <hr className="uk-margin-remove-top" />
                                            <a className="Course-tags uk-margin-small-right tags-active" href="Course-all-tags.html"> JavaScript </a>
                                            <a className="Course-tags" href="Course-all-tags.html"> Beginner </a>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div>
                                <div className="uk-card-default uk-card-hover  uk-card-small Course-card uk-inline-clip">
                                    <a href="Course-intro-one.html" className="uk-link-reset">
                                        <img src="../../assets/images/courses/course-8.jpg" className="course-img" />
                                        <div className="uk-card-body">
                                            <h4>Beginning Bython</h4>
                                            <p> Lorem ipsum dolor sit amet tempor consectetur adipiscing elit, sed do eiusmod tempor ... </p>
                                            <hr className="uk-margin-remove-top" />
                                            <a className="Course-tags uk-margin-small-right tags-active" href="Course-all-tags.html"> JavaScript </a>
                                            <a className="Course-tags" href="Course-all-tags.html"> Beginner </a>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div>
                                <div className="uk-card-default uk-card-hover  uk-card-small Course-card uk-inline-clip">
                                    <a href="Course-intro-one.html" className="uk-link-reset">
                                        <img src="../../assets/images/courses/course-4.jpg" className="course-img" />
                                        <div className="uk-card-body">
                                            <h4>The Ultimate HTML Developer</h4>
                                            <p> Lorem ipsum dolor sit amet tempor consectetur adipiscing elit, sed do eiusmod tempor ... </p>
                                            <hr className="uk-margin-remove-top" />
                                            <a className="Course-tags uk-margin-small-right tags-active" href="Course-all-tags.html"> JavaScript </a>
                                            <a className="Course-tags" href="Course-all-tags.html"> Beginner </a>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <a href="Browse-courses-single-page.html" className="uk-align-center uk-text-center uk-margin-large uk-h4 uk-link-heading"> Browse more Courses </a>
                    </div>
                </li>
                <li>

                    <div className="uk-container">
                        <div className="uk-clearfix boundary-align">
                            <div className="uk-float-left section-heading none-border">
                                <h2>Browse Development Tools courses</h2>
                                <p>Adipisici elit, sed eiusmod tempor incidunt ut labore et</p>
                            </div>
                            <div className="uk-float-right">
                                <a href="#" className="uk-link-reset uk-margin-small-right" uk-tooltip="title: list; pos: top-right"> <i className="fas fa-th-large"></i> </a>
                                <a href="Course-list.html" className="uk-margin-small-right" uk-tooltip="title: list; pos: top-right"><i className="fas fa-th-list"></i> </a>
                                <span className="uk-text-small"> Sort by :</span>
                                <button className="uk-button uk-button-default uk-background-default uk-button-small" type="button" uk-tooltip="title: Sort; pos: top-right"> Newest  </button>
                                <div uk-dropdown="pos: top-right ;mode : click" className="uk-dropdown  uk-dropdown-top-right"
                                //style="left: -141px; top: -267px;"
                                >
                                    <ul className="uk-nav uk-dropdown-nav">
                                        <li className="uk-active">
                                            <a href="#">Best Courses</a>
                                        </li>
                                        <li>
                                            <a href="#">Newest Courses</a>
                                        </li>
                                        <li>
                                            <a href="#">Most Courses takes </a>
                                        </li>
                                        <li>
                                            <a href="#">Oldest Courses</a>
                                        </li>
                                        <li className="uk-nav-divider"></li>
                                        <li>
                                            <a href="#">trending Courses</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="uk-child-width-1-2@s uk-child-width-1-3@m uk-grid-match uk-margin" uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 200" uk-grid>

                            <div>
                                <div className="uk-card-default uk-card-hover  uk-card-small Course-card uk-inline-clip">
                                    <a href="Course-intro-one.html" className="uk-link-reset">
                                        <img src="../../assets/images/courses/course-1.jpg" className="course-img" />
                                        <div className="uk-card-body">
                                            <h4>Build from Scratch</h4>
                                            <p> Lorem ipsum dolor sit amet tempor consectetur adipiscing elit, sed do eiusmod tempor ... </p>
                                            <hr className="uk-margin-remove-top" />
                                            <a className="Course-tags uk-margin-small-right tags-active" href="Course-all-tags.html"> JavaScript </a>
                                            <a className="Course-tags" href="Course-all-tags.html"> Beginner </a>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div>
                                <div className="uk-card-default uk-card-hover  uk-card-small Course-card uk-inline-clip">
                                    <a href="Course-intro-one.html" className="uk-link-reset">
                                        <img src="../../assets/images/courses/course-2.jpg" className="course-img" />
                                        <div className="uk-card-body">
                                            <h4> How to Create A Website</h4>
                                            <p> Lorem ipsum dolor sit amet tempor consectetur adipiscing elit, sed do eiusmod tempor ... </p>
                                            <hr className="uk-margin-remove-top" />
                                            <a className="Course-tags uk-margin-small-right tags-active" href="Course-all-tags.html"> JavaScript </a>
                                            <a className="Course-tags" href="Course-all-tags.html"> Beginner </a>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div>
                                <div className="uk-card-default uk-card-hover  uk-card-small Course-card uk-inline-clip">
                                    <a href="Course-intro-one.html" className="uk-link-reset">
                                        <img src="../../assets/images/courses/course-3.jpg" className="course-img" />
                                        <div className="uk-card-body">
                                            <h4>The Ultimate HTML Developer</h4>
                                            <p> Lorem ipsum dolor sit amet tempor consectetur adipiscing elit, sed do eiusmod tempor ... </p>
                                            <hr className="uk-margin-remove-top" />
                                            <a className="Course-tags uk-margin-small-right tags-active" href="Course-all-tags.html"> JavaScript </a>
                                            <a className="Course-tags" href="Course-all-tags.html"> Beginner </a>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div>
                                <div className="uk-card-default uk-card-hover  uk-card-small Course-card uk-inline-clip">
                                    <a href="Course-intro-one.html" className="uk-link-reset">
                                        <img src="../../assets/images/courses/course-5.jpg" className="course-img" />
                                        <div className="uk-card-body">
                                            <h4>Build from Scratch</h4>
                                            <p> Lorem ipsum dolor sit amet tempor consectetur adipiscing elit, sed do eiusmod tempor ... </p>
                                            <hr className="uk-margin-remove-top" />
                                            <a className="Course-tags uk-margin-small-right tags-active" href="Course-all-tags.html"> JavaScript </a>
                                            <a className="Course-tags" href="Course-all-tags.html"> Beginner </a>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div>
                                <div className="uk-card-default uk-card-hover  uk-card-small Course-card uk-inline-clip">
                                    <a href="Course-intro-one.html" className="uk-link-reset">
                                        <img src="../../assets/images/courses/course-8.jpg" className="course-img" />
                                        <div className="uk-card-body">
                                            <h4>Beginning Bython</h4>
                                            <p> Lorem ipsum dolor sit amet tempor consectetur adipiscing elit, sed do eiusmod tempor ... </p>
                                            <hr className="uk-margin-remove-top" />
                                            <a className="Course-tags uk-margin-small-right tags-active" href="Course-all-tags.html"> JavaScript </a>
                                            <a className="Course-tags" href="Course-all-tags.html"> Beginner </a>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div>
                                <div className="uk-card-default uk-card-hover  uk-card-small Course-card uk-inline-clip">
                                    <a href="Course-intro-one.html" className="uk-link-reset">
                                        <img src="../../assets/images/courses/course-4.jpg" className="course-img" />
                                        <div className="uk-card-body">
                                            <h4>The Ultimate HTML Developer</h4>
                                            <p> Lorem ipsum dolor sit amet tempor consectetur adipiscing elit, sed do eiusmod tempor ... </p>
                                            <hr className="uk-margin-remove-top" />
                                            <a className="Course-tags uk-margin-small-right tags-active" href="Course-all-tags.html"> JavaScript </a>
                                            <a className="Course-tags" href="Course-all-tags.html"> Beginner </a>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <a href="Browse-courses-single-page.html" className="uk-align-center uk-text-center uk-margin-large uk-h4 uk-link-heading"> Browse more Courses </a>
                    </div>
                </li>
                <li>

                    <div className="uk-container">
                        <div className="uk-clearfix boundary-align">
                            <div className="uk-float-left section-heading none-border">
                                <h2>Browse Ecommerce courses</h2>
                                <p>Adipisici elit, sed eiusmod tempor incidunt ut labore et</p>
                            </div>
                            <div className="uk-float-right">
                                <a href="#" className="uk-link-reset uk-margin-small-right" uk-tooltip="title: list; pos: top-right"> <i className="fas fa-th-large"></i> </a>
                                <a href="Course-list.html" className="uk-margin-small-right" uk-tooltip="title: list; pos: top-right"><i className="fas fa-th-list"></i> </a>
                                <span className="uk-text-small"> Sort by :</span>
                                <button className="uk-button uk-button-default uk-background-default uk-button-small" type="button" uk-tooltip="title: Sort; pos: top-right"> Newest  </button>
                                <div uk-dropdown="pos: top-right ;mode : click" className="uk-dropdown  uk-dropdown-top-right"
                                //style="left: -141px; top: -267px;"
                                >
                                    <ul className="uk-nav uk-dropdown-nav">
                                        <li className="uk-active">
                                            <a href="#">Best Courses</a>
                                        </li>
                                        <li>
                                            <a href="#">Newest Courses</a>
                                        </li>
                                        <li>
                                            <a href="#">Most Courses takes </a>
                                        </li>
                                        <li>
                                            <a href="#">Oldest Courses</a>
                                        </li>
                                        <li className="uk-nav-divider"></li>
                                        <li>
                                            <a href="#">trending Courses</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="uk-child-width-1-2@s uk-child-width-1-3@m uk-grid-match uk-margin" uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 200" uk-grid>

                            <div>
                                <div className="uk-card-default uk-card-hover  uk-card-small Course-card uk-inline-clip">
                                    <a href="Course-intro-one.html" className="uk-link-reset">
                                        <img src="../../assets/images/courses/course-1.jpg" className="course-img" />
                                        <div className="uk-card-body">
                                            <h4>Build from Scratch</h4>
                                            <p> Lorem ipsum dolor sit amet tempor consectetur adipiscing elit, sed do eiusmod tempor ... </p>
                                            <hr className="uk-margin-remove-top" />
                                            <a className="Course-tags uk-margin-small-right tags-active" href="Course-all-tags.html"> JavaScript </a>
                                            <a className="Course-tags" href="Course-all-tags.html"> Beginner </a>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            \
                            <div>
                                <div className="uk-card-default uk-card-hover  uk-card-small Course-card uk-inline-clip">
                                    <a href="Course-intro-one.html" className="uk-link-reset">
                                        <img src="../../assets/images/courses/course-2.jpg" className="course-img" />
                                        <div className="uk-card-body">
                                            <h4> How to Create A Website</h4>
                                            <p> Lorem ipsum dolor sit amet tempor consectetur adipiscing elit, sed do eiusmod tempor ... </p>
                                            <hr className="uk-margin-remove-top" />
                                            <a className="Course-tags uk-margin-small-right tags-active" href="Course-all-tags.html"> JavaScript </a>
                                            <a className="Course-tags" href="Course-all-tags.html"> Beginner </a>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div>
                                <div className="uk-card-default uk-card-hover  uk-card-small Course-card uk-inline-clip">
                                    <a href="Course-intro-one.html" className="uk-link-reset">
                                        <img src="../../assets/images/courses/course-3.jpg" className="course-img" />
                                        <div className="uk-card-body">
                                            <h4>The Ultimate HTML Developer</h4>
                                            <p> Lorem ipsum dolor sit amet tempor consectetur adipiscing elit, sed do eiusmod tempor ... </p>
                                            <hr className="uk-margin-remove-top" />
                                            <a className="Course-tags uk-margin-small-right tags-active" href="Course-all-tags.html"> JavaScript </a>
                                            <a className="Course-tags" href="Course-all-tags.html"> Beginner </a>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div>
                                <div className="uk-card-default uk-card-hover  uk-card-small Course-card uk-inline-clip">
                                    <a href="Course-intro-one.html" className="uk-link-reset">
                                        <img src="../../assets/images/courses/course-5.jpg" className="course-img" />
                                        <div className="uk-card-body">
                                            <h4>Build from Scratch</h4>
                                            <p> Lorem ipsum dolor sit amet tempor consectetur adipiscing elit, sed do eiusmod tempor ... </p>
                                            <hr className="uk-margin-remove-top" />
                                            <a className="Course-tags uk-margin-small-right tags-active" href="Course-all-tags.html"> JavaScript </a>
                                            <a className="Course-tags" href="Course-all-tags.html"> Beginner </a>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div>
                                <div className="uk-card-default uk-card-hover  uk-card-small Course-card uk-inline-clip">
                                    <a href="Course-intro-one.html" className="uk-link-reset">
                                        <img src="../../assets/images/courses/course-8.jpg" className="course-img" />
                                        <div className="uk-card-body">
                                            <h4>Beginning Bython</h4>
                                            <p> Lorem ipsum dolor sit amet tempor consectetur adipiscing elit, sed do eiusmod tempor ... </p>
                                            <hr className="uk-margin-remove-top" />
                                            <a className="Course-tags uk-margin-small-right tags-active" href="Course-all-tags.html"> JavaScript </a>
                                            <a className="Course-tags" href="Course-all-tags.html"> Beginner </a>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div>
                                <div className="uk-card-default uk-card-hover  uk-card-small Course-card uk-inline-clip">
                                    <a href="Course-intro-one.html" className="uk-link-reset">
                                        <img src="../../assets/images/courses/course-4.jpg" className="course-img" />
                                        <div className="uk-card-body">
                                            <h4>The Ultimate HTML Developer</h4>
                                            <p> Lorem ipsum dolor sit amet tempor consectetur adipiscing elit, sed do eiusmod tempor ... </p>
                                            <hr className="uk-margin-remove-top" />
                                            <a className="Course-tags uk-margin-small-right tags-active" href="Course-all-tags.html"> JavaScript </a>
                                            <a className="Course-tags" href="Course-all-tags.html"> Beginner </a>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <a href="Browse-courses-single-page.html" className="uk-align-center uk-text-center uk-margin-large uk-h4 uk-link-heading"> Browse more Courses </a>
                    </div>
                </li>
            </ul>

        );
    }

}

export default Home_container; 