import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import Navbar from '../navbar/navbar';
import Header from '../header/header';
import Main_banner from '../main_banner/main_banner';
import Home_container from '../home_container/home_container';
import Footer from '../footer/footer';

class Home extends React.Component {
    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e) {

    }

    render() {

        return (
            <div>
                <Navbar />
                <div id="spinneroverlay">
                    <div className="spinner"></div>
                </div>
                <div className="app">
                    <Header/>
                    <Main_banner/>
                    <Home_container/>
                </div>
            </div>
        );
    }

}

export default Home; 