import {AUTH_TOKEN, USER_NAME, LAST_NAME, FIRST_NAME, USER_TYPE } from '../constants/index';
export const initialData = {
    isLoggedIn: false,
    userDetails: [],
}

export const isLoggedIn = () => {
    if ( localStorage.AUTH_TOKEN ) {
        return {
            token: localStorage.AUTH_TOKEN,
            userName: localStorage.USER_NAME,
            lastName: localStorage.LAST_NAME,
            firstName: localStorage.FIRST_NAME,
            userType: localStorage.USER_TYPE,
            userId: localStorage.USER_ID,
        };
    } else {
        return false;
    }
}

export const logoutUser = () => {
    localStorage.AUTH_TOKEN =  '';
    localStorage.FIRST_NAME =  '';
    localStorage.LAST_NAME = '';
    localStorage.USER_TYPE = '';
    localStorage.USER_NAME = '';
    localStorage.USER_ID = '';
    localStorage.removeItem('IS_FIRST_OPEN')
    // localStorage.removeItem('IS_FIRST_OPEN');
}

export const getUserFromCache = async (context) => {
    
}