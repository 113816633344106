import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";
import detector from "i18next-browser-languagedetector";

import translationEN from './languages/En/translation.json';
import translationAR from './languages/Ar/translation.json';
import translationFR from './languages/Fr/translation.json';
import translationSP from './languages/Sp/translation.json';


// the translations
const resources = {
  en: {
    translation: translationEN
  },
  ar: {
    translation: translationAR
  },
  fr: {
    translation: translationFR
  },
  sp: {
    translation: translationSP
  }
};

i18n
  .use(detector)
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources,
    returnObjects: true,
    fallbackLng: "en", // use en if detected lng is not available
    value: "en",
    // lng: "en",

    keySeparator: false, // we do not use keys in form messages.welcome
    joinArrays:'',

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;





// i18n.use(LanguageDetector).init({
//     // we init with resources
//     resources: {
//         en: {
//             translations: translationEN
//         },
//         ar: {
//             translations: translationAR
//         },

//         fr: {
//             translations: translationFR
//         },

//         sp: {
//             translations: translationSP
//         }
//     },
//     fallbackLng: "en",
//     debug: true,

//     // have a common namespace used around the full app
//     ns: ["translations"],
//     defaultNS: "translations",

//     keySeparator: false, // we use content as keys

//     interpolation: {
//         escapeValue: false, // not needed for react!!
//         formatSeparator: ","
//     },

//     react: {
//         wait: true
//     }
// });

// export default i18n;
