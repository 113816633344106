import React, { Component } from "react";
import { Form, Button } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { withApollo } from 'react-apollo';
import { ADD_SECTION } from '../../../queries/SectionQueries';

class AddQuestion extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            title: '',
            subTitle: '',
            description: '',
            imageUrl:'',
            isMandatory: '',
            sequence:'',
            error: false,
            success: false,
        };
        this._handleSubmit = this._handleSubmit.bind(this);
    }

    _handleSubmit = () => {
        console.log(this.state);
        this.setState({ 
          loading: true,
          success: false,
          error: false,
        });
    }

    _handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value,
        });
    }

  render() {
    return (
      <div className="admin-content-inner">
        <div className="uk-flex-inline uk-flex-middle">
          <i className="fas fa-th-list icon-large uk-margin-right"></i>
          <h4 className="uk-margin-remove"> Add New Section </h4>
        </div>
        <div className="uk-background-default uk-margin-top uk-padding">
          <div className="uk-grid" uk-grid>
            <div className="uk-width-expand@m">
              <form className=" uk-flex-inline uk-flex-middle uk-text-small">
                <p className="uk-margin-remove-bottom uk-margin-right">
                  {" "}
                  You are adding a question under the section  :{" "}
                </p>
              </form>
            </div>
            <div className="uk-width-auto@m uk-text-small">
            { this.state.success ? (
              <p className="uk-margin-remove-bottom uk-margin-right uk-text-success">
                  {" "}
                  Section Added Successfully!  {" "} <button class="uk-button uk-button-success">View Sections</button>
            </p>
            ) : ("")  }
            {
              this.state.error ? (
                <p className="uk-margin-remove-bottom uk-margin-right uk-text-danger">
                  {" "}
                  There was an error adding section!  {" "}
            </p>
              ) : ("")
            }
            
            </div>
          </div>
        </div>
        <div className="uk-margin-medium">
            <Form onSubmit={this._handleSubmit}>
            <div className="uk-grid">
              <div className="uk-width-1@m">
                <div className="uk-card-small uk-card-default">
                  <div className="uk-card-header uk-text-bold">
                    <i className="fas fa-envelope uk-margin-small-right"></i> Question Structure
                  </div>
                  <div className="uk-card-body uk-padding-remove-top">
                    <div className="uk-form-label"> Section Title </div>
                    <input required className="uk-input" type="text" id="title" name="title" value={this.state.title} onChange={this._handleChange} />
                    <div className="uk-form-label"> Subtitle </div>
                    <input className="uk-input" type="text" id="subTitle" name="subTitle" value={this.state.subTitle} onChange={this._handleChange}/>
                    <div className="uk-form-label"> Description </div>
                    <textarea class="uk-textarea" rows="5" placeholder="Section Description" id="description" name="description" value={this.state.description} onChange={this._handleChange}></textarea>
                    <div className="uk-form-label"> Weight (This field is used in ordering the sections ) </div>
                    <input required className="uk-input" type="number" id="sequence" name="sequence" value={this.state.sequence} onChange={this._handleChange}/>
                  </div>
                </div>
              </div>
            </div>
            {/* <input className="uk-button uk-button-success  uk-margin" type="submit" value="Save" loading/> */}
            <Button className="uk-button uk-button-success  uk-margin" type="submit" loading={this.state.loading}>Click Here</Button>
            </Form>
          </div>
      </div>
    );
  }
}

export default withApollo(withRouter(AddQuestion));
