import React, { Component } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';
import Section4 from './Section4';
import Section5 from './Section5';
import Section6 from './Section6';
import SectionHome from './SectionHome';

import NewSection1 from './create/newsection1';
import NewSection2 from './create/newsection2';
import NewSection3 from './create/newsection3';
import NewSection4 from './create/newsection4';
import NewSection5 from './create/newsection5';
import NewSection6 from './create/newsection6';

import EditSection1 from './edit/editsection1';
import EditSection2 from './edit/editsection2';
import EditSection3 from './edit/editsection3';
import EditSection4 from './edit/editsection4';
import EditSection5 from './edit/editsection5';
import EditSection6 from './edit/editsection6';

import ViewSection1 from './view/viewsection1';
import ViewSection2 from './view/viewsection2';
import ViewSection3 from './view/viewsection3';
import ViewSection4 from './view/viewsection4';
import ViewSection5 from './view/viewsection5';
import ViewSection6 from './view/viewsection6';

import Thank from './Thank';
import SectionLoader from './SectionLoader';
import { withRouter } from 'react-router-dom';
import { hist } from './../../../App';

class AssesmentHome extends Component {
  constructor(props) {
    super(props);

  }
  componentDidMount() {
    // console.log(this.props)
  }
  render() {
    const { match } = this.props;
    return (
      <div>
        <Router>

          <Route exact path={`${match.url}`} component={SectionHome} />
          <Route exact path={`${match.url}/section1`} component={Section1} />
          <Route exact path={`${match.url}/new/section1`} component={NewSection1} />
          <Route exact path={`${match.url}/edit/section1`} component={EditSection1} />
          <Route exact path={`${match.url}/view/section1`} component={ViewSection1} />
          <Route path={`${match.url}/section2`} component={Section2} />
          <Route path={`${match.url}/new/section2`} component={NewSection2} />
          <Route path={`${match.url}/edit/section2`} component={EditSection2} />
          <Route path={`${match.url}/view/section2`} component={ViewSection2} />
          <Route path={`${match.url}/section3`} component={Section3} />
          <Route path={`${match.url}/new/section3`} component={NewSection3} />
          <Route path={`${match.url}/edit/section3`} component={EditSection3} />
          <Route path={`${match.url}/view/section3`} component={ViewSection3} />
          <Route path={`${match.url}/section4`} component={Section4} />
          <Route path={`${match.url}/new/section4`} component={NewSection4} />
          <Route path={`${match.url}/edit/section4`} component={EditSection4} />
          <Route path={`${match.url}/view/section4`} component={ViewSection4} />
          <Route path={`${match.url}/section5`} component={Section5} />
          <Route path={`${match.url}/new/section5`} component={NewSection5} />
          <Route path={`${match.url}/edit/section5`} component={EditSection5} />
          <Route path={`${match.url}/view/section5`} component={ViewSection5} />
          <Route path={`${match.url}/section6`} component={Section6} />
          <Route path={`${match.url}/new/section6`} component={NewSection6} />
          <Route path={`${match.url}/edit/section6`} component={EditSection6} />
          <Route path={`${match.url}/view/section6`} component={ViewSection6} />
          <Route path={`${match.url}/thankyou`} component={Thank} />
          <Route path={`${match.url}/loader`} component={SectionLoader} />
        </Router>
      </div>
    );
  }
}


export default withRouter(AssesmentHome);
