import React, { Component } from "react";
import { Form, Dropdown } from "semantic-ui-react";
import userImage from '../../../../assets/images/avatures/avature.jpg';
import UserList from './UserList';
import SingleUser from './SingleUser';
import AddSection from '../AddSection';
import AddQuestion from '../AddQuestion';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { logoutUser } from '../../../../services/CacheOperations';
import SubmissionsHome from './SubmissionsHome';

import ViewSection1 from './submission_view/viewsection1';
import ViewSection2 from './submission_view/viewsection2';
import ViewSection3 from './submission_view/viewsection3';
import ViewSection4 from './submission_view/viewsection4';
import ViewSection5 from './submission_view/viewsection5';
import ViewSection6 from './submission_view/viewsection6';

import ReviewSection1 from './submission_review/reviewsection1';
import ReviewSection2 from './submission_review/reviewsection2';
import ReviewSection3 from './submission_review/reviewsection3';
import ReviewSection4 from './submission_review/reviewsection4';
import ReviewSection5 from './submission_review/reviewsection5';
import ReviewSection6 from './submission_review/reviewsection6';
import { LANGUAGE_OPTIONS } from "../../../../constants/index";
import { withNamespaces } from "react-i18next";

class SubmissionsMain extends Component {
    constructor(props) {
        super(props);
    }

    logout() {
        logoutUser();
        window.location.href = '/';
    }

    changeLanguage = async (e, { value }, data) => {
        localStorage.RAT_LANG = value;
        this.setState(prevState => ({ lang_value: value }));
        this.props.i18n.changeLanguage(value);
        // window.location.reload();
    }

    render() {
        const { match, t, i18n } = this.props;
        return (
            <div className="admin-content">
                <nav className="uk-navbar">
                    <div className="uk-navbar-left">
                        <ul className="uk-navbar-nav">
                            <li className="uk-active">
                                <a href="/" style={{ fontSize: "22px" }}>{t("Dashboard")}</a>
                            </li>
                            <li>
                                <a href="/submissions" style={{ fontSize: "22px" }}> {t("Submissions")}</a>
                            </li>
                        </ul>
                    </div>
                    <div className="uk-navbar-right">
                        {/* <ul className="uk-navbar-nav toolbar-nav">
                            <li>
                                <a className="remove-affter"><Dropdown options={LANGUAGE_OPTIONS} value={this.props.i18n.language === "en-US" ? "en" : this.props.i18n.language} onChange={this.changeLanguage} /></a>
                            </li>
                        </ul> */}
                        <a href="#">
                            <i className="fas fa-user icon-large uk-border-circle" ></i>
                        </a>
                        <div uk-dropdown="pos: top-right ;mode : click ;animation: uk-animation-slide-bottom-small" className="uk-dropdown uk-padding-small angle-top-right uk-dropdown-bottom-right" >
                            <ul className="uk-nav uk-dropdown-nav">
                                <li>
                                    <a onClick={() => this.logout()}> <i className="fas fa-sign-out-alt uk-margin-small-right"></i> {t("Log out")}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
                <div className="admin-content-inner" style={{ paddingTop: 0 }}>
                    <div className="uk-flex-inline uk-flex-middle">
                        <i className="fa fa-paper-plane icon-large uk-margin-right"></i>
                        <h4 className="uk-margin-remove"> {t("Submissions")} </h4>
                    </div>
                    <div className="uk-margin"></div>
                    <Router>
                        <Route exact path={`${match.url}`} component={SubmissionsHome} />

                        <Route exact path={`${match.url}/view/section1`} component={ViewSection1} />
                        <Route path={`${match.url}/view/section2`} component={ViewSection2} />
                        <Route path={`${match.url}/view/section3`} component={ViewSection3} />
                        <Route path={`${match.url}/view/section4`} component={ViewSection4} />
                        <Route path={`${match.url}/view/section5`} component={ViewSection5} />
                        <Route path={`${match.url}/view/section6`} component={ViewSection6} />

                        <Route exact path={`${match.url}/review/section1`} component={ReviewSection1} />
                        <Route path={`${match.url}/review/section2`} component={ReviewSection2} />
                        <Route path={`${match.url}/review/section3`} component={ReviewSection3} />
                        <Route path={`${match.url}/review/section4`} component={ReviewSection4} />
                        <Route path={`${match.url}/review/section5`} component={ReviewSection5} />
                        <Route path={`${match.url}/review/section6`} component={ReviewSection6} />
                    </Router>
                </div>

            </div>
        )
    }
}

export default withRouter(withNamespaces()(SubmissionsMain));
