import React, { Component } from "react";
import SectionLoader from "./SectionLoader";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import {
  Form,
  Button,
  TextArea,
  Input,
  Label,
  Loader,
  Message,
  Icon,
  Popup,
  Grid,
  Header,
  Dropdown,
} from "semantic-ui-react";
import { GET_SECTION_IV } from "../../../queries/SectionQueries";
import {
  GET_SECTION_1,
  SUBMIT_SECTION_1
} from "../../../queries/SubmissionQueries";

import { USER_NAME } from "../../../constants/index";

import { uploadFile, deleteFile } from "../../../firebase/FileServices";
import { Promise } from "q";
import { setFormStatus, setSuccessStatus } from '../../../actions/user-actions';
import { connect } from 'react-redux';

const mapStateToProps = (state, ownProps) => {
  return {
    formErrorStatus: state.custom_reducer.formErrorStatus,
    formSuccessState: state.custom_reducer.formSuccessState,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setFormStatus: (status) => {
      dispatch(setFormStatus(status))
    },
    setSuccessStatus: (status) => {
      dispatch(setSuccessStatus(status))
    }
  }
}
const trainingOptions = [
  {
    key: "Internal",
    text: "Internal",
    value: "Internal"
  },
  {
    key: "External",
    text: "External",
    value: "External"
  },
  {
    key: "Both the above",
    text: "Both the above",
    value: "Both the above"
  }
]

const systemTypeOptions = [
  { key: "Internally developed", value: "Internally developed", text: "Internally developed" },
  { key: "Readymade software​", value: "Readymade software", text: "Readymade software" }
]
class Section5 extends Component {
  constructor(props) {
    super(props);

    this.props.setFormStatus({ status: false, title: '', message: '' });
    this.props.setSuccessStatus({ status: false, title: '', message: '' });

    this.state = {
      loading: true,
      spinner: false,
      saveNContinue: false,
      sectionEmpty: true,
      section: {
        id: "",
        sectionNumber: "",
        title: "",
        subTitle: "",
        description: "",
        sequence: "",
        createdAt: "",
        updatedAt: ""
      },
      question1: {
        showFollowupQuestion: false,
        primaryQuestion:
          "Does your organization own any buildings?",
        primaryAnswer: false,
        secondaryQuestion: "If Yes, list out, including the value.",
        secondaryAnswer: "",
        secondaryJson: {},
        secondaryArray: [{ buildingName: "", value: "" }],
        weightFactor: "0.1",
        uploads: [],
        validated: true
      },
      question2: {
        showFollowupQuestion: false,
        primaryQuestion:
          "Does your organization own any vehicles?",
        primaryAnswer: false,
        secondaryQuestion: "If Yes, list out the types, including the value.",
        secondaryAnswer: "",
        secondaryJson: {},
        secondaryArray: [{ vehicleType: "", value: "" }],
        weightFactor: "0.3",
        uploads: [],
        validated: true
      },
      question3: {
        showFollowupQuestion: false,
        primaryQuestion:
          "Does your organization own any land? ",
        primaryAnswer: false,
        secondaryQuestion: "If Yes, list out, including the value.",
        secondaryAnswer: "",
        secondaryJson: {},
        secondaryArray: [{ landLocation: "", landSize: "", value: "" }],
        weightFactor: "0.1",
        uploads: [],
        validated: true
      },
      question4: {
        showFollowupQuestion: true,
        primaryQuestion: "What other assets, facilities and equipment does your organization own?",
        primaryAnswer: "",
        secondaryQuestion: "",
        q4secondaryAnswer: "",
        uploads: [],
        fileUrl: null,
        fileRef: null,
        tempFile: "",
        weightFactor: "0.0",
        isUploading: false,
        validated: false
      },
      question5: {
        showFollowupQuestion: false,
        primaryQuestion:
          "Can your organization mortgage any of the properties mentioned above to obtain a credit facility?",
        primaryAnswer: false,
        secondaryQuestion:
          "If Yes, which properties can be mortgaged.",
        secondaryAnswer: "",
        weightFactor: "0.3",
        uploads: [],
        validated: true
      },
      question6: {
        showFollowupQuestion: true,
        primaryQuestion:
          "Where does your organization conduct its training activities?",
        primaryAnswer: "",
        secondaryQuestion: "",
        secondaryAnswer: "",
        secondaryJson: {},
        weightFactor: "0.0",
        uploads: [],
        validated: false
      },
      question7: {
        showFollowupQuestion: true,
        primaryQuestion:
          "What data management system(s) does your organization use to collate service statistics?",
        primaryAnswer: "",
        secondaryQuestion: "",
        secondaryAnswer: "",
        weightFactor: "0.0",
        uploads: [],
        validated: false
      },
      question8: {
        showFollowupQuestion: true,
        primaryQuestion:
          "How does your organization use the data collated from the data management system?",
        primaryAnswer: "",
        secondaryQuestion: "",
        secondaryAnswer: "",
        weightFactor: "0.0",
        uploads: [],
        validated: false
      },
      question9: {
        showFollowupQuestion: true,
        primaryQuestion:
          "What enterprise resource planning (ERP) systems does your organization use for Finance, HR, other supporting functions, etc.?",
        primaryAnswer: "",
        secondaryQuestion: "",
        secondaryAnswer: "",
        primaryArray: [{ systemOrSoftwareName: "", department: "" }],
        primaryJson: {},
        weightFactor: "0.0",
        uploads: [],
        validated: false
      }
    };

    this._getSection3();
    this._getSection3Submissions();

    this._question1Onchange = this._question1Onchange.bind(this);
    this._question2Onchange = this._question2Onchange.bind(this);
    this._question3Onchange = this._question3Onchange.bind(this);

  }
  componentDidMount() {
    window.scrollTo(0, 0)
    setTimeout(() => {
      this.setState({
        loading: false
      });
    }, 2000);
  }
  _goBack = () => {
    this.props.history.goBack();
  };

  _next = () => {
    this.setState({
      saveNContinue: true
    });
    this._submitSection(true);
  };

  _nextWithoutUpdate = () => {
    this.props.history.push(`/assessment/section6`);
  };

  _updateSection = () => {
    this.setState({
      spinner: true
    });
    this._submitSection(false);
  };

  _submitSection = (flag) => {

    this.props.setFormStatus({ status: false, title: '', message: '' });
    this.props.setSuccessStatus({ status: false, title: '', message: '' });

    // this.setState({
    //   spinner: false,
    //   saveNContinue: false,
    // });
    // console.log(this.state.question1);
    // console.log(this.state.question2);
    // console.log(this.state.question3);
    // console.log(this.state.question4);
    // console.log(this.state.question5);
    // console.log(this.state.question6);
    // console.log(this.state.question7);
    // console.log(this.state.question8);
    // console.log(this.state.question9);

    const submission = {
      question1: this.state.question1,
      question2: this.state.question2,
      question3: this.state.question3,
      question4: this.state.question4,
      question5: this.state.question5,
      question6: this.state.question6,
      question7: this.state.question7,
      question8: this.state.question8,
      question9: this.state.question9
    };
    this._saveSection(submission, flag);
  };

  _saveSection = (submission, flag) => {
    // console.log(submission);
    this.props.client.mutate({
      mutation: SUBMIT_SECTION_1,
      variables: {
        submission: submission,
        sectionId: "ck1gmw55c1ko10b88d4v0x3yi"
      }
    }).then(result => {
      this._getSection3Submissions();
      this.setState({
        spinner: false,
        saveNContinue: false,
      });
      if (flag) {
        this.props.history.push(`/assessment/section6`);
      }

      this.props.setSuccessStatus({
        status: true,
        title: "",
        message: "Saved successfully!"
      });

    }).catch(error => {
      this.setState({
        spinner: false,
        saveNContinue: false,
      });

      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message:
          "There was an error while trying to update data"

      });
    });
  };

  _getSection3 = () => {
    this.props.client
      .query({
        query: GET_SECTION_IV
      })
      .then(result => {
        this.setState({
          section: { ...result.data.getSection1 }
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  _getSection3Submissions = () => {
    this.props.client
      .query({
        query: GET_SECTION_1,
        variables: { sectionId: "ck1gmw55c1ko10b88d4v0x3yi" },
        fetchPolicy: 'network-only'
      })
      .then(result => {
        console.log(result);
        if (result.data.getSectionSubmission === null || result.data.getSectionSubmission.length === 0) {
          console.log('there is nothing here');

        } else {
          // Apply values 
          console.log(result.data.getSectionSubmission.submission.question1)
          this.setState({
            question1: result.data.getSectionSubmission.submission.question1
              ? result.data.getSectionSubmission.submission.question1
              : this.state.question1,
            question2: result.data.getSectionSubmission.submission.question2
              ? result.data.getSectionSubmission.submission.question2
              : this.state.question2,
            question3: result.data.getSectionSubmission.submission.question3
              ? result.data.getSectionSubmission.submission.question3
              : this.state.question3,
            question4: result.data.getSectionSubmission.submission.question4
              ? result.data.getSectionSubmission.submission.question4
              : this.state.question4,
            question5: result.data.getSectionSubmission.submission.question5
              ? result.data.getSectionSubmission.submission.question5
              : this.state.question5,
            question6: result.data.getSectionSubmission.submission.question6
              ? result.data.getSectionSubmission.submission.question6
              : this.state.question6,
            question7: result.data.getSectionSubmission.submission.question7
              ? result.data.getSectionSubmission.submission.question7
              : this.state.question7,
            question8: result.data.getSectionSubmission.submission.question8
              ? result.data.getSectionSubmission.submission.question8
              : this.state.question8,
            question9: result.data.getSectionSubmission.submission.question9
              ? result.data.getSectionSubmission.submission.question9
              : this.state.question9,
            sectionEmpty: false
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  // Question1 Stuff
  _question1Followup = state => {
    let fields = this.state.question1.secondaryArray;
    const toArray = fields.reduce((acc, obj) => [...acc, Object.values(obj).map(y => y.trim())], []);
    const singleArr = [].concat(...toArray);
    const emptyStrings = singleArr.filter(str => str.trim().length <= 0);

    state
      ? this.setState({
        question1: {
          ...this.state.question1,
          showFollowupQuestion: true,
          primaryAnswer: true,
          validated: emptyStrings.length === 0 ? true : false
        }
      })
      : this.setState({
        question1: {
          ...this.state.question1,
          showFollowupQuestion: false,
          primaryAnswer: false,
          validated: true
        }
      });
  };

  _question1Onchange = event => {
    this.setState({
      question1: {
        ...this.state.question1,
        secondaryJson: {
          ...this.state.question1.secondaryJson,
          [event.target.id]: event.target.value
        }
      }
    });
  };

  // Question 2 stuff
  _question2Followup = state => {
    let fields = this.state.question2.secondaryArray;
    const toArray = fields.reduce((acc, obj) => [...acc, Object.values(obj).map(y => y.trim())], []);
    const singleArr = [].concat(...toArray);
    const emptyStrings = singleArr.filter(str => str.trim().length <= 0);
    state
      ? this.setState({
        question2: {
          ...this.state.question2,
          showFollowupQuestion: true,
          primaryAnswer: true,
          validated: emptyStrings.length === 0 ? true : false
        }
      })
      : this.setState({
        question2: {
          ...this.state.question2,
          showFollowupQuestion: false,
          primaryAnswer: false,
          validated: true
        }
      });
  };

  _question2Onchange = event => {
    this.setState({
      question2: {
        ...this.state.question2,
        secondaryJson: {
          ...this.state.question2.secondaryJson,
          [event.target.id]: event.target.value
        }
      }
    });
  };

  // Question 3 stuff
  _question3Followup = state => {
    let fields = this.state.question3.secondaryArray;
    const toArray = fields.reduce((acc, obj) => [...acc, Object.values(obj).map(y => y.trim())], []);
    const singleArr = [].concat(...toArray);
    const emptyStrings = singleArr.filter(str => str.trim().length <= 0);
    state
      ? this.setState({
        question3: {
          ...this.state.question3,
          showFollowupQuestion: true,
          primaryAnswer: true,
          validated: emptyStrings.length === 0 ? true : false
        }
      })
      : this.setState({
        question3: {
          ...this.state.question3,
          showFollowupQuestion: false,
          primaryAnswer: false,
          validated: true
        }
      });
  };

  _question3Onchange = event => {
    this.setState({
      question3: {
        ...this.state.question3,
        secondaryJson: {
          ...this.state.question3.secondaryJson,
          [event.target.id]: event.target.value
        }
      }
    });
  };

  //Question 4 stuff
  _question4Followup = state => {
    state
      ? this.setState({
        question4: {
          ...this.state.question4,
          showFollowupQuestion: true,
          primaryAnswer: true
        }
      })
      : this.setState({
        question4: {
          ...this.state.question4,
          showFollowupQuestion: false,
          primaryAnswer: false
        }
      });
  };

  _question4Onchange = event => {
    this.setState({
      question4: {
        ...this.state.question4,
        [event.target.id]: event.target.value,
        validated: event.target.value.trim() === "" ? false : true
      }
    });
  };

  // Question 5 stuff
  _question5Followup = state => {
    state
      ? this.setState({
        question5: {
          ...this.state.question5,
          showFollowupQuestion: true,
          primaryAnswer: true,
          validated: this.state.question5.secondaryAnswer.trim() === "" ? false : true
        }
      })
      : this.setState({
        question5: {
          ...this.state.question5,
          showFollowupQuestion: false,
          primaryAnswer: false,
          validated: true
        }
      });
  };

  _question5Onchange = event => {
    this.setState({
      question5: {
        ...this.state.question5,
        [event.target.id]: event.target.value,
        validated: event.target.value.trim() === "" ? false : true
      }
    });
  };

  _question6Onchange = event => {
    this.setState({
      question6: {
        ...this.state.question6,
        [event.target.id]: event.target.value
      }
    });
  };

  //Question 7 Stuff
  _question7Onchange = event => {
    this.setState({
      question7: {
        ...this.state.question7,
        [event.target.id]: event.target.value,
        validated: event.target.value.trim() === "" ? false : true
      }
    });
  };

  // Question 8 stuff
  _question8Onchange = event => {
    this.setState({
      question8: {
        ...this.state.question8,
        [event.target.id]: event.target.value,
        validated: event.target.value.trim() === "" ? false : true
      }
    });
  };

  // Question 9 stuff
  _question9Followup = state => {
    state
      ? this.setState({
        question9: {
          ...this.state.question9,
          showFollowupQuestion: false,
          primaryAnswer: true
        }
      })
      : this.setState({
        question9: {
          ...this.state.question9,
          showFollowupQuestion: false,
          primaryAnswer: false
        }
      });
  };

  // _question9Onchange = event => {
  //   this.setState({
  //     question9: {
  //       ...this.state.question9,
  //       [event.target.id]: event.target.value
  //     }
  //   });
  // };

  _question9Onchange = event => {
    this.setState({
      question9: {
        ...this.state.question9,
        primaryJson: {
          ...this.state.question9.primaryJson,
          [event.target.id]: event.target.value
        }
      }
    });
  };

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

  _handleMultiChange31(i, e) {
    if (e.target.value < 0) {
      e.preventDefault();
    } else {
      const { name, value } = e.target;
      let fields = this.state.question1.secondaryArray;
      fields[i][name] = value;

      const toArray = fields.reduce((acc, obj) => [...acc, Object.values(obj).map(y => y.trim())], []);
      const singleArr = [].concat(...toArray);
      const emptyStrings = singleArr.filter(str => str.trim().length <= 0);

      this.setState({
        question1: {
          ...this.state.question1,
          validated: emptyStrings.length === 0 ? true : false
        }
      });

      this.setState({ fields });
    }

  }

  _handleMultiChange32(i, e) {
    if (e.target.value < 0) {
      e.preventDefault();
    } else {
      const { name, value } = e.target;
      let fields = this.state.question2.secondaryArray;
      fields[i][name] = value;

      const toArray = fields.reduce((acc, obj) => [...acc, Object.values(obj).map(y => y.trim())], []);
      const singleArr = [].concat(...toArray);
      const emptyStrings = singleArr.filter(str => str.trim().length <= 0);

      this.setState({
        question2: {
          ...this.state.question2,
          validated: emptyStrings.length === 0 ? true : false
        }
      });
      this.setState({ fields });
    }
  }

  _handleMultiChange33(i, e) {
    if (e.target.value < 0) {
      e.preventDefault();
    } else {
      const { name, value } = e.target;
      let fields = this.state.question3.secondaryArray;
      fields[i][name] = value;

      const toArray = fields.reduce((acc, obj) => [...acc, Object.values(obj).map(y => y.trim())], []);
      const singleArr = [].concat(...toArray);
      const emptyStrings = singleArr.filter(str => str.trim().length <= 0);

      this.setState({
        question3: {
          ...this.state.question3,
          validated: emptyStrings.length === 0 ? true : false
        }
      });
      this.setState({ fields });
    }
  }

  _handleMultiChange39(i, e) {
    const { name, value } = e.target;
    let fields = this.state.question9.primaryArray;
    fields[i][name] = value;
    const toArray = fields.reduce((acc, obj) => [...acc, Object.values(obj).map(y => y.trim())], []);
    const singleArr = [].concat(...toArray);
    const emptyStrings = singleArr.filter(str => str.trim().length <= 0);

    this.setState({
      question9: {
        ...this.state.question9,
        validated: emptyStrings.length === 0 ? true : false
      }
    });
    this.setState({ fields });
  }

  _addMoreClick31() {
    let fields = this.state.question1.secondaryArray;
    fields.push({ buildingName: "", value: "" });
    this.setState({
      question1: {
        ...this.state.question1,
        validated: false
      }
    });
    this.setState(fields)
  }

  _addMoreClick32() {
    let fields = this.state.question2.secondaryArray;
    fields.push({ vehicleType: "", value: "" });
    this.setState({
      question2: {
        ...this.state.question2,
        validated: false
      }
    });
    this.setState(fields)
  }

  _addMoreClick33() {
    let fields = this.state.question3.secondaryArray;
    fields.push({ landLocation: "", landSize: "", value: "" });
    this.setState({
      question3: {
        ...this.state.question3,
        validated: false
      }
    });
    this.setState(fields)
  }

  _addMoreClick39() {
    let fields = this.state.question9.primaryArray;
    fields.push({ systemOrSoftwareName: "", department: "" });
    this.setState({
      question9: {
        ...this.state.question9,
        validated: false
      }
    });
    this.setState(fields)
  }

  _remoevMoreClick31(i) {
    let secondaryArray = this.state.question1.secondaryArray;
    secondaryArray.splice(i, 1);

    const toArray = secondaryArray.reduce((acc, obj) => [...acc, Object.values(obj).map(y => y.trim())], []);
    const singleArr = [].concat(...toArray);
    const emptyStrings = singleArr.filter(str => str.trim().length <= 0);

    this.setState({
      question1: {
        ...this.state.question1,
        validated: emptyStrings.length === 0 ? true : false
      }
    });

    this.setState({ secondaryArray });
  }

  _remoevMoreClick32(i) {
    let secondaryArray = this.state.question2.secondaryArray;
    secondaryArray.splice(i, 1);

    const toArray = secondaryArray.reduce((acc, obj) => [...acc, Object.values(obj).map(y => y.trim())], []);
    const singleArr = [].concat(...toArray);
    const emptyStrings = singleArr.filter(str => str.trim().length <= 0);

    this.setState({
      question2: {
        ...this.state.question2,
        validated: emptyStrings.length === 0 ? true : false
      }
    });

    this.setState({ secondaryArray });
  }

  _remoevMoreClick33(i) {
    let secondaryArray = this.state.question3.secondaryArray;
    secondaryArray.splice(i, 1);

    const toArray = secondaryArray.reduce((acc, obj) => [...acc, Object.values(obj).map(y => y.trim())], []);
    const singleArr = [].concat(...toArray);
    const emptyStrings = singleArr.filter(str => str.trim().length <= 0);

    this.setState({
      question3: {
        ...this.state.question3,
        validated: emptyStrings.length === 0 ? true : false
      }
    });

    this.setState({ secondaryArray });
  }

  _remoevMoreClick39(i) {
    let primaryArray = this.state.question9.primaryArray;
    primaryArray.splice(i, 1);

    const toArray = primaryArray.reduce((acc, obj) => [...acc, Object.values(obj).map(y => y.trim())], []);
    const singleArr = [].concat(...toArray);
    const emptyStrings = singleArr.filter(str => str.trim().length <= 0);

    this.setState({
      question9: {
        ...this.state.question9,
        validated: emptyStrings.length === 0 ? true : false
      }
    });

    this.setState({ primaryArray });
  }

  _createMulipleQ31() {

    if (this.state.question1.secondaryArray != undefined) {
      return this.state.question1.secondaryArray.map((el, i) => (
        <div key={i}>
          <br />

          <div className="uk-grid">
            <div className="uk-width-1-2">
              <label>Building Name</label> <br />
              <input
                id="buildingName"
                type="text"
                name="buildingName"
                placeholder="Building Name"
                value={el.buildingName || ''}
                onChange={this._handleMultiChange31.bind(this, i)}
              />

            </div>

            <div className="uk-width-1-2">
              <label>Value</label> <br />
              <Input
                labelPosition="right"
                type="number"
                placeholder="Value"
                id="value"
                name="value"
                style={{ width: "60%" }}
                value={el.value || ''}
                onChange={this._handleMultiChange31.bind(this, i)}
                onKeyDown={e => {
                  if (/[\+\-\.\,]/.test(e.key) || e.key.toUpperCase() === "E") {
                    e.preventDefault();
                  }
                }}
              >
                <Label basic>$</Label>
                <input />
                <Label>.00</Label>
              </Input>
            </div>

          </div>
          <br />

          {this.state.question1.secondaryArray.length <= 1 ? (
            null
          ) : (
              <div className="ms-Grid-col ms-sm6 ms-lg2 uk-text-right">
                <i class="fas fa-trash-alt multiple_Field_Remove" aria-hidden="true" onClick={this._remoevMoreClick31.bind(this, i)}></i>
                {/* <i className="big minus circle icon multiple_Field_Remove" onClick={this._remoevMoreClick31.bind(this, i)}></i> */}
              </div>
            )}

          < hr className="multiple_Field_Seperator" />

        </div >

      ))
    } else {
      let fields = this.state.question1;
      fields["secondaryArray"] = [{ buildingName: "", value: "" }];
      this.setState(fields)
    }
  }

  _createMulipleQ32() {
    if (this.state.question2.secondaryArray != undefined) {
      return this.state.question2.secondaryArray.map((el, i) => (
        <div key={i}>
          <br />

          <div className="uk-grid">
            <div className="uk-width-1-2">
              <label>Vehicle Type</label>
              <input
                value={el.vehicleType || ''}
                onChange={this._handleMultiChange32.bind(this, i)}
                id="vehicleType"
                name="vehicleType"
                placeholder="Vehicle Type"
              />
            </div>

            <div className="uk-width-1-2">
              <label>Value</label> <br />
              <Input
                labelPosition="right"
                type="number"
                placeholder="Value"
                id="value"
                name="value"
                style={{ width: "60%" }}
                value={el.value || ''}
                onChange={this._handleMultiChange32.bind(this, i)}
                onKeyDown={e => {
                  if (/[\+\-\.\,]/.test(e.key) || e.key.toUpperCase() === "E") {
                    e.preventDefault();
                  }
                }}
              >
                <Label basic>$</Label>
                <input />
                <Label>.00</Label>
              </Input>
            </div>

          </div>
          <br />

          {this.state.question2.secondaryArray.length <= 1 ? (
            null
          ) : (
              <div className="ms-Grid-col ms-sm6 ms-lg2 uk-text-right">
                <i class="fas fa-trash-alt multiple_Field_Remove" aria-hidden="true" onClick={this._remoevMoreClick32.bind(this, i)}></i>
                {/* <i className="big minus circle icon multiple_Field_Remove" onClick={this._remoevMoreClick32.bind(this, i)}></i> */}
              </div>
            )}
          < hr className="multiple_Field_Seperator" />
        </div >

      ))
    } else {
      let fields = this.state.question2;
      fields["secondaryArray"] = [{ vehicleType: "", value: "" }];
      this.setState(fields)
    }
  }

  _createMulipleQ33() {
    if (this.state.question3.secondaryArray != undefined) {
      return this.state.question3.secondaryArray.map((el, i) => (
        <div key={i}>
          <br />

          <div className="uk-grid">
            <div className="uk-width-1-3">
              <label>Land location (city/town)</label>
              <input
                value={el.landLocation || ''}
                onChange={this._handleMultiChange33.bind(this, i)}
                id="landLocation"
                // style={{marginTop: "21px"}}
                className={localStorage.RAT_LANG=='en'? "land_loca_other":  localStorage.RAT_LANG=='sp'? "land_loca_other": localStorage.RAT_LANG=='ar'? "land_loca_other": "" }
                name="landLocation"
                placeholder="Land Location"
              />
            </div>

            <div className="uk-width-1-3">
              <label>Land size (in Square meters)</label>
              <input
                value={el.landSize || ''}
                onChange={this._handleMultiChange33.bind(this, i)}
                // style={{marginTop: "21px"}}
                id="landSize"
                name="landSize"
                placeholder="1 sq meter = 0.0001 hectare"
                type="number"
                onKeyDown={e => {
                  if (/[\+\-\.\,]/.test(e.key) || e.key.toUpperCase() === "E") {
                    e.preventDefault();
                  }
                }}
              />
            </div>

            <div className="uk-width-1-3">
              <label>Value</label> <br />
              <Input
                size='mini'
                labelPosition="right"
                type="number"
                placeholder="Value"
                // style={{marginTop: "25px"}}
                id="value"
                name="value"
                style={{ width: "50%" }}
                value={el.value || ''}
                onChange={this._handleMultiChange33.bind(this, i)}
                onKeyDown={e => {
                  if (/[\+\-\.\,]/.test(e.key) || e.key.toUpperCase() === "E") {
                    e.preventDefault();
                  }
                }}
              >
                <Label basic>$</Label>
                <input />
                <Label>.00</Label>
              </Input>
            </div>

          </div>
          <br />

          {this.state.question3.secondaryArray.length <= 1 ? (
            null
          ) : (
              <div className="ms-Grid-col ms-sm6 ms-lg2 uk-text-right">
                <i class="fas fa-trash-alt multiple_Field_Remove" aria-hidden="true" onClick={this._remoevMoreClick33.bind(this, i)}></i>
                {/* <i className="big minus circle icon multiple_Field_Remove" onClick={this._remoevMoreClick33.bind(this, i)}></i> */}
              </div>
            )}
          < hr className="multiple_Field_Seperator" />
        </div >

      ))
    } else {
      let fields = this.state.question3;
      fields["secondaryArray"] = [{ landLocation: "", landSize: "", value: "" }];
      this.setState(fields)
    }
  }

  _createMulipleQ39() {
    if (this.state.question9.primaryArray != undefined) {
      return this.state.question9.primaryArray.map((el, i) => (
        <div key={i}>
          <br />

          <div className="uk-grid">
            <div className="uk-width-1-2">
              <label>System/Software name (SAGE, SAP HR, etc.)</label>
              <input
                value={el.systemOrSoftwareName || ''}
                onChange={this._handleMultiChange39.bind(this, i)}
                id="systemOrSoftwareName"
                name="systemOrSoftwareName"
                placeholder="System/Software name (SAGE, SAP HR, etc.)"
              />

              {/* <div>
              <Dropdown
                onChange={e =>this._handleMultiChange39(i,e.target.value, e.target.name)}
                options={systemTypeOptions}
                placeholder="Choose an option"
                selection
                value={el.systemType || ''}
              />
              </div> */}

            </div>

            <div className="uk-width-1-2">
              <label>Department (Finance, HR, etc.)</label>
              <input
                value={el.department || ''}
                onChange={this._handleMultiChange39.bind(this, i)}
                id="department"
                name="department"
                placeholder="Department (Finance, HR, etc.)"
              />
            </div>

          </div>
          <br />

          {this.state.question9.primaryArray.length <= 1 ? (
            null
          ) : (
              <div className="ms-Grid-col ms-sm6 ms-lg2 uk-text-right">
                <i class="fas fa-trash-alt multiple_Field_Remove" aria-hidden="true" onClick={this._remoevMoreClick39.bind(this, i)}></i>
                {/* <i className="big minus circle icon multiple_Field_Remove" onClick={this._remoevMoreClick39.bind(this, i)}></i> */}
              </div>
            )}
          < hr className="multiple_Field_Seperator" />
        </div >

      ))
    } else {
      let fields = this.state.question9;
      fields["primaryArray"] = [{ systemOrSoftwareName: "", department: "" }];
      this.setState(fields)

    }
  }
  _question1Onchange = event => {
    this.setState({
      question1: {
        ...this.state.question1,
        [event.target.id]: event.target.value
      }
    });
  };

  _question1jsonOnchange = (event, data) => {
    this.setState({
      question6: {
        ...this.state.question6,
        [data.id]: data.value,
        validated: data.value === "" ? false : true
      }
    });

    // this.setState({
    //   question6: {
    //     ...this.state.question6,
    //     secondaryJson: {
    //       ...this.state.question6.secondaryJson,
    //       [data.id]: data.value
    //     }
    //   }
    // });
  };


  render() {
    const { formErrorStatus, formSuccessState } = this.props;

    return (
      <div>
        {this.state.loading ? (
          <SectionLoader />
        ) : (
            <div>
              {/* Begin Dark Header */}
              <div className="uk-section-default uk-section">
                <div className="uk-container-small uk-margin-auto uk-margin-medium-top">
                  <h3 className=" uk-text-light">
                    {" "}
                    Section {this.state.section.sectionNumber}{" "}
                    {/* {this.state.section.title}{" "} */}
                  </h3>
                </div>
                <hr />

                <div
                  className="uk-container-small uk-margin-auto uk-margin-small-top uk-padding-small paper-read"
                  uk-scrollspy="target: > .paper; cls:uk-animation-slide-bottom-small; delay: 200"
                >
                  <div className="paper uk-link-reset " style={{ backgroundColor: "#151F6D" }}>
                    <div className="uk-grid uk-flex-middle">
                      <div className="uk-width-1-6 uk-flex-first uk-text-center">
                        <i className="far fa-comment-alt icon-xxlarge"></i>
                      </div>
                      <div className="uk-width-5-6">
                        <h4 className="uk-margin-remove" style={{ color: "#ffffff" }}>
                          {" "}
                          {this.state.section.title}{" "}
                        </h4>
                        {/* <p className="uk-margin-remove">
                          {" "}
                          {this.state.section.description}{" "}
                        </p> */}
                      </div>
                    </div>
                  </div>
                  <Form success error className="uk-margin">
                    {/* Begin Question 1 */}
                    <div className="uk-link-reset paper uk-margin s1q1">
                      <h4>
                        <b>27)</b>{" "}
                        Does your organization own any buildings?{" "}
                      </h4>

                      <Button.Group size="large" className={localStorage.RAT_LANG=='ar'? "buttons_arabic_style": "" } >
                        <Button positive={this.state.question1.showFollowupQuestion ? true : false} onClick={() => this._question1Followup(true)}>
                          YES
                      </Button>
                        <Button.Or />
                        <Button positive={this.state.question1.showFollowupQuestion ? false : true} onClick={() => this._question1Followup(false)}>
                          NO
                      </Button>
                      </Button.Group>
                      {this.state.question1.showFollowupQuestion ? (
                        <div>
                          <div className="uk-form-label">
                            If Yes, list out, including the value.
                        </div>
                          <div >

                            {this._createMulipleQ31()}
                            <div className="uk-text-right"><button onClick={this._addMoreClick31.bind(this)} className="uk-button uk-button-big uk-button-primary multiple_Field_Plus" type="button">Add One More</button></div>

                          </div>
                        </div>
                      ) : (
                          ""
                        )}
                    </div>
                    {/* End Question 1 */}


                    {/* Begin Question2 */}
                    <div className="uk-link-reset paper uk-margin s1q1">
                      <h4>
                        <b>28)</b>{" "}
                        Does your organization own any vehicles?{" "}
                      </h4>

                      <Button.Group size="large" className={localStorage.RAT_LANG=='ar'? "buttons_arabic_style": "" } >
                        <Button positive={this.state.question2.showFollowupQuestion ? true : false} onClick={() => this._question2Followup(true)}>
                          YES
                      </Button>
                        <Button.Or />
                        <Button positive={this.state.question2.showFollowupQuestion ? false : true} onClick={() => this._question2Followup(false)}>
                          NO
                      </Button>
                      </Button.Group>
                      {this.state.question2.showFollowupQuestion ? (
                        <div>
                          <div className="uk-form-label">
                            If Yes, list out the types, including the value.
                        </div>
                          <div>
                            {this._createMulipleQ32()}
                            <div className="uk-text-right"><button onClick={this._addMoreClick32.bind(this)} className="uk-button uk-button-big uk-button-primary multiple_Field_Plus" type="button">Add One More</button></div>
                          </div>

                        </div>
                      ) : (
                          ""
                        )}
                    </div>
                    {/* End Question 2 */}

                    {/* Begin Question 3 */}

                    <div className="uk-link-reset paper uk-margin s1q1">
                      <h4>
                        <b>29)</b>{" "}
                        Does your organization own any land?{" "}
                      </h4>

                      <Button.Group size="large" className={localStorage.RAT_LANG=='ar'? "buttons_arabic_style": "" } >
                        <Button positive={this.state.question3.showFollowupQuestion ? true : false} onClick={() => this._question3Followup(true)}>
                          YES
                      </Button>
                        <Button.Or />
                        <Button positive={this.state.question3.showFollowupQuestion ? false : true} onClick={() => this._question3Followup(false)}>
                          NO
                      </Button>
                      </Button.Group>
                      {this.state.question3.showFollowupQuestion ? (
                        <div>
                          <div className="uk-form-label">
                            If Yes, list out, including the value.
                        </div>
                          <div>
                            {this._createMulipleQ33()}
                            <div className="uk-text-right"><button onClick={this._addMoreClick33.bind(this)} className="uk-button uk-button-big uk-button-primary multiple_Field_Plus" type="button">Add One More</button></div>
                          </div>
                        </div>
                      ) : (
                          ""
                        )}
                    </div>

                    {/* End Question 3 */}

                    {/* Begin Question 4 */}
                    <div className="uk-link-reset paper uk-margin s1q1">
                      <h4 style={{fontSize:"1.20rem"}}><b>30)</b> What other assets, facilities and equipment does your organization own? </h4>
                      <p class="uk-text-muted uk-margin-remove-top uk-margin-small-bottom">Please answer and provide details.</p>

                      <TextArea
                        onChange={this._question4Onchange}
                        name="primaryAnswer"
                        id="primaryAnswer"
                        value={this.state.question4.primaryAnswer}
                        className="text-area-height uk-input uk-form-width-large"
                        placeholder="Describe here"
                        type="text"
                      />

                    </div>
                    {/* End Question 4 */}

                    {/* Begin Question 5 */}
                    <div className="uk-link-reset paper uk-margin s1q1">
                      <h4>
                        <b>31)</b>{" "}
                        Can your organization mortgage any of the properties mentioned above to obtain a credit facility?{" "}
                      </h4>

                      <Button.Group size="large" className={localStorage.RAT_LANG=='ar'? "buttons_arabic_style": "" } >
                        <Button positive={this.state.question5.showFollowupQuestion ? true : false} onClick={() => this._question5Followup(true)}>
                          YES
                        </Button>
                        <Button.Or />
                        <Button positive={this.state.question5.showFollowupQuestion ? false : true} onClick={() => this._question5Followup(false)}>
                          NO
                      </Button>
                      </Button.Group>
                      {this.state.question5.showFollowupQuestion ? (
                        <div>
                          <div className="uk-form-label">
                            If Yes, which properties can be mortgaged.
                        </div>
                          <div className="uk-margin">
                            <span className="uk-form-icon">
                              <i className="far fa-User icon-medium"></i>
                            </span>

                            <TextArea
                              onChange={this._question5Onchange}
                              name="secondaryAnswer"
                              id="secondaryAnswer"
                              value={this.state.question5.secondaryAnswer}
                              className="text-area-height uk-input uk-form-width-large"
                              placeholder="Describe here"
                              type="text"
                            />

                          </div>
                        </div>
                      ) : (
                          null
                        )}
                    </div>
                    {/* End Question 5 */}

                    {/* Begin Question 6 */}
                    <div className="uk-link-reset paper uk-margin s1q1">
                      <h4>
                        <b>32)</b>{" "}
                        Where does your organization conduct its training activities?{" "}
                      </h4>
                      {/* <p class="uk-text-muted uk-margin-remove-top uk-margin-small-bottom">Please answer and support the response with additional details.</p> */}

                      <div className="uk-form-label">
                        Training Activities{"  :  "}
                        {" "}
                        <Dropdown
                          style={{ marginLeft: "10px" }}
                          onChange={this._question1jsonOnchange}
                          name="primaryAnswer"
                          id="primaryAnswer"
                          selection
                          value={this.isEmpty(this.state.question6.primaryAnswer) ? (
                            null
                          ) : (
                              this.state.question6.primaryAnswer
                            )}
                          placeholder="Select location"
                          options={trainingOptions}
                        />
                      </div>

                      {/* <TextArea
                        onChange={this._question6Onchange}
                        name="secondaryAnswer"
                        id="secondaryAnswer"
                        value={this.state.question6.secondaryAnswer}
                        className="text-area-height uk-input uk-form-width-large"
                        placeholder="Describe here"
                        type="text"
                      /> */}

                    </div>


                    {/* End Question 6 */}

                    {/* Begin Question 7 */}
                    <div className="uk-link-reset paper uk-margin s1q1">
                      <h4>
                        <b>33)</b>{" "}
                        What data management system(s) does your organization use to collate service statistics?{" "}
                      </h4>
                      <p class="uk-text-muted uk-margin-remove-top uk-margin-small-bottom">Please answer and support the response with additional details.</p>
                      <div>
                        <div className="uk-form-label"></div>
                        <div className="uk-margin">
                          <span className="uk-form-icon">
                            <i className="far fa-User icon-medium"></i>
                          </span>

                          <TextArea
                            onChange={this._question7Onchange}
                            name="primaryAnswer"
                            id="primaryAnswer"
                            value={this.state.question7.primaryAnswer}
                            className="text-area-height uk-input uk-form-width-large"
                            placeholder="Describe here"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                    {/* End Question 7 */}

                    {/* Begin Question 8 */}
                    <div className="uk-link-reset paper uk-margin s1q1">
                      <h4>
                        <b>34)</b>{" "}
                        How does your organization use the data collated from the data management system?{" "}
                      </h4>
                      <p class="uk-text-muted uk-margin-remove-top uk-margin-small-bottom">Please answer and support the response with additional details.</p>
                      <div>
                        <div className="uk-form-label"></div>
                        <div className="uk-margin">
                          <span className="uk-form-icon">
                            <i className="far fa-User icon-medium"></i>
                          </span>

                          <TextArea
                            onChange={this._question8Onchange}
                            name="primaryAnswer"
                            id="primaryAnswer"
                            value={this.state.question8.primaryAnswer}
                            className="text-area-height uk-input uk-form-width-large"
                            placeholder="Describe here"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                    {/* End Question 8 */}

                    {/* Begin Question 9 */}
                    <div className="uk-link-reset paper uk-margin s1q1">
                      <h4>
                        <b>35)</b>{" "}
                        What enterprise resource planning (ERP) systems does your organization use for Finance, HR, other supporting functions, etc.?{" "}
                      </h4>
                      <div>
                        <div>
                          {this._createMulipleQ39()}
                          <div className="uk-text-right"><button onClick={this._addMoreClick39.bind(this)} className="uk-button uk-button-big uk-button-primary multiple_Field_Plus" type="button">Add One More</button></div>
                        </div>
                      </div>
                    </div>
                    {/* End Question 9 */}


                    <div className="uk-margin">
                      {(formErrorStatus.status) ? (
                         <div className="danger_alert">{formErrorStatus.message}</div>
                        // <Message
                        //   error
                        //   content={formErrorStatus.message}
                        // />
                      ) : ((formSuccessState.status) ? (
                        <div className="success_alert">{formSuccessState.message}</div>
                        // <Message
                        //   success
                        //   content={formSuccessState.message}
                        // />
                      ) : (''))}
                    </div>

                    <hr />
                    {/* Begin Footer */}
                    <div className="uk-grid uk-text-center button_div">
                      <div className="uk-width-1-3@m">
                        <Button
                          basic
                          className="outline-button blueButton"
                          onClick={this._goBack}
                        >
                          Go Back
                      </Button>
                      </div>

                      <div className="uk-width-expand@m">
                        {!this.state.sectionEmpty ? (
                          <Button
                            basic
                            onClick={this._updateSection}
                            className="outline-button yellowButton"
                            loading={this.state.spinner}
                          >
                            Save
                        </Button>
                        ) : (
                            ""
                          )}
                      </div>

                      <div className="uk-width-1-3@m">
                        {!this.state.sectionEmpty ? (
                          <div>
                            <Button
                              // onClick={this._nextWithoutUpdate}
                              onClick={this._next}
                              className="next-button purpleButton"
                              loading={this.state.saveNContinue}
                              basic
                            >
                              Save and Next
                          </Button>
                          </div>
                        ) : (
                            <div>
                              <Button
                                onClick={this._next}
                                basic
                                className="outline-button"
                                loading={this.state.saveNContinue}
                              >
                                Save & Continue
                          </Button>
                            </div>
                          )}

                        {/* <div class="spinner"></div>  */}
                      </div>
                    </div>
                    {/* End Footer */}
                  </Form>
                </div>
              </div>
              {/* End Dark Header */}
            </div>
          )
        }
      </div>
    );
  }
}

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(Section5)));
