import gql from "graphql-tag";
import GraphQLJSON, { GraphQLJSONObject } from "graphql-type-json";

export const ADD_SECTION = gql`
  mutation AddSection(
    $title: String!
    $subTitle: String
    $description: String
    $sequence: Int!
  ) {
    addSection(
      title: $title
      subTitle: $subTitle
      description: $description
      sequence: $sequence
    ) {
      id
      createdBy
    }
  }
`;

export const GET_SECTIONS = gql`
  query {
    getSections(orderBy: createdAt_DESC) {
      id
      title
      subTitle
      description
      sequence
      updatedAt
    }
  }
`;

export const GET_SINGLE_SECTION = gql`
  query GetSingleQuestion($sectionId: String!) {
    getSingleSection(sectionId: $sectionId) {
      title
      description
      subTitle
      createdAt
      updatedAt
    }
  }
`;

export const SECTIONS_BY_USER = gql`
  query GetSectionsCompletedByUser($userId: String!) {
    getSectionsCompletedByUser(userId: $userId) {
      id
      submissionID
      createdAt
      updatedAt
      sectionId
      submission
      sectionName
      sectionNumber
    }
  }
`;

// export const SECTIONS_BY_USER = gql`
//   query {
//     GetSectionsCompletedByUser($userId: String!){
//       getSectionsCompletedByUser(userId: $userId) {
//         id
//         submissionID
//         createdAt
//         updatedAt
//         sectionId
//         submission
//         sectionName
//         sectionNumber
//       }
//     }
//   }
// `;

export const GET_SECTION_TWO = gql`
  query {
    getSection1(sectionId: "ck09iwgjk6ftg0b53chrw6bkf") {
      id
      sectionNumber
      title
      subTitle
      description
      sequence
      createdAt
      updatedAt
    }
  }
`;

export const GET_SECTION_II = gql`
  query {
    getSection1(sectionId: "ck0xh4o71pbyx0b17v0fmtjb4") {
      id
      sectionNumber
      title
      subTitle
      description
      sequence
      createdAt
      updatedAt
    }
  }
`

export const GET_SECTION_III = gql`
  query {
    getSection1(sectionId: "ck1gm0lvs16l60b8869dwmzb3") {
      id
      sectionNumber
      title
      subTitle
      description
      sequence
      createdAt
      updatedAt
    }
  }
`

export const GET_SECTION_IV = gql`
  query {
    getSection1(sectionId: "ck1gmw55c1ko10b88d4v0x3yi") {
      id
      sectionNumber
      title
      subTitle
      description
      sequence
      createdAt
      updatedAt
    }
  }
`

export const GET_SECTION_V = gql`
  query {
    getSection1(sectionId: "ck1gn1be31sbe0b88lv3y56ny") {
      id
      sectionNumber
      title
      subTitle
      description
      sequence
      createdAt
      updatedAt
    }
  }
`