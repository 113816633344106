export const LOGGING_USER = 'LOGGIN_USER';
export const FOUND_RESTRICTED = 'FOUND_RESTRICTED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const FORM_STATUS = 'FORM_STATUS';
export const SUCCESS_STATUS = 'SUCCESS_STATUS';
export const FIRST_NAME = 'firstname';
export const AUTH_TOKEN = 'w101authtoken';
export const USER_NAME = 'userName';
export const LAST_NAME = 'lastName';
export const USER_TYPE = 'userType';
export const USER_ID = 'userId';
export const CURRENT_LIST = '';
export const API_URL = 'https://fsdonutopia.herokuapp.com';
export const LANGUAGE_OPTIONS = [
    { text: 'English', value: 'en', key: 'en' },
    { text: 'Arabic', value: 'ar', key: 'ar' },
    { text: 'French', value: 'fr', key: 'fr' },
    { text: 'Spanish', value: 'sp', key: 'sp' },
];
// export const API_URL = 'http://localhost:8088'