import React, { Component } from "react";
import { GENERIC_PROFILE } from "../../../../queries/UserQueries";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import SingleUserHome from './SingleUserHome';
import UserSubmissions from './UserSubmissions';
import UserSubmissionList from './UserSubmissionList';
import SectionHeaderLoader from '../../../../components/loaders/SectionHeaderLoader'
import moment from "moment";
import { hist } from './../../../../App';
import { GET_USER_SCORE } from '../../../../queries/SubmissionQueries';

import ViewSection1 from './view/viewsection1';
import ViewSection2 from './view/viewsection2';
import ViewSection3 from './view/viewsection3';
import ViewSection4 from './view/viewsection4';
import ViewSection5 from './view/viewsection5';
import ViewSection6 from './view/viewsection6';

import ReviewSection1 from './review/reviewsection1';
import ReviewSection2 from './review/reviewsection2';
import ReviewSection3 from './review/reviewsection3';
import ReviewSection4 from './review/reviewsection4';
import ReviewSection5 from './review/reviewsection5';
import ReviewSection6 from './review/reviewsection6';
import { withNamespaces } from "react-i18next";

class UserList extends Component {
  constructor(props) {
    super(props);
    const proObj = this.props.location.pathname.split('/');
    // console.log(this.props.location.state);
    this.state = {
      loading: true,
      _isPDF: proObj[4] === "all" ? true : false,
      user: {
        firstName: "...",
        lastName: "...",
        email: "...",
        id: "...",
        createdAt: "...",
      },
      general: {
        fullName: "...",
        country: "...",
        region: "...",
        year: "...",
      },
      userId: this.props.match.params.userId,
      result_status: "Not Ready",
      result_color: "red",
      score: 0,
      // assessmentName: this.props.location.state.assessmentName,
    };
    this._getGenericProfile();
  }

  componentDidMount() {
    // this.get_user_score().then(result => {

    //   const high_impact = result['0.4']['weightedValue'] === "NaN" ? parseFloat(0) : parseFloat(result['0.4']['weightedValue']);
    //   const tot_others = (result['0.3']['weightedValue'] === "NaN" ? parseFloat(0) : parseFloat(result['0.3']['weightedValue'])) + (result['0.2']['weightedValue'] === "NaN" ? parseFloat(0) : parseFloat(result['0.2']['weightedValue'])) + (result['0.1']['weightedValue'] === "NaN" ? parseFloat(0) : parseFloat(result['0.1']['weightedValue']));

    //   const tot_marks = tot_others + high_impact;
    //   console.log(tot_marks);
    //   this.setState({ score: tot_marks });
    //   if (high_impact === 40 && tot_others >= 20) {
    //     this.setState({ result_status: "Ready", result_color: "RGB(146,208,80)", loading: false });
    //   } else if (high_impact > 0 && tot_marks >= 60) {
    //     this.setState({ result_status: "Partially Ready.Additional development required in certain areas.", result_color: "RGB(255,192,0)", loading: false });
    //   }
    // }).catch(error => {
    //   console.log(error);
    //   this.setState({ loading: false });
    // });
  }

  get_user_score = async () => {
    const result = await this.props.client.query({
      query: GET_USER_SCORE,
      variables: { userId: this.state.userId },
      fetchPolicy: 'network-only'
    });
    return result.data.getUserScore;
  };

  _getGenericProfile = () => {
    this.props.client.query({
      query: GENERIC_PROFILE,
      variables: { userId: this.props.match.params.userId }
    }).then(result => {
      this.setState({
        user: { ...result.data.getUserFullProfile.user },
        general: { ...result.data.getUserFullProfile.general },
        loading: false,
      })
    }).catch(error => {
      console.log(error);
      this.setState({ loading: false });
    });
  }
  _allSubmissions = () => {
    // href={`${match.url}/list/all`}
    this.props.history.push(`${this.props.match.url}/list/all`);
  };

  _exportPDF = () => {
    console.log('PDF');
    var page = '<html><table border="1" style="border-spacing:0;width:100%">' + document.getElementById("print_pdf").innerHTML + '</table></html>';
    var w = window.open();
    w.document.write(page);
    w.print();
    w.close();
  }

  render() {
    const { match, t, i18n } = this.props;
    const { result_status, result_color, score, _isPDF } = this.state;
    return (
      <div className="admin-content-inner" style={{paddingTop:0}}>
        <div className="uk-flex-inline uk-flex-middle">
          <i className="fa fa-paper-plane icon-large uk-margin-right"></i>
          <h4 className="uk-margin-remove"> {t("Submissions")} </h4>
        </div>
        <div className="uk-margin"></div>

        {/* Begin Section Header */}

        {this.state.loading ? (
          <SectionHeaderLoader />

        ) : (
            // <div>
            //   <div class="uk-background-muted uk-margin-top uk-padding" style={{ paddingBottom: "10px" }}>
            //     <div class="uk-grid" uk-grid>
            //       <div class="uk-width-expand@m">
            //         <form class=" uk-flex-inline uk-flex-middle uk-text-small">
            //           <h4 class="uk-margin-remove-bottom uk-margin-right">
            //             {" "}
            //             {this.state.general.fullName}
            //           </h4>
            //         </form>
            //         <h6 class="uk-margin-remove-top uk-text-left">
            //           {" "}
            //           {this.state.general.country}, {this.state.general.region}
            //         </h6>
            //       </div>
            //       <div class="uk-width-auto@m uk-text-small">
            //         <p class="uk-text-right uk-margin-remove-bottom"> Submitted by: <strong> {this.state.user.firstName} {this.state.user.lastName} </strong> </p>                   
            //         <p class="uk-text-right uk-margin-remove-top uk-margin-remove-bottom">Account created on: {moment(this.state.user.createdAt).format('DD-MMMM-YYYY')}</p>
            //       </div>
            //     </div>
            //   </div>
            //   <div class="uk-background-muted" style={{ paddingRight: "40px", paddingBottom: "20px" }}>
            //     <div class="uk-width-auto@m uk-text-small">               

            //     </div>
            //   </div>
            // </div>
            ""
          )}

        {/* End Section Header */}


        {/* Begin Router */}
        <Router>
          <Route exact path={`${match.url}`} component={SingleUserHome} />
          <Route exact path={`${match.url}/:sectionId`} component={UserSubmissions} />
          <Route exact path={`${match.url}/list/all`} component={UserSubmissionList} />

          <Route exact path={`${match.url}/view/section1`} component={ViewSection1} />
          <Route path={`${match.url}/view/section2`} component={ViewSection2} />
          <Route path={`${match.url}/view/section3`} component={ViewSection3} />
          <Route path={`${match.url}/view/section4`} component={ViewSection4} />
          <Route path={`${match.url}/view/section5`} component={ViewSection5} />
          <Route path={`${match.url}/view/section6`} component={ViewSection6} />

          <Route exact path={`${match.url}/review/section1`} component={ReviewSection1} />
          <Route path={`${match.url}/review/section2`} component={ReviewSection2} />
          <Route path={`${match.url}/review/section3`} component={ReviewSection3} />
          <Route path={`${match.url}/review/section4`} component={ReviewSection4} />
          <Route path={`${match.url}/review/section5`} component={ReviewSection5} />
          <Route path={`${match.url}/review/section6`} component={ReviewSection6} />
        </Router>
        {/* End Router */}
      </div>
    );
  }
}

export default withRouter(withApollo(withNamespaces()(UserList)));
