import React, { Component } from "react";
import { Form, Dropdown } from "semantic-ui-react";
import userImage from '../../../../assets/images/avatures/avature.jpg';
import UserList from './UserList';
import SingleUser from './SingleUser';
import AddSection from '../AddSection';
import AddQuestion from '../AddQuestion';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { logoutUser } from '../../../../services/CacheOperations';
import { LANGUAGE_OPTIONS } from "../../../../constants/index";
// import { withNamespaces } from "react-i18next";

class UserMain extends Component {

    logout() {
        logoutUser();
        window.location.href = '/';
    }
    changeLanguage = async (e, { value }, data) => {
        localStorage.RAT_LANG = value;
        this.setState(prevState => ({ lang_value: value }));
        this.props.i18n.changeLanguage(value);
        // window.location.reload();
    }

    render() {
        const { match, t, i18n } = this.props;
        return (
            <div className="admin-content">
                <nav className="uk-navbar">
                    <div className="uk-navbar-left">
                        <ul className="uk-navbar-nav">
                            <li className="uk-active">
                                <a href="/" style={{ fontSize: "22px" }}>Dashboard</a>
                            </li>
                            <li>
                                <a href="/users" style={{ fontSize: "22px" }}> User Profiles</a>
                            </li>
                        </ul>
                    </div>
                    <div className="uk-navbar-right">
                        {/* <ul className="uk-navbar-nav toolbar-nav">
                            <li>
                                <a className="remove-affter"><Dropdown options={LANGUAGE_OPTIONS} value={this.props.i18n.language === "en-US" ? "en" : this.props.i18n.language} onChange={this.changeLanguage} /></a>
                            </li>
                        </ul> */}
                        <a href="#">
                            <i className="fas fa-user icon-large uk-border-circle" ></i>
                            {/* <img src={userImage} alt="" class="uk-border-circle user-profile-tiny" /> */}
                        </a>
                        <div uk-dropdown="pos: top-right ;mode : click ;animation: uk-animation-slide-bottom-small" className="uk-dropdown uk-padding-small angle-top-right uk-dropdown-bottom-right" >
                            {/* <p className="uk-margin-remove-bottom uk-margin-small-top uk-text-bold"> Hamse Mohamoud  </p>                                           */}
                            <ul className="uk-nav uk-dropdown-nav">
                                <li>
                                    <a onClick={() => this.logout()}> <i className="fas fa-sign-out-alt uk-margin-small-right"></i> Log out</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
                <Route exact path={`${match.url}`} component={UserList} />
                <Route exact path={`${match.url}/new`} component={AddSection} />
                <Route path={`${match.url}/:userId`} component={SingleUser} />
                <Route exact path={`${match.url}/:someid/new`} component={AddQuestion} />
            </div>
        );
    }
}

export default withRouter(UserMain);
