import React, { Component } from "react";
import TableLoader from "../../../../components/loaders/TableLoader";
import { SECTIONS_BY_USER } from "../../../../queries/SectionQueries";
import { GET_ALL_USER_ASSESSMENT, GET_ALL_EDIT_SUBMMISSIONS, DOWNLOAD_PDF, DELETE_SUBMMISSION, REOPEN_SUBMMISSION } from '../../../../queries/SubmissionQueries';
import { GENERIC_PROFILE } from "../../../../queries/UserQueries";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { Label } from 'semantic-ui-react';
import moment from "moment";
import { Form, Select, Dropdown, Button, Message, Modal, Image, Grid, Segment, Header, Table, Accordion, Icon, Popup, Confirm } from "semantic-ui-react";
import _ from 'lodash'
import SubmissionsAdminLoader from "../../../../components/loaders/SubmissionsAdminLoader";
import { withNamespaces } from "react-i18next";

class SingleUserHome extends Component {
  constructor(props) {
    super(props);
    const proObj = this.props.match.path.split('/');
    this.state = {
      loading: true,
      // sections: [],
      userId: proObj[2],
      assessments: [],
      status: { DRAFT: "#DB2828", SUBMITTED: "#28DB82", REVIEWED: "#EAAA00", INACTIVE: "#686C6D", FINAL: "#1A2674" },
      column: null,
      direction: null,
      sections: [],
      activeIndex: 0,
      downloading: false,
      download_sub_id: "",
      confirmDelete: false,
      deleteSubmissionId: "",
      deleteSubmissionType: "",
      confirmReopen: false,
      reOpemSubmissionId: "",
      reOpenSubmissionType: "",
      submissionListLoading: true,
    };
    this._show_all_submissions();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  _show_all_submissions = () => {
    this.setState({ assessments: [], submissionListLoading: true })
    this.get_all_user_assessment().then((result) => {
      const temp_result = result.map(({ submission }) => submission)
      this.setState({ assessments: temp_result, submissionListLoading: false });
    });
  }

  // _getCompletedSections = () => {
  //   const { userId } = this.state;
  //   this.props.client.query({
  //     query: SECTIONS_BY_USER,
  //     variables: { userId: userId },
  //     fetchPolicy: 'network-only'
  //   }).then(result => {
  //     this.setState({
  //       sections: result.data.getSectionsCompletedByUser,
  //       loading: false
  //     });
  //   }).catch(error => {
  //     console.log(error);
  //     this.setState({ loading: false });
  //   });
  // };

  _viewSectionSubmissions = (id) => {
    this.props.history.push(`${this.props.match.url}/${id}`);
  }

  get_all_user_assessment = async () => {
    const { userId } = this.state;
    const result = await this.props.client.query({
      query: GET_ALL_USER_ASSESSMENT,
      variables: { userId },
      fetchPolicy: "network-only",
    });
    return result.data.getAllUserAssessment;
  };

  _reviewAssessment = (submission, submissionType) => {
    const assessmentName = submission.assessmentName;
    if (submissionType === "LATEST") {
      this.props.history.push({ pathname: `${this.props.match.url}/review/section2`, state: { submissionId: submission.id, submissionType: submissionType, assessmentName: assessmentName } });
    }
    if (submissionType === "HISTORY") {
      this.props.history.push({ pathname: `${this.props.match.url}/review/section2`, state: { submissionId: submission.submissionId, submissionType: submissionType, assessmentName: assessmentName } });
    }

  }

  _viewAssessment = (submission, submissionType) => {
    const assessmentName = submission.assessmentName;
    if (submissionType === "LATEST") {
      this.props.history.push({ pathname: `${this.props.match.url}/view/section1`, state: { submissionId: submission.id, submissionType: submissionType, assessmentName: assessmentName } });
    }
    if (submissionType === "HISTORY") {
      this.props.history.push({ pathname: `${this.props.match.url}/view/section1`, state: { submissionId: submission.submissionId, submissionType: submissionType, assessmentName: assessmentName } });
    }

  }

  _downloadAssessment = (submission, submissionType) => {
    const submissionId = submissionType === "LATEST" ? submission.id : submission.submissionId;
    this.setState({ downloading: true, download_sub_id: submission.id })
    this.get_pdf(submissionId, submissionType).then(result => {
      const linkSource = `data:application/pdf;base64,${result}`;
      const downloadLink = document.createElement("a");
      const fileName = submission.assessmentName.concat(".pdf");//"download.pdf";
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      this.setState({ downloading: false, download_sub_id: "" })
    })
    // const submissionId = submissionType === "LATEST" ? submission.id : submission.submissionId;
    // this.setState({ downloading: true, download_sub_id: submission.id })
    // this.get_all_submissions(submissionId).then(result => {
    //   this.setState({ downloading: false, sections: result, download_sub_id: "" })

    //   let mywindow = window.open('', 'PRINT', 'height=650,width=900,top=100,left=150');

    //   mywindow.document.write(`<html><head><title>download</title>`);
    //   mywindow.document.write('</head><body >');
    //   mywindow.document.write(document.getElementById("print_pdf").innerHTML);
    //   mywindow.document.write('</body></html>');

    //   mywindow.document.close(); 
    //   mywindow.focus(); 

    //   mywindow.print();
    //   mywindow.close();

    //   return true;

    // });
  }

  get_pdf = async (submissionId, submissionType) => {
    const result = await this.props.client.query({
      query: DOWNLOAD_PDF,
      variables: { submissionId: submissionId, submissionType: submissionType },
      fetchPolicy: 'network-only'
    });
    return result.data.downloadPDF;
  };

  get_all_submissions = async (submissionId) => {
    const result = await this.props.client.query({
      query: GET_ALL_EDIT_SUBMMISSIONS,
      variables: { submissionId: submissionId },
      fetchPolicy: 'network-only'
    });
    return result.data.getAllEditSubmissionsByUser;
  };

  handleSort = clickedColumn => {
    const { column, data, direction, assessments } = this.state
    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        assessments: _.sortBy(assessments, [clickedColumn]),
        direction: 'ascending',
      })

      return
    }

    this.setState({
      assessments: assessments.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    })
  }
  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }
  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });

  };

  _showDeleteModal = (submission, submissionType) => {
    this.setState({ confirmDelete: true, deleteSubmissionId: submissionType === "LATEST" ? submission.id : submission.submissionId, deleteSubmissionType: submissionType })
  }

  _showReopenModal = (submission, submissionType) => {
    this.setState({ confirmReopen: true, reOpemSubmissionId: submissionType === "LATEST" ? submission.id : submission.submissionId, reOpenSubmissionType: submissionType })
  }

  handleConfirmDelete = () => {
    const { deleteSubmissionId, deleteSubmissionType } = this.state;
    this._delete_submission(deleteSubmissionId, deleteSubmissionType).then(result => {
      this._show_all_submissions();
      this.setState({ confirmDelete: false, deleteSubmissionId: "" })
    })
  }

  _delete_submission = async (submissionId, submissionType) => {
    const result = await this.props.client.mutate({
      mutation: DELETE_SUBMMISSION,
      variables: { submissionId: submissionId, submissionType: submissionType }
    });
    return result.data.deleteSubmission;
  };

  handleConfirmReopen = () => {
    const { reOpemSubmissionId, reOpenSubmissionType } = this.state
    this._reopen_submission(reOpemSubmissionId, reOpenSubmissionType).then(result => {
      this._show_all_submissions();
      this.setState({ confirmReopen: false, reOpemSubmissionId: "" })
    })
  }

  _reopen_submission = async (submissionId, submissionType) => {
    const result = await this.props.client.mutate({
      mutation: REOPEN_SUBMMISSION,
      variables: { submissionId: submissionId, submissionType: submissionType },
    });
    return result.data.reopenSubmission;
  };

  handleCancelDelete = () => this.setState({ confirmDelete: false, deleteSubmissionId: "" })

  handleCancelReopen = () => this.setState({ confirmReopen: false, reOpemSubmissionId: "" })

  render() {
    const { t, i18n } = this.props;
    const { assessments, status, column, direction, sections, activeIndex, download_sub_id, confirmDelete, confirmReopen, deleteSubmissionId, reOpemSubmissionId } = this.state
    var question_number = 0;
    return (
      <div>
        <div className="uk-link-reset paper uk-margin-remove-bottom" style={{ border: "none" }}>
          <div
            id="register"
            className="animation: uk-animation-slide-left-medium"
          >
            <Table sortable celled padded>
              <Table.Header>
                <Table.Row style={{ fontSize: "15px" }}>
                  <Table.HeaderCell width={3} sorted={column === 'assessmentName' ? direction : null}
                    onClick={(e) => this.handleSort('assessmentName')}>{t("Assessment Name")}</Table.HeaderCell>
                  <Table.HeaderCell width={1} sorted={column === 'status' ? direction : null}
                    onClick={(e) => this.handleSort('status')}>{t("Assessment Status")}</Table.HeaderCell>
                  <Table.HeaderCell  width={1} sorted={column === 'scoreDescription' ? direction : null}
                    onClick={(e) => this.handleSort('scoreDescription')}>{t("SE Readiness Status")}</Table.HeaderCell>
                  <Table.HeaderCell style={{minWidth:"120px"}} sorted={column === 'createdAt' ? direction : null}
                    onClick={(e) => this.handleSort('createdAt')}>{t("Created")}</Table.HeaderCell>
                  <Table.HeaderCell style={{minWidth:"120px"}} sorted={column === 'updatedAt' ? direction : null}
                    onClick={(e) => this.handleSort('updatedAt')}>{t("Last Edited")}</Table.HeaderCell>
                  <Table.HeaderCell style={{width:"135px"}}>{t("Actions")}</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              {this.state.submissionListLoading ? (
                <SubmissionsAdminLoader />
              ) : (
                  <Table.Body>
                    {assessments.map((submission) => (
                      <Table.Row key={submission.id}>
                        <Table.Cell>{submission.assessmentName}</Table.Cell>
                        <Table.Cell style={{ color: status[submission.status], fontWeight: "bold", fontSize: "17px" }}>{t(submission.status)}</Table.Cell>
                        <Table.Cell style={{ color: submission.scoreDescriptionColor, fontWeight: "bold", fontSize: "17px" }}>{submission.status === "REVIEWED" || submission.status === "FINAL" ? (<span>{t(submission.seReadinessStatus)}</span>) : ""}</Table.Cell>
                        <Table.Cell style={{width:"120px"}}>{localStorage.RAT_LANG=='ar'? (moment(submission.createdAt, "YYYY-MM-DD").format("YYYY-MM-DD")): localStorage.RAT_LANG=='en'? ((moment(submission.createdAt).format("DD-MMM-YYYY"))):((moment(submission.createdAt).format("DD-MM-YYYY"))) }</Table.Cell>
                        <Table.Cell style={{width:"120px"}}> {localStorage.RAT_LANG=='ar'?(moment(submission.updatedAt, "YYYY-MM-DD").format("YYYY-MM-DD")): localStorage.RAT_LANG=='en'? ((moment(submission.updatedAt).format("DD-MMM-YYYY"))):((moment(submission.updatedAt).format("DD-MM-YYYY"))) }</Table.Cell>
                        <Table.Cell textAlign='left' style={{minWidth:"120px"}} >
                          <Grid columns='equal'>
                            <Grid.Row className="grid-column">
                              <Grid.Column>
                                <button onClick={(e) => this._viewAssessment(submission, submission.type)} class="uk-button uk-button-danger light-blue-btn" uk-tooltip={t("View")} title="" aria-expanded="false"> <i className="fas fa-eye uk-visible@m"></i> </button>
                              </Grid.Column>
                              <Grid.Column>
                                {submission.status === "SUBMITTED" ? (
                                  <button onClick={(e) => this._reviewAssessment(submission, submission.type)} class="uk-button uk-button-danger yellow-btn" uk-tooltip={t("Review")} title="" aria-expanded="false"> <i className="fas fa-comments uk-visible@m"></i> </button>
                                ) : (
                                    <button disabled class="uk-button uk-button-danger yellow-btn" uk-tooltip={t("Review")} title="" aria-expanded="false"> <i className="fas fa-comments uk-visible@m"></i> </button>
                                  )}
                              </Grid.Column>
                              <Grid.Column>
                                <Button loading={download_sub_id === submission.id} onClick={(e) => this._downloadAssessment(submission, submission.type)} className="uk-button uk-button-danger purple-btn" uk-tooltip={t("Download")} title="" aria-expanded="false"> <i className="fas fa-download uk-visible@m"></i> </Button>
                                {/* <button class="uk-button uk-button-danger purple-btn" uk-tooltip="title: Download ; delay: 500 ; pos: top ;animation:	uk-animation-scale-up" title="" aria-expanded="false"> Download </button> */}
                              </Grid.Column>
                              <Grid.Column>
                                {submission.status === "FINAL" || submission.status === "INACTIVE" ? (
                                  <div>
                                    <Button loading={reOpemSubmissionId === submission.id} onClick={(e) => this._showReopenModal(submission, submission.type)} className="uk-button uk-button-danger grey-btn-active" uk-tooltip={t("Reopen")} title="" aria-expanded="false"> <i className="fas fa-redo uk-visible@m"></i> </Button>
                                    <Confirm
                                      size='tiny'
                                      open={confirmReopen}
                                      onCancel={(e) => this.handleCancelReopen()}
                                      onConfirm={(e) => this.handleConfirmReopen()}
                                      content={t("Are you sure you want to reopen this assessment?")}
                                      confirmButton={t("Yes")}
                                      cancelButton={t("No")}
                                    />
                                  </div>
                                  // <button class="uk-button uk-button-danger grey-btn" uk-tooltip="title: Reopen ; delay: 500 ; pos: top ;animation:	uk-animation-scale-up" title="" aria-expanded="false"> <i className="fas fa-redo uk-visible@m"></i> </button>
                                ) : (
                                    <button disabled class="uk-button uk-button-danger grey-btn" uk-tooltip={t("Reopen")} title="" aria-expanded="false"> <i className="fas fa-redo uk-visible@m"></i> </button>
                                  )}
                              </Grid.Column>
                              <Grid.Column>
                                {submission.status === "INACTIVE" ? (
                                  <button disabled class="uk-button uk-button-danger red-btn" uk-tooltip={t("Delete")} title="" aria-expanded="false"> <i className="fas fa-trash uk-visible@m"></i> </button>
                                ) : (
                                    <div>
                                      <Button loading={deleteSubmissionId === submission.id} onClick={(e) => this._showDeleteModal(submission, submission.type)} className="uk-button uk-button-danger red-btn-active" uk-tooltip={t("Delete")} title="" aria-expanded="false"> <i className="fas fa-trash uk-visible@m"></i> </Button>
                                      <Confirm
                                        size='tiny'
                                        open={confirmDelete}
                                        onCancel={(e) => this.handleCancelDelete()}
                                        onConfirm={(e) => this.handleConfirmDelete()}
                                        content={t("Are you sure you want to delete this assessment?")}
                                        confirmButton={t("Yes")}
                                        cancelButton={t("No")}
                                      />
                                    </div>
                                  )}
                                {/* <button class="uk-button uk-button-danger red-btn" uk-tooltip="title: Delete ; delay: 500 ; pos: top ;animation:	uk-animation-scale-up" title="" aria-expanded="false"> <i className="fas fa-trash uk-visible@m"></i> </button> */}
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </Table.Cell>

                      </Table.Row>
                    ))}
                  </Table.Body>
                )}
            </Table>
            {this.state.submissionListLoading ? (
              ""
            ) : (
                <div>
                  <p><span style={{ color: "#DB2828", fontWeight: "bold", fontSize: "17px" }}>{t("DRAFT")}</span> - {t("Assessment is in draft / preliminary version")}</p>
                  <p><span style={{ color: "#28DB82", fontWeight: "bold", fontSize: "17px" }}>{t("SUBMITTED")}</span> - {t("Submitted to SE Hub for review")}</p>
                  <p><span style={{ color: "#EAAA00", fontWeight: "bold", fontSize: "17px" }}>{t("REVIEWED")}</span> - {t("Reviewed and comments added by SE Hub")}</p>
                  <p><span style={{ color: "#686C6D", fontWeight: "bold", fontSize: "17px" }}>{t("INACTIVE")}</span> - {t("Inactive or Deleted")}</p>
                  <p><span style={{ color: "#1A2674", fontWeight: "bold", fontSize: "17px" }}>{t("FINAL")}</span> - {t("Assessment is finalized with reviews")}</p>
                  {/* <div className="uk-margin"></div> */}
                </div>
              )}
          </div>
          {/* General Details */}
        </div>
      </div>
    );
  }
}

export default withRouter(withApollo(withNamespaces()(SingleUserHome)));
