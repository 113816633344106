import React, { Component } from "react";

class About extends Component {
  render() {
    return (
      <div className="contactStyle">
        {/* Begin Dark Header */}
        {/* <div className="uk-section-default uk-height-large" uk-scrollspy="{&quot;target&quot;:&quot;[uk-scrollspy-class]&quot;,&quot;cls&quot;:&quot;uk-animation-slide-top-small&quot;,&quot;delay&quot;:false}" tm-header-transparent="dark" tm-header-transparent-placeholder="">
            <div data-src="../assets/images/backgrounds/about.jpg" uk-img class="uk-background-norepeat uk-background-center-center uk-background-image@s uk-background-fixe uk-section uk-section-large uk-padding-remove-top uk-flex uk-flex-middle uk-background-cover" uk-height-viewport="offset-top: true" style={ styles.darkBack }>
                <div className="uk-container">
                    <div className="uk-grid-margin uk-grid uk-grid-stack" uk-grid>
                        <div style={{ marginTop:"-150px"}}  className="uk-width-1-1@m uk-text-center">
                            <h1 className=" uk-animation-slide-top-small uk-text-white">
                                About Us
                            </h1>
                            <p className="uk-text-white uk-text-large">
                                Maecenas vel dolor sit amet ligula interdum tempor id ipsum.<br/>
                                Suspendisse pharetra risus ut metus elementum pulvinar </p>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
        {/* <div className="uk-section-default uk-section">
            <div className="uk-margin-auto uk-container-small">
                <h1 className="uk-text-center">
                    About Us</h1>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
                     Etiam sit amet augue non velit aliquet consectetur. Proin gravida, odio in facilisis pharetra, neque enim aliquam eros, vitae gravida orci elit vel magna. Integer viverra a purus id gravida. Donec laoreet mi ac auctor ultricies. Pellentesque ullamcorper est et erat ullamcorper gravida. In hac habitasse platea dictumst. Pellentesque justo mauris, mollis at tortor ut, commodo venenatis elit. Curabitur suscipit pellentesque nunc, id tempus mi facilisis sed. Curabitur molestie eu odio vitae condimentum. Donec placerat tristique neque a blandit. Nullam commodo massa ut eros elementum, in suscipit libero aliquamp</p>
                <p>
                    Nulla facilisi. Aenean pharetra fringilla nunc a finibus. Nulla vitae pretium nunc. Pellentesque sagittis sollicitudin turpis id aliquam. Cras lobortis diam in nunc posuere, et malesuada sem gravida. Curabitur ornare massa id orci faucibus elementum. Phasellus pharetra, velit in egestas rutrum, metus dolor maximus massa, feugiat molestie eros mauris sit amet massa. Maecenas blandit diam lacus, in luctus nulla efficitur nec. Sed sit amet quam sit amet odio scelerisque vestibulum vel sit amet ante. Cras fringilla efficitur lacinia. Cras posuere, arcu id consequat ultrices, urna urna cursus massa, at sollicitudin elit lacus quis dui. In laoreet nulla a turpis blandit sollicitudin. Donec in risus eu lorem volutpat hendrerit quis non lorem. Nulla in metus ipsum.</p>
            </div>
        </div> */}
        {/* End Dark Header */}
      </div>
    );
  }
}
const styles = {
    darkBack: {
        backgroundColor:'rgba(0, 0, 0, 0.52)',
        backgroundBlendMode:'color-burn',
    },
    poweredBy: {
        bottom: '8px',
        right: '60px',

    }
}

export default About;
