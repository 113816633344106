import React, { Component } from "react";
import TableLoader from "../../../../components/loaders/TableLoader";
import SectionHeaderLoader from "../../../../components/loaders/SectionHeaderLoader";
import { GET_MA_PROFILE_LIST, EDIT_USER, DELETE_USER } from "../../../../queries/UserQueries";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { _generate_email } from '../../../../middleware';

//ui
import { Dropdown, Header, Table, Rating, Button, Confirm, Grid } from "semantic-ui-react";
import { withNamespaces } from "react-i18next";
const userStatus = [
  { "key": "all", "value": 0, "text": "All" },
  { "key": "active", "value": true, "text": "Active" },
  { "key": "pending", "value": false, "text": "Pending" }
];

class UserList extends Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);
    const status = query.get('status');
    console.log(status)
    this.state = {
      loading: true,
      userStatus: "",
      users: [],
      isActive: status ? JSON.parse(status) : 0,
      open: false,
      result: 'show the modal to capture a result',
      userId: ""
    };
    console.log(localStorage.USER_ID);
    // if (localStorage.USER_ID == undefined) {
    //   this.props.history.push('/');
    // }
    this._getUserList();
  }
  // This is a redirect function
  addNewSection = () => {
    console.log("addNew");
    this.props.history.push("sections/new");
  };

  _getUserList = async () => {
    const { isActive } = this.state;

    await this.props.client.query({
      query: GET_MA_PROFILE_LIST,
      variables: isActive === 0 ? {} : { isActive: isActive },
      fetchPolicy: 'network-only'
    }).then(result => {
      console.log(result.data.getMAUserList)
      this.setState({
        loading: false,
        users: result.data.getMAUserList
      });
    }).catch(error => {
      this.setState({ loading: false });
    });
  };

  _handleUserStatusChange = (e, { value }) => {
    this.state.isActive = value;
    this.setState({
      loading: true
    });
    this._getUserList();
  };

  _userStatusOnClick = (id, status) => {
    var tmpStatus = "";
    if (status) {
      tmpStatus = false;
    } else {
      tmpStatus = true;
    }

    this._updateUser(id, tmpStatus);

  }

  ViewUserProfile = userId => {
    this.props.history.push(`${this.props.match.url}/${userId}`);
  };

  deleteUser = async userId => {
    this.setState({ open: true, userId: userId })

  };

  handleConfirm = async () => {
    const { userId } = this.state

    this.setState({
      loading: true
    });
    await this.props.client.mutate({
      mutation: DELETE_USER,
      variables: {
        userID: userId
      }
    }).then(result => {
      this.setState({ open: false })
      this._getUserList();
    });

  }
  handleCancel = () => this.setState({ open: false })

  _updateUser = async (userId, isActive) => {

    this.setState({
      loading: true
    });
    await this.props.client.mutate({
      mutation: EDIT_USER,
      variables: {
        userId: userId,
        isActive: isActive,
      }
    }).then(result => {
      this._getUserList();
      const USER_DATA = result.data.editUser.user;
      const USER_EMAIL = USER_DATA.email;
      const FULL_NAME = USER_DATA.firstName + " " + USER_DATA.Lakshitha;
      const FIRST_NAME = USER_DATA.firstName;
      const userLang = result.data.editUser.user.languagePreference ? result.data.editUser.user.languagePreference : 'en'

      const personalizations = {
        "personalizations": [
          {
            "to": [
              {
                "email": USER_EMAIL,
                "name": FULL_NAME
              }
            ],
            "bcc": [
              // {
              //   "email": "manjula@fpasrilanka.org",
              //   "name": "Manjula Chathuranga Liyanage"
              // },
              {
                "email": "chameera.lakshitha212@gmail.com",
                "name": "Lakshitha"
              },
              {
                "email": "rishi@fpasrilanka.org",
                "name": "Rishikeshan Thiyagaraja"
              }
            ],
            "dynamic_template_data": {
              "name": FIRST_NAME
            },
            "subject": "SE Readiness Assessment Tool – Your Account has been Approved"
          }
        ],
        // COMMENT KEY - C-01/06/2021
        // "template_id": "d-2b8abca47c294d218e0bd63ce19a794b"
        "template_id": userLang === 'en' ? "d-2b8abca47c294d218e0bd63ce19a794b" 
                                    : userLang === 'ar' ? "d-0aec8e29e8924598ac6092129119219c" 
                                        : userLang === 'fr' ? "d-c7127efdefbe4b5b9c8485a4dff20ca0" 
                                            : userLang === 'sp' ? "d-17101cd8b9d449c78076970163c59aaa" 
                                                : "d-2b8abca47c294d218e0bd63ce19a794b"
      };
      // "template_id": "d-867dd342db084fa386561642fe663291"
      if (isActive) {
        // console.log(isActive);
        const data = _generate_email('https://ra.seippf.org/mail/mail.php', personalizations);
      }

      // window.location.reload();

      // setTimeout(() => {

      // }, 3000);


    });


  }
  downloadCsv = () => {
    console.log(this.state.activeList);
    const arrayHeader = ['First name', 'Last name', 'User Category', 'Email Address', 'Status', 'Organization name', 'Country', 'IPPF Region', 'Year Established', 'Account created'];
    const arrayData = this.state.users;
    const delimiter = ',';
    const fileName = 'User List';
    this.export_csv(arrayHeader, arrayData, delimiter, fileName);
  }

  export_csv = (arrayHeader, arrayData, delimiter, fileName) => {
    // console.log(arrayData)
    // let key = ['firstName', 'lastName', 'email', 'isActive', 'fullName', 'country', 'region', 'year', 'createdAt', 'newsletterSubscription'];
    let keys = ['firstName', 'lastName', 'userCategory', 'email', 'isActive', 'fullName', 'country', 'region', 'year', 'createdAt'];
    let header = arrayHeader.join(delimiter) + '\n';
    let csv = header;
    arrayData.forEach(({ user, general }) => {
      const obj = { firstName: user.firstName, lastName: user.lastName, userCategory: (general.fullName === "International Planned Parenthood Federation") ? "CO" : (general.fullName === "NA" ? "RO" : "MA"), email: user.email, isActive: user.isActive === true ? "Active" : "Inactive", createdAt: moment(user.createdAt).format('DD-MM-YYYY'), fullName: general.fullName, country: general.country, region: general.region, year: general.year }
      // console.log(obj)
      let row = [];
      let date = '';
      keys.map(key => (
        obj.hasOwnProperty(key) ? row.push(obj[key]) : null
      ))
      csv += row.join(delimiter) + "\n";
    });

    let csvData = new Blob([csv], { type: 'text/csv' });
    let csvUrl = URL.createObjectURL(csvData);

    let hiddenElement = document.createElement('a');
    hiddenElement.href = csvUrl;
    hiddenElement.target = '_blank';
    hiddenElement.download = fileName + '.csv';
    hiddenElement.click();
  }



  render() {
    const { t, i18n } = this.props;
    const { isActive, open, result } = this.state;
    return (
      <div className="admin-content-inner" style={{ paddingTop: 0 }}>
        <div className="uk-grid button_div">

          <div className="uk-width-expand@m">
            <div className="uk-flex-inline uk-flex-middle">
              <i className="fas fa-user icon-large uk-margin-right"></i>
              <h4 className="uk-margin-remove"> {t("User Profiles and Submissions")} </h4>
            </div>
          </div>


          <div className="uk-width-1-4@m" style={{ textAlign: "right" }}>
            <button class="uk-button uk-button-success view-profile-btn" uk-tooltip={t("Download user list")} onClick={() => this.downloadCsv()}><i class="fa fa-download" aria-hidden="true"></i></button>
          </div>
          <div>
            <div>
              <div class="uk-inline">
                <Dropdown
                  value={isActive}
                  onChange={this._handleUserStatusChange}
                  options={[
                    { "key": "all", "value": 0, "text": t("All") },
                    { "key": "active", "value": true, "text": t("Active") },
                    { "key": "pending", "value": false, "text": t("Pending") }
                  ]}
                  placeholder="Select User Status"
                  selection
                />
              </div>
            </div>
          </div>
        </div>
        <div className="uk-margin"></div>
        <div className="uk-link-reset paper uk-margin-remove-bottom" style={{ border: "none" }}>
          <div id="register" className="animation: uk-animation-slide-left-medium">
            <Table celled padded>
              <Table.Header>
                <Table.Row style={{ fontSize: "15px" }}>
                  <Table.HeaderCell width={6}>{t("Profile Details")}</Table.HeaderCell>
                  <Table.HeaderCell width={1}>{t("Email")}</Table.HeaderCell>
                  <Table.HeaderCell>{t("REGISTERED ON")}</Table.HeaderCell>
                  <Table.HeaderCell width={4}>{t("ACTIONS")}</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              {this.state.loading ? (
                <TableLoader />
              ) : (
                  <Table.Body>
                    {this.state.users.map(({ user, general }) => (
                      <Table.Row key={user.id}>
                        <Table.Cell><b>{t("Name")}: </b><br />{user.firstName} {user.lastName}<br />
                          <b>{(general.fullName === "International Planned Parenthood Federation") ? (
                            t("Central Office")
                          ) : (general.fullName === "NA") ? t("Regional Office") : t("Member Association")}:</b><br />
                          {general.fullName}
                          <br />
                          <b>{t("IPPF Region")}: </b><br />{general.region}
                          <br /><b>{t("Country")}: </b><br />{general.country}
                          <br /><b>{t("Year of establishment")}: </b><br />{general.year}
                        </Table.Cell>
                        <Table.Cell>{user.email}</Table.Cell>
                        <Table.Cell>{moment(user.createdAt).format("DD-MMM-YYYY")}</Table.Cell>
                        <Table.Cell>
                          {/* <Grid columns='equal'>
                          <Grid.Row className="grid-column">
                            <Grid.Column> */}
                          {user.isActive ? (
                            <button
                              onClick={() => this._userStatusOnClick(user.id, user.isActive)}
                              className="uk-button uk-button-danger admin-table-btn"
                              uk-tooltip={t("Edit Status")}
                            > {t("Deactivate")} </button>
                          ) : (
                              <button
                                onClick={() => this._userStatusOnClick(user.id, user.isActive)}
                                className="uk-button uk-button-success admin-table-btn button_Success"
                                uk-tooltip={t("Edit Status")}
                              > {t("Activate")} </button>
                            )}
                          {/* </Grid.Column>
                            <Grid.Column> */}
                          <button
                            onClick={() => this.ViewUserProfile(user.id)}
                            className="uk-button uk-button-success admin-table-btn view-profile-btn"
                            uk-tooltip={t("VIEW SUBMISSIONS")}
                          >
                            <i className="fas fa-eye uk-visible@m"></i>
                          </button>
                          {/* </Grid.Column>
                            <Grid.Column> */}
                          <button
                            onClick={() => this.deleteUser(user.id)}
                            className="uk-button uk-button-danger admin-table-btn"
                            uk-tooltip={t("Delete Profile")}
                          >
                            <i className="fas fa-trash uk-visible@m"></i>
                          </button>
                          <Confirm
                            open={open}
                            content={t("Are you sure you want to reject/delete this user?")}
                            onCancel={this.handleCancel}
                            onConfirm={this.handleConfirm}
                            size='tiny'
                            confirmButton={t("Yes")}
                            cancelButton={("No")}
                          />
                          {/* </Grid.Column>                                                        
                          </Grid.Row>
                        </Grid> */}
                        </Table.Cell>

                      </Table.Row>
                    ))}
                  </Table.Body>
                )}
            </Table>
          </div>
        </div>
      </div >
    );
  }
}

export default withRouter(withApollo(withNamespaces()(UserList)));
