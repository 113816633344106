import { LOGGING_USER,FORM_STATUS,SUCCESS_STATUS } from '../constants/index';

export function loginUser(user) {
    return {type: LOGGING_USER, user};
}

export const setFormStatus = (status) => {
    return {type: FORM_STATUS, status}
}

export const setSuccessStatus = (status) => {
    return {type: SUCCESS_STATUS, status}
}