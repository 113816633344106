import React, { Component } from "react";
import SectionLoader from "../../../../generic/assesment/SectionLoader";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import {
    Form,
    Button,
    TextArea,
    Input,
    Label,
    Loader,
    Message,
    Icon,
    Popup,
    Grid,
    Header,
    Confirm
} from "semantic-ui-react";
import { GET_SECTION_II } from "../../../../../queries/SectionQueries";
import {
    GET_SECTION_1,
    SUBMIT_SECTION_1,
    UPDATE_SECTION_1,
    GET_EDIT_SECTION_SUBMISSION,
    UPDATE_USER_SECTION_1
} from "../../../../../queries/SubmissionQueries";

import { USER_NAME } from "../../../../../constants/index";

import { uploadFile, deleteFile } from "../../../../../firebase/FileServices";
import { Promise } from "q";
import { setFormStatus, setSuccessStatus } from '../../../../../actions/user-actions';
import { connect } from 'react-redux';
import { GENERIC_PROFILE } from "../../../../../queries/UserQueries";
import moment from "moment";
import sectionTwoQuestions from '../../../../questions/section2_questions.json';
import { withNamespaces } from "react-i18next";

const mapStateToProps = (state, ownProps) => {
    return {
        formErrorStatus: state.custom_reducer.formErrorStatus,
        formSuccessState: state.custom_reducer.formSuccessState,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setFormStatus: (status) => {
            dispatch(setFormStatus(status))
        },
        setSuccessStatus: (status) => {
            dispatch(setSuccessStatus(status))
        }
    }
}

class ReviewSection3 extends Component {
    constructor(props) {
        super(props);

        this.props.setFormStatus({ status: false, title: '', message: '' });
        this.props.setSuccessStatus({ status: false, title: '', message: '' });
        // const proObj = this.props.location.pathname.split('/');
        this.state = {
            submissionId: this.props.location.state.submissionId,
            submissionType: this.props.location.state.submissionType,
            assessmentName: this.props.location.state.assessmentName,
            userId: this.props.location.state.userId,
            loading: true,
            spinner: false,
            saveNContinue: false,
            sectionEmpty: true,
            open: false,
            confirmAction: false,
            section: {
                id: "",
                sectionNumber: "",
                title: "",
                subTitle: "",
                description: "",
                sequence: "",
                createdAt: "",
                updatedAt: ""
            },
            // question1: {
            //     showFollowupQuestion: false,
            //     primaryQuestion:
            //         "Will your current donor(s) be supportive if your organization adopts income generating activities for delivering SRHR products and services?",
            //     primaryAnswer: false,
            //     secondaryQuestion: "If No, explain the concerns.",
            //     secondaryAnswer: "",
            //     weightFactor: "0.4",
            //     uploads: [],
            //     validated: false,
            //     feedBack: "",
            //     score: "",
            //     isScore: false
            // },
            // question2: {
            //     showFollowupQuestion: true,
            //     primaryQuestion:
            //         "Does your organization issue an annual financial statement, monthly income and expenditure statement and (if applicable) any profit and loss report(s)?",
            //     primaryAnswer: false,
            //     secondaryQuestion: "If No, specify the reasons.",
            //     secondaryAnswer: "",
            //     weightFactor: "0.2",
            //     uploads: [],
            //     validated: false,
            //     feedBack: "",
            //     score: "",
            //     isScore: false
            // },
            // question3: {
            //     showFollowupQuestion: false,
            //     primaryQuestion:
            //         "Does your organization charge clients for any activities/services rendered?",
            //     primaryAnswer: false,
            //     secondaryQuestion: "If Yes, specify the details.",
            //     secondaryAnswer: "",
            //     weightFactor: "0.1",
            //     uploads: [],
            //     validated: true,
            //     feedBack: "",
            //     score: "",
            //     isScore: false
            // },
            // question4: {
            //     showFollowupQuestion: false,
            //     primaryQuestion: "Does your organization have a procurement policy?",
            //     primaryAnswer: false,
            //     secondaryQuestion:
            //         "If Yes, please attach a copy of the policy. If No, specify the reasons.",
            //     secondaryAnswer: "",
            //     uploads: [],
            //     fileUrl: null,
            //     fileRef: null,
            //     tempFile: "",
            //     weightFactor: "0.1",
            //     isUploading: false,
            //     validated: false,
            //     feedBack: "",
            //     score: "",
            //     isScore: false
            // },
            // question5: {
            //     showFollowupQuestion: false,
            //     primaryQuestion:
            //         "Does your organization have a staff remuneration policy and salary scales?",
            //     primaryAnswer: false,
            //     secondaryQuestion:
            //         "If Yes, please attach the policy and salary scale guidelines. If No, specify the reasons.",
            //     secondaryAnswer: "",
            //     uploads: [],
            //     fileUrl: null,
            //     fileRef: null,
            //     tempFile: "",
            //     weightFactor: "0.2",
            //     isUploading: false,
            //     validated: false,
            //     feedBack: "",
            //     score: "",
            //     isScore: false
            // },
            // question6: {
            //     showFollowupQuestion: false,
            //     primaryQuestion:
            //         "Is your organization audited by an external auditor?",
            //     primaryAnswer: false,
            //     secondaryQuestion:
            //         "If Yes, specify the type of audits and frequency. If No, specify the reasons why not audited.",
            //     secondaryAnswer: "",
            //     weightFactor: "0.1",
            //     uploads: [],
            //     validated: false,
            //     feedBack: "",
            //     score: "",
            //     isScore: false
            // },
            // question7: {
            //     showFollowupQuestion: true,
            //     primaryQuestion:
            //         "As a statutory obligation, in what matters do the Board members get involved as signatories?",
            //     primaryAnswer: "",
            //     secondaryQuestion: "",
            //     secondaryAnswer: "",
            //     weightFactor: "-0.1",
            //     uploads: [],
            //     validated: false,
            //     feedBack: "",
            //     score: "",
            //     isScore: true
            // },
            // question8: {
            //     showFollowupQuestion: true,
            //     primaryQuestion:
            //         "Which financial institutions does your organization engage with (banks, savings societies, etc.)?",
            //     primaryAnswer: "",
            //     secondaryQuestion: "",
            //     secondaryAnswer: "",
            //     weightFactor: "-0.1",
            //     uploads: [],
            //     validated: false,
            //     feedBack: "",
            //     score: "",
            //     isScore: true
            // },
            // question9: {
            //     showFollowupQuestion: false,
            //     primaryQuestion:
            //         "Can your organization borrow working capital from the bank to finance the social enterprise/business idea?",
            //     primaryAnswer: false,
            //     secondaryQuestion: "",
            //     secondaryAnswer: "",
            //     weightFactor: "0.4",
            //     uploads: [],
            //     validated: true,
            //     feedBack: "",
            //     score: "",
            //     isScore: false
            // },
            // question10: {
            //     showFollowupQuestion: true,
            //     primaryQuestion:
            //         "What is the USD value of your Annual Programme Budget (APB) for the ongoing year?",
            //     primaryAnswer: "",
            //     secondaryQuestion: "",
            //     secondaryAnswer: "",
            //     weightFactor: "-0.2",
            //     uploads: [],
            //     validated: false,
            //     feedBack: "",
            //     score: "",
            //     isScore: true
            // },
            // question11: {
            //     showFollowupQuestion: true,
            //     primaryQuestion:
            //         "What is the USD value of the IPPF core grant (if applicable) for the ongoing year?",
            //     primaryAnswer: "",
            //     secondaryQuestion: "",
            //     secondaryAnswer: "",
            //     weightFactor: "-0.2",
            //     uploads: [],
            //     validated: false,
            //     feedBack: "",
            //     score: "",
            //     isScore: true
            // },
            // question12: {
            //     showFollowupQuestion: true,
            //     primaryQuestion:
            //         "What is the USD value of your (local) self-generated income for the last financial year?",
            //     primaryAnswer: "",
            //     secondaryQuestion: "",
            //     secondaryAnswer: "",
            //     weightFactor: "-0.4",
            //     uploads: [],
            //     validated: false,
            //     feedBack: "",
            //     score: "",
            //     isScore: true
            // },
            ...sectionTwoQuestions,
            sectionFeedBack: "",
            user: {
                firstName: "...",
                lastName: "...",
                email: "...",
                id: "...",
                createdAt: "...",
            },
            general: {
                fullName: "...",
                country: "...",
                region: "...",
                year: "...",
            },
        };
        props.setFormStatus({ status: false, title: "", message: "" });
        props.setSuccessStatus({ status: false, title: "", message: "" });
        this._getSection3();
        this._getSection3Submissions();
        this._getGenericProfile();
    }
    _getGenericProfile = () => {
        const { userId } = this.state;
        this.props.client.query({
            query: GENERIC_PROFILE,
            variables: { userId: userId }
        }).then(result => {
            this.setState({
                user: { ...result.data.getUserFullProfile.user },
                general: { ...result.data.getUserFullProfile.general },
                loading: false,
            })
        }).catch(error => {
            console.log(error);
            this.setState({ loading: false });
        });
    }
    componentDidMount() {
        window.scrollTo(0, 0)
        setTimeout(() => {
            this.setState({
                loading: false
            });
        }, 2000);
    }
    _goBack = () => {
        const { submissionId, submissionType, userId, assessmentName } = this.state;
        this.props.history.push({ pathname: "/submissions/review/section2", state: { submissionId: submissionId, submissionType: submissionType, userId: userId, assessmentName: assessmentName } });
    };

    _next = () => {
        this.setState({
            saveNContinue: true
        });
        this._submitSection(true);
    };

    _updateSection = () => {
        this.setState({
            spinner: true
        });
        this._submitSection(false);
    };

    _submitSection = (flag) => {
        this.props.setFormStatus({ status: false, title: '', message: '' });
        this.props.setSuccessStatus({ status: false, title: '', message: '' });
        const submission = {
            question1: this.state.question1,
            question2: this.state.question2,
            question3: this.state.question3,
            question4: this.state.question4,
            question5: this.state.question5,
            question6: this.state.question6,
            question7: this.state.question7,
            question8: this.state.question8,
            question9: this.state.question9,
            question10: this.state.question10,
            question11: this.state.question11,
            question12: this.state.question12,
            sectionFeedBack: this.state.sectionFeedBack
        };
        this._saveSection(submission, flag);
    };

    _saveSection = (submission, flag) => {
        const { submissionId, submissionType, userId, assessmentName } = this.state;
        this.props.client.mutate({
            mutation: UPDATE_USER_SECTION_1,
            variables: {
                submission: submission,
                sectionId: "ck0xh4o71pbyx0b17v0fmtjb4",
                submissionId: submissionId,
                submissionType: submissionType,
                userId: userId,
                actionType: flag
            }
        }).then(result => {
            this._getSection3Submissions();
            this.setState({
                spinner: false,
                saveNContinue: false,
            });

            this.props.setSuccessStatus({
                status: true,
                title: "",
                message: "Saved successfully!"
            });

            if (flag) {
                this.props.history.push({ pathname: "/submissions/review/section4", state: { submissionId: submissionId, submissionType: submissionType, userId: userId, assessmentName: assessmentName } });
            }

        }).catch(error => {
            this.setState({
                spinner: false,
                saveNContinue: false,
            });

            this.props.setFormStatus({
                status: true,
                title: "Oops!",
                message:
                    "There was an error while trying to update data"

            });
        });
    };

    _getSection3 = () => {
        this.props.client
            .query({
                query: GET_SECTION_II
            })
            .then(result => {
                this.setState({
                    section: { ...result.data.getSection1 }
                });
            })
            .catch(error => {
                console.log(error);
            });
    };

    _getSection3Submissions = () => {
        const { submissionId, submissionType } = this.state;
        this.props.client
            .query({
                query: GET_EDIT_SECTION_SUBMISSION,
                variables: { sectionId: "ck0xh4o71pbyx0b17v0fmtjb4", submissionId: submissionId },
                fetchPolicy: 'network-only'
            })
            .then(result => {
                console.log(result);
                if (result.data.getEditSectionSubmission === null || result.data.getEditSectionSubmission.length === 0) {
                    console.log('there is nothing here');
                } else {
                    // Apply values 
                    this.setState({
                        question1: result.data.getEditSectionSubmission.submission.question1
                            ? { ...result.data.getEditSectionSubmission.submission.question1, weightFactor: this.state.question1.weightFactor }
                            : this.state.question1,
                        question2: result.data.getEditSectionSubmission.submission.question2
                            ? { ...result.data.getEditSectionSubmission.submission.question2, weightFactor: this.state.question2.weightFactor }
                            : this.state.question2,
                        question3: result.data.getEditSectionSubmission.submission.question3
                            ? { ...result.data.getEditSectionSubmission.submission.question3, weightFactor: this.state.question3.weightFactor }
                            : this.state.question3,
                        question4: result.data.getEditSectionSubmission.submission.question4
                            ? { ...result.data.getEditSectionSubmission.submission.question4, weightFactor: this.state.question4.weightFactor }
                            : this.state.question4,
                        question5: result.data.getEditSectionSubmission.submission.question5
                            ? { ...result.data.getEditSectionSubmission.submission.question5, weightFactor: this.state.question5.weightFactor }
                            : this.state.question5,
                        question6: result.data.getEditSectionSubmission.submission.question6
                            ? { ...result.data.getEditSectionSubmission.submission.question6, weightFactor: this.state.question6.weightFactor }
                            : this.state.question6,
                        question7: result.data.getEditSectionSubmission.submission.question7
                            ? { ...result.data.getEditSectionSubmission.submission.question7, weightFactor: this.state.question7.weightFactor }
                            : this.state.question7,
                        question8: result.data.getEditSectionSubmission.submission.question8
                            ? { ...result.data.getEditSectionSubmission.submission.question8, weightFactor: this.state.question8.weightFactor }
                            : this.state.question8,
                        question9: result.data.getEditSectionSubmission.submission.question9
                            ? { ...result.data.getEditSectionSubmission.submission.question9, weightFactor: this.state.question9.weightFactor }
                            : this.state.question9,
                        question10: result.data.getEditSectionSubmission.submission.question10
                            ? { ...result.data.getEditSectionSubmission.submission.question10, weightFactor: this.state.question10.weightFactor }
                            : this.state.question10,
                        question11: result.data.getEditSectionSubmission.submission.question11
                            ? { ...result.data.getEditSectionSubmission.submission.question11, weightFactor: this.state.question11.weightFactor }
                            : this.state.question11,
                        question12: result.data.getEditSectionSubmission.submission.question12
                            ? { ...result.data.getEditSectionSubmission.submission.question12, weightFactor: this.state.question12.weightFactor }
                            : this.state.question12,
                        sectionFeedBack: result.data.getEditSectionSubmission.submission.sectionFeedBack ? result.data.getEditSectionSubmission.submission.sectionFeedBack : this.state.sectionFeedBack,
                        sectionEmpty: false
                    });
                }
            })
            .catch(error => {
                console.log(error);
            });
    };

    // Question1 Stuff
    _question1Followup = state => {
        state
            ? this.setState({
                question1: {
                    ...this.state.question1,
                    showFollowupQuestion: true,
                    primaryAnswer: true,
                    validated: true
                }
            })
            : this.setState({
                question1: {
                    ...this.state.question1,
                    showFollowupQuestion: false,
                    primaryAnswer: false,
                    validated: this.state.question1.secondaryAnswer.trim() === "" ? false : true
                }
            });
    };

    _question1Onchange = event => {
        this.setState({
            question1: {
                ...this.state.question1,
                [event.target.id]: event.target.value,
                validated: event.target.value.trim() === "" ? false : true
            }
        });
    };

    // Question 2 stuff
    _question2Followup = state => {
        state
            ? this.setState({
                question2: {
                    ...this.state.question2,
                    showFollowupQuestion: false,
                    primaryAnswer: true,
                    validated: true
                }
            })
            : this.setState({
                question2: {
                    ...this.state.question2,
                    showFollowupQuestion: true,
                    primaryAnswer: false,
                    validated: this.state.question2.secondaryAnswer.trim() === "" ? false : true
                }
            });
    };

    _question2Onchange = event => {
        this.setState({
            question2: {
                ...this.state.question2,
                secondaryAnswer: event.target.value,
                validated: event.target.value.trim() === "" ? false : true
            }
        });
    };

    // Question 3 stuff
    _question3Followup = state => {
        state
            ? this.setState({
                question3: {
                    ...this.state.question3,
                    showFollowupQuestion: true,
                    primaryAnswer: true,
                    validated: this.state.question3.secondaryAnswer.trim() === "" ? false : true
                }
            })
            : this.setState({
                question3: {
                    ...this.state.question3,
                    showFollowupQuestion: false,
                    primaryAnswer: false,
                    validated: true
                }
            });
    };

    _question3Onchange = event => {
        this.setState({
            question3: {
                ...this.state.question3,
                secondaryAnswer: event.target.value,
                validated: event.target.value.trim() === "" ? false : true
            }
        });
    };

    //Question 4 stuff
    _question4Followup = state => {
        state
            ? this.setState({
                question4: {
                    ...this.state.question4,
                    showFollowupQuestion: true,
                    primaryAnswer: true,
                    validated: (this.state.question4.fileUrl === null || this.state.question4.fileUrl === undefined) ? false : true
                }
            })
            : this.setState({
                question4: {
                    ...this.state.question4,
                    showFollowupQuestion: false,
                    primaryAnswer: false,
                    validated: this.state.question4.secondaryAnswer.trim() === "" ? false : true
                }
            });
    };
    _question4FileOnchange = event => {
        this.setState({
            question4: {
                ...this.state.question4,
                tempFile: event.target.files[0],
                isUploading: true,
            }
        });
        console.log(event.target.files[0]);
        console.log(this.state);
        this._startUpload(event.target.files[0]);
        this.setState({
            isUploading: true
        });
    };

    _startUpload = async file => {
        const result = await uploadFile(file, localStorage.USER_NAME);
        if (result.status) {
            this.setState({
                isUploading: false,
                question4: {
                    ...this.state.question4,
                    fileRef: 'uploads/' + localStorage.USER_NAME + '/' + file.name,
                    fileUrl: result.url,
                    isUploading: false,
                    validated: true
                }
            });
        }
    };

    _deleteFile = async (path) => {
        const deleteRef = await deleteFile(path);
        this.setState({
            question4: {
                ...this.state.question4,
                validated: false
            }
        });
        return deleteRef;
    }

    _question4Onchange = event => {
        this.setState({
            question4: {
                ...this.state.question4,
                secondaryAnswer: event.target.value,
                validated: event.target.value.trim() === "" ? false : true
            }
        });
    };

    // Question 5 stuff
    _question5Followup = state => {
        state
            ? this.setState({
                question5: {
                    ...this.state.question5,
                    showFollowupQuestion: true,
                    primaryAnswer: true,
                    validated: (this.state.question5.fileUrl === null || this.state.question5.fileUrl === undefined) ? false : true
                }
            })
            : this.setState({
                question5: {
                    ...this.state.question5,
                    showFollowupQuestion: false,
                    primaryAnswer: false,
                    validated: this.state.question5.secondaryAnswer.trim() === "" ? false : true
                }
            });
    };


    _question5Onchange = event => {
        this.setState({
            question5: {
                ...this.state.question5,
                secondaryAnswer: event.target.value,
                validated: event.target.value.trim() === "" ? false : true
            }
        });
    };

    _question5FileOnchange = event => {
        this.setState({
            question5: {
                ...this.state.question5,
                tempFile: event.target.files[0],
                isUploading: true,
            }
        });
        console.log(event.target.files[0]);
        console.log(this.state);
        this._startUpload5(event.target.files[0]);
        this.setState({
            isUploading: true
        });
    };

    _startUpload5 = async file => {
        const result = await uploadFile(file, localStorage.USER_NAME);
        if (result.status) {
            console.log(result.url);
            this.setState({
                isUploading: false,
                question5: {
                    ...this.state.question5,
                    fileRef: 'uploads/' + localStorage.USER_NAME + '/' + file.name,
                    fileUrl: result.url,
                    isUploading: false,
                    validated: true
                }
            });
        }
    };

    _deleteFile5 = async (path) => {
        const deleteRef = await deleteFile(this.state.question5.fileRef);
        this.setState({
            question5: {
                ...this.state.question5,
                validated: false
            }
        });
        return deleteRef;
    }


    //Question 6 stuff
    _question6Followup = state => {
        state
            ? this.setState({
                question6: {
                    ...this.state.question6,
                    showFollowupQuestion: true,
                    primaryAnswer: true,
                    validated: this.state.question6.secondaryAnswer.trim() === "" ? false : true
                }
            })
            : this.setState({
                question6: {
                    ...this.state.question6,
                    showFollowupQuestion: false,
                    primaryAnswer: false,
                    validated: this.state.question6.secondaryAnswer.trim() === "" ? false : true
                }
            });
    };
    _question6Onchange = event => {
        this.setState({
            question6: {
                ...this.state.question6,
                secondaryAnswer: event.target.value,
                validated: event.target.value.trim() === "" ? false : true
            }
        });
    };

    //Question 7 Stuff
    _question7Onchange = event => {
        this.setState({
            question7: {
                ...this.state.question7,
                primaryAnswer: event.target.value,
                validated: event.target.value.trim() === "" ? false : true
            }
        });
    };

    // Question 8 stuff
    _question8Onchange = event => {
        this.setState({
            question8: {
                ...this.state.question8,
                primaryAnswer: event.target.value,
                validated: event.target.value.trim() === "" ? false : true
            }
        });
    };

    // Question 9 stuff

    _question9Followup = state => {
        state
            ? this.setState({
                question9: {
                    ...this.state.question9,
                    showFollowupQuestion: true,
                    primaryAnswer: true
                }
            })
            : this.setState({
                question9: {
                    ...this.state.question9,
                    showFollowupQuestion: false,
                    primaryAnswer: false
                }
            });
    };

    // Question 10 stuff
    _qeustion10OnChange = event => {
        if (event.target.value < 0) {
            event.preventDefault();
        } else {
            this.setState({
                question10: {
                    ...this.state.question10,
                    primaryAnswer: event.target.value,
                    validated: event.target.value.trim() === "" ? false : true
                }
            });
        }
    };

    //Question 11 stuff
    _qeustion11OnChange = event => {
        if (event.target.value < 0) {
            event.preventDefault();
        } else {
            this.setState({
                question11: {
                    ...this.state.question11,
                    primaryAnswer: event.target.value,
                    validated: event.target.value.trim() === "" ? false : true
                }
            });
        }
    };
    validateQty = event => {
        console.log('s');
        return false;
        // var key = window.event ? event.keyCode : event.which;
        // if (event.keyCode == 8 || event.keyCode == 46
        //   || event.keyCode == 37 || event.keyCode == 39) {
        //   return true;
        // }
        // else if (key < 48 || key > 57) {
        //   return false;
        // }
        // else return true;
    }

    _qeustion12OnChange = event => {
        if (event.target.value < 0) {
            event.preventDefault();
        } else {
            this.setState({
                question12: {
                    ...this.state.question12,
                    primaryAnswer: event.target.value,
                    validated: event.target.value.trim() === "" ? false : true
                }
            });
        }
    };
    show = () => this.setState({ open: true })
    handleConfirm = () => this.setState({ confirmAction: true, open: false })
    handleCancel = () => this.setState({ confirmAction: false, open: false })
    _pagi = (path) => {
        const { submissionId, submissionType, userId, assessmentName } = this.state;
        this.props.history.push({ pathname: '/submissions/review/'.concat(path), state: { submissionId: submissionId, submissionType: submissionType, userId: userId, assessmentName: assessmentName } });
    }

    _feedBackHandler = (event, name) => {
        this.setState({
            [name]: {
                ...this.state[name],
                feedBack: event.target.value

            }
        });
    }

    _scoreHandler = (event, name) => {
        if (event.target.value > 100 || event.target.value < 0) {
            // if (event.target.value > 10 || event.target.value < 0 || event.target.value.length > 2 || event.target.value === "00") {
            event.preventDefault();
        } else {
            this.setState({
                [name]: {
                    ...this.state[name],
                    score: event.target.value

                }
            });
        }
    }

    isEmpty(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    _sectionFeedBackHandler = event => {
        this.setState({ [event.target.id]: event.target.value });
    }

    render() {
        const { formErrorStatus, formSuccessState, t, i18n } = this.props;
        const { open, confirmAction } = this.state
        return (
            <div style={{ backgroundColor: "#fff" }} class="remove-disabled">
                <div>
                    <div class="uk-background-muted uk-margin-top uk-padding" style={{ paddingBottom: "15px", paddingTop: "15px" }}>
                        <div class="uk-grid" uk-grid>
                            <div class="uk-width-expand@m">
                                <form class=" uk-flex-inline uk-flex-middle uk-text-small">
                                    <h4 class="uk-margin-remove-bottom uk-margin-right">
                                        {" "}
                                        {this.state.general.fullName}
                                    </h4>
                                </form>
                                <h6 class="uk-margin-remove-top uk-text-left">
                                    {" "}
                                    {this.state.general.country}, {this.state.general.region}
                                </h6>
                            </div>
                            <div class="uk-width-auto@m uk-text-small">
                                <p class="uk-text-right uk-margin-remove-bottom"> {t("Submitted by")}: <strong> {this.state.user.firstName} {this.state.user.lastName} </strong> </p>
                                <p class="uk-text-right uk-margin-remove-top uk-margin-remove-bottom"> {t("Assessment name")}: <strong>{this.state.assessmentName} </strong></p>
                                <p class="uk-text-right uk-margin-remove-top uk-margin-remove-bottom">{t("Account created on")}: {moment(this.state.user.createdAt).format('DD-MMMM-YYYY')}</p>
                            </div>
                        </div>
                    </div>
                    {/* <div class="uk-background-muted" style={{ paddingRight: "40px", paddingBottom: "20px" }}>
                        <div class="uk-width-auto@m uk-text-small">

                        </div>
                    </div> */}
                </div>
                {/* <div class="uk-background-muted" style={{ paddingRight: "40px", paddingBottom: "20px" }}>
                    <div class="uk-width-auto@m uk-text-small">
                        <p class="uk-text-right uk-margin-remove-top" style={{ color: result_color }}><b>{result_status}: {score}</b></p>

                    </div>
                </div> */}
                {this.state.loading ? (
                    <SectionLoader />
                ) : (
                    <div>
                        {/* Begin Dark Header */}
                        <div className="uk-section-default uk-section" style={{ paddingTop: "10px" }}>
                            <div className="uk-container-small uk-margin-auto" style={{ marginTop: "10px" }}>
                                <h3 className=" uk-text-light">
                                    {" "}
                                    {t("Section")} {this.state.section.sectionNumber}{" "}
                                    {/* {this.state.section.title}{" "} */}
                                </h3>
                            </div>
                            {/* <hr /> */}

                            <div
                                className="uk-container-small uk-margin-auto uk-margin-small-top uk-padding-small paper-read"
                                uk-scrollspy="target: > .paper; cls:uk-animation-slide-bottom-small; delay: 200"
                            >
                                <div className="paper uk-link-reset" style={{ backgroundColor: "#84329B" }} >
                                    <div className="uk-grid uk-flex-middle">
                                        <div className="uk-width-1-6 uk-flex-first uk-text-center">
                                            <i className="far fa-comment-alt icon-xxlarge"></i>
                                        </div>
                                        <div className="uk-width-5-6">
                                            <h4 className="uk-margin-remove" style={{ color: "#ffffff" }} >
                                                {" "}
                                                {t(this.state.section.title)}{" "}
                                            </h4>
                                            {/* <p className="uk-margin-remove">
                          {" "}
                          {this.state.section.description}{" "}
                        </p> */}
                                        </div>
                                    </div>
                                </div>
                                <Form success error className="uk-margin">
                                    {/* Begin Question 1 */}
                                    <div className="uk-link-reset paper uk-margin s1q1">
                                        <h4>
                                            <b>7)</b>{" "}
                                            {t("Will your current donor(s) be supportive if your organization adopts income generating activities for delivering SRHR products and services?")}{" "}
                                        </h4>
                                        {localStorage.RAT_LANG == 'ar' ? (
                                            <Button.Group size="large" className="buttons_arabic_style" >
                                                
                                              
                                                <Button disabled positive={this.state.question1.showFollowupQuestion ? false : true} onClick={() => this._question1Followup(false)}>
                                                    {t("NO")}
                                                </Button>
                                                {/* <Button.Or /> */}
                                                <div class="or" data-text={localStorage.RAT_LANG=='sp'? "o" : localStorage.RAT_LANG=='fr'? "ou" : localStorage.RAT_LANG=='ar'? "أو": "or"}></div>
                                                <Button disabled positive={this.state.question1.showFollowupQuestion ? true : false} onClick={() => this._question1Followup(true)}>
                                                    {t("YES")}
                                                </Button>
                                            </Button.Group>
                                        ) : (
                                            <Button.Group size="large" >
                                                <Button disabled positive={this.state.question1.showFollowupQuestion ? true : false} onClick={() => this._question1Followup(true)}>
                                                    {t("YES")}
                                                </Button>
                                                {/* <Button.Or /> */}
                                                <div class="or" data-text={localStorage.RAT_LANG=='sp'? "o" : localStorage.RAT_LANG=='fr'? "ou" : localStorage.RAT_LANG=='ar'? "أو": "or"}></div>
                                                <Button disabled positive={this.state.question1.showFollowupQuestion ? false : true} onClick={() => this._question1Followup(false)}>
                                                    {t("NO")}
                                                </Button>
                                            </Button.Group>
                                        )}

                                        {this.state.question1.showFollowupQuestion ? (
                                            ""
                                        ) : (
                                            <div>
                                                <div className="uk-form-label">
                                                    {t("If No, explain the concerns.")}
                                                </div>
                                                <div className="uk-margin">
                                                    <span className="uk-form-icon">
                                                        <i className="far fa-User icon-medium"></i>
                                                    </span>

                                                    <TextArea
                                                        onChange={this._question1Onchange}
                                                        name="secondaryAnswer"
                                                        id="secondaryAnswer"
                                                        value={this.state.question1.secondaryAnswer}
                                                        className="text-area-height uk-input uk-form-width-large"
                                                        placeholder={t("Describe here")}
                                                        type="text"
                                                        disabled
                                                    />
                                                </div>

                                            </div>
                                        )}
                                        <div className="uk-form-label">{t("Comments/Feedback from SE Hub")}</div>
                                        <div>
                                            <span className="uk-form-icon">
                                                <i className="far fa-User icon-medium"></i>
                                            </span>

                                            <TextArea
                                                onChange={(e) => this._feedBackHandler(e, "question1")}
                                                name="question1feedBack"
                                                id="question1feedBack"
                                                value={this.state.question1.feedBack || ""}
                                                className="uk-textarea"
                                                placeholder={t("Comments/Feedback here")}
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                    {/* End Question 1 */}

                                    {/* Begin Question2 */}
                                    <div className="uk-link-reset paper uk-margin s1q1">
                                        <h4>
                                            <b>8)</b>{" "}
                                            {t("Does your organization issue an annual financial statement, monthly income and expenditure statement and (if applicable) any profit and loss report(s)?")}{" "}
                                        </h4>
                                        {localStorage.RAT_LANG=='ar'? (
                                             <Button.Group size="large" className="buttons_arabic_style">
                                            
                                             
                                             <Button disabled positive={this.state.question2.showFollowupQuestion ? true : false} onClick={() => this._question2Followup(false)}>
                                                 {t("NO")}
                                             </Button>
                                             {/* <Button.Or /> */}
                                             <div class="or" data-text={localStorage.RAT_LANG=='sp'? "o" : localStorage.RAT_LANG=='fr'? "ou" : localStorage.RAT_LANG=='ar'? "أو": "or"}></div>
                                             <Button disabled positive={this.state.question2.showFollowupQuestion ? false : true} onClick={() => this._question2Followup(true)}>
                                                 {t("YES")}
                                             </Button>
                                         </Button.Group>
                                        ):(
                                            <Button.Group size="large" >
                                            <Button disabled positive={this.state.question2.showFollowupQuestion ? false : true} onClick={() => this._question2Followup(true)}>
                                                {t("YES")}
                                            </Button>
                                            {/* <Button.Or /> */}
                                            <div class="or" data-text={localStorage.RAT_LANG=='sp'? "o" : localStorage.RAT_LANG=='fr'? "ou" : localStorage.RAT_LANG=='ar'? "أو": "or"}></div>
                                            <Button disabled positive={this.state.question2.showFollowupQuestion ? true : false} onClick={() => this._question2Followup(false)}>
                                                {t("NO")}
                                            </Button>
                                        </Button.Group>
                                        )}
                                       
                                        {this.state.question2.showFollowupQuestion ? (
                                            <div>
                                                <div className="uk-form-label">
                                                    {t("If No, specify the reasons.")}
                                                </div>
                                                <div className="uk-margin">
                                                    <span className="uk-form-icon">
                                                        <i className="far fa-User icon-medium"></i>
                                                    </span>

                                                    <TextArea
                                                        onChange={this._question2Onchange}
                                                        name="q2secondaryAnswer"
                                                        id="q2secondaryAnswer"
                                                        value={this.state.question2.secondaryAnswer}
                                                        className="text-area-height uk-input uk-form-width-large"
                                                        placeholder={t("Describe here")}
                                                        type="text"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        <div className="uk-form-label">{t("Comments/Feedback from SE Hub")}</div>
                                        <div>
                                            <span className="uk-form-icon">
                                                <i className="far fa-User icon-medium"></i>
                                            </span>

                                            <TextArea
                                                onChange={(e) => this._feedBackHandler(e, "question2")}
                                                name="question2feedBack"
                                                id="question2feedBack"
                                                value={this.state.question2.feedBack || ""}
                                                className="uk-textarea"
                                                placeholder={t("Comments/Feedback here")}
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                    {/* End Question 2 */}

                                    {/* Begin Question 3 */}

                                    <div className="uk-link-reset paper uk-margin s1q1">
                                        <h4>
                                            <b>9)</b>{" "}
                                            {t("Does your organization charge clients for any activities/services rendered?")}{" "}
                                        </h4>
                                        {localStorage.RAT_LANG=='ar'? (
                                            <Button.Group size="large" className="buttons_arabic_style" >
                                            
                                            
                                            <Button disabled positive={this.state.question3.showFollowupQuestion ? false : true} onClick={() => this._question3Followup(false)}>
                                                {t("NO")}
                                            </Button>
                                            {/* <Button.Or /> */}
                                            <div class="or" data-text={localStorage.RAT_LANG=='sp'? "o" : localStorage.RAT_LANG=='fr'? "ou" : localStorage.RAT_LANG=='ar'? "أو": "or"}></div>
                                            <Button disabled positive={this.state.question3.showFollowupQuestion ? true : false} onClick={() => this._question3Followup(true)}>
                                                {t("YES")}
                                            </Button>
                                        </Button.Group>
                                        ):(
                                            <Button.Group size="large">
                                            <Button disabled positive={this.state.question3.showFollowupQuestion ? true : false} onClick={() => this._question3Followup(true)}>
                                                {t("YES")}
                                            </Button>
                                            {/* <Button.Or /> */}
                                            <div class="or" data-text={localStorage.RAT_LANG=='sp'? "o" : localStorage.RAT_LANG=='fr'? "ou" : localStorage.RAT_LANG=='ar'? "أو": "or"}></div>
                                            <Button disabled positive={this.state.question3.showFollowupQuestion ? false : true} onClick={() => this._question3Followup(false)}>
                                                {t("NO")}
                                            </Button>
                                        </Button.Group>
                                        )}
                                        
                                        {this.state.question3.showFollowupQuestion ? (
                                            <div>
                                                <div className="uk-form-label">
                                                    {t("If Yes, specify the details.")}
                                                </div>
                                                <div className="uk-margin">
                                                    <span className="uk-form-icon">
                                                        <i className="far fa-User icon-medium"></i>
                                                    </span>

                                                    <TextArea
                                                        onChange={this._question3Onchange}
                                                        name="q3secondaryAnswer"
                                                        id="q3secondaryAnswer"
                                                        value={this.state.question3.secondaryAnswer}
                                                        className="text-area-height uk-input uk-form-width-large"
                                                        placeholder={t("Describe here")}
                                                        type="text"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        <div className="uk-form-label">{t("Comments/Feedback from SE Hub")}</div>
                                        <div>
                                            <span className="uk-form-icon">
                                                <i className="far fa-User icon-medium"></i>
                                            </span>

                                            <TextArea
                                                onChange={(e) => this._feedBackHandler(e, "question3")}
                                                name="question3feedBack"
                                                id="question3feedBack"
                                                value={this.state.question3.feedBack || ""}
                                                className="uk-textarea"
                                                placeholder={t("Comments/Feedback here")}
                                                type="text"
                                            />
                                        </div>
                                    </div>

                                    {/* End Question 3 */}

                                    {/* Begin Question 4 */}
                                    <div className="uk-link-reset paper uk-margin s1q1">
                                        <h4><b>10)</b> {t("Does your organization have a procurement policy?")} </h4>
                                        {localStorage.RAT_LANG=='ar'? (
                                            <Button.Group size="large" className="buttons_arabic_style" >
                                            
                                           
                                            <Button disabled positive={this.state.question4.showFollowupQuestion ? false : true} onClick={() => this._question4Followup(false)}>
                                                {t("NO")}
                                            </Button>
                                            {/* <Button.Or /> */}
                                            <div class="or" data-text={localStorage.RAT_LANG=='sp'? "o" : localStorage.RAT_LANG=='fr'? "ou" : localStorage.RAT_LANG=='ar'? "أو": "or"}></div>
                                            <Button disabled positive={this.state.question4.showFollowupQuestion ? true : false} onClick={() => this._question4Followup(true)}>
                                                {t("YES")}
                                            </Button>
                                        </Button.Group>
                                        ):(
                                            <Button.Group size="large">
                                            <Button disabled positive={this.state.question4.showFollowupQuestion ? true : false} onClick={() => this._question4Followup(true)}>
                                                {t("YES")}
                                            </Button>
                                            {/* <Button.Or /> */}
                                            <div class="or" data-text={localStorage.RAT_LANG=='sp'? "o" : localStorage.RAT_LANG=='fr'? "ou" : localStorage.RAT_LANG=='ar'? "أو": "or"}></div>
                                            <Button disabled positive={this.state.question4.showFollowupQuestion ? false : true} onClick={() => this._question4Followup(false)}>
                                                {t("NO")}
                                            </Button>
                                        </Button.Group>
                                        )}
                                        
                                        {this.state.question4.showFollowupQuestion ? (
                                            <div>
                                                <div className="uk-form-label">
                                                    {t("If Yes, please attach a copy of the policy.")}
                                                </div>
                                                <div className="uk-margin">
                                                    <span className="uk-form-icon">
                                                        <i className="far fa-User icon-medium"></i>
                                                    </span>

                                                    {/* File Component */}
                                                    {this.state.question4.fileUrl !== null && this.state.question4.fileUrl !== undefined ?
                                                        (
                                                            <div className="file-upload-group" >
                                                                <Popup
                                                                    trigger={<Button className="file-icon" circular icon="attach" />}
                                                                    flowing
                                                                    hoverable
                                                                >
                                                                    <Grid centered divided columns={2}>
                                                                        <Grid.Column textAlign="center">
                                                                            <Button circular icon='download' onClick={() => { window.open(this.state.question4.fileUrl, '_blank'); }} />

                                                                        </Grid.Column>
                                                                        <Grid.Column textAlign="center">
                                                                            <Button disabled circular icon='trash alternate outline' onClick={() => {
                                                                                this._deleteFile(this.state.question4.fileRef).then(result => {
                                                                                    // console.log(result);
                                                                                    this.setState({
                                                                                        question4: {
                                                                                            ...this.state.question4,
                                                                                            fileUrl: null,
                                                                                            fileRef: null,
                                                                                        }
                                                                                    });
                                                                                });
                                                                            }} />
                                                                        </Grid.Column>
                                                                    </Grid>
                                                                </Popup>
                                                            </div>
                                                        ) : (
                                                            <Input
                                                                onChange={this._question4FileOnchange}
                                                                name="q4upload"
                                                                id="q4upload"
                                                                value={this.state.question4.uploads}
                                                                className="text-area-height uk-input uk-form-width-large"
                                                                placeholder={t("Upload here")}
                                                                type="file"
                                                                disabled
                                                            />
                                                        )
                                                    }

                                                    {/* File Component */}


                                                </div>
                                                {this.state.question4.isUploading ? (
                                                    <Message icon>
                                                        <Icon name="circle notched" loading />
                                                        <Message.Content>
                                                            <Message.Header>{t("Just a moment…")}</Message.Header>
                                                            {t("We are uploading your document.")}
                                                        </Message.Content>
                                                    </Message>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        ) : (
                                            <div>
                                                <div className="uk-form-label">
                                                    {t("If No, specify the reasons.")}
                                                </div>
                                                <div className="uk-margin">
                                                    <span className="uk-form-icon">
                                                        <i className="far fa-User icon-medium"></i>
                                                    </span>

                                                    <TextArea
                                                        onChange={this._question4Onchange}
                                                        name="q4secondaryAnswer"
                                                        id="q4secondaryAnswer"
                                                        value={this.state.question4.secondaryAnswer}
                                                        className="text-area-height uk-input uk-form-width-large"
                                                        placeholder={t("Describe here")}
                                                        type="text"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        <div className="uk-form-label">{t("Comments/Feedback from SE Hub")}</div>
                                        <div>
                                            <span className="uk-form-icon">
                                                <i className="far fa-User icon-medium"></i>
                                            </span>

                                            <TextArea
                                                onChange={(e) => this._feedBackHandler(e, "question4")}
                                                name="question4feedBack"
                                                id="question4feedBack"
                                                value={this.state.question4.feedBack || ""}
                                                className="uk-textarea"
                                                placeholder={t("Comments/Feedback here")}
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                    {/* End Question 4 */}

                                    {/* Begin Question 5 */}
                                    <div className="uk-link-reset paper uk-margin s1q1">
                                        <h4><b>11)</b> {t("Does your organization have a staff remuneration policy and salary scales?")}{" "} </h4>
                                        {localStorage.RAT_LANG=='ar'? (
                                             <Button.Group size="large" className="buttons_arabic_style" >
                                             
                                             
                                             <Button disabled positive={this.state.question5.showFollowupQuestion ? false : true} onClick={() => this._question5Followup(false)}>
                                                 {t("NO")}
                                             </Button>
                                             {/* <Button.Or /> */}
                                             <div class="or" data-text={localStorage.RAT_LANG=='sp'? "o" : localStorage.RAT_LANG=='fr'? "ou" : localStorage.RAT_LANG=='ar'? "أو": "or"}></div>
                                             <Button disabled positive={this.state.question5.showFollowupQuestion ? true : false} onClick={() => this._question5Followup(true)}>
                                                 {t("YES")}
                                             </Button>
                                         </Button.Group>
                                        ):(
                                             <Button.Group size="large" >
                                             <Button disabled positive={this.state.question5.showFollowupQuestion ? true : false} onClick={() => this._question5Followup(true)}>
                                                 {t("YES")}
                                             </Button>
                                             {/* <Button.Or /> */}
                                             <div class="or" data-text={localStorage.RAT_LANG=='sp'? "o" : localStorage.RAT_LANG=='fr'? "ou" : localStorage.RAT_LANG=='ar'? "أو": "or"}></div>
                                             <Button disabled positive={this.state.question5.showFollowupQuestion ? false : true} onClick={() => this._question5Followup(false)}>
                                                 {t("NO")}
                                             </Button>
                                         </Button.Group>
                                        )}
                                       
                                        {this.state.question5.showFollowupQuestion ? (
                                            <div>
                                                <div className="uk-form-label">
                                                    {t("If Yes, please attach the policy and salary scale guidelines.")}
                                                </div>
                                                <div className="uk-margin">
                                                    <span className="uk-form-icon">
                                                        <i className="far fa-User icon-medium"></i>
                                                    </span>

                                                    {/* File Component */}
                                                    {this.state.question5.fileUrl !== null && this.state.question5.fileUrl !== undefined ?
                                                        (
                                                            <div className="file-upload-group" >
                                                                <Popup
                                                                    trigger={<Button className="file-icon" circular icon="attach" />}
                                                                    flowing
                                                                    hoverable
                                                                >
                                                                    <Grid centered divided columns={2}>
                                                                        <Grid.Column textAlign="center">
                                                                            <Button circular icon='download' onClick={() => { window.open(this.state.question5.fileUrl, '_blank'); }} />

                                                                        </Grid.Column>
                                                                        <Grid.Column textAlign="center">
                                                                            <Button disabled circular icon='trash alternate outline' onClick={() => {
                                                                                this._deleteFile5(this.state.question5.fileRef).then(result => {
                                                                                    this.setState({
                                                                                        question5: {
                                                                                            ...this.state.question5,
                                                                                            fileUrl: null,
                                                                                            fileRef: null,
                                                                                        }
                                                                                    });
                                                                                });
                                                                                // this.setState({
                                                                                //   question4: {
                                                                                //     ...this.state.question4,
                                                                                //     fileRef: null,
                                                                                //     fileUrl: null,
                                                                                //   }
                                                                                // })
                                                                            }} />

                                                                        </Grid.Column>
                                                                    </Grid>
                                                                </Popup>
                                                            </div>
                                                        ) : (
                                                            <Input
                                                                onChange={this._question5FileOnchange}
                                                                name="q5upload"
                                                                id="q5upload"
                                                                value={this.state.question5.uploads}
                                                                className="text-area-height uk-input uk-form-width-large"
                                                                placeholder={t("Upload here")}
                                                                type="file"
                                                                disabled
                                                            />
                                                        )
                                                    }

                                                    {/* File Component */}


                                                </div>
                                                {this.state.question5.isUploading ? (
                                                    <Message icon>
                                                        <Icon name="circle notched" loading />
                                                        <Message.Content>
                                                            <Message.Header>{t("Just a moment…")}</Message.Header>
                                                            {t("We are uploading your document.")}
                                                        </Message.Content>
                                                    </Message>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        ) : (
                                            <div>
                                                <div className="uk-form-label">
                                                    {t("If No, specify the reasons.")}
                                                </div>
                                                <div className="uk-margin">
                                                    <span className="uk-form-icon">
                                                        <i className="far fa-User icon-medium"></i>
                                                    </span>

                                                    <TextArea
                                                        onChange={this._question5Onchange}
                                                        name="q5secondaryAnswer"
                                                        id="q5secondaryAnswer"
                                                        value={this.state.question5.secondaryAnswer}
                                                        className="text-area-height uk-input uk-form-width-large"
                                                        placeholder={t("Describe here")}
                                                        type="text"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        <div className="uk-form-label">{t("Comments/Feedback from SE Hub")}</div>
                                        <div>
                                            <span className="uk-form-icon">
                                                <i className="far fa-User icon-medium"></i>
                                            </span>

                                            <TextArea
                                                onChange={(e) => this._feedBackHandler(e, "question5")}
                                                name="question5feedBack"
                                                id="question5feedBack"
                                                value={this.state.question5.feedBack || ""}
                                                className="uk-textarea"
                                                placeholder={t("Comments/Feedback here")}
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                    {/* End Question 5 */}

                                    {/* Begin Question 6 */}
                                    <div className="uk-link-reset paper uk-margin s1q1">
                                        <h4>
                                            <b>12)</b>{" "}
                                            {t("Is your organization audited by an external auditor?")}{" "}
                                        </h4>
                                        {localStorage.RAT_LANG=='ar'? (
                                            <Button.Group size="large" className="buttons_arabic_style">
                                
                                            <Button disabled positive={this.state.question6.showFollowupQuestion ? false : true} onClick={() => this._question6Followup(false)}>
                                                {t("NO")}
                                            </Button>
                                            {/* <Button.Or /> */}
                                            <div class="or" data-text={localStorage.RAT_LANG=='sp'? "o" : localStorage.RAT_LANG=='fr'? "ou" : localStorage.RAT_LANG=='ar'? "أو": "or"}></div>
                                            <Button disabled positive={this.state.question6.showFollowupQuestion ? true : false} onClick={() => this._question6Followup(true)}>
                                                {t("YES")}
                                            </Button>
                                        </Button.Group>
                                        ):(
                                            <Button.Group size="large">
                                            <Button disabled positive={this.state.question6.showFollowupQuestion ? true : false} onClick={() => this._question6Followup(true)}>
                                                {t("YES")}
                                            </Button>
                                            {/* <Button.Or /> */}
                                            <div class="or" data-text={localStorage.RAT_LANG=='sp'? "o" : localStorage.RAT_LANG=='fr'? "ou" : localStorage.RAT_LANG=='ar'? "أو": "or"}></div>
                                            <Button disabled positive={this.state.question6.showFollowupQuestion ? false : true} onClick={() => this._question6Followup(false)}>
                                                {t("NO")}
                                            </Button>
                                        </Button.Group>
                                        )}
                                        
                                        {this.state.question6.showFollowupQuestion ? (
                                            <div>
                                                <div className="uk-form-label">
                                                    {t("If Yes, specify the type of audits and frequency.")}
                                                </div>
                                                <div className="uk-margin">
                                                    <span className="uk-form-icon">
                                                        <i className="far fa-User icon-medium"></i>
                                                    </span>

                                                    <TextArea
                                                        onChange={this._question6Onchange}
                                                        name="q6secondaryAnswer"
                                                        id="q6secondaryAnswer"
                                                        value={this.state.question6.secondaryAnswer}
                                                        className="text-area-height uk-input uk-form-width-large"
                                                        placeholder={t("Describe here")}
                                                        type="text"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                <div className="uk-form-label">
                                                    {t("If No, specify the reasons why not audited.")}
                                                </div>
                                                <div className="uk-margin">
                                                    <span className="uk-form-icon">
                                                        <i className="far fa-User icon-medium"></i>
                                                    </span>

                                                    <TextArea
                                                        onChange={this._question6Onchange}
                                                        name="q6secondaryAnswer"
                                                        id="q6secondaryAnswer"
                                                        value={this.state.question6.secondaryAnswer}
                                                        className="text-area-height uk-input uk-form-width-large"
                                                        placeholder={t("Describe here")}
                                                        type="text"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        <div className="uk-form-label">{t("Comments/Feedback from SE Hub")}</div>
                                        <div>
                                            <span className="uk-form-icon">
                                                <i className="far fa-User icon-medium"></i>
                                            </span>

                                            <TextArea
                                                onChange={(e) => this._feedBackHandler(e, "question6")}
                                                name="question6feedBack"
                                                id="question6feedBack"
                                                value={this.state.question6.feedBack || ""}
                                                className="uk-textarea"
                                                placeholder={t("Comments/Feedback here")}
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                    {/* End Question 6 */}

                                    {/* Begin Question 7 */}
                                    <div className="uk-link-reset paper uk-margin s1q1">
                                        <h4>
                                            <b>13)</b>{" "}
                                            {t("As a statutory obligation, in what matters do the Board members get involved as signatories?")}{" "}
                                        </h4>
                                        <p class="uk-text-muted uk-margin-remove-top uk-margin-small-bottom">{t("Please answer and support the response with additional details.")}</p>
                                        <div>
                                            <div className="uk-form-label"></div>
                                            <div className="uk-margin">
                                                <span className="uk-form-icon">
                                                    <i className="far fa-User icon-medium"></i>
                                                </span>

                                                <TextArea
                                                    onChange={this._question7Onchange}
                                                    name="q6primaryAnswer"
                                                    id="q6primaryAnswer"
                                                    value={this.state.question7.primaryAnswer}
                                                    className="text-area-height uk-input uk-form-width-large"
                                                    placeholder={t("Describe here")}
                                                    type="text"
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="uk-form-label">{t("Score given")}</div>
                                        <div>
                                            <span className="uk-form-icon">
                                                <i className="far fa-User icon-medium"></i>
                                            </span>

                                            {/* <input
                                                    type="number"
                                                    placeholder="Enter score here"
                                                    id="question7score"
                                                    name="question7score"
                                                    value={this.state.question7.score || ""}
                                                    maxlength="2"
                                                    onChange={(e) => this._scoreHandler(e, "question7")}
                                                    style={{ width: "20%" }}
                                                    onKeyDown={(e) => {
                                                        if (/[\+\-\.\,]/.test(e.key) || e.key.toUpperCase() === "E") {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                /> */}
                                            <Input
                                                style={{ width: "180px" }}
                                                labelPosition={localStorage.RAT_LANG == 'ar' ? "left" : "right"}
                                                type="number"
                                                placeholder={t("Enter score here")}
                                                id="question7score"
                                                name="question7score"
                                                value={this.isEmpty(this.state.question7.score) ? (
                                                    null
                                                ) : (
                                                    this.state.question7.score
                                                )}
                                                onChange={(e) => this._scoreHandler(e, "question7")}
                                                onKeyDown={(e) => {
                                                    if (/[\+\-\.\,]/.test(e.key) || e.key.toUpperCase() === "E") {
                                                        e.preventDefault();
                                                    }
                                                }}>
                                                <input />
                                                <Label basic>%</Label>
                                            </Input>
                                        </div>
                                        <div className="uk-form-label">{t("Comments/Feedback from SE Hub")}</div>
                                        <div>
                                            <span className="uk-form-icon">
                                                <i className="far fa-User icon-medium"></i>
                                            </span>

                                            <TextArea
                                                onChange={(e) => this._feedBackHandler(e, "question7")}
                                                name="question7feedBack"
                                                id="question7feedBack"
                                                value={this.state.question7.feedBack || ""}
                                                className="uk-textarea"
                                                placeholder={t("Comments/Feedback here")}
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                    {/* End Question 7 */}

                                    {/* Begin Question 8 */}
                                    <div className="uk-link-reset paper uk-margin s1q1">
                                        <h4>
                                            <b>14)</b>{" "}
                                            {t("Which financial institutions does your organization engage with (banks, savings societies, etc.)?")}{" "}
                                        </h4>

                                        <div>
                                            <div className="uk-form-label"></div>
                                            <div className="uk-margin">
                                                <span className="uk-form-icon">
                                                    <i className="far fa-User icon-medium"></i>
                                                </span>
                                                <TextArea
                                                    onChange={this._question8Onchange}
                                                    name="q8primaryAnswer"
                                                    id="q8primaryAnswer"
                                                    value={this.state.question8.primaryAnswer}
                                                    className="text-area-height uk-input uk-form-width-large"
                                                    placeholder={t("Specify the types and names of the financial institutions.")}
                                                    type="text"
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="uk-form-label">{t("Score given")}</div>
                                        <div>
                                            <span className="uk-form-icon">
                                                <i className="far fa-User icon-medium"></i>
                                            </span>

                                            {/* <input
                                                    type="number"
                                                    placeholder="Enter score here"
                                                    id="question8score"
                                                    name="question8score"
                                                    value={this.state.question8.score || ""}
                                                    maxlength="2"
                                                    onChange={(e) => this._scoreHandler(e, "question8")}
                                                    style={{ width: "20%" }}
                                                    onKeyDown={(e) => {
                                                        if (/[\+\-\.\,]/.test(e.key) || e.key.toUpperCase() === "E") {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                /> */}
                                            <Input
                                                style={{ width: "180px" }}
                                                labelPosition={localStorage.RAT_LANG == 'ar' ? "left" : "right"}
                                                type="number"
                                                placeholder={t("Enter score here")}
                                                id="question8score"
                                                name="question8score"
                                                value={this.isEmpty(this.state.question8.score) ? (
                                                    null
                                                ) : (
                                                    this.state.question8.score
                                                )}
                                                onChange={(e) => this._scoreHandler(e, "question8")}
                                                onKeyDown={(e) => {
                                                    if (/[\+\-\.\,]/.test(e.key) || e.key.toUpperCase() === "E") {
                                                        e.preventDefault();
                                                    }
                                                }}>
                                                <input />
                                                <Label basic>%</Label>
                                            </Input>
                                        </div>
                                        <div className="uk-form-label">{t("Comments/Feedback from SE Hub")}</div>
                                        <div>
                                            <span className="uk-form-icon">
                                                <i className="far fa-User icon-medium"></i>
                                            </span>

                                            <TextArea
                                                onChange={(e) => this._feedBackHandler(e, "question8")}
                                                name="question8feedBack"
                                                id="question8feedBack"
                                                value={this.state.question8.feedBack || ""}
                                                className="uk-textarea"
                                                placeholder={t("Comments/Feedback here")}
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                    {/* End Question 8 */}

                                    {/* Begin Question 9 */}
                                    <div className="uk-link-reset paper uk-margin s1q1">
                                        <h4>
                                            <b>15)</b>{" "}
                                            {t("Can your organization borrow working capital from the bank to finance the social enterprise/business idea?")}{" "}
                                        </h4>
                                        <div>
                                            <div className="uk-form-label"></div>
                                            <div className="uk-margin">
                                                <span className="uk-form-icon">
                                                    <i className="far fa-User icon-medium"></i>
                                                </span>
                                                {localStorage.RAT_LANG=='ar'? (
                                                     <Button.Group size="large" className="buttons_arabic_style" >
                                                    
                                                    
                                                     <Button
                                                         disabled
                                                         positive={this.state.question9.showFollowupQuestion ? false : true}
                                                         onClick={() => this._question9Followup(false)}
                                                     >
                                                         {t("NO")}
                                                     </Button>
                                                     {/* <Button.Or /> */}
                                                     <div class="or" data-text={localStorage.RAT_LANG=='sp'? "o" : localStorage.RAT_LANG=='fr'? "ou" : localStorage.RAT_LANG=='ar'? "أو": "or"}></div>
                                                     <Button disabled positive={this.state.question9.showFollowupQuestion ? true : false} onClick={() => this._question9Followup(true)}>
                                                         {t("YES")}
                                                     </Button>
                                                 </Button.Group>
                                                ):(
                                                    <Button.Group size="large">
                                                    <Button disabled positive={this.state.question9.showFollowupQuestion ? true : false} onClick={() => this._question9Followup(true)}>
                                                        {t("YES")}
                                                    </Button>
                                                    {/* <Button.Or /> */}
                                                    <div class="or" data-text={localStorage.RAT_LANG=='sp'? "o" : localStorage.RAT_LANG=='fr'? "ou" : localStorage.RAT_LANG=='ar'? "أو": "or"}></div>
                                                    <Button
                                                        disabled
                                                        positive={this.state.question9.showFollowupQuestion ? false : true}
                                                        onClick={() => this._question9Followup(false)}
                                                    >
                                                        {t("NO")}
                                                    </Button>
                                                </Button.Group>
                                                )}
                                               
                                            </div>
                                        </div>
                                        <div className="uk-form-label">{t("Comments/Feedback from SE Hub")}</div>
                                        <div>
                                            <span className="uk-form-icon">
                                                <i className="far fa-User icon-medium"></i>
                                            </span>

                                            <TextArea
                                                onChange={(e) => this._feedBackHandler(e, "question9")}
                                                name="question9feedBack"
                                                id="question9feedBack"
                                                value={this.state.question9.feedBack || ""}
                                                className="uk-textarea"
                                                placeholder={t("Comments/Feedback here")}
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                    {/* End Question 9 */}

                                    {/* Begin Question 10 */}
                                    <div className="uk-link-reset paper uk-margin s1q1">
                                        <h4>
                                            <b>16)</b>{" "}
                                            {t("What is the USD value of your Annual Programme Budget (APB) for the ongoing year?")}{" "}
                                        </h4>
                                        <div>
                                            <div className="uk-form-label"></div>
                                            <div className="uk-margin">
                                                <span className="uk-form-icon">
                                                    <i className="far fa-User icon-medium"></i>
                                                </span>

                                                <Input
                                                    labelPosition={localStorage.RAT_LANG == 'ar' ? "left" : "right"}
                                                    type="number"
                                                    placeholder={t("Amount")}
                                                    id="q10primaryAnswer"
                                                    name="q10primaryAnswer"
                                                    className={localStorage.RAT_LANG=='ar'? "input_arabic":""}
                                                    value={this.state.question10.primaryAnswer}
                                                    onChange={this._qeustion10OnChange}
                                                    disabled
                                                    onKeyDown={e => {
                                                        if (/[\+\-\.\,]/.test(e.key) || e.key.toUpperCase() === "E") {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                >
                                                    <Label  className={localStorage.RAT_LANG=='ar'? "label_arabic":""}  basic>$</Label>
                                                    <input />
                                                    <Label>.00</Label>
                                                </Input>
                                            </div>
                                        </div>
                                        <div className="uk-form-label">{t("Score given")}</div>
                                        <div>
                                            <span className="uk-form-icon">
                                                <i className="far fa-User icon-medium"></i>
                                            </span>

                                            {/* <input
                                                    type="number"
                                                    placeholder="Enter score here"
                                                    id="question10score"
                                                    name="question10score"
                                                    value={this.state.question10.score || ""}
                                                    maxlength="2"
                                                    onChange={(e) => this._scoreHandler(e, "question10")}
                                                    style={{ width: "20%" }}
                                                    onKeyDown={(e) => {
                                                        if (/[\+\-\.\,]/.test(e.key) || e.key.toUpperCase() === "E") {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                /> */}
                                            <Input
                                                style={{ width: "180px" }}
                                                labelPosition={localStorage.RAT_LANG == 'ar' ? "left" : "right"}
                                                type="number"
                                                placeholder={t("Enter score here")}
                                                id="question10score"
                                                name="question10score"
                                                value={this.isEmpty(this.state.question10.score) ? (
                                                    null
                                                ) : (
                                                    this.state.question10.score
                                                )}
                                                onChange={(e) => this._scoreHandler(e, "question10")}
                                                onKeyDown={(e) => {
                                                    if (/[\+\-\.\,]/.test(e.key) || e.key.toUpperCase() === "E") {
                                                        e.preventDefault();
                                                    }
                                                }}>
                                                <input />
                                                <Label basic>%</Label>
                                            </Input>
                                        </div>
                                        <div className="uk-form-label">{t("Comments/Feedback from SE Hub")}</div>
                                        <div>
                                            <span className="uk-form-icon">
                                                <i className="far fa-User icon-medium"></i>
                                            </span>

                                            <TextArea
                                                onChange={(e) => this._feedBackHandler(e, "question10")}
                                                name="question10feedBack"
                                                id="question10feedBack"
                                                value={this.state.question10.feedBack || ""}
                                                className="uk-textarea"
                                                placeholder={t("Comments/Feedback here")}
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                    {/* End Question 10 */}

                                    {/* Begin Quesion 11 */}
                                    <div className="uk-link-reset paper uk-margin s1q1">
                                        <h4>
                                            <b>17)</b>{" "}
                                            {t("What is the USD value of the IPPF core grant (if applicable) for the ongoing year?")}{" "}
                                        </h4>
                                        <div>
                                            <div className="uk-form-label"></div>
                                            <div className="uk-margin">
                                                <span className="uk-form-icon">
                                                    <i className="far fa-User icon-medium"></i>
                                                </span>

                                                <Input
                                                    labelPosition={localStorage.RAT_LANG == 'ar' ? "left" : "right"}
                                                    type="number"
                                                    placeholder={t("Amount")}
                                                    id="q11primaryAnswer"
                                                    name="q11primaryAnswer"
                                                    className={localStorage.RAT_LANG=='ar'? "input_arabic":""}
                                                    value={this.state.question11.primaryAnswer}
                                                    onChange={this._qeustion11OnChange}
                                                    disabled
                                                    onKeyDown={e => {
                                                        if (/[\+\-\.\,]/.test(e.key) || e.key.toUpperCase() === "E") {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                >
                                                    <Label  className={localStorage.RAT_LANG=='ar'? "label_arabic":""} basic>$</Label>
                                                    <input />
                                                    <Label>.00</Label>
                                                </Input>
                                            </div>
                                        </div>
                                        <div className="uk-form-label">{t("Score given")}</div>
                                        <div>
                                            <span className="uk-form-icon">
                                                <i className="far fa-User icon-medium"></i>
                                            </span>

                                            {/* <input
                                                    type="number"
                                                    placeholder="Enter score here"
                                                    id="question11score"
                                                    name="question11score"
                                                    value={this.state.question11.score || ""}
                                                    maxlength="2"
                                                    onChange={(e) => this._scoreHandler(e, "question11")}
                                                    style={{ width: "20%" }}
                                                    onKeyDown={(e) => {
                                                        if (/[\+\-\.\,]/.test(e.key) || e.key.toUpperCase() === "E") {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                /> */}
                                            <Input
                                                style={{ width: "180px" }}
                                                labelPosition={localStorage.RAT_LANG == 'ar' ? "left" : "right"}
                                                type="number"
                                                placeholder={t("Enter score here")}
                                                id="question11score"
                                                name="question11score"
                                                value={this.isEmpty(this.state.question11.score) ? (
                                                    null
                                                ) : (
                                                    this.state.question11.score
                                                )}
                                                onChange={(e) => this._scoreHandler(e, "question11")}
                                                onKeyDown={(e) => {
                                                    if (/[\+\-\.\,]/.test(e.key) || e.key.toUpperCase() === "E") {
                                                        e.preventDefault();
                                                    }
                                                }}>
                                                <input />
                                                <Label basic>%</Label>
                                            </Input>
                                        </div>
                                        <div className="uk-form-label">{t("Comments/Feedback from SE Hub")}</div>
                                        <div>
                                            <span className="uk-form-icon">
                                                <i className="far fa-User icon-medium"></i>
                                            </span>

                                            <TextArea
                                                onChange={(e) => this._feedBackHandler(e, "question11")}
                                                name="question11feedBack"
                                                id="question11feedBack"
                                                value={this.state.question11.feedBack || ""}
                                                className="uk-textarea"
                                                placeholder={t("Comments/Feedback here")}
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                    {/* End Question 11 */}

                                    {/* Begin Question 12 */}
                                    <div className="uk-link-reset paper uk-margin s1q1">
                                        <h4>
                                            <b>18)</b>{" "}
                                            {t("What is the USD value of your (local) self-generated income for the last financial year?")}{" "}
                                        </h4>
                                        <div>
                                            <div className="uk-form-label"></div>
                                            <div className="uk-margin">
                                                <span className="uk-form-icon">
                                                    <i className="far fa-User icon-medium"></i>
                                                </span>

                                                <Input
                                                    labelPosition={localStorage.RAT_LANG == 'ar' ? "left" : "right"}
                                                    type="number"
                                                    placeholder={t("Amount")}
                                                    id="q12primaryAnswer"
                                                    name="q12primaryAnswer"
                                                    value={this.state.question12.primaryAnswer}
                                                    onChange={this._qeustion12OnChange}
                                                    className={localStorage.RAT_LANG=='ar'? "input_arabic":""}
                                                    disabled
                                                    onKeyDown={e => {
                                                        if (/[\+\-\.\,]/.test(e.key) || e.key.toUpperCase() === "E") {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                >
                                                    <Label  className={localStorage.RAT_LANG=='ar'? "label_arabic":""} basic>$</Label>
                                                    <input />
                                                    <Label>.00</Label>
                                                </Input>
                                            </div>
                                        </div>
                                        <div className="uk-form-label">{t("Score given")}</div>
                                        <div>
                                            <span className="uk-form-icon">
                                                <i className="far fa-User icon-medium"></i>
                                            </span>

                                            {/* <input
                                                    type="number"
                                                    placeholder="Enter score here"
                                                    id="question12score"
                                                    name="question12score"
                                                    value={this.state.question12.score || ""}
                                                    maxlength="2"
                                                    onChange={(e) => this._scoreHandler(e, "question12")}
                                                    style={{ width: "20%" }}
                                                    onKeyDown={(e) => {
                                                        if (/[\+\-\.\,]/.test(e.key) || e.key.toUpperCase() === "E") {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                /> */}
                                            <Input
                                                style={{ width: "180px" }}
                                                labelPosition={localStorage.RAT_LANG == 'ar' ? "left" : "right"}
                                                type="number"
                                                placeholder={t("Enter score here")}
                                                id="question12score"
                                                name="question12score"
                                                value={this.isEmpty(this.state.question12.score) ? (
                                                    null
                                                ) : (
                                                    this.state.question12.score
                                                )}
                                                onChange={(e) => this._scoreHandler(e, "question12")}
                                                onKeyDown={(e) => {
                                                    if (/[\+\-\.\,]/.test(e.key) || e.key.toUpperCase() === "E") {
                                                        e.preventDefault();
                                                    }
                                                }}>
                                                <input />
                                                <Label basic>%</Label>
                                            </Input>
                                        </div>
                                        <div className="uk-form-label">{t("Comments/Feedback from SE Hub")}</div>
                                        <div>
                                            <span className="uk-form-icon">
                                                <i className="far fa-User icon-medium"></i>
                                            </span>

                                            <TextArea
                                                onChange={(e) => this._feedBackHandler(e, "question12")}
                                                name="question12feedBack"
                                                id="question12feedBack"
                                                value={this.state.question12.feedBack || ""}
                                                className="uk-textarea"
                                                placeholder={t("Comments/Feedback here")}
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                    {/* End Question 12 */}
                                    <div
                                        href="help-post-read.html"
                                        className="uk-link-reset paper uk-margin-remove-bottom"
                                    >
                                        <h4 className="uk-margin-remove">
                                            {" "}
                                            {t("Overall section comments/feedback from SE Hub")}
                                        </h4>

                                        <div className="uk-margin">
                                            <span className="uk-form-icon">
                                                <i className="far fa-User icon-medium"></i>
                                            </span>

                                            <TextArea
                                                onChange={this._sectionFeedBackHandler}
                                                name="sectionFeedBack"
                                                id="sectionFeedBack"
                                                value={this.state.sectionFeedBack || ""}
                                                className="uk-textarea"
                                                placeholder={t("Overall section comments/feedback here")}
                                                type="text"
                                            />
                                        </div>
                                    </div>

                                    <div className="uk-margin">
                                        {(formErrorStatus.status) ? (
                                            <div className="danger_alert">{t(formErrorStatus.message)}</div>
                                        ) : ((formSuccessState.status) ? (
                                            <div className="success_alert">{t(formSuccessState.message)}</div>
                                        ) : (''))}
                                    </div>

                                    <div className="uk-text-center uk-grid uk-grid-small uk-child-width-1-5 uk-margin-small quick-nav" uk-grid>
                                        {/* <div>
                                                <div className="uk-card">
                                                    <li><a className="uk-button uk-button-default uk-button-small uk-width-1-1" onClick={(e) => this._pagi("section1")} href="javascript:void(0)">MA Contact</a></li>
                                                </div>
                                            </div> */}
                                        <div>
                                            <div className="uk-card">
                                                <li><a className="uk-button uk-button-default uk-button-small uk-width-1-1" onClick={(e) => this._pagi("section2")} href="javascript:void(0)">{t("Operations")}</a></li>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="uk-card">
                                                <li className="uk-active"><a className="uk-button uk-button-default uk-button-small uk-width-1-1" onClick={(e) => this._pagi("section3")} href="javascript:void(0)">{t("Financial")}</a></li>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="uk-card">
                                                <li><a className="uk-button uk-button-default uk-button-small uk-width-1-1" onClick={(e) => this._pagi("section4")} href="javascript:void(0)">{t("HR")}</a></li>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="uk-card">
                                                <li><a className="uk-button uk-button-default uk-button-small uk-width-1-1" onClick={(e) => this._pagi("section5")} href="javascript:void(0)">{t("Resources")}</a></li>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="uk-card">
                                                <li><a className="uk-button uk-button-default uk-button-small uk-width-1-1" onClick={(e) => this._pagi("section6")} href="javascript:void(0)">{t("Other")}</a></li>
                                            </div>
                                        </div>
                                    </div>

                                    <hr />
                                    {/* Begin Footer */}
                                    <div className="uk-grid uk-text-center button_div">
                                        <div className="uk-width-1-3@m">
                                            <Button
                                                basic
                                                className="outline-button blueButton"
                                                onClick={this._goBack}
                                            >
                                                {t("Go Back")}
                                            </Button>
                                        </div>

                                        <div className="uk-width-expand@m">
                                            {!this.state.sectionEmpty ? (
                                                <Button
                                                    basic
                                                    onClick={this._updateSection}
                                                    className="outline-button yellowButton"
                                                    loading={this.state.spinner}
                                                >
                                                    {t("Save")}
                                                </Button>
                                            ) : (
                                                ""
                                            )}
                                        </div>

                                        <div className="uk-width-1-3@m">
                                            {!this.state.sectionEmpty ? (
                                                <div>
                                                    <Button
                                                        onClick={this._next}
                                                        className="next-button purpleButton"
                                                        loading={this.state.saveNContinue}
                                                        basic
                                                    >
                                                        {t("Save and Next")}
                                                    </Button>
                                                </div>
                                            ) : (
                                                <div>
                                                    <Button
                                                        onClick={this._next}
                                                        basic
                                                        className="outline-button"
                                                        loading={this.state.saveNContinue}
                                                    >
                                                        {t("Save & Continue")}
                                                    </Button>
                                                </div>
                                            )}

                                            {/* <div class="spinner"></div>  */}
                                        </div>
                                    </div>
                                    {/* End Footer */}
                                </Form>
                            </div>
                        </div>
                        {/* End Dark Header */}
                    </div>
                )}
            </div>
        );
    }
}


export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(ReviewSection3))));
