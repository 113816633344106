import React, { Component } from 'react'
import { Container, Divider, Header, Image, Segment } from 'semantic-ui-react'
import { withNamespaces } from "react-i18next";

class ReadinessAssessment extends Component {
    render() {
        const { t, i18n } = this.props
        return (
            <div className="uk-section-default uk-section">
                <div className="uk-margin-auto uk-container-small">
                </div>
                <hr />
                <Container className="containerStyle containerMoveBottom" >
                    <Segment className="HeaderBorder">
                        <Header as='h4'> {t("What is SE Readiness Assessment?")}</Header>
                    </Segment>
                    <div className="readinessAss" >
                        <p> <strong> {t("What is an organizational Social Enterprise (SE) Readiness Assessment?")}  </strong></p>
                        <p>{t("An organizational readiness assessment is an official measurement of the preparedness of your Association to embark on any business related activity with the intention of funding your social mission (like starting a social enterprise/income generating business model). Taking such a significant step without knowing if your Association has the internal/external support and resources to accomplish it effectively will be inadvisable.")}</p>
                        <p> {t("Conducting an organizational SE readiness assessment to a large extent gives you the knowledge and assurance that your Association’s proposed endeavor will be successful if you decide to go ahead and do it. It can also safeguard your Association’s reputation and support the investment decision you make by allowing you to minimise associated risks and losses.")}</p>
                        <p>{t("A readiness assessment usually assesses areas related to the following:")}
                            <ul>
                                <li>{t("Ability/readiness to accept change, challenges and risks (physically, spiritually, psychologically, intellectually)")}</li>
                                <li>{t("Goals, expectations and concerns")}</li>
                                <li>{t("Current operational environment and existing resources")}</li>
                                <li>{t("Leadership and Board support towards the enterprise")}</li>
                                <li>{t("Project governance and decision making")}</li>
                                <li>{t("Conceptualising the project that you aspire to undertake")}</li>
                                <li>{t("Other critical project needs")}</li>
                            </ul>
                        </p>

                        <p><strong>{t("Readiness Assessments help address…")}</strong></p>
                        <p>{t("Readiness Assessments help identify areas of potential project failures of an enterprise and prepare better to guide employees through the change process.")}</p>
                        <p>{t("this_type_of")}</p>
                        <p>{t("This is important because it will save your Association time and money, help with your negotiations, and even improve the profitability of the project in the long run.")}</p>
                        <p>{t("In addition, the organizational readiness assessment allows you to address the details of the change with your stakeholders to determine if they are ready for such a transition and have the ability and resources they need to make it happen.")}</p>

                        <p><strong>{t("In general, what can a readiness assessment do for your Enterprise?")}</strong></p>
                        <p>{t("An effectively conducted readiness assessment reveals an organization's overall readiness to begin a project, i.e. the willingness to make the necessary organizational changes, restructuring the income-earning model, or taking on a large and important new project.")}</p>
                        <p>{t("Your objectively completed organizational readiness assessment will pinpoint:")}
                            <ul>
                                <li>{t("the organizational strengths to start an enterprise that is aligned to them,")} </li>
                                <li>{t("the available resources,")} </li>
                                <li>{t("the characteristics of your Board/Leadership and employees, and")} </li>
                                <li>{t("areas in which your Association needs to improve in order to take on the change or project and make it a potential success.")} </li>
                            </ul>
                        </p>

                        <p><strong>{t("How to use your organization readiness assessment")} </strong></p>
                        <p>{t("The results of the organizational assessment will give you the additional benefit of identifying the existing strengths of your organization, particularly the factors that will you a comparative advantage.")}</p>
                        <p>{t("Finally, your organizational readiness assessment will allow you to know whether your organization meets the requirements to even consider the change or project you have in mind. If you don’t have the requirements now, the assessment process should ideally indicate what you need to acquire and changes you need to make or training you need to undergo in order to make the proposed idea a truly successful project.")}</p>
                    </div>
                </Container>
            </div>
        )
    }
}

export default withNamespaces()(ReadinessAssessment);
