import React, { Component } from "react";
import { withNamespaces } from "react-i18next";

class SectionLoader extends Component {

  render() {
    const { t, i18n } = this.props;
    return (
      <div>
        {/* Begin Dark Header */}
        <div className="uk-section-default uk-section">
        <div className="uk-container-small uk-margin-auto uk-margin-medium-top"> 
                <h3 className=" uk-text-light"> {t("Loading...")} </h3>
        </div>
        {/* <hr/> */}

        <div ref={this.myRef} className="uk-container-small uk-margin-auto uk-margin-small-top uk-padding-small paper-read" uk-scrollspy="target: > .paper; cls:uk-animation-slide-bottom-small; delay: 200">
                <div  className="paper uk-link-reset"> 
                    <div className="uk-grid uk-flex-middle" >
                        <div className="uk-width-1-6 uk-flex-first uk-text-center"> 
                            <i className="far fa-comment-alt icon-xxlarge"></i> 
                        </div>                         
                        <div className="uk-width-5-6"> 
                            <h4 className="uk-margin-remove"> ... </h4> 
                            {/* <p className="uk-margin-remove">...</p> */}
                        </div>                         
                    </div>
                </div>
            </div>            
        </div>
        {/* End Dark Header */}
      </div>
    );
  }
}


export default withNamespaces()(SectionLoader);
