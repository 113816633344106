import React, { Component } from "react";
import SectionLoader from "./SectionLoader";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import { GET_SECTION_TWO } from "../../../queries/SectionQueries";
import {
  SUBMIT_SECTION_1,
  GET_SECTION_1
} from "../../../queries/SubmissionQueries";
import { setFormStatus, setSuccessStatus } from '../../../actions/user-actions';
import { connect } from 'react-redux';
import { Form, Button, TextArea, Message } from "semantic-ui-react";

const mapStateToProps = (state, ownProps) => {
  return {
    formErrorStatus: state.custom_reducer.formErrorStatus,
    formSuccessState: state.custom_reducer.formSuccessState,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setFormStatus: (status) => {
      dispatch(setFormStatus(status))
    },
    setSuccessStatus: (status) => {
      dispatch(setSuccessStatus(status))
    }
  }
}

class Section2 extends Component {
  constructor(props) {
    super(props);
    this.props.setFormStatus({ status: false, title: '', message: '' });
    this.props.setSuccessStatus({ status: false, title: '', message: '' });
    this.state = {
      loading: true,
      spinner: false,
      saveNContinue: false,
      sectionEmpty: true,
      section: {
        id: "",
        sectionNumber: "",
        title: "",
        subTitle: "",
        description: "",
        sequence: "",
        createdAt: "",
        updatedAt: ""
      },
      question1: {
        showFollowupQuestion: false,
        primaryQuestion:
          "Does your domestic law allow NGOs to carry out income generating activities (to support the social mission)?",
        primaryAnswer: false,
        secondaryQuestion: "If Yes, what kind of provisions are in place?",
        secondaryAnswer: "",
        weightFactor: "0.4",
        uploads: [],
        validated: true
      },
      question2: {
        showFollowupQuestion: false,
        primaryQuestion:
          "Does the constitution of your organization place explicit constraints or controls on income generating activities?",
        primaryAnswer: "",
        secondaryQuestion: "",
        secondaryAnswer: "",
        weightFactor: "0.0",
        uploads: [],
        validated: false
      },
      question3: {
        showFollowupQuestion: false,
        primaryQuestion:
          "Does the constitution of your organization need to be amended to allow income generating activities?",
        primaryAnswer: "",
        secondaryQuestion: "",
        secondaryAnswer: "",
        weightFactor: "0.0",
        uploads: [],
        validated: false
      },
      question4: {
        showFollowupQuestion: false,
        primaryQuestion:
          "Is the Governing Board of your organization representative of multi-disciplinary fields?",
        primaryAnswer: false,
        secondaryQuestion: "If Yes, add all the applicable Board Members details",
        secondaryAnswer: "",
        secondaryJson: {},
        secondaryArray: [{ fullName: "", professionalTitle: "", roleOnTheBoard: "", typeOfExpertise: "" }],
        weightFactor: "0.2",
        uploads: [],
        validated: true
      },
      question5: {
        showFollowupQuestion: false,
        primaryQuestion:
          "Does at least one Board member have knowledge of business management?",
        primaryAnswer: false,
        secondaryQuestion: "If Yes, specify the exact advisory capacity of the Board Members who have business management knowledge and any evidence of their business experience (including any academic qualification)?",
        secondaryAnswer: "",
        secondaryJson: {},
        weightFactor: "0.2",
        uploads: [],
        validated: true
      },
      question6: {
        showFollowupQuestion: false,
        primaryQuestion:
          "Is there consensus or a documented agreement (i.e. meeting minutes) between your organization’s management and Board on the need to be self-sufficient financially (sustainability)?",
        primaryAnswer: false,
        secondaryQuestion: "If Yes, does your organization have a Resource Mobilization plan/strategy?",
        secondaryAnswer: "",
        secondaryJson: {},
        weightFactor: "0.4",
        uploads: [],
        validated: true
      },
      multiquestions: [{ fullName: "", professionalTitle: "", roleOnTheBoard: "", typeOfExpertise: "" }],
    };
    this._getSection2();
    this._getSection1Submissions();
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    setTimeout(() => {
      this.setState({
        loading: false
      });
    }, 2000);
  }

  _getSection2 = () => {
    this.props.client
      .query({
        query: GET_SECTION_TWO
      })
      .then(result => {

        this.setState({
          section: { ...result.data.getSection1 }
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  _getSection1Submissions = () => {
    this.props.client
      .query({
        query: GET_SECTION_1,
        variables: { sectionId: "ck09iwgjk6ftg0b53chrw6bkf" },
        fetchPolicy: 'network-only'
      })
      .then(result => {
        console.log(result);
        if (
          result.data.getSectionSubmission === null ||
          result.data.getSectionSubmission.length === 0
        ) {
          console.log("there is nothing here");
        } else {
          console.log(result.data.getSectionSubmission);
          // setTimeout(() => {
          // this.setState({ position: 1 });

          this.setState({
            question1: result.data.getSectionSubmission.submission.question1 ? result.data.getSectionSubmission.submission.question1 : this.state.question1,
            //question1: this.state.question1,
            question2: result.data.getSectionSubmission.submission.question2 ? result.data.getSectionSubmission.submission.question2 : this.state.question2,
            question3: result.data.getSectionSubmission.submission.question3 ? result.data.getSectionSubmission.submission.question3 : this.state.question3,
            question4: result.data.getSectionSubmission.submission.question4 ? result.data.getSectionSubmission.submission.question4 : this.state.question4,
            question5: result.data.getSectionSubmission.submission.question5 ? result.data.getSectionSubmission.submission.question5 : this.state.question5,
            question6: result.data.getSectionSubmission.submission.question6 ? result.data.getSectionSubmission.submission.question6 : this.state.question6,
            sectionEmpty: false,
          });
          // }, 1000);
        }
        console.log(this.state);
      })
      .catch(error => {
        console.log(error);
      });
  };

  _goBack = () => {
    //this.props.history.goBack();
    window.location.href = "/assessment/section1";
  };

  // Question 1 Stuff

  _question1Followup = state => {
    state
      ? this.setState({
        question1: {
          ...this.state.question1,
          showFollowupQuestion: true,
          primaryAnswer: true,
          validated: this.state.question1.secondaryAnswer.trim() === "" ? false : true

        }
      })
      : this.setState({
        question1: {
          ...this.state.question1,
          showFollowupQuestion: false,
          primaryAnswer: false,
          validated: true
        }
      });
  };

  _question1Onchange = event => {
    this.setState({
      question1: {
        ...this.state.question1,
        [event.target.id]: event.target.value,
        validated: (event.target.value === "" && this.state.question1.primaryAnswer === true) ? false : true
      }
    });
  };

  _composeQ1Answers = () => {
    const answers = {
      primaryQuestion:
        "Does your domestic laws allow income generating activities by NGOs?",
      primaryAnswer: this.state.question1.showFollowupQuestion,
      hasFollowup: this.state.question1.showFollowupQuestion,
      secondaryQuestion: "If Yes, what kind of provisions are in place?",
      secondaryAnswer: this.state.question1.secondaryAnswer,
      uploads: []
    };
    const submission = {
      question1: answers
    };
    this.setState({
      question1: answers
    });
    return submission;
  };

  // Question2 stuff
  _question2Onchange = event => {
    this.setState({
      question2: {
        ...this.state.question2,
        primaryAnswer: event.target.value,
        validated: event.target.value.trim() === "" ? false : true
      }
    });
  };

  // Question3 stuff
  _question3Onchange = event => {
    this.setState({
      question3: {
        ...this.state.question3,
        primaryAnswer: event.target.value,
        validated: event.target.value.trim() === "" ? false : true
      }
    });
  };

  // Question 4 Stuff

  _setupQuestion4 = () => { };

  _question4Followup = state => {
    let fields = this.state.question4.secondaryArray;
    const toArray = fields.reduce((acc, obj) => [...acc, Object.values(obj).map(y => y.trim())], []);
    const singleArr = [].concat(...toArray);
    const emptyStrings = singleArr.filter(str => str.trim().length <= 0);

    state
      ? this.setState({
        question4: { ...this.state.question4, showFollowupQuestion: true, primaryAnswer: true, validated: emptyStrings.length === 0 ? true : false }
      })
      : this.setState({
        question4: { ...this.state.question4, showFollowupQuestion: false, primaryAnswer: false, validated: true }
      });
  };

  _question4jsonOnchange = event => {
    this.setState({
      question4: {
        ...this.state.question4,
        secondaryJson: {
          ...this.state.question4.secondaryJson,
          [event.target.id]: event.target.value
        }
      }
    });
  };

  _question4Onchange = event => {
    this.setState({
      question4: {
        ...this.state.question4,
        [event.target.id]: event.target.value
      }
    });
  };

  // Question 5 stuff
  _question5Followup = state => {
    state
      ? this.setState({
        question5: {
          ...this.state.question5,
          showFollowupQuestion: true,
          primaryAnswer: true,
          validated: this.state.question5.secondaryAnswer.trim() === "" ? false : true
        }
      })
      : this.setState({
        question5: {
          ...this.state.question5,
          showFollowupQuestion: false,
          primaryAnswer: false,
          validated: true
        }
      });
  };

  _question5Onchange = event => {
    this.setState({
      question5: {
        ...this.state.question5,
        [event.target.id]: event.target.value
      }
    });
  };

  _question5TextOnchange = event => {
    this.setState({
      question5: {
        ...this.state.question5,
        secondaryAnswer: event.target.value,
        validated: event.target.value.trim() === "" ? false : true
      }
    });
  };

  // Question 6 stuff
  _question6Followup = state => {
    state
      ? this.setState({
        question6: {
          ...this.state.question6,
          showFollowupQuestion: true,
          primaryAnswer: true,
          // validated: false
        }
      })
      : this.setState({
        question6: {
          ...this.state.question6,
          showFollowupQuestion: false,
          primaryAnswer: false,
          // validated: true
        }
      });
  };

  _question6SecondaryFollowup = state => {
    state
      ? this.setState({
        question6: { ...this.state.question6, secondaryAnswer: true }
      })
      : this.setState({
        question6: { ...this.state.question6, secondaryAnswer: false }
      });
  };

  _next = () => {
    this.setState({
      saveNContinue: true,
    })
    this._submitSection(true);
  };

  _updateSection = () => {
    this.setState({
      spinner: true,
    })
    this._submitSection(false);
  }

  _nextWithoutUpdate = () => {
    this.props.history.push(`/assessment/section3`);
  };

  _submitSection = (flag) => {
    this.props.setFormStatus({ status: false, title: '', message: '' });
    this.props.setSuccessStatus({ status: false, title: '', message: '' });
    // const question1 = this._composeQ1Answers();

    const submission = {
      question1: this.state.question1,
      question2: this.state.question2,
      question3: this.state.question3,
      question4: this.state.question4,
      question5: this.state.question5,
      question6: this.state.question6
    };

    // this.setState({
    //   spinner: false,
    //   saveNContinue: false,
    // });
    // console.log(this.state.question1);
    // console.log(this.state.question2);
    // console.log(this.state.question3);
    // console.log(this.state.question4);
    // console.log(this.state.question5);
    // console.log(this.state.question6);

    this._saveSection(submission, flag);
  };

  _saveSection = (submission, flag) => {

    this.props.client.mutate({
      mutation: SUBMIT_SECTION_1,
      variables: { submission: submission, sectionId: "ck09iwgjk6ftg0b53chrw6bkf" }
    }).then(result => {
      this._getSection1Submissions();
      this.setState({
        spinner: false,
        saveNContinue: false,
      })

      this.props.setSuccessStatus({
        status: true,
        title: "",
        message: "Saved successfully!"
      });

      if (flag) {
        this.props.history.push(`/assessment/section3`);
      }
    }).catch(error => {
      this.setState({
        spinner: false,
        saveNContinue: false,
      })
      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message:
          "There was an error while trying to update data"

      });
    });
  };

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

  _handleMultiChange(i, e) {
    const { name, value } = e.target;
    let fields = this.state.question4.secondaryArray;
    fields[i][name] = value;

    const toArray = fields.reduce((acc, obj) => [...acc, Object.values(obj).map(y => y.trim())], []);
    const singleArr = [].concat(...toArray);
    const emptyStrings = singleArr.filter(str => str.trim().length <= 0);
    // const isEmpty = emptyStrings.length === 0 ? true : false;
    // // console.log(isEmpty);


    this.setState({
      question4: {
        ...this.state.question4,
        validated: emptyStrings.length === 0 ? true : false
      }
    });

    this.setState({ fields });
  }

  _addMoreClick() {
    let fields = this.state.question4.secondaryArray;
    fields.push({ fullName: "", professionalTitle: "", roleOnTheBoard: "", typeOfExpertise: "" });
    this.setState({
      question4: {
        ...this.state.question4,
        validated: false
      }
    });
    this.setState(fields);
  }

  _removeMoreClick(i) {

    let secondaryArray = this.state.question4.secondaryArray;
    secondaryArray.splice(i, 1);

    const toArray = secondaryArray.reduce((acc, obj) => [...acc, Object.values(obj).map(y => y.trim())], []);
    const singleArr = [].concat(...toArray);
    const emptyStrings = singleArr.filter(str => str.trim().length <= 0);

    this.setState({
      question4: {
        ...this.state.question4,
        validated: emptyStrings.length === 0 ? true : false
      }
    });
    this.setState({ secondaryArray });
  }

  _createMuliQuestion() {

    if (this.state.question4.secondaryArray != undefined) {
      return this.state.question4.secondaryArray.map((el, i) => (
        <div key={i}>
          <br /><br />
          <div className="ms-Grid">
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm6 ms-lg10">
                <Form.Field>
                  <label>Full Name</label>
                  <input type="text" id='fullName' name='fullName' label="Full Name" placeholder="Full Name" value={el.fullName || ''} onChange={this._handleMultiChange.bind(this, i)} />
                </Form.Field>
                <Form.Field>
                  <label>Professional Title</label>
                  <input type="text" id='professionalTitle' name='professionalTitle' label="Professional Title" placeholder="Professional Title" value={el.professionalTitle || ''} onChange={this._handleMultiChange.bind(this, i)} />
                </Form.Field>
                <Form.Field>
                  <label>Role on the Board</label>
                  <input type="text" id='roleOnTheBoard' name='roleOnTheBoard' label="Role on the Board" placeholder="Role on the Board" value={el.roleOnTheBoard || ''} onChange={this._handleMultiChange.bind(this, i)} />
                </Form.Field>
                <Form.Field>
                  <label>Type of Expertise</label>
                  <input type="text" id='typeOfExpertise' name='typeOfExpertise' label="Type of Expertise" placeholder="Type of Expertise" value={el.typeOfExpertise || ''} onChange={this._handleMultiChange.bind(this, i)} />
                </Form.Field>
              </div>

              <br />

              {this.state.question4.secondaryArray.length <= 1 ? (
                null
              ) : (
                  <div className="ms-Grid-col ms-sm6 ms-lg2 uk-text-right">
                    <i class="fas fa-trash-alt multiple_Field_Remove" aria-hidden="true" onClick={this._removeMoreClick.bind(this, i)}></i>
                    {/* <i className="big minus circle icon multiple_Field_Remove" onClick={this._removeMoreClick.bind(this, i)}></i> */}
                  </div>
                )}

            </div>
          </div>
          <hr className="multiple_Field_Seperator" />
        </div>

      ))

    } else {
      let fields = this.state.question4;
      fields["secondaryArray"] = [{ fullName: "", professionalTitle: "", roleOnTheBoard: "", typeOfExpertise: "" }];
      this.setState(fields)
    }
  }


  render() {
    const { formErrorStatus, formSuccessState } = this.props;
    return (
      <div>
        {this.state.loading ? (
          <SectionLoader />
        ) : (
            <div>
              {/* Begin Dark Header */}
              <div className="uk-section-default uk-section">
                <div className="uk-container-small uk-margin-auto uk-margin-medium-top">
                  <h3 className=" uk-text-light">
                    {" "}
                    Section {this.state.section.sectionNumber}{" "}
                    {/* {this.state.section.title}{" "} */}
                  </h3>
                </div>
                <hr />
                <div
                  className="uk-container-small uk-margin-auto uk-margin-small-top uk-padding-small paper-read"
                  uk-scrollspy="target: > .paper; cls:uk-animation-slide-bottom-small; delay: 200"
                >
                  <div className="paper uk-link-reset sectionTwo  " style={{ backgroundColor: "#008C95" }}>
                    <div className="uk-grid uk-flex-middle">
                      <div className="uk-width-1-6 uk-flex-first uk-text-center">
                        <i className="far fa-comment-alt icon-xxlarge"></i>
                      </div>
                      <div className="uk-width-5-6">
                        <h4 className="uk-margin-remove" style={{ color: "#ffffff" }}>
                          {" "}
                          {this.state.section.title}{" "}
                        </h4>
                        {/* <p className="uk-margin-remove">
                          {" "}
                          {this.state.section.description}{" "}
                        </p> */}
                      </div>
                    </div>
                  </div>
                  <Form success error className="uk-margin">
                    {/* Begin Question Group */}
                    {/* question1 */}
                    <div className="uk-link-reset paper uk-margin s1q1">
                      <h4>
                        {" "}
                        <b>1)</b> Does your domestic law allow NGOs to carry out income generating activities (to support the social mission)?{" "}
                      </h4>

                      <Button.Group
                        size="large"
                        onChange={this._question1Onchange}
                        className={localStorage.RAT_LANG=='ar'? "buttons_arabic_style": "" } 
                      >
                        <Button positive={this.state.question1.showFollowupQuestion ? true : false} onClick={() => this._question1Followup(true)}>
                          YES
                      </Button>
                        <Button.Or />
                        <Button positive={this.state.question1.showFollowupQuestion ? false : true} onClick={() => this._question1Followup(false)}>
                          NO
                      </Button>
                      </Button.Group>
                      {this.state.question1.showFollowupQuestion ? (
                        <div>
                          <div className="uk-form-label">
                            If Yes, what kind of provisions are in place?
                        </div>
                          <div className="uk-margin">
                            <span className="uk-form-icon">
                              <i className="far fa-User icon-medium"></i>
                            </span>

                            <TextArea
                              onChange={this._question1Onchange}
                              name="secondaryAnswer"
                              id="secondaryAnswer"
                              value={this.state.question1.secondaryAnswer}
                              className="text-area-height uk-input uk-form-width-large"
                              placeholder="Describe here"
                              type="text"
                            />
                          </div>
                        </div>
                      ) : (
                          ""
                        )}
                    </div>

                    {/* End Question Group */}
                    {/* Begin Question 2 */}
                    <div className="uk-link-reset paper uk-margin">
                      <h4 className="uk-margin-remove">
                        {" "}
                        <b>2)</b> Does the constitution of your organization place explicit constraints or controls on income generating activities?
                    </h4>
                      <p class="uk-text-muted uk-margin-remove-top uk-margin-small-bottom">Please answer and support the response with additional details.</p>
                      <TextArea
                        onChange={this._question2Onchange}
                        name="q2primaryAnswer"
                        id="q2primaryAnswer"
                        value={this.state.question2.primaryAnswer}
                        className="text-area-height uk-input uk-form-width-large"
                        placeholder="Describe here"
                        type="text"
                      />
                    </div>
                    {/* End Question 2 */}
                    {/* Begin Question 3 */}
                    <div
                      href="help-post-read.html"
                      className="uk-link-reset paper uk-margin-remove-bottom"
                    >
                      <h4 className="uk-margin-remove">
                        {" "}
                        <b>3)</b> Does the constitution of your organization need to be amended to allow income generating activities?
                    </h4>
                      <p class="uk-text-muted uk-margin-remove-top uk-margin-small-bottom">Please answer and support the response with additional details.</p>
                      <p className="uk-margin-remove">
                        {" "}
                        <TextArea
                          onChange={this._question3Onchange}
                          name="q3primaryAnswer"
                          id="q3primaryAnswer"
                          value={this.state.question3.primaryAnswer}
                          className="text-area-height uk-input uk-form-width-large"
                          placeholder="Describe here"
                          type="text"
                        />
                      </p>
                    </div>
                    {/* End Question 3 */}

                    {/* Begin Question 4 */}
                    <div
                      href="help-post-read.html"
                      className="uk-link-reset paper uk-margin"
                    >
                      <h4 className="uk-margin-remove">
                        {" "}
                        <b>4)</b> Is the Governing Board of your organization representative of multi-disciplinary fields?
                    </h4>
                      <Button.Group
                        size="large"
                        onChange={this._question1Onchange}
                        className={localStorage.RAT_LANG=='ar'? "buttons_arabic_style": "" } 
                      >
                        <Button positive={this.state.question4.showFollowupQuestion ? true : false} onClick={() => this._question4Followup(true)}>
                          YES
                      </Button>
                        <Button.Or />
                        <Button positive={this.state.question4.showFollowupQuestion ? false : true} onClick={() => this._question4Followup(false)}>
                          NO
                      </Button>
                      </Button.Group>
                      {this.state.question4.showFollowupQuestion ? (
                        <div className="uk-margin">
                          <div class="uk-form-label">If Yes, add all the applicable Board Members details</div>
                          <div>
                            {this._createMuliQuestion()}
                            <div className="uk-text-right"><button onClick={this._addMoreClick.bind(this)} className="uk-button uk-button-big uk-button-primary multiple_Field_Plus" type="button">Add details for another Board Member</button></div>
                          </div>
                        </div>
                      ) : (
                          " "
                        )}
                    </div>
                    {/* End Question 4 */}

                    {/* Begin Question 5 */}
                    <div
                      href="help-post-read.html"
                      className="uk-link-reset paper uk-margin-remove-bottom"
                    >
                      <h4 className="uk-margin-remove">
                        <b>5)</b>{" "}
                        Does at least one Board member have knowledge of business management?
                    </h4>
                      <Button.Group
                        size="large"
                        onChange={this._question5Onchange}
                        className={localStorage.RAT_LANG=='ar'? "buttons_arabic_style": "" } 
                      >
                        <Button positive={this.state.question5.showFollowupQuestion ? true : false} onClick={() => this._question5Followup(true)}>
                          YES
                      </Button>
                        <Button.Or />
                        <Button positive={this.state.question5.showFollowupQuestion ? false : true} onClick={() => this._question5Followup(false)}>
                          NO
                      </Button>
                      </Button.Group>
                      {this.state.question5.showFollowupQuestion ? (
                        <div>
                          <div className="uk-form-label">
                            If Yes, specify the exact advisory capacity of the Board Members who have business management knowledge and any evidence of their business experience (including any academic qualification)?
                        </div>
                          <div className="uk-margin">
                            <span className="uk-form-icon">
                              <i className="far fa-User icon-medium"></i>
                            </span>
                            <TextArea
                              onChange={this._question5TextOnchange}
                              name="q5secondaryAnswer"
                              id="q5secondaryAnswer"
                              value={this.state.question5.secondaryAnswer}
                              className="text-area-height uk-input uk-form-width-large"
                              placeholder="Describe here"
                              type="text"
                            />
                          </div>
                        </div>
                      ) : (
                          " "
                        )}
                    </div>

                    {/* End Question5 */}

                    {/* Begin Question 6 */}

                    <div
                      href="help-post-read.html"
                      className="uk-link-reset paper uk-margin"
                    >
                      <h4 className="uk-margin-remove">
                        <b>6)</b>{" "}
                        Is there consensus or a documented agreement (i.e. meeting minutes) between your organization’s management and Board on the need to be self-sufficient financially (sustainability)?
                    </h4>
                      <Button.Group
                        size="large"
                        onChange={this._question6Onchange}
                        className={localStorage.RAT_LANG=='ar'? "buttons_arabic_style": "" } 
                      >
                        <Button positive={this.state.question6.showFollowupQuestion ? true : false} onClick={() => this._question6Followup(true)}>
                          YES
                      </Button>
                        <Button.Or />
                        <Button positive={this.state.question6.showFollowupQuestion ? false : true} onClick={() => this._question6Followup(false)}>
                          NO
                      </Button>
                      </Button.Group>
                      {this.state.question6.showFollowupQuestion ? (
                        <div>
                          <div className="uk-form-label">
                          If Yes, does your organization have a Resource Mobilization plan/strategy?
                        </div>
                          <div className="uk-margin">
                            <span className="uk-form-icon">
                              <i className="far fa-User icon-medium"></i>
                            </span>

                            <Button.Group size="large" className={localStorage.RAT_LANG=='ar'? "buttons_arabic_style": "" } >
                              <Button
                                positive={this.state.question6.secondaryAnswer ? true : false}
                                onClick={() =>
                                  this._question6SecondaryFollowup(true)
                                }
                              >
                                YES
                            </Button>
                              <Button.Or />
                              <Button
                                positive={this.state.question6.secondaryAnswer ? false : true}
                                onClick={() =>
                                  this._question6SecondaryFollowup(false)
                                }
                              >
                                NO
                            </Button>
                            </Button.Group>
                          </div>
                        </div>
                      ) : (
                          ""
                        )}
                    </div>

                    {/* End Question 6 */}

                    <div className="uk-margin">
                      {(formErrorStatus.status) ? (
                         <div className="danger_alert">{formErrorStatus.message}</div>
                        // <Message
                        //   error
                        //   content={formErrorStatus.message}
                        // />
                      ) : ((formSuccessState.status) ? (
                        <div className="success_alert">{formSuccessState.message}</div>
                        // <Message
                        //   success
                        //   content={formSuccessState.message}
                        // />
                      ) : (''))}
                    </div>

                    <hr />
                    <div className="uk-grid uk-text-center button_div">
                      <div className="uk-width-1-3@m">
                        <Button
                          basic
                          className="outline-button blueButton"
                          onClick={this._goBack}
                        >
                          Go Back
                      </Button>
                      </div>

                      <div className="uk-width-expand@m">
                        {!this.state.sectionEmpty ? (

                          <Button
                            basic
                            onClick={this._updateSection}
                            className="outline-button yellowButton"
                            loading={this.state.spinner}
                          >
                            Save
                        </Button>
                        ) : (
                            ""
                          )}
                      </div>

                      <div className="uk-width-1-3@m">
                        {!this.state.sectionEmpty ? (
                          <div>
                            <Button
                              // onClick={this._nextWithoutUpdate}
                              onClick={this._next}
                              className="next-button purpleButton"
                              loading={this.state.saveNContinue}
                              basic
                            >
                              Save and Next
                          </Button>
                          </div>
                        ) : (
                            <div>
                              <Button
                                onClick={this._next}
                                basic
                                className="outline-button"
                                loading={this.state.saveNContinue}
                              >
                                Save & Continue
                          </Button>
                            </div>
                          )}


                        {/* <div class="spinner"></div>  */}
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
              {/* End Dark Header */}
            </div>
          )}
      </div>
    );
  }
}

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(Section2)));