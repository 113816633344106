import React, { Component } from "react";
import SectionLoader from "./SectionLoader";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import {
  Form,
  Button,
  TextArea,
  Input,
  Label,
  Loader,
  Message,
  Icon,
  Popup,
  Grid,
  Header,
  Confirm
} from "semantic-ui-react";
import { GET_SECTION_V } from "../../../queries/SectionQueries";
import {
  GET_SECTION_1,
  SUBMIT_SECTION_1,
  GET_ALL_SUBMMISSIONS
} from "../../../queries/SubmissionQueries";

import { USER_NAME } from "../../../constants/index";

import { uploadFile, deleteFile } from "../../../firebase/FileServices";
import { Promise } from "q";
import { setFormStatus, setSuccessStatus } from '../../../actions/user-actions';
import { connect } from 'react-redux';



const mapStateToProps = (state, ownProps) => {
  return {
    formErrorStatus: state.custom_reducer.formErrorStatus,
    formSuccessState: state.custom_reducer.formSuccessState,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setFormStatus: (status) => {
      dispatch(setFormStatus(status))
    },
    setSuccessStatus: (status) => {
      dispatch(setSuccessStatus(status))
    }
  }
}

class Section6 extends Component {
  constructor(props) {
    super(props);

    this.props.setFormStatus({ status: false, title: '', message: '' });
    this.props.setSuccessStatus({ status: false, title: '', message: '' });

    this.state = {
      open: false,
      loading: true,
      spinner: false,
      saveNContinue: false,
      sectionEmpty: true,
      userId: localStorage.USER_ID,
      section: {
        id: "",
        sectionNumber: "",
        title: "",
        subTitle: "",
        description: "",
        sequence: "",
        createdAt: "",
        updatedAt: ""
      },
      question1: {
        showFollowupQuestion: false,
        primaryQuestion:
          "Has your organization been involved in any earned income (income generating) activities in the past 10 years?",
        primaryAnswer: false,
        secondaryQuestion: "If yes, state the exact number of years and provide details of the activities.",
        secondaryAnswer: "",
        weightFactor: "0.3",
        uploads: [],
        validated: true
      },
      question2: {
        showFollowupQuestion: false,
        primaryQuestion:
          "Who will approve your organization’s business plans, once developed?",
        primaryAnswer: "",
        secondaryQuestion: "",
        secondaryAnswer: "",
        weightFactor: "0.0",
        uploads: [],
        validated: false
      },
      question3: {
        showFollowupQuestion: false,
        primaryQuestion:
          "What potential funding sources can your organization access to start a social enterprise?",
        primaryAnswer: "",
        secondaryQuestion: "",
        secondaryAnswer: "",
        secondaryJson: {},
        primaryArray: [{ fundingSource: "" }],
        weightFactor: "0.0",
        uploads: [],
        validated: false
      },
      question4: {
        showFollowupQuestion: false,
        primaryQuestion:
          "Will your organization be able to allocate funding from its internal reserves towards the start-up of the social enterprise?",
        primaryAnswer: false,
        secondaryQuestion: "If Yes, what is the amount from the internal reserves that can be allocated for the start-up of the social enterprise?",
        secondaryAnswer: "",
        weightFactor: "0.4",
        uploads: [],
        validated: true
      },
      question5: {
        showFollowupQuestion: false,
        primaryQuestion:
          "Will your organization recruit specialist staff to support the social enterprise venture?",
        primaryAnswer: false,
        secondaryQuestion:
          "If No, specify the reason.",
        secondaryAnswer: "",
        weightFactor: "0.4",
        uploads: [],
        validated: false
      },
      question6: {
        showFollowupQuestion: false,
        primaryQuestion:
          "Is your organization able and willing to invest in training and capacity building for the internal staff or the specialist recruited?",
        primaryAnswer: false,
        secondaryQuestion:
          "If No, specify the reason.",
        secondaryAnswer: "",
        weightFactor: "0.4",
        uploads: [],
        validated: false
      },
      question7: {
        showFollowupQuestion: false,
        primaryQuestion:
          "Does your organization have cash reserves that can be used as collateral for borrowing venture capital?",
        primaryAnswer: false,
        secondaryQuestion: "",
        secondaryAnswer: "",
        weightFactor: "0.4",
        uploads: [],
        validated: true
      },
      question8: {
        showFollowupQuestion: false,
        primaryQuestion: "In general, has your organization done a recent organizational SWOT or PESTLE analysis?",
        primaryAnswer: false,
        secondaryQuestion:
          "If Yes, please attach a copy of the analysis.",
        secondaryAnswer: "",
        uploads: [],
        fileUrl: null,
        fileRef: null,
        tempFile: "",
        weightFactor: "0.4",
        isUploading: false,
        validated: true
      },
      question9: {
        showFollowupQuestion: false,
        primaryQuestion:
          "Does your organization have a concept about the type of product or service to market to generate an income?",
        primaryAnswer: false,
        secondaryQuestion: "If Yes, details.",
        secondaryAnswer: "",
        weightFactor: "0.4",
        uploads: [],
        validated: true
      },
      question10: {
        showFollowupQuestion: false,
        primaryQuestion: "Does your organization have a basic business plan or a recently conducted market research report/analysis to build up on?",
        primaryAnswer: false,
        secondaryQuestion:
          "If Yes, please attach a copy of the relevant document.",
        secondaryAnswer: "",
        uploads: [],
        fileUrl: null,
        fileRef: null,
        tempFile: "",
        weightFactor: "0.3",
        isUploading: false,
        validated: true
      },
      question11: {
        showFollowupQuestion: true,
        primaryQuestion:
          "What is your organization’s vision for social enterprise?",
        primaryAnswer: "",
        secondaryQuestion: "",
        secondaryAnswer: "",
        weightFactor: "0.0",
        uploads: [],
        validated: false
      },
      question12: {
        showFollowupQuestion: true,
        primaryQuestion:
          "What portion (as a %) of the project funding can your organization raise if it were to start-up an enterprise?",
        primaryAnswer: "",
        secondaryQuestion: "",
        secondaryAnswer: "",
        weightFactor: "0.0",
        uploads: [],
        validated: false
      },
      question13: {
        showFollowupQuestion: true,
        primaryQuestion:
          "Any closing comments?",
        primaryAnswer: "",
        secondaryQuestion: "",
        secondaryAnswer: "",
        weightFactor: "0.0",
        uploads: [],
        validated: true
      },
      validation_status: false,
      validation_arr: []
    };

    this.get_all_submissions().then(submission => {
      submission.map(item => Object.keys(item.submission).map(key => {
        if (item.submission[key]['validated'] !== true) {
          this.state.validation_arr.push(item.submission[key]['validated']);
        }
      }));
    });

    this._getSection3();
    this._getSection3Submissions();

  }

  get_all_submissions = async () => {
    const result = await this.props.client.query({
      query: GET_ALL_SUBMMISSIONS,
      fetchPolicy: 'network-only'
    });
    return result.data.getAllSubmissionsByUser;
  };

  show = () => this.setState({ open: true });

  handleConfirm = () => {
    this._next();
  }

  handleCancel = () => {
    this.setState({
      open: false,

    })
  }


  componentDidMount() {
    window.scrollTo(0, 0)
    setTimeout(() => {
      this.setState({
        loading: false
      });
    }, 2000);
  }
  _goBack = () => {
    this.props.history.goBack();
  };

  _next = () => {
    this.setState({
      saveNContinue: true,
      open: false
    });
    this._submitSection(true);
  };

  _nextWithoutUpdate = () => {
    this.props.history.push(`/assessment/thankyou`);
  };

  _updateSection = () => {
    this.setState({
      spinner: true
    });
    this._submitSection(false);
  };

  _submitSection = (flag) => {
    const { validation_status, validation_arr } = this.state;
    this.props.setFormStatus({ status: false, title: '', message: '' });
    this.props.setSuccessStatus({ status: false, title: '', message: '' });

    const submission = {
      question1: this.state.question1,
      question2: this.state.question2,
      question3: this.state.question3,
      question4: this.state.question4,
      question12: this.state.question12,
      question5: this.state.question5,
      question6: this.state.question6,
      question7: this.state.question7,
      question8: this.state.question8,
      question9: this.state.question9,
      question10: this.state.question10,
      question11: this.state.question11,
      question13: this.state.question13
    };
    let submission6_validation = [];
    Object.keys(submission).map(key => {
      if (submission[key]['validated'] !== true) {
        submission6_validation.push(submission[key]['validated']);
      }
    });
    // console.log(validation_arr);
    // console.log(submission6_validation);

    this.setState({
      spinner: false,
      saveNContinue: false
    });
    this.props.setFormStatus({ status: (validation_arr.length>0 || submission6_validation.length>0)?true:false, title: (validation_arr.length>0 || submission6_validation.length>0)?'Oops! Looks like you have missed something.':'', message: (validation_arr.length>0 || submission6_validation.length>0)?'Please check and answer all the questions/fields before you submit.':"" });

    if((validation_arr.length===0 && submission6_validation.length===0)){
      this.setState({
        open:true
      });
      this._saveSection(submission, flag);
    }
  };

  _saveSection = (submission, flag) => {
    // console.log(submission);
    this.props.client.mutate({
      mutation: SUBMIT_SECTION_1,
      variables: {
        submission: submission,
        sectionId: "ck1gn1be31sbe0b88lv3y56ny"
      }
    }).then(result => {
      this._getSection3Submissions();
      this.setState({
        spinner: false,
        saveNContinue: false,
      });
      if (flag) {
        this.props.history.push(`/assessment/thankyou`);
      }

      this.props.setSuccessStatus({
        status: true,
        title: "",
        message: "Saved successfully!"
      });

    }).catch(error => {
      this.setState({
        spinner: false,
        saveNContinue: false,
      });

      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message:
          "There was an error while trying to update data"

      });
    });
  };

  _getSection3 = () => {
    this.props.client
      .query({
        query: GET_SECTION_V
      })
      .then(result => {
        this.setState({
          section: { ...result.data.getSection1 }
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  _getSection3Submissions = () => {
    this.props.client
      .query({
        query: GET_SECTION_1,
        variables: { sectionId: "ck1gn1be31sbe0b88lv3y56ny" },
        fetchPolicy: 'network-only'
      })
      .then(result => {
        console.log(result);
        if (result.data.getSectionSubmission === null || result.data.getSectionSubmission.length === 0) {
          console.log('there is nothing here');
        } else {
          // Apply values 
          this.setState({
            question1: result.data.getSectionSubmission.submission.question1
              ? result.data.getSectionSubmission.submission.question1
              : this.state.question1,
            question2: result.data.getSectionSubmission.submission.question2
              ? result.data.getSectionSubmission.submission.question2
              : this.state.question2,
            question3: result.data.getSectionSubmission.submission.question3
              ? result.data.getSectionSubmission.submission.question3
              : this.state.question3,
            question4: result.data.getSectionSubmission.submission.question4
              ? result.data.getSectionSubmission.submission.question4
              : this.state.question4,
            question12: result.data.getSectionSubmission.submission.question12
              ? result.data.getSectionSubmission.submission.question12
              : this.state.question12,
            question5: result.data.getSectionSubmission.submission.question5
              ? result.data.getSectionSubmission.submission.question5
              : this.state.question5,
            question6: result.data.getSectionSubmission.submission.question6
              ? result.data.getSectionSubmission.submission.question6
              : this.state.question6,
            question7: result.data.getSectionSubmission.submission.question7
              ? result.data.getSectionSubmission.submission.question7
              : this.state.question7,
            question8: result.data.getSectionSubmission.submission.question8
              ? result.data.getSectionSubmission.submission.question8
              : this.state.question8,
            question9: result.data.getSectionSubmission.submission.question9
              ? result.data.getSectionSubmission.submission.question9
              : this.state.question9,
            question10: result.data.getSectionSubmission.submission.question10
              ? result.data.getSectionSubmission.submission.question10
              : this.state.question10,
            question11: result.data.getSectionSubmission.submission.question11
              ? result.data.getSectionSubmission.submission.question11
              : this.state.question11,
            question13: result.data.getSectionSubmission.submission.question13
              ? result.data.getSectionSubmission.submission.question13
              : this.state.question13,
            sectionEmpty: false
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  // Question1 Stuff
  _question1Followup = state => {
    state
      ? this.setState({
        question1: {
          ...this.state.question1,
          showFollowupQuestion: true,
          primaryAnswer: true,
          validated: this.state.question1.secondaryAnswer.trim() === "" ? false : true
        }
      })
      : this.setState({
        question1: {
          ...this.state.question1,
          showFollowupQuestion: false,
          primaryAnswer: false,
          validated: true
        }
      });
  };

  _question1Onchange = event => {
    this.setState({
      question1: {
        ...this.state.question1,
        [event.target.id]: event.target.value,
        validated: event.target.value.trim() === "" ? false : true
      }
    });
  };

  // Question 2 stuff
  _question2Followup = state => {
    state
      ? this.setState({
        question2: {
          ...this.state.question2,
          showFollowupQuestion: false,
          primaryAnswer: true
        }
      })
      : this.setState({
        question2: {
          ...this.state.question2,
          showFollowupQuestion: true,
          primaryAnswer: false
        }
      });
  };

  _question2Onchange = event => {
    this.setState({
      question2: {
        ...this.state.question2,
        [event.target.id]: event.target.value,
        validated: event.target.value.trim() === "" ? false : true
      }
    });
  };

  // Question 3 stuff
  _question3Followup = state => {
    state
      ? this.setState({
        question3: {
          ...this.state.question3,
          showFollowupQuestion: true,
          primaryAnswer: true
        }
      })
      : this.setState({
        question3: {
          ...this.state.question3,
          showFollowupQuestion: false,
          primaryAnswer: false
        }
      });
  };

  _question3Onchange = event => {
    this.setState({
      question3: {
        ...this.state.question3,
        [event.target.id]: event.target.value
      }
    });
  };

  //Question 4 stuff
  _question4Followup = state => {
    state
      ? this.setState({
        question4: {
          ...this.state.question4,
          showFollowupQuestion: true,
          primaryAnswer: true,
          validated: this.state.question4.secondaryAnswer.trim() === "" ? false : true
        }
      })
      : this.setState({
        question4: {
          ...this.state.question4,
          showFollowupQuestion: false,
          primaryAnswer: false,
          validated: true
        }
      });
  };

  _question4Onchange = event => {
    if (event.target.value < 0) {
      event.preventDefault();
    } else {
      this.setState({
        question4: {
          ...this.state.question4,
          [event.target.id]: event.target.value,
          validated: event.target.value.trim() === "" ? false : true
        }
      });
    }

  };

  // Question 5 stuff
  _question5Followup = state => {
    state
      ? this.setState({
        question5: {
          ...this.state.question5,
          showFollowupQuestion: true,
          primaryAnswer: true,
          validated: true
        }
      })
      : this.setState({
        question5: {
          ...this.state.question5,
          showFollowupQuestion: false,
          primaryAnswer: false,
          validated: this.state.question5.secondaryAnswer.trim() === "" ? false : true
        }
      });
  };

  _question5Onchange = event => {
    this.setState({
      question5: {
        ...this.state.question5,
        [event.target.id]: event.target.value,
        validated: event.target.value.trim() === "" ? false : true
      }
    });
  };

  //Question 6 stuff
  _question6Followup = state => {
    state
      ? this.setState({
        question6: {
          ...this.state.question6,
          showFollowupQuestion: true,
          primaryAnswer: true,
          validated: true
        }
      })
      : this.setState({
        question6: {
          ...this.state.question6,
          showFollowupQuestion: false,
          primaryAnswer: false,
          validated: this.state.question6.secondaryAnswer.trim() === "" ? false : true
        }
      });
  };
  _question6Onchange = event => {
    this.setState({
      question6: {
        ...this.state.question6,
        [event.target.id]: event.target.value,
        validated: event.target.value.trim() === "" ? false : true
      }
    });
  };

  //Question 7 Stuff
  _question7Onchange = state => {
    state
      ? this.setState({
        question7: {
          ...this.state.question7,
          showFollowupQuestion: true,
          primaryAnswer: true
        }
      })
      : this.setState({
        question7: {
          ...this.state.question7,
          showFollowupQuestion: false,
          primaryAnswer: false
        }
      });
  };
  //Question 12 Stuff
  _question12Onchange = event => {
    if (event.target.value > 100 || event.target.value < 0) {
      event.preventDefault();
    } else {
      this.setState({
        question12: {
          ...this.state.question12,
          [event.target.id]: event.target.value,
          validated: event.target.value.trim() === "" ? false : true
        }
      });
    }

  };

  // Question 8 stuff
  _question8Followup = state => {
    state
      ? this.setState({
        question8: {
          ...this.state.question8,
          showFollowupQuestion: true,
          primaryAnswer: true,
          validated: (this.state.question8.fileUrl === null || this.state.question8.fileUrl === undefined) ? false : true
        }
      })
      : this.setState({
        question8: {
          ...this.state.question8,
          showFollowupQuestion: false,
          primaryAnswer: false,
          validated: true
        }
      });
  };


  _question8FileOnchange = event => {
    this.setState({
      question8: {
        ...this.state.question8,
        tempFile: event.target.files[0],
        isUploading: true,
      }
    });
    console.log(event.target.files[0]);
    console.log(this.state);
    this._startUpload8(event.target.files[0]);
    this.setState({
      isUploading: true
    });
  };

  _startUpload8 = async file => {
    const result = await uploadFile(file, localStorage.USER_NAME);
    if (result.status) {
      this.setState({
        isUploading: false,
        question8: {
          ...this.state.question8,
          fileRef: 'uploads/' + localStorage.USER_NAME + '/' + file.name,
          fileUrl: result.url,
          isUploading: false,
          validated: true
        }
      });
    }
  };

  _deleteFile = async (path) => {
    const deleteRef = await deleteFile(path);
    this.setState({
      question8: {
        ...this.state.question8,
        validated: false
      }
    });
    return deleteRef;
  }

  _delete47File = async (path) => {
    const deleteRef = await deleteFile(path);
    this.setState({
      question10: {
        ...this.state.question10,
        validated: false
      }
    });
    return deleteRef;
  }

  _question8Onchange = event => {
    this.setState({
      question8: {
        ...this.state.question8,
        [event.target.id]: event.target.value
      }
    });
  };

  // Question 9 stuff
  _question9Followup = state => {
    state
      ? this.setState({
        question9: {
          ...this.state.question9,
          showFollowupQuestion: true,
          primaryAnswer: true,
          validated: this.state.question9.secondaryAnswer.trim() === "" ? false : true
        }
      })
      : this.setState({
        question9: {
          ...this.state.question9,
          showFollowupQuestion: false,
          primaryAnswer: false,
          validated: true
        }
      });
  };

  _question9Onchange = event => {
    this.setState({
      question9: {
        ...this.state.question9,
        [event.target.id]: event.target.value,
        validated: event.target.value.trim() === "" ? false : true
      }
    });
  };

  // Question 10 stuff
  _question10Followup = state => {
    state
      ? this.setState({
        question10: {
          ...this.state.question10,
          showFollowupQuestion: true,
          primaryAnswer: true,
          validated: (this.state.question10.fileUrl === null || this.state.question10.fileUrl === undefined) ? false : true
        }
      })
      : this.setState({
        question10: {
          ...this.state.question10,
          showFollowupQuestion: false,
          primaryAnswer: false,
          validated: true
        }
      });
  };

  _question10FileOnchange = event => {
    this.setState({
      question10: {
        ...this.state.question10,
        tempFile: event.target.files[0],
        isUploading: true,
      }
    });
    console.log(event.target.files[0]);
    console.log(this.state);
    this._startUpload10(event.target.files[0]);
    this.setState({
      isUploading: true
    });
  };

  _startUpload10 = async file => {
    const result = await uploadFile(file, localStorage.USER_NAME);
    if (result.status) {
      this.setState({
        isUploading: false,
        question10: {
          ...this.state.question10,
          fileRef: 'uploads/' + localStorage.USER_NAME + '/' + file.name,
          fileUrl: result.url,
          isUploading: false,
          validated: true
        }
      });
    }
  };

  // _deleteFile = async (path) => {
  //   const deleteRef = await deleteFile(this.state.question10.fileRef);
  //   return deleteRef;
  // }

  _question10Onchange = event => {
    this.setState({
      question10: {
        ...this.state.question10,
        [event.target.id]: event.target.value
      }
    });
  };

  //Question 11 stuff
  _qeustion11OnChange = event => {
    this.setState({
      question11: {
        ...this.state.question11,
        [event.target.id]: event.target.value,
        validated: event.target.value.trim() === "" ? false : true
      }
    });
  };

  //Question 13 stuff
  _qeustion13OnChange = event => {
    this.setState({
      question13: {
        ...this.state.question13,
        [event.target.id]: event.target.value
      }
    });
  };

  _handleMultiChange31(i, e) {
    const { name, value } = e.target;
    let fields = this.state.question3.primaryArray;
    fields[i][name] = value;

    const toArray = fields.reduce((acc, obj) => [...acc, Object.values(obj).map(y => y.trim())], []);
    const singleArr = [].concat(...toArray);
    const emptyStrings = singleArr.filter(str => str.trim().length <= 0);

    this.setState({
      question3: {
        ...this.state.question3,
        validated: emptyStrings.length === 0 ? true : false
      }
    });
    this.setState({ fields });
  }

  _createMulipleQ31() {

    if (this.state.question3.primaryArray != undefined) {
      return this.state.question3.primaryArray.map((el, i) => (
        <div key={i}>
          <br />

          <div className="uk-grid">
            <div className="uk-width-1-2">
              <label>Funding Source</label> <br />
              <input
                id="fundingSource"
                type="text"
                name="fundingSource"
                placeholder="Funding Source"
                value={el.fundingSource || ''}
                onChange={this._handleMultiChange31.bind(this, i)}
              />

            </div>

            {/* <div className="uk-width-1-2">
              <label>Value</label> <br />
              <Input
                labelPosition="right"
                type="number"
                placeholder="Value"
                id="value"
                name="value"
                style={{ width: "60%" }}
                value={el.value || ''}
                onChange={this._handleMultiChange31.bind(this, i)}
              >
                <Label basic>$</Label>
                <input />
                <Label>.00</Label>
              </Input>
            </div> */}

          </div>
          <br />

          {this.state.question3.primaryArray.length <= 1 ? (
            null
          ) : (
              <div className="ms-Grid-col ms-sm6 ms-lg2 uk-text-right">
                <i class="fas fa-trash-alt multiple_Field_Remove" aria-hidden="true" onClick={this._remoevMoreClick31.bind(this, i)}></i>
                {/* <i className="big minus circle icon multiple_Field_Remove" onClick={this._remoevMoreClick31.bind(this, i)}></i> */}
              </div>
            )}

          < hr className="multiple_Field_Seperator" />

        </div >

      ))
    } else {
      let fields = this.state.question3;
      fields["primaryArray"] = [{ fundingSource: "" }];
      this.setState(fields);
    }
  }

  _addMoreClick31() {
    let fields = this.state.question3.primaryArray;
    fields.push({ fundingSource: "" });
    this.setState({
      question3: {
        ...this.state.question3,
        validated: false
      }
    });
    this.setState(fields);
  }
  _remoevMoreClick31(i) {
    let primaryArray = this.state.question3.primaryArray;
    primaryArray.splice(i, 1);

    const toArray = primaryArray.reduce((acc, obj) => [...acc, Object.values(obj).map(y => y.trim())], []);
    const singleArr = [].concat(...toArray);
    const emptyStrings = singleArr.filter(str => str.trim().length <= 0);

    this.setState({
      question3: {
        ...this.state.question3,
        validated: emptyStrings.length === 0 ? true : false
      }
    });

    this.setState({ primaryArray });
  }

  render() {
    const { formErrorStatus, formSuccessState } = this.props;
    return (
      <div>
        {this.state.loading ? (
          <SectionLoader />
        ) : (
            <div>
              {/* Begin Dark Header */}
              <div className="uk-section-default uk-section">
                <div className="uk-container-small uk-margin-auto uk-margin-medium-top">
                  <h3 className=" uk-text-light">
                    {" "}
                    Section {this.state.section.sectionNumber}{" "}
                    {/* {this.state.section.title}{" "} */}
                  </h3>
                </div>
                <hr />

                <div
                  className="uk-container-small uk-margin-auto uk-margin-small-top uk-padding-small paper-read"
                  uk-scrollspy="target: > .paper; cls:uk-animation-slide-bottom-small; delay: 200"
                >
                  <div className="paper uk-link-reset" style={{ backgroundColor: "#99D6EA" }}>
                    <div className="uk-grid uk-flex-middle">
                      <div className="uk-width-1-6 uk-flex-first uk-text-center">
                        <i className="far fa-comment-alt icon-xxlarge"></i>
                      </div>
                      <div className="uk-width-5-6">
                        <h4 className="uk-margin-remove" style={{ color: "#ffffff" }}>
                          {" "}
                          {this.state.section.title}{" "}
                        </h4>
                        {/* <p className="uk-margin-remove">
                          {" "}
                          {this.state.section.description}{" "}
                        </p> */}
                      </div>
                    </div>
                  </div>


                  <div className="uk-link-reset paper uk-margin notify">
                    <p className="uk-text-muted uk-margin-remove-top uk-margin-small-bottom notify">Please answer all the questions, check and submit your responses by clicking ‘Finish’.</p>
                    <p>Once you submit the assessment, you will not be able to amend the responses unless authorised by an Administrator from the SE Hub.</p>

                  </div>
                  <Form error success className="uk-margin">
                    {/* Begin Question 1 */}
                    <div className="uk-link-reset paper uk-margin s1q1">
                      <h4>
                        <b>36)</b>{" "}
                        Has your organization been involved in any earned income (income generating) activities in the past 10 years?{" "}
                      </h4>

                      <Button.Group size="large" className={localStorage.RAT_LANG=='ar'? "buttons_arabic_style": "" } >
                        <Button positive={this.state.question1.showFollowupQuestion ? true : false} onClick={() => this._question1Followup(true)}>
                          YES
                      </Button>
                        <Button.Or />
                        <Button positive={this.state.question1.showFollowupQuestion ? false : true} onClick={() => this._question1Followup(false)}>
                          NO
                      </Button>
                      </Button.Group>
                      {this.state.question1.showFollowupQuestion ? (
                        <div>
                          <div className="uk-form-label">
                            If yes, state the exact number of years and  provide details
    of the activities.
                        </div>
                          <div className="uk-margin">
                            <span className="uk-form-icon">
                              <i className="far fa-User icon-medium"></i>
                            </span>

                            <TextArea
                              onChange={this._question1Onchange}
                              name="secondaryAnswer"
                              id="secondaryAnswer"
                              value={this.state.question1.secondaryAnswer}
                              className="text-area-height uk-input uk-form-width-large"
                              placeholder="Describe here"
                              type="text"
                            />
                          </div>
                        </div>
                      ) : (
                          ""
                        )}
                    </div>
                    {/* End Question 1 */}

                    {/* Begin Question2 */}
                    <div className="uk-link-reset paper uk-margin s1q1">
                      <h4>
                        <b>37)</b>{" "}
                        Who will approve your organization’s business plans, once developed?{" "}
                      </h4>
                      <p class="uk-text-muted uk-margin-remove-top uk-margin-small-bottom">Please answer and support the response with additional details.</p>
                      <div>
                        <div className="uk-form-label"></div>
                        <div className="uk-margin">
                          <span className="uk-form-icon">
                            <i className="far fa-User icon-medium"></i>
                          </span>

                          <TextArea
                            onChange={this._question2Onchange}
                            name="primaryAnswer"
                            id="primaryAnswer"
                            value={this.state.question2.primaryAnswer}
                            className="text-area-height uk-input uk-form-width-large"
                            placeholder="Describe here"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                    {/* End Question 2 */}

                    {/* Begin Question 3 */}
                    <div className="uk-link-reset paper uk-margin s1q1">
                      <h4>
                        <b>38)</b>{" "}
                        What potential funding sources can your organization access to start a social enterprise?{" "}
                      </h4>
                      <p class="uk-text-muted uk-margin-remove-top uk-margin-small-bottom">(eg: Special projects, External grants, Financial Institutions, etc.)</p>
                      <div>
                        <div className="uk-form-label"></div>
                        <div className="uk-margin">
                          <span className="uk-form-icon">
                            <i className="far fa-User icon-medium"></i>
                          </span>
                          <div >

                            {this._createMulipleQ31()}
                            <div className="uk-text-right"><button onClick={this._addMoreClick31.bind(this)} className="uk-button uk-button-big uk-button-primary multiple_Field_Plus" type="button">Add One More</button></div>

                          </div>

                          {/* <TextArea
                            onChange={this._question3Onchange}
                            name="primaryAnswer"
                            id="primaryAnswer"
                            value={this.state.question3.primaryAnswer}
                            className="text-area-height uk-input uk-form-width-large"
                            placeholder="Describe here"
                            type="text"
                          /> */}
                        </div>
                      </div>
                    </div>
                    {/* End Question 3 */}

                    {/* Begin Question 4 */}
                    <div className="uk-link-reset paper uk-margin s1q1">
                      <h4>
                        <b>39)</b>{" "}
                        Will your organization be able to allocate funding from its internal reserves towards the start-up of the social enterprise?{" "}
                      </h4>
                      <Button.Group size="large" className={localStorage.RAT_LANG=='ar'? "buttons_arabic_style": "" } >
                        <Button positive={this.state.question4.showFollowupQuestion ? true : false} onClick={() => this._question4Followup(true)}>
                          YES
                      </Button>
                        <Button.Or />
                        <Button positive={this.state.question4.showFollowupQuestion ? false : true} onClick={() => this._question4Followup(false)}>
                          NO
                      </Button>
                      </Button.Group>
                      {this.state.question4.showFollowupQuestion ? (
                        <div>
                          <div className="uk-form-label">
                          If Yes, what is the amount from the internal reserves that can be allocated for the start-up of the social enterprise?
                          </div>
                          <div className="uk-margin">
                            <span className="uk-form-icon">
                              <i className="far fa-User icon-medium"></i>
                            </span>

                            <Input
                              labelPosition="right"
                              type="number"
                              placeholder="Amount"
                              id="secondaryAnswer"
                              name="secondaryAnswer"
                              value={this.state.question4.secondaryAnswer}
                              onChange={this._question4Onchange}
                              onKeyDown={e => {
                                if (/[\+\-\.\,]/.test(e.key) || e.key.toUpperCase() === "E") {
                                  e.preventDefault();
                                }
                              }}
                            >
                              <Label basic>$</Label>
                              <input />
                              <Label>.00</Label>
                            </Input>
                          </div>
                        </div>
                      ) : (
                          ""
                        )}
                    </div>
                    {/* End Question 4 */}

                    {/* Begin Quesion 11 */}
                    <div className="uk-link-reset paper uk-margin s1q1">
                      <h4>
                        <b>40)</b>{" "}
                        What portion (as a %) of the project funding can your organization raise if it were to start-up an enterprise?{" "}
                      </h4>
                      <div>
                        <div className="uk-form-label"></div>
                        <div className="uk-margin">
                          <span className="uk-form-icon">
                            <i className="far fa-User icon-medium"></i>
                          </span>

                          <Input
                            labelPosition="right"
                            type="number"
                            placeholder="Portion"
                            id="primaryAnswer"
                            name="primaryAnswer"
                            value={this.state.question12.primaryAnswer}
                            onChange={this._question12Onchange}
                            onKeyDown={(e) => {
                              if (/[\+\-\.\,]/.test(e.key) || e.key.toUpperCase() === "E") {
                                e.preventDefault();
                              }
                            }}
                          >
                            {/* <Label basic>$</Label> */}
                            <input />
                            <Label>%</Label>
                          </Input>
                        </div>
                      </div>
                    </div>
                    {/* End Question 11 */}

                    {/* Begin Question 5 */}
                    <div className="uk-link-reset paper uk-margin s1q1">
                      <h4>
                        <b>41)</b>{" "}
                        Will your organization recruit specialist staff to support the social enterprise venture?{" "}
                      </h4>

                      <Button.Group size="large" className={localStorage.RAT_LANG=='ar'? "buttons_arabic_style": "" } >
                        <Button positive={this.state.question5.showFollowupQuestion ? true : false} onClick={() => this._question5Followup(true)}>
                          YES
                      </Button>
                        <Button.Or />
                        <Button positive={this.state.question5.showFollowupQuestion ? false : true} onClick={() => this._question5Followup(false)}>
                          NO
                      </Button>
                      </Button.Group>
                      {this.state.question5.showFollowupQuestion ? (
                        ""
                      ) : (
                          <div>
                            <div className="uk-form-label">
                              If No, specify the reason.
                      </div>
                            <div className="uk-margin">
                              <span className="uk-form-icon">
                                <i className="far fa-User icon-medium"></i>
                              </span>

                              <TextArea
                                onChange={this._question5Onchange}
                                name="secondaryAnswer"
                                id="secondaryAnswer"
                                value={this.state.question5.secondaryAnswer}
                                className="text-area-height uk-input uk-form-width-large"
                                placeholder="Describe here"
                                type="text"
                              />
                            </div>
                          </div>
                        )}
                    </div>
                    {/* End Question 5 */}

                    {/* Begin Question 6 */}
                    <div className="uk-link-reset paper uk-margin s1q1">
                      <h4>
                        <b>42)</b>{" "}
                        Is your organization able and willing to invest in training and capacity building for the internal staff or the specialist recruited?{" "}
                      </h4>

                      <Button.Group size="large" className={localStorage.RAT_LANG=='ar'? "buttons_arabic_style": "" } >
                        <Button positive={this.state.question6.showFollowupQuestion ? true : false} onClick={() => this._question6Followup(true)}>
                          YES
                      </Button>
                        <Button.Or />
                        <Button positive={this.state.question6.showFollowupQuestion ? false : true} onClick={() => this._question6Followup(false)}>
                          NO
                      </Button>
                      </Button.Group>
                      {this.state.question6.showFollowupQuestion ? (
                        ""
                      ) : (
                          <div>
                            <div className="uk-form-label">
                              If No, specify the reason.
                      </div>
                            <div className="uk-margin">
                              <span className="uk-form-icon">
                                <i className="far fa-User icon-medium"></i>
                              </span>

                              <TextArea
                                onChange={this._question6Onchange}
                                name="secondaryAnswer"
                                id="secondaryAnswer"
                                value={this.state.question6.secondaryAnswer}
                                className="text-area-height uk-input uk-form-width-large"
                                placeholder="Describe here"
                                type="text"
                              />
                            </div>
                          </div>
                        )}
                    </div>
                    {/* End Question 6 */}

                    {/* Begin Question 7 */}
                    <div className="uk-link-reset paper uk-margin s1q1">
                      <h4>
                        <b>43)</b>{" "}
                        Does your organization have cash reserves that can be used as collateral for borrowing venture capital?{" "}
                      </h4>
                      {/* <p class="uk-text-muted uk-margin-remove-top uk-margin-small-bottom">Please answer and support the response with additional details.</p> */}
                      <div>
                        <div className="uk-form-label"></div>
                        <div className="uk-margin">
                          <span className="uk-form-icon">
                            <i className="far fa-User icon-medium"></i>
                          </span>

                          <Button.Group size="large" className={localStorage.RAT_LANG=='ar'? "buttons_arabic_style": "" } >
                            <Button positive={this.state.question7.showFollowupQuestion ? true : false} onClick={() => this._question7Onchange(true)}>
                              YES
                          </Button>
                            <Button.Or />
                            <Button
                              positive={this.state.question7.showFollowupQuestion ? false : true}
                              onClick={() => this._question7Onchange(false)}
                            >
                              NO
                          </Button>
                          </Button.Group>

                          {/* <TextArea
                            onChange={this._question7Onchange}
                            name="primaryAnswer"
                            id="primaryAnswer"
                            value={this.state.question7.primaryAnswer}
                            className="text-area-height uk-input uk-form-width-large"
                            placeholder="Describe here"
                            type="text"
                          /> */}
                        </div>
                      </div>
                    </div>
                    {/* End Question 7 */}

                    {/* Begin Question 8 */}
                    <div className="uk-link-reset paper uk-margin s1q1">
                      <h4><b>44)</b> In general, has your organization done a recent organizational SWOT or PESTLE analysis? </h4>

                      <Button.Group size="large" className={localStorage.RAT_LANG=='ar'? "buttons_arabic_style": "" } >
                        <Button positive={this.state.question8.showFollowupQuestion ? true : false} onClick={() => this._question8Followup(true)}>
                          YES
                      </Button>
                        <Button.Or />
                        <Button positive={this.state.question8.showFollowupQuestion ? false : true} onClick={() => this._question8Followup(false)}>
                          NO
                      </Button>
                      </Button.Group>

                      {this.state.question8.showFollowupQuestion ? (
                        <div>

                          <div className="uk-form-label">
                            If Yes, please attach a copy of the analysis.
                      </div>

                          <div className="uk-margin">
                            <span className="uk-form-icon">
                              <i className="far fa-User icon-medium"></i>
                            </span>

                            {/* File Component */}
                            {this.state.question8.fileUrl !== null && this.state.question8.fileUrl !== undefined ?
                              (
                                <div className="file-upload-group" >
                                  <Popup
                                    trigger={<Button className="file-icon" circular icon="attach" />}
                                    flowing
                                    hoverable
                                  >
                                    <Grid centered divided columns={2}>
                                      <Grid.Column textAlign="center">
                                        <Button circular icon='download' onClick={() => { window.open(this.state.question8.fileUrl, '_blank'); }} />

                                      </Grid.Column>
                                      <Grid.Column textAlign="center">
                                        <Button circular icon='trash alternate outline' onClick={() => {
                                          this._deleteFile(this.state.question8.fileRef).then(result => {
                                            console.log(result);
                                            this.setState({
                                              question8: {
                                                ...this.state.question8,
                                                fileUrl: null,
                                                fileRef: null,
                                              }
                                            });
                                          });
                                          // this.setState({
                                          //   question4: {
                                          //     ...this.state.question4,
                                          //     fileRef: null,
                                          //     fileUrl: null,
                                          //   }
                                          // })
                                        }} />

                                      </Grid.Column>
                                    </Grid>
                                  </Popup>
                                </div>
                              ) : (
                                <Input
                                  onChange={this._question8FileOnchange}
                                  name="upload"
                                  id="upload"
                                  value={this.state.question8.uploads}
                                  className="text-area-height uk-input uk-form-width-large"
                                  placeholder="Upload here"
                                  type="file"
                                />
                              )
                            }

                            {/* File Component */}

                          </div>
                          {this.state.question8.isUploading ? (
                            <Message icon>
                              <Icon name="circle notched" loading />
                              <Message.Content>
                                <Message.Header>Just a moment…</Message.Header>
                                We are uploading your document.
                            </Message.Content>
                            </Message>
                          ) : (
                              ""
                            )}
                        </div>
                      ) : (
                          ""
                        )}
                    </div>
                    {/* End Question 8 */}

                    {/* Begin Question 8 */}
                    <div className="uk-link-reset paper uk-margin s1q1">
                      <h4>
                        <b>45)</b>{" "}
                        Does your organization have a concept about the type of product or service to market to generate an income?{" "}
                      </h4>
                      {/* <p class="uk-text-muted uk-margin-remove-top uk-margin-small-bottom">Please answer and support the response with additional details.</p> */}

                      <Button.Group size="large" className={localStorage.RAT_LANG=='ar'? "buttons_arabic_style": "" } >
                        <Button positive={this.state.question9.showFollowupQuestion ? true : false} onClick={() => this._question9Followup(true)}>
                          YES
                      </Button>
                        <Button.Or />
                        <Button positive={this.state.question9.showFollowupQuestion ? false : true} onClick={() => this._question9Followup(false)}>
                          NO
                      </Button>
                      </Button.Group>
                      {this.state.question9.showFollowupQuestion ? (
                        <div>
                          <div className="uk-form-label">
                            If Yes, details.
                  </div>
                          <div className="uk-margin">
                            <span className="uk-form-icon">
                              <i className="far fa-User icon-medium"></i>
                            </span>

                            <TextArea
                              onChange={this._question9Onchange}
                              name="secondaryAnswer"
                              id="secondaryAnswer"
                              value={this.state.question9.secondaryAnswer}
                              className="text-area-height uk-input uk-form-width-large"
                              placeholder="Describe here"
                              type="text"
                            />
                          </div>
                        </div>
                      ) : (
                          ""
                        )}
                    </div>
                    {/* Begin Question 8 */}

                    {/* Begin Question 9 */}
                    <div className="uk-link-reset paper uk-margin s1q1">
                      <h4><b>46)</b> Does your organization have a basic business plan or a recently conducted market research report/analysis to build up on? </h4>

                      <Button.Group size="large" className={localStorage.RAT_LANG=='ar'? "buttons_arabic_style": "" } >
                        <Button positive={this.state.question10.showFollowupQuestion ? true : false} onClick={() => this._question10Followup(true)}>
                          YES
                      </Button>
                        <Button.Or />
                        <Button positive={this.state.question10.showFollowupQuestion ? false : true} onClick={() => this._question10Followup(false)}>
                          NO
                      </Button>
                      </Button.Group>

                      {this.state.question10.showFollowupQuestion ? (
                        <div>
                          <div className="uk-form-label">
                            If Yes, please attach a copy of the relevant document.
                  </div>

                          <div className="uk-margin">
                            <span className="uk-form-icon">
                              <i className="far fa-User icon-medium"></i>
                            </span>

                            {/* File Component */}
                            {this.state.question10.fileUrl !== null && this.state.question10.fileUrl !== undefined ?
                              (
                                <div className="file-upload-group" >
                                  <Popup
                                    trigger={<Button className="file-icon" circular icon="attach" />}
                                    flowing
                                    hoverable
                                  >
                                    <Grid centered divided columns={2}>
                                      <Grid.Column textAlign="center">
                                        <Button circular icon='download' onClick={() => { window.open(this.state.question10.fileUrl, '_blank'); }} />

                                      </Grid.Column>
                                      <Grid.Column textAlign="center">
                                        <Button circular icon='trash alternate outline' onClick={() => {
                                          this._delete47File(this.state.question10.fileRef).then(result => {
                                            console.log(result);
                                            this.setState({
                                              question10: {
                                                ...this.state.question10,
                                                fileUrl: null,
                                                fileRef: null,
                                              }
                                            });
                                          });
                                          // this.setState({
                                          //   question4: {
                                          //     ...this.state.question4,
                                          //     fileRef: null,
                                          //     fileUrl: null,
                                          //   }
                                          // })
                                        }} />

                                      </Grid.Column>
                                    </Grid>
                                  </Popup>
                                </div>
                              ) : (
                                <Input
                                  onChange={this._question10FileOnchange}
                                  name="upload"
                                  id="upload"
                                  value={this.state.question10.uploads}
                                  className="text-area-height uk-input uk-form-width-large"
                                  placeholder="Upload here"
                                  type="file"
                                />
                              )
                            }

                            {/* File Component */}

                          </div>
                          {this.state.question10.isUploading ? (
                            <Message icon>
                              <Icon name="circle notched" loading />
                              <Message.Content>
                                <Message.Header>Just a moment…</Message.Header>
                                We are uploading your document.
                            </Message.Content>
                            </Message>
                          ) : (
                              ""
                            )}
                        </div>
                      ) : (
                          ""
                        )}
                    </div>
                    {/* End Question 9 */}

                    {/* Begin Question 10 */}
                    <div className="uk-link-reset paper uk-margin s1q1">
                      <h4>
                        <b>47)</b>{" "}
                        What is your organization’s vision for social enterprise?{" "}
                      </h4>
                      <p class="uk-text-muted uk-margin-remove-top uk-margin-small-bottom">
                        {/* Please answer and support the response with additional details. */}
                      </p>

                      {this.state.question11.showFollowupQuestion ? (
                        <div>

                          <div className="uk-margin">
                            <span className="uk-form-icon">
                              <i className="far fa-User icon-medium"></i>
                            </span>

                            <TextArea
                              onChange={this._qeustion11OnChange}
                              name="primaryAnswer"
                              id="primaryAnswer"
                              value={this.state.question11.primaryAnswer}
                              className="text-area-height uk-input uk-form-width-large"
                              placeholder="Describe here"
                              type="text"
                            />
                          </div>
                        </div>
                      ) : (
                          ""
                        )}
                    </div>
                    {/* End Question 10 */}

                    {/* Begin Question 11 */}
                    <div className="uk-link-reset paper uk-margin s1q1">
                      <h4>
                        <b>48)</b>{" "}
                        Any closing comments?{" "}
                      </h4>
                      {/* <p class="uk-text-muted uk-margin-remove-top uk-margin-small-bottom">Please answer and support the response with additional details.</p> */}

                      {this.state.question13.showFollowupQuestion ? (
                        <div>

                          <div className="uk-margin">
                            <span className="uk-form-icon">
                              <i className="far fa-User icon-medium"></i>
                            </span>

                            <TextArea
                              onChange={this._qeustion13OnChange}
                              name="primaryAnswer"
                              id="primaryAnswer"
                              value={this.state.question13.primaryAnswer}
                              className="text-area-height uk-input uk-form-width-large"
                              placeholder="Describe here"
                              type="text"
                            />
                          </div>
                        </div>
                      ) : (
                          ""
                        )}
                    </div>
                    {/* End Question 11 */}
                    {(formErrorStatus.status) ? (
                       <div className="danger_alert">{formErrorStatus.message}</div>
                      // <Message
                      //   error
                      //   header={formErrorStatus.title}
                      //   content={formErrorStatus.message}
                      // />
                    ) : ((formSuccessState.status) ? (
                      <div className="success_alert">{formSuccessState.message}</div>
                      // <Message
                      //   success
                      //   content={formSuccessState.message}
                      // />
                    ) : (''))}

                    <hr />
                    {/* Begin Footer */}
                    <div className="uk-grid uk-text-center button_div">
                      <div className="uk-width-1-3@m">
                        <Button
                          basic
                          className="outline-button blueButton"
                          onClick={this._goBack}
                        >
                          Go Back
                      </Button>
                      </div>

                      <div className="uk-width-expand@m">
                        {!this.state.sectionEmpty ? (
                          <Button
                            basic
                            onClick={this._updateSection}
                            className="outline-button yellowButton"
                            loading={this.state.spinner}
                          >
                            Save
                        </Button>
                        ) : (
                            ""
                          )}
                      </div>

                      <div className="uk-width-1-3@m">
                        {!this.state.sectionEmpty ? (
                          <div>
                            <Button
                              onClick={this._nextWithoutUpdate}
                              className="next-button purpleButton"
                              loading={this.state.saveNContinue}
                              basic
                              onClick={this.show}

                            >
                              Finish
                          </Button>
                            <Confirm
                              open={this.state.open}
                              onCancel={this.handleCancel}
                              onConfirm={this.handleConfirm}
                              confirmButton="Yes"
                              cancelButton='No'
                              content='Are you sure you want to submit?'
                            />
                          </div>
                        ) : (
                            <div>
                              <Button
                                onClick={this._next}
                                basic
                                className="outline-button"
                                loading={this.state.saveNContinue}
                                // onClick={this.show}

                              >
                                Finish
                          </Button>
                              <Confirm
                                open={this.state.open}
                                onCancel={this.handleCancel}
                                onConfirm={this.handleConfirm}
                                confirmButton="Yes"
                                cancelButton='No'
                                content='Are you sure you want to submit?'
                              />
                            </div>
                          )}

                        {/* <div class="spinner"></div>  */}
                      </div>
                    </div>
                    {/* End Footer */}
                  </Form>
                </div>
              </div>
              {/* End Dark Header */}
            </div>
          )}
      </div>
    );
  }
}


export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(Section6)));
