import React, { Component } from "react";

class SectionHeaderLoader extends Component {
  render() {
    return (
      <div class="uk-background-default uk-margin-top uk-padding loading-table">
        <div class="uk-grid" uk-grid>
          <div class="uk-width-expand@m">
            <form class=" uk-flex-inline uk-flex-middle uk-text-small">
              <p class="uk-margin-remove-bottom uk-margin-right">
                {" "}
                {" "}
              </p>
            </form>
          </div>
          <div class="uk-width-auto@m uk-text-small"></div>
        </div>
      </div>
    );
  }
}

export default SectionHeaderLoader;
