import React, { Component } from "react";

class SubmissionsAdminUserLoader extends Component {
    render() {
        return (
            <tbody>
                <tr className="loading-table">
                    <td> ... </td>
                    <td> ... </td>
                    <td> ... </td>
                    <td> ... </td>
                    <td> DD-MMM-YYYY </td>
                    <td> DD-MMM-YYYY </td>
                    <td className="uk-flex-inline uk-flex-middle">
                        <button className="uk-button uk-button-default admin-table-btn">
                            <i className="fas fa-eye uk-visible@m"></i>
                        </button>
                        <button className="uk-button uk-button-default admin-table-btn">
                            <i className="fas fa-comments uk-visible@m"></i>
                        </button>
                        <button className="uk-button uk-button-default admin-table-btn">
                            <i className="fas fa-download uk-visible@m"></i>
                        </button>
                        <button className="uk-button uk-button-default admin-table-btn">
                            <i className="fas fa-redo uk-visible@m"></i>
                        </button>
                        <button className="uk-button uk-button-default admin-table-btn">
                            <i className="fas fa-trash uk-visible@m"></i>
                        </button>
                    </td>
                </tr>
                <br></br>
                <tr className="loading-table">
                    <td> ... </td>
                    <td> ... </td>
                    <td> ... </td>
                    <td> ... </td>
                    <td> DD-MMM-YYYY </td>
                    <td> DD-MMM-YYYY </td>
                    <td className="uk-flex-inline uk-flex-middle">
                        <button className="uk-button uk-button-default admin-table-btn">
                            <i className="fas fa-eye uk-visible@m"></i>
                        </button>
                        <button className="uk-button uk-button-default admin-table-btn">
                            <i className="fas fa-comments uk-visible@m"></i>
                        </button>
                        <button className="uk-button uk-button-default admin-table-btn">
                            <i className="fas fa-download uk-visible@m"></i>
                        </button>
                        <button className="uk-button uk-button-default admin-table-btn">
                            <i className="fas fa-redo uk-visible@m"></i>
                        </button>
                        <button className="uk-button uk-button-default admin-table-btn">
                            <i className="fas fa-trash uk-visible@m"></i>
                        </button>
                    </td>
                </tr>
                <br></br>
                <tr className="loading-table">
                    <td> ... </td>
                    <td> ... </td>
                    <td> ... </td>
                    <td> ... </td>
                    <td> DD-MMM-YYYY </td>
                    <td> DD-MMM-YYYY </td>
                    <td className="uk-flex-inline uk-flex-middle">
                        <button className="uk-button uk-button-default admin-table-btn">
                            <i className="fas fa-eye uk-visible@m"></i>
                        </button>
                        <button className="uk-button uk-button-default admin-table-btn">
                            <i className="fas fa-comments uk-visible@m"></i>
                        </button>
                        <button className="uk-button uk-button-default admin-table-btn">
                            <i className="fas fa-download uk-visible@m"></i>
                        </button>
                        <button className="uk-button uk-button-default admin-table-btn">
                            <i className="fas fa-redo uk-visible@m"></i>
                        </button>
                        <button className="uk-button uk-button-default admin-table-btn">
                            <i className="fas fa-trash uk-visible@m"></i>
                        </button>
                    </td>
                </tr>
                <br></br>
                <tr className="loading-table">
                    <td> ... </td>
                    <td> ... </td>
                    <td> ... </td>
                    <td> ... </td>
                    <td> DD-MMM-YYYY </td>
                    <td> DD-MMM-YYYY </td>
                    <td className="uk-flex-inline uk-flex-middle">
                        <button className="uk-button uk-button-default admin-table-btn">
                            <i className="fas fa-eye uk-visible@m"></i>
                        </button>
                        <button className="uk-button uk-button-default admin-table-btn">
                            <i className="fas fa-comments uk-visible@m"></i>
                        </button>
                        <button className="uk-button uk-button-default admin-table-btn">
                            <i className="fas fa-download uk-visible@m"></i>
                        </button>
                        <button className="uk-button uk-button-default admin-table-btn">
                            <i className="fas fa-redo uk-visible@m"></i>
                        </button>
                        <button className="uk-button uk-button-default admin-table-btn">
                            <i className="fas fa-trash uk-visible@m"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        );
    }
}

export default SubmissionsAdminUserLoader;