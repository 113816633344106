import React, { Component } from "react";
import Reg from "./Reg";
import { Tab } from "semantic-ui-react";
import Progress from './Progress';
import AccountInfo from './AccountInfo';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: this.props.location.pathname === "/submissions" ? 1 : 0
    };
  }
  render() {
    const { match } = this.props;
    const { activeIndex } = this.state;
    return (
      <div
        // uk-height-viewport="offset-top: true; offset-bottom: true"
        className="uk-flex uk-flex-middle uk-section-default uk-section"
        // class="uk-margin"
        style={{backgroundColor:"#edeff0"}}
        uk-scrollspy="target: > .reg-holder; cls:uk-animation-slide-bottom-small; delay: 200"
      >
        <div className="uk-width-1-2@m uk-width-1-2@s uk-margin-auto reg-holder">
          <Router>
              <Route exact path={`${match.url}`} component={AccountInfo} />
            </Router>
        </div>

      </div>
    );
  }
}


const styles = {
  darkBack: {
    backgroundColor: "rgba(0, 0, 0, 0.52)",
    backgroundBlendMode: "color-burn"
  },
  poweredBy: {
    bottom: "8px",
    right: "60px"
  },
  graduationIcon: {
    fontSize: "60px"
  },
  tabStyle: {
    position: "absolute",
    marginTop: "-52px",
    marginLeft: "32px"
  }
};
export default withRouter(Profile);
// export default Profile;
