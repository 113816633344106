import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { setFormStatus, setSuccessStatus } from '../../actions/user-actions';
import { withApollo } from "react-apollo";
import { Form, Dropdown, input, Message } from "semantic-ui-react";
import { GET_MY_PROFILE, UPDATE_PASSWORD } from "../../queries/UserQueries";
import zxcvbn from 'zxcvbn';
import { withNamespaces } from "react-i18next";

var countryOptions = [];
const countryList = {
  rg1: [
    { "key": "ctr1", "value": "Benin", "text": "Benin" },
    { "key": "ctr2", "value": "Botswana", "text": "Botswana" },
    { "key": "ctr3", "value": "Burkina Faso", "text": "Burkina Faso" },
    { "key": "ctr4", "value": "Burundi", "text": "Burundi" },
    { "key": "ctr5", "value": "Cameroon", "text": "Cameroon" },
    { "key": "ctr6", "value": "Cape Verde", "text": "Cape Verde" },
    { "key": "ctr7", "value": "Central African Republic", "text": "Central African Republic" },
    { "key": "ctr8", "value": "Chad", "text": "Chad" },
    { "key": "ctr9", "value": "Comoros", "text": "Comoros" },
    { "key": "ctr10", "value": "Republic of the Congo", "text": "Republic of the Congo" },
    { "key": "ctr11", "value": "Democratic Republic of the Congo", "text": "Democratic Republic of the Congo" },
    { "key": "ctr12", "value": "Cote d’Ivoire", "text": "Cote d’Ivoire" },
    { "key": "ctr13", "value": "Ethiopia", "text": "Ethiopia" },
    { "key": "ctr14", "value": "Ghana", "text": "Ghana" },
    { "key": "ctr15", "value": "Guinea-Bissau", "text": "Guinea-Bissau" },
    { "key": "ctr16", "value": "Guinea-Conakry", "text": "Guinea-Conakry" },
    { "key": "ctr17", "value": "Kenya", "text": "Kenya" },
    { "key": "ctr18", "value": "Lesotho", "text": "Lesotho" },
    { "key": "ctr19", "value": "Liberia", "text": "Liberia" },
    { "key": "ctr20", "value": "Madagascar", "text": "Madagascar" },
    { "key": "ctr21", "value": "Malawi", "text": "Malawi" },
    { "key": "ctr22", "value": "Mali", "text": "Mali" },
    { "key": "ctr23", "value": "Mozambique", "text": "Mozambique" },
    { "key": "ctr24", "value": "Namibia", "text": "Namibia" },
    { "key": "ctr25", "value": "Niger", "text": "Niger" },
    { "key": "ctr26", "value": "Nigeria", "text": "Nigeria" },
    { "key": "ctr27", "value": "Rwanda", "text": "Rwanda" },
    { "key": "ctr28", "value": "Senegal", "text": "Senegal" },
    { "key": "ctr29", "value": "Sierra Leone", "text": "Sierra Leone" },
    { "key": "ctr30", "value": "Swaziland", "text": "Swaziland" },
    { "key": "ctr31", "value": "Tanzania", "text": "Tanzania" },
    { "key": "ctr32", "value": "Togo", "text": "Togo" },
    { "key": "ctr33", "value": "Uganda", "text": "Uganda" },
    { "key": "ctr34", "value": "Zambia", "text": "Zambia" },
    { "key": "ctr35", "value": "Angola", "text": "Angola" },
    { "key": "ctr36", "value": "Mauritius", "text": "Mauritius" },
    { "key": "ctr37", "value": "Republic of South Africa", "text": "Republic of South Africa" },
    { "key": "ctr38", "value": "Sao Tome & Principe", "text": "Sao Tome & Principe" },
    { "key": "ctr39", "value": "Seychelles", "text": "Seychelles" },
    { "key": "ctr40", "value": "South Sudan", "text": "South Sudan" },
    { "key": "ctr41", "value": "Zimbabwe", "text": "Zimbabwe" }
  ],
  rg2: [
    { "key": "ctr42", "value": "Algeria", "text": "Algeria" },
    { "key": "ctr43", "value": "Bahrain", "text": "Bahrain" },
    { "key": "ctr44", "value": "Djibouti", "text": "Djibouti" },
    { "key": "ctr45", "value": "Egypt", "text": "Egypt" },
    { "key": "ctr46", "value": "Jordan", "text": "Jordan" },
    { "key": "ctr47", "value": "Lebanon", "text": "Lebanon" },
    { "key": "ctr48", "value": "Mauritania", "text": "Mauritania" },
    { "key": "ctr49", "value": "Morocco", "text": "Morocco" },
    { "key": "ctr50", "value": "Palestine", "text": "Palestine" },
    { "key": "ctr51", "value": "Somaliland", "text": "Somaliland" },
    { "key": "ctr52", "value": "Sudan", "text": "Sudan" },
    { "key": "ctr53", "value": "Syria", "text": "Syria" },
    { "key": "ctr54", "value": "Tunisia", "text": "Tunisia" },
    { "key": "ctr55", "value": "Iraq", "text": "Iraq" },
    { "key": "ctr56", "value": "Yemen", "text": "Yemen" }
  ],
  rg3: [
    { "key": "ctr57", "value": "Cambodia", "text": "Cambodia" },
    { "key": "ctr58", "value": "China", "text": "China" },
    { "key": "ctr59", "value": "Cook Islands", "text": "Cook Islands" },
    { "key": "ctr60", "value": "Fiji", "text": "Fiji" },
    { "key": "ctr61", "value": "Hong Kong", "text": "Hong Kong" },
    { "key": "ctr62", "value": "Indonesia", "text": "Indonesia" },
    { "key": "ctr63", "value": "Japan", "text": "Japan" },
    { "key": "ctr64", "value": "Kiribati", "text": "Kiribati" },
    { "key": "ctr65", "value": "Democratic People's Republic of Korea", "text": "Democratic People's Republic of Korea" },
    { "key": "ctr66", "value": "Republic of Korea", "text": "Republic of Korea" },
    { "key": "ctr67", "value": "Malaysia", "text": "Malaysia" },
    { "key": "ctr68", "value": "Mongolia", "text": "Mongolia" },
    { "key": "ctr69", "value": "New Zealand", "text": "New Zealand" },
    { "key": "ctr70", "value": "Philippines", "text": "Philippines" },
    { "key": "ctr71", "value": "Samoa", "text": "Samoa" },
    { "key": "ctr72", "value": "Solomon Islands", "text": "Solomon Islands" },
    { "key": "ctr73", "value": "Thailand", "text": "Thailand" },
    { "key": "ctr74", "value": "Tonga", "text": "Tonga" },
    { "key": "ctr75", "value": "Tuvalu", "text": "Tuvalu" },
    { "key": "ctr76", "value": "Vanuatu", "text": "Vanuatu" },
    { "key": "ctr77", "value": "Vietnam", "text": "Vietnam" },
    { "key": "ctr78", "value": "Australia", "text": "Australia" },
    { "key": "ctr79", "value": "Laos", "text": "Laos" },
    { "key": "ctr80", "value": "Myanmar", "text": "Myanmar" },
    { "key": "ctr81", "value": "Papua New Guinea", "text": "Papua New Guinea" }
  ],
  rg4: [
    { "key": "ctr82", "value": "Albania", "text": "Albania" },
    { "key": "ctr83", "value": "Austria", "text": "Austria" },
    { "key": "ctr84", "value": "Belgium", "text": "Belgium" },
    { "key": "ctr85", "value": "Bosnia and Herzegovina", "text": "Bosnia and Herzegovina" },
    { "key": "ctr86", "value": "Bulgaria", "text": "Bulgaria" },
    { "key": "ctr87", "value": "Cyprus", "text": "Cyprus" },
    { "key": "ctr88", "value": "Denmark", "text": "Denmark" },
    { "key": "ctr89", "value": "Estonia", "text": "Estonia" },
    { "key": "ctr90", "value": "Finland", "text": "Finland" },
    { "key": "ctr91", "value": "France", "text": "France" },
    { "key": "ctr92", "value": "Georgia", "text": "Georgia" },
    { "key": "ctr93", "value": "Germany", "text": "Germany" },
    { "key": "ctr94", "value": "Ireland", "text": "Ireland" },
    { "key": "ctr95", "value": "Israel", "text": "Israel" },
    { "key": "ctr96", "value": "Kazakhstan", "text": "Kazakhstan" },
    { "key": "ctr97", "value": "Kyrgyzstan", "text": "Kyrgyzstan" },
    { "key": "ctr98", "value": "Latvia", "text": "Latvia" },
    { "key": "ctr99", "value": "Lithuania", "text": "Lithuania" },
    { "key": "ctr100", "value": "Netherlands", "text": "Netherlands" },
    { "key": "ctr101", "value": "Norway", "text": "Norway" },
    { "key": "ctr102", "value": "Portugal", "text": "Portugal" },
    { "key": "ctr103", "value": "Republic of Macedonia", "text": "Republic of Macedonia" },
    { "key": "ctr104", "value": "Republic of Serbia", "text": "Republic of Serbia" },
    { "key": "ctr105", "value": "Romania", "text": "Romania" },
    { "key": "ctr106", "value": "Slovak Republic", "text": "Slovak Republic" },
    { "key": "ctr107", "value": "Spain", "text": "Spain" },
    { "key": "ctr108", "value": "Sweden", "text": "Sweden" },
    { "key": "ctr109", "value": "Switzerland", "text": "Switzerland" },
    { "key": "ctr110", "value": "Tajikistan", "text": "Tajikistan" },
    { "key": "ctr111", "value": "Ukraine", "text": "Ukraine" },
    { "key": "ctr112", "value": "Greece", "text": "Greece" },
    { "key": "ctr113", "value": "Hungary", "text": "Hungary" },
    { "key": "ctr114", "value": "Italy", "text": "Italy" },
    { "key": "ctr115", "value": "Luxembourg", "text": "Luxembourg" },
    { "key": "ctr116", "value": "Montenegro", "text": "Montenegro" }
  ],
  rg5: [
    { "key": "ctr117", "value": "Afghanistan", "text": "Afghanistan" },
    { "key": "ctr118", "value": "Bangladesh", "text": "Bangladesh" },
    { "key": "ctr119", "value": "India", "text": "India" },
    { "key": "ctr120", "value": "Iran", "text": "Iran" },
    { "key": "ctr121", "value": "Maldives", "text": "Maldives" },
    { "key": "ctr122", "value": "Nepal", "text": "Nepal" },
    { "key": "ctr123", "value": "Pakistan", "text": "Pakistan" },
    { "key": "ctr124", "value": "Sri Lanka", "text": "Sri Lanka" },
    { "key": "ctr125", "value": "Bhutan", "text": "Bhutan" },
  ],
  rg6: [
    { "key": "ctr126", "value": "Barbados", "text": "Barbados" },
    { "key": "ctr127", "value": "Caribbean Family Planning Affiliation", "text": "Caribbean Family Planning Affiliation" },
    { "key": "ctr128", "value": "Colombia", "text": "Colombia" },
    { "key": "ctr129", "value": "El Salvador", "text": "El Salvador" },
    { "key": "ctr130", "value": "Jamaica", "text": "Jamaica" },
    { "key": "ctr131", "value": "Mexico", "text": "Mexico" },
    { "key": "ctr132", "value": "Peru", "text": "Peru" },
    { "key": "ctr133", "value": "Suriname", "text": "Suriname" },
    { "key": "ctr134", "value": "Trinidad and Tobago", "text": "Trinidad and Tobago" },
    { "key": "ctr135", "value": "United States of America", "text": "United States of America" },
    { "key": "ctr136", "value": "Anguilla", "text": "Anguilla" },
    { "key": "ctr137", "value": "Antigua", "text": "Antigua" },
    { "key": "ctr138", "value": "Aruba", "text": "Aruba" },
    { "key": "ctr139", "value": "Bahamas", "text": "Bahamas" },
    { "key": "ctr140", "value": "Bermuda", "text": "Bermuda" },
    { "key": "ctr141", "value": "Curacao", "text": "Curacao" },
    { "key": "ctr142", "value": "Dominica", "text": "Dominica" },
    { "key": "ctr143", "value": "Grenada", "text": "Grenada" },
    { "key": "ctr144", "value": "Guadeloupe", "text": "Guadeloupe" },
    { "key": "ctr145", "value": "Martinique", "text": "Martinique" },
    { "key": "ctr146", "value": "St. Lucia", "text": "St. Lucia" },
    { "key": "ctr147", "value": "St. Vincent", "text": "St. Vincent" }
  ]
}

// rg6: [
//   { "key": "ctr126", "value": "Barbados", "text": "Barbados" },
//   { "key": "ctr127", "value": "Belize", "text": "Belize" },
//   { "key": "ctr128", "value": "Bolivia", "text": "Bolivia" },
//   { "key": "ctr129", "value": "Caribbean Family Planning Affiliation", "text": "Caribbean Family Planning Affiliation" },
//   { "key": "ctr130", "value": "Chile", "text": "Chile" },
//   { "key": "ctr131", "value": "Colombia", "text": "Colombia" },
//   { "key": "ctr132", "value": "Costa Rica", "text": "Costa Rica" },
//   { "key": "ctr133", "value": "Dominican Republic", "text": "Dominican Republic" },
//   { "key": "ctr134", "value": "Ecuador", "text": "Ecuador" },
//   { "key": "ctr135", "value": "El Salvador", "text": "El Salvador" },
//   { "key": "ctr136", "value": "Guatemala", "text": "Guatemala" },
//   { "key": "ctr137", "value": "Guyana", "text": "Guyana" },
//   { "key": "ctr138", "value": "Haiti", "text": "Haiti" },
//   { "key": "ctr139", "value": "Honduras", "text": "Honduras" },
//   { "key": "ctr140", "value": "Jamaica", "text": "Jamaica" },
//   { "key": "ctr141", "value": "Mexico", "text": "Mexico" },
//   { "key": "ctr142", "value": "Panama", "text": "Panama" },
//   { "key": "ctr143", "value": "Paraguay", "text": "Paraguay" },
//   { "key": "ctr144", "value": "Peru", "text": "Peru" },
//   { "key": "ctr145", "value": "Puerto Rico", "text": "Puerto Rico" },
//   { "key": "ctr146", "value": "Suriname", "text": "Suriname" },
//   { "key": "ctr147", "value": "Trinidad and Tobago", "text": "Trinidad and Tobago" },
//   { "key": "ctr148", "value": "United States of America", "text": "United States of America" },
//   { "key": "ctr149", "value": "Uruguay", "text": "Uruguay" },
//   { "key": "ctr150", "value": "Venezuela", "text": "Venezuela" },
//   { "key": "ctr151", "value": "Anguilla", "text": "Anguilla" },
//   { "key": "ctr152", "value": "Antigua", "text": "Antigua" },
//   { "key": "ctr153", "value": "Aruba", "text": "Aruba" },
//   { "key": "ctr154", "value": "Bahamas", "text": "Bahamas" },
//   { "key": "ctr155", "value": "Bermuda", "text": "Bermuda" },
//   { "key": "ctr156", "value": "Curacao", "text": "Curacao" },
//   { "key": "ctr157", "value": "Dominica", "text": "Dominica" },
//   { "key": "ctr158", "value": "Grenada", "text": "Grenada" },
//   { "key": "ctr159", "value": "Guadeloupe", "text": "Guadeloupe" },
//   { "key": "ctr160", "value": "Martinique", "text": "Martinique" },
//   { "key": "ctr161", "value": "St. Lucia", "text": "St. Lucia" },
//   { "key": "ctr162", "value": "St. Vincent", "text": "St. Vincent" },
//   { "key": "ctr163", "value": "Argentina", "text": "Argentina" },
//   { "key": "ctr164", "value": "Brazil", "text": "Brazil" },
//   { "key": "ctr165", "value": "Canada", "text": "Canada" },
//   { "key": "ctr166", "value": "Chile", "text": "Chile" },
//   { "key": "ctr167", "value": "Haiti", "text": "Haiti" },
//   { "key": "ctr168", "value": "Cuba", "text": "Cuba" }
// ]

const regionOption = [
  { key: "rg1", value: "Africa", text: "Africa" },
  { key: "rg6", value: "Americas and the Caribbean", text: "Americas and the Caribbean" },
  { key: "rg2", value: "Arab World", text: "Arab World" },
  { key: "rg3", value: "East and Southeast Asia and Oceania", text: "East and Southeast Asia and Oceania" },
  { key: "rg4", value: "European Network", text: "European Network" },
  { key: "rg5", value: "South Asia", text: "South Asia" }
];

const yearOptions = [
  { key: "1940", value: 1940, text: "1940" },
  { key: "1941", value: 1941, text: "1941" },
  { key: "1942", value: 1942, text: "1942" },
  { key: "1943", value: 1943, text: "1943" },
  { key: "1944", value: 1944, text: "1944" },
  { key: "1945", value: 1945, text: "1945" },
  { key: "1946", value: 1946, text: "1946" },
  { key: "1947", value: 1947, text: "1947" },
  { key: "1948", value: 1948, text: "1948" },
  { key: "1949", value: 1949, text: "1949" },
  { key: "1950", value: 1950, text: "1950" },
  { key: "1951", value: 1951, text: "1951" },
  { key: "1952", value: 1952, text: "1952" },
  { key: "1953", value: 1953, text: "1953" },
  { key: "1954", value: 1954, text: "1954" },
  { key: "1955", value: 1955, text: "1955" },
  { key: "1956", value: 1956, text: "1956" },
  { key: "1957", value: 1957, text: "1957" },
  { key: "1958", value: 1958, text: "1958" },
  { key: "1959", value: 1959, text: "1959" },
  { key: "1960", value: 1960, text: "1960" },
  { key: "1961", value: 1961, text: "1961" },
  { key: "1962", value: 1962, text: "1962" },
  { key: "1963", value: 1963, text: "1963" },
  { key: "1964", value: 1964, text: "1964" },
  { key: "1965", value: 1965, text: "1965" },
  { key: "1966", value: 1966, text: "1966" },
  { key: "1967", value: 1967, text: "1967" },
  { key: "1968", value: 1968, text: "1968" },
  { key: "1969", value: 1969, text: "1969" },
  { key: "1970", value: 1970, text: "1970" },
  { key: "1971", value: 1971, text: "1971" },
  { key: "1972", value: 1972, text: "1972" },
  { key: "1973", value: 1973, text: "1973" },
  { key: "1974", value: 1974, text: "1974" },
  { key: "1975", value: 1975, text: "1975" },
  { key: "1976", value: 1976, text: "1976" },
  { key: "1977", value: 1977, text: "1977" },
  { key: "1978", value: 1978, text: "1978" },
  { key: "1979", value: 1979, text: "1979" },
  { key: "1980", value: 1980, text: "1980" },
  { key: "1981", value: 1981, text: "1981" },
  { key: "1982", value: 1982, text: "1982" },
  { key: "1983", value: 1983, text: "1983" },
  { key: "1984", value: 1984, text: "1984" },
  { key: "1985", value: 1985, text: "1985" },
  { key: "1986", value: 1986, text: "1986" },
  { key: "1987", value: 1987, text: "1987" },
  { key: "1988", value: 1988, text: "1988" },
  { key: "1989", value: 1989, text: "1989" },
  { key: "1990", value: 1990, text: "1990" },
  { key: "1991", value: 1991, text: "1991" },
  { key: "1992", value: 1992, text: "1992" },
  { key: "1993", value: 1993, text: "1993" },
  { key: "1994", value: 1994, text: "1994" },
  { key: "1995", value: 1995, text: "1995" },
  { key: "1996", value: 1996, text: "1996" },
  { key: "1997", value: 1997, text: "1997" },
  { key: "1998", value: 1998, text: "1998" },
  { key: "1999", value: 1999, text: "1999" },
  { key: "2000", value: 2000, text: "2000" },
  { key: "2001", value: 2001, text: "2001" },
  { key: "2002", value: 2002, text: "2002" },
  { key: "2003", value: 2003, text: "2003" },
  { key: "2004", value: 2004, text: "2004" },
  { key: "2005", value: 2005, text: "2005" },
  { key: "2006", value: 2006, text: "2006" },
  { key: "2007", value: 2007, text: "2007" },
  { key: "2008", value: 2008, text: "2008" },
  { key: "2009", value: 2009, text: "2009" },
  { key: "2010", value: 2010, text: "2010" },
  { key: "2011", value: 2011, text: "2011" },
  { key: "2012", value: 2012, text: "2012" },
  { key: "2013", value: 2013, text: "2013" },
  { key: "2014", value: 2014, text: "2014" },
  { key: "2015", value: 2015, text: "2015" },
  { key: "2016", value: 2016, text: "2016" },
  { key: "2017", value: 2017, text: "2017" },
  { key: "2018", value: 2018, text: "2018" },
  { key: "2019", value: 2019, text: "2019" }
];
const mapStateToProps = (state, ownProps) => {
  return {
    formErrorStatus: state.custom_reducer.formErrorStatus,
    formSuccessState: state.custom_reducer.formSuccessState,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setFormStatus: (status) => {
      dispatch(setFormStatus(status))
    },
    setSuccessStatus: (status) => {
      dispatch(setSuccessStatus(status))
    }
  }
}
class AccountInfo extends Component {
  constructor(props) {
    super(props);
    const { minStrength = 3, thresholdLength = 7 } = props;

    this.minStrength = typeof minStrength === 'number'
      ? Math.max(Math.min(minStrength, 4), 0)
      : 3;

    this.thresholdLength = typeof thresholdLength === 'number'
      ? Math.max(thresholdLength, 7)
      : 7;
    this.state = {
      loading: false,
      firstName: "",
      lastName: "",
      maFullName: "",
      email: "",
      country: "",
      region: "",
      year: 0,
      password: "",
      confPassword: "",
      userId: localStorage.USER_ID,
      strength: 0,
      minStrength: 3,
      thresholdLength: 7,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this._loadUserProfile();
  }

  _loadUserProfile = () => {
    this.props.client.query({
      query: GET_MY_PROFILE,
    }).then(result => {
      console.log(result);
      if (result.data.getMyProfile.general.region !== "NA") {
        const selectedOption = regionOption.filter(function (item) {
          return item.value == result.data.getMyProfile.general.region;
        });
        const key = selectedOption[0].key;
        countryOptions = countryList[key];
      }
      this.setState({
        loading: false,
        firstName: result.data.getMyProfile.user.firstName,
        lastName: result.data.getMyProfile.user.lastName,
        email: result.data.getMyProfile.user.email,
        country: result.data.getMyProfile.general.country === "NA" ? "" : result.data.getMyProfile.general.country,
        maFullName: result.data.getMyProfile.general.fullName,
        region: result.data.getMyProfile.general.region === "NA" ? "" : result.data.getMyProfile.general.region,
        year: result.data.getMyProfile.general.year,
      })
    }).catch(error => {
      console.log(error);
      this.setState({
        loading: false,
      })
    });
  }
  _handleSubmit = () => {
    this.setState({ loading: true });
    const { firstName, lastName, maFullName, email, country, region, year, password, confPassword, userId } = this.state;
    const { t, i18n } = this.props;
    this.props.setFormStatus({ status: false, title: '', message: '' });
    this.props.setSuccessStatus({ status: false, title: '', message: '' });

    if (password !== confPassword) {
      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message: localStorage.RAT_LANG == 'ar' ?
          (
            <div style={{ paddingRight: "15px" }}> <span>{t("Passwords do not match!")}</span> </div>
          ) : (
            <div> <span>{t("Passwords do not match!")}</span> </div>
          )
      });
      this.setState({ loading: false });
      return;
    }

    if (this.state.password.length <= this.state.thresholdLength) {
      this.props.setFormStatus({
          status: true,
          title: "Oops!",
          message: localStorage.RAT_LANG=='ar' ? (
            <div style={{paddingRight:"10px"}}> { this.props.t('password_validity')} </div>
          ):(
            <div> { this.props.t('password_validity')} </div>
          )
      });
      this.setState({ loading: false });
      return;
  }

    // console.log(userId);
    this.reset_password(password, userId).then(result => {
      // console.log(result);

      this.setState({
        loading: false,
        password: "",
        confPassword: ""

      });
      setTimeout(() => {
        this.props.setFormStatus({ status: false, title: '', message: '' });
        this.props.setSuccessStatus({ status: false, title: '', message: '' });
        //   this.props.history.push('/');
      }, 5000);
      this.props.setSuccessStatus({
        status: true,
        title: "",
        message: localStorage.RAT_LANG == 'ar' ? (
          <div style={{paddingRight:"15px"}}><span>{t("Password Updated Successfully!")}</span></div>
        ) : (
          <div><span>{t("Password Updated Successfully!")}</span></div>
        )
      });

    }).catch(error => {
      if (error) {
        this.setState({ loading: false });
        this.props.setFormStatus({
          status: true,
          title: "Oops!",
          message:
            <span>{t("There was an error while trying to update password.")}</span>

        });
      }
    });
  };

  reset_password = async (password, userID) => {
    const result = await this.props.client.mutate({
      mutation: UPDATE_PASSWORD,
      variables: { password, userID },
    });
    return result.data.updatePassword;
  };

  _handleChange = event => {
    this.setState({ [event.target.id]: event.target.value });
  };

  passwordChange = (event) => {
    this.setState({
      password: event.target.value,
      strength: zxcvbn(event.target.value).score,
    })
  };

  
  _handleYearChange = (e, { value }) => this.setState({ year: value });

  _handleRegionChange = (e, { value }) => {
    countryOptions = [];
    const selectedOption = regionOption.filter(function (item) {
      return item.value == value;
    });
    this.setState({ region: value });
    const key = selectedOption[0].key;
    countryOptions = countryList[key];
  }

  _handleCountryChange = (e, { value }) => this.setState({ country: value });


  render() {
    const { strength, password } = this.state;
    const { formErrorStatus, formSuccessState, validator, children, t, i18n, ...restProps } = this.props;
    const passwordLength = password.length;
    const passwordStrong = strength >= this.minStrength;
    const passwordLong = passwordLength > this.thresholdLength;

    const counterClass = ['badge badge-pill', passwordLong ? passwordStrong ? 'badge-success' : 'badge-warning' : 'badge-danger'].join(' ').trim();
    const strengthClass = ['strength-meter mt-2', passwordLength > 0 ? 'visible' : 'invisible'].join(' ').trim();
    return (
      <div className="uk-padding">
        <Form className="formsegment" error success onSubmit={this._handleSubmit}>
          <h2 className={localStorage.RAT_LANG=='ar' ? "uk-text-bold uk-text-right" : "uk-text-bold uk-text-left" }>{t("Profile Details")}</h2>
          <div className="uk-form-label">{t("User Category")}<i style={styles.icon} className="icon red">*</i></div>
          <div>
            <input
              name="firstName"
              id="firstName"
              value={(this.state.maFullName === "International Planned Parenthood Federation") ? (
                // "Central Office"
                t("Central Office")
              ) : (this.state.maFullName === "NA") ? t("Regional Office") : t("Member Association")}
              className="uk-input uk-form-width-large"
              placeholder=""
              type="text"
              required
              readOnly
              disabled
            />
          </div>

          <div className="uk-form-label">{t("First Name")}<i style={styles.icon} className="icon red">*</i></div>
          <div>
            <input
              name="firstName"
              id="firstName"
              value={this.state.firstName}
              className="uk-input uk-form-width-large"
              placeholder=""
              type="text"
              onChange={this._handleChange}
              required
              readOnly
              disabled
            />
          </div>

          <div className="uk-form-label">{t("Last Name")}<i style={styles.icon} className="icon red">*</i></div>
          <div>
            <input
              name="lastName"
              id="lastName"
              value={this.state.lastName}
              className="uk-input uk-form-width-large"
              placeholder=""
              type="text"
              onChange={this._handleChange}
              required
              readOnly
              disabled
            />
          </div>

          <div className="uk-form-label">
            {(this.state.maFullName === "International Planned Parenthood Federation") ? (
              <span>{t("Organization")}</span>
            ) : (this.state.maFullName === "NA") ? <span>{t("Regional Office Name")}</span> : (<span>{t("Full name of the Member Association (MA)")}</span>)}<i style={styles.icon} className="icon red">*</i>
          </div>
          <div>
            <input
              name="maFullName"
              id="maFullName"
              value={this.state.maFullName}
              className="uk-input uk-form-width-large"
              placeholder=""
              type="text"
              onChange={this._handleChange}
              required
              readOnly
              disabled
            />
          </div>

          <div className="uk-form-label">{t("Official Email Address")}<i style={styles.icon} className="icon red">*</i></div>
          <div>
            <input
              name="email"
              id="email"
              value={this.state.email}
              className="uk-input uk-form-width-large"
              placeholder=""
              type="email"
              onChange={this._handleChange}
              required
              readOnly
              disabled
            />
          </div>
          <div className="uk-form-label">{t("IPPF Region")}<i style={styles.icon} className="icon red">*</i></div>
          <div>
            <input
              name="region"
              id="region"
              value={this.state.region === "" ? "NA" : this.state.region}
              className="uk-input uk-form-width-large"
              placeholder=""
              type="text"
              required
              readOnly
              disabled
            />
          </div>

          <div className="uk-form-label">{t("Country")}<i style={styles.icon} className="icon red">*</i></div>
          <div>
            <input
              name="country"
              id="country"
              value={this.state.country === "" ? "NA" : this.state.country}
              className="uk-input uk-form-width-large"
              placeholder=""
              type="text"
              required
              readOnly
              disabled
            />
          </div>

          <div className="uk-form-label">{t("Year of establishment")}</div>
          <div>
            {/* <Dropdown
              onChange={this._handleYearChange}
              options={yearOptions}
              placeholder="Select Year"
              selection
              value={this.state.year}
              readOnly
              disabled
            /> */}
            <input
              value={this.state.year === 0 || this.state.year === "" ? "NA" : this.state.year}
              className="uk-input uk-form-width-large"
              placeholder=""
              type="text"
              required
              readOnly
              disabled
            />
          </div>
          <p>{t("Please")} <a href="/contact" target="_blank">{t("contact the Social Enterprise Hub")}</a> {t("to make any changes to your profile information.")}</p>
          <hr />
          <div className="uk-form-label"><b>{t("Change Password")}</b></div>
          <div className="uk-form-label">{t("New Password")}<i style={styles.icon} className="icon red">*</i></div>
          <Form.Field {...restProps} validator={this.validatePasswordStrong}  >
            <span style={{ fontSize: "14px" }} className="d-block form-hint">{t("To conform with our Strong Password policy, you are required to use a sufficiently strong password. Password must be more than 7 characters.")}</span>
            {children}
            <div className={strengthClass}>
              <div className="strength-meter-fill" data-strength={strength}></div>
            </div>
          </Form.Field>
          <div>
            <input
              name="password"
              id="password"
              value={this.state.password}
              className="uk-input uk-form-width-large"
              placeholder={t("New Password")}
              type="password"
              onChange={this.passwordChange}
              required
            />
          </div>
          {/* <div className="position-absolute password-count mx-3">
            <span className={counterClass}>{passwordLength ? passwordLong ? `${this.thresholdLength}+` : passwordLength : ''}</span>
          </div> */}

          <div className="uk-form-label">{t("Confirm Password")}<i style={styles.icon} className="icon red">*</i></div>
          <div>
            <input
              name="confPassword"
              id="confPassword"
              value={this.state.confPassword}
              className="uk-input uk-form-width-large"
              placeholder={t("Confirm Password")}
              type="password"
              onChange={this._handleChange}
              required
            />
          </div>
          <div></div>
          <input
            className="uk-button uk-button-success uk-width-1-1  uk-margin"
            type="submit"
            value={t("Update Password")}
            loading={this.state.loading}
            disabled={this.state.loading ? (true) : (false)}
          />
          {(formErrorStatus.status) ? (
            <div className="danger_alert">{formErrorStatus.message}</div>
            // <Message
            //   style={{ margin: 0 }}
            //   error
            //   content={formErrorStatus.message}
            // />
          ) : ((formSuccessState.status) ? (
            <div className="success_alert">{formSuccessState.message}</div>
            // <Message
            //   style={{ margin: 0 }}
            //   success
            //   content={formSuccessState.message}
            // />
          ) : (''))}
        </Form>

        {this.state.loading ? <div className="spinner"></div> : ""}
      </div>
    );
  }
}
const styles = {
  icon: {
    width: "0px"
  }
};
export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(AccountInfo))));