import React, { Component } from "react";
import TableLoader from "../../../components/loaders/TableLoader";
import { GET_SECTIONS } from "../../../queries/SectionQueries";
import { withApollo } from "react-apollo";
import { withRouter } from 'react-router-dom';
import moment from 'moment';

class SectionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      sections: [],
    };
    this._getSections();
  }
  // This is a redirect function
  addNewSection = () => {
    console.log("addNew");
    this.props.history.push("sections/new");
  };

  _getSections = () => {
    this.props.client
      .query({
        query: GET_SECTIONS
      })
      .then(result => {
        console.log(result);
        this.setState({
          loading: false,
          sections: result.data.getSections,
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  viewQuestionList = (sectionId) => {
    console.log(sectionId);
    // this.props.history.push("sections/"+sectionId);
    window.location.href = '/sections/' + sectionId;
  }

  render() {
    return (
      <div className="admin-content-inner">
        <div className="uk-flex-inline uk-flex-middle">
          <i className="fas fa-th-list icon-large uk-margin-right"></i>
          <h4 className="uk-margin-remove"> Sections </h4>
          <button
            onClick={this.addNewSection}
            className="uk-button uk-button-success uk-margin-medium-left admin-btn"
            href="#new"
            uk-tooltip="title: Create New User ; delay: 500 ; pos: top ;animation:	uk-animation-scale-up"
          >
            <i className="fas fa-folder"></i> add new
          </button>
        </div>
        <div class="uk-background-default uk-margin-top uk-padding">
          <div class="uk-grid" uk-grid>
            <div class="uk-width-expand@m">
              <form class=" uk-flex-inline uk-flex-middle uk-text-small">
                <p class="uk-margin-remove-bottom uk-margin-right">
                  {" "}
                  Current Sections :{" "}
                </p>
              </form>
            </div>
            <div class="uk-width-auto@m uk-text-small"></div>
          </div>
        </div>
        <div className="uk-overflow-auto">
          <table className="uk-table uk-table-hover uk-table-middle uk-table-divider">
            <thead>
              <tr className="uk-text-small uk-text-bold">
                <th>Order</th>
                <th>Title</th>
                <th>Sub Title</th>
                <th>Description</th>
                <th>Last Updated At</th>
                <th>No of Questions</th>
              </tr>
            </thead>

            { this.state.loading ? ( <TableLoader/> ) : (
                <tbody>
            { this.state.sections.map(section => (
                <tr key={section.id}>
                <td>
                  { section.sequence }
                </td>
                <td>
                { section.title }
                </td>
                <td> { section.subTitle } </td>
                <td> { section.description } </td>
                <td> {  moment(section.updatedAt).format('LL')  } </td>
                <td> NA </td>
                <td className="uk-flex-inline uk-flex-middle">
                  <button onClick={() => this.viewQuestionList(section.id)} className="uk-button uk-button-success admin-table-btn">
                    <i className="fas fa-eye uk-visible@m"></i> 
                  </button>
                  <button className="uk-button uk-button-danger admin-table-btn">
                    <i className="fas fa-trash uk-visible@m"></i>
                  </button>
                </td>
              </tr>
            )) }
            </tbody>
            ) }
          </table>
        </div>
      </div>
    );
  }
}

export default withRouter(withApollo(SectionList));
