import React, { Component } from "react";
import { Form, Dropdown } from "semantic-ui-react";
import { withApollo } from "react-apollo";
import { withNamespaces } from "react-i18next";


class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };

  }

  redirectToLogin = () => {
    this.props.history.push("/");
    window.location.reload();

  }

  render() {
    const { t, i18n } = this.props
    return (
      <div
        uk-height-viewport="offset-top: true; offset-bottom: true"
        className="uk-flex uk-flex-middle uk-margin"
        uk-scrollspy="target: > .welc-holder; cls:uk-animation-slide-bottom-small; delay: 200"
        style={{ backgroundColor: "#fff" }}
      >
        {/* uk-width-1-2@m uk-width-1-2@s removed july8th */} 
        <div className="uk-margin-auto welc-holder">
          <div style={{display:"block"}} className="uk-margin-medium-top extra-top-padding ">
            <Form >
              {/* <h4 style={{color:result_color}}>{result_status}</h4> */}
              <h4 className="uk-text-bold uk-margin-medium-top">{t("Thank you for registering to access the Readiness Assessment Tool.")}</h4>
              <h4 className="uk-text-bold uk-margin-remove-top">{t("IPPF’s Social Enterprise Hub will review your request and revert soon.")}</h4>
              <h4 className="uk-text-bold uk-margin-remove-top">{t("Do check your Email for notifications related to this account creation.")}</h4>
          <br />
          <button className="uk-button uk-button-primary uk-align-center" onClick={() => this.redirectToLogin()}> {t("Click here to login")} </button>
 
            </Form>
            {this.state.loading ? (<div class="spinner"></div>) : ("")}
          </div>
         
        </div>
      </div>
    );
  }
}

export default withApollo(withNamespaces()(Register));
