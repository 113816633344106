import React, { Component } from "react";
import TableLoader from "../../../../components/loaders/TableLoader";
import { GET_SUBMITTED_LIST } from '../../../../queries/SubmissionQueries';
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { Label, Accordion, Icon,Button,Popup,Grid } from "semantic-ui-react";
import moment from "moment";
import { split } from "apollo-link";

class UserSubmissionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      sections: [],
      activeIndex: 0
    };
    this._loadSubmissions();
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
    
  };

  _loadSubmissions = () => {

    const components = this.props.match.path.split('/');
    this.props.client.query({
        query: GET_SUBMITTED_LIST,
        variables: { userId: components[2] }
    }).then(result => {
        result.data.getAllSubmissionsForUser.map(section => {
          console.log(section);
        });

        this.setState({
          loading: false,
          sections: result.data.getAllSubmissionsForUser
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({
          loading: false
        });
      });
  }

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

  render() {
    const { activeIndex, sections } = this.state;
    var question_number=0;
    return (
      <div>
        {/* Begin Router */}
        <br />
        <h4 class="uk-heading-line">
          <span>Assessment Progress</span>
        </h4>

        <ul class="uk-breadcrumb">
          <li>
            <a href="#">Section List</a>
          </li>
          <li class="uk-disabled">
            <a>Overall Progress</a>
          </li>
          {/* <li>
            <a href="#">Item</a>
          </li> */}
          {/* 
          <li>
            <span>Active</span>
          </li> */}
        </ul>
        <div className="uk-overflow-auto" id="print_pdf">
          {/* Submission List comes here */}
          <Accordion fluid styled>
          {/* Begin Section Main */}
          {sections.map((index, section) => (
            <div key={index.section.id}>

              <Accordion.Title
                active={activeIndex === index}
                index={index}
                onClick={this.handleClick}
              >
                <Icon name="dropdown" />
                {index.section.sectionNumber} {"  "} {index.section.title}
              </Accordion.Title>
              <Accordion.Content active={activeIndex === index}>
                {
                  Object.keys(index.submission).map(question => (
                    <div>
                      <p class="uk-text-small uk-text-muted uk-margin-remove-bottom uk-margin-top">Primary Question</p>
                      <p class="uk-text-emphasis uk-margin-remove">{++question_number}) {index.submission[question].primaryQuestion}</p>
                      <p class="uk-text-primary uk-margin-remove-top">
                        Answer:  {typeof (index.submission[question].primaryAnswer) === 'boolean' ? (
                          index.submission[question].primaryAnswer ? 'YES' : 'NO'
                        ) : (

                            index.submission[question].primaryArray ? (
                              index.submission[question].primaryArray.length > 0 ? (
                                //console.log(index.submission[question].secondaryArray)

                                index.submission[question].primaryArray.map((ans, i) => {

                                  return [<br />, Object.keys(ans).map((key, index) => (
                                    //console.log(key +"--"+ans[key])
                                    <div> {key.split(/(?=[A-Z])/).join(" ").replace(/^\w/, c => c.toUpperCase())}: {ans[key]} </div>
                                  ))]

                                })
                              ) : (
                                  null
                                )
                            ) : (
                                index.submission[question].primaryJson || !this.isEmpty(index.submission[question].primaryJson) ? (
                                  Object.keys(index.submission[question].primaryJson).map(function (key) {
                                    return <div> {key.split(/(?=[A-Z])/).join(" ").replace(/^\w/, c => c.toUpperCase())}:  {index.submission[question].primaryJson[key]} </div>
                                  })
                                ) : (
                                    index.submission[question].primaryAnswer
                                  )
                              )

                          )}
                      </p>

                      <p class="uk-text-small uk-text-muted uk-margin-remove-bottom uk-margin-top">Secondary Question</p>
                      <p class="uk-text-emphasis uk-margin-remove">{index.submission[question].secondaryQuestion}</p>
                      <p class="uk-text-primary uk-margin-remove-top">
                        Answer:  {typeof (index.submission[question].secondaryAnswer) === 'boolean' ? (
                          index.submission[question].secondaryAnswer ? 'YES' : 'NO'
                        ) : (index.submission[question].secondaryAnswer)}
                        {(

                            index.submission[question].secondaryArray ? (
                              index.submission[question].secondaryArray.length > 0 ? (
                                //console.log(index.submission[question].secondaryArray)

                                index.submission[question].secondaryArray.map((ans, i) => {

                                  return [<br />, Object.keys(ans).map((key, index) => (
                                    //console.log(key +"--"+ans[key])
                                    <div> {key.split(/(?=[A-Z])/).join(" ").replace(/^\w/, c => c.toUpperCase())}: {ans[key]} </div>
                                  ))]

                                })
                              ) : (
                                  null
                                )
                            ) : (
                                index.submission[question].secondaryJson || !this.isEmpty(index.submission[question].secondaryJson) ? (
                                  Object.keys(index.submission[question].secondaryJson).map(function (key) {
                                    return <div> {key.split(/(?=[A-Z])/).join(" ").replace(/^\w/, c => c.toUpperCase())}: {index.submission[question].secondaryJson[key]} </div>
                                  })
                                ) : (
                                  null
                                  )
                              )
                          )}
                      </p>
                      <div>
                        {index.submission[question].fileUrl ? (
                          index.submission[question].fileUrl != "" ? (
                            // <img src={index.submission[question].fileUrl} style={{ width: "300px" }} />

                            <div className="file-upload-group" >
                                  <Popup
                                    trigger={<Button className="file-icon" circular icon="attach" />}
                                    flowing
                                    hoverable
                                  >
                                    <Grid centered divided columns={1}>
                                      <Grid.Column textAlign="center">
                                        <Button circular icon='download' onClick={() => { window.open(index.submission[question].fileUrl, '_blank'); }} />

                                      </Grid.Column>
                                    </Grid>
                                  </Popup>
                                </div>
                                
                          ) : (
                              null
                            )
                        ) : (
                            null
                          )}
                      </div>
                      <hr />
                    </div>
                  ))
                }

              </Accordion.Content>
            </div>
          ))}
          {/* End Section Main */}
        </Accordion>
        </div>

        
        {/* End Router */}
      </div>
    );
  }
}

export default withRouter(withApollo(UserSubmissionList));
