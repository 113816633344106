import React, { Component } from "react";
import SectionLoader from "../../../../generic/assesment/SectionLoader";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import { GET_SECTION_TWO } from "../../../../../queries/SectionQueries";
import {
    SUBMIT_SECTION_1,
    GET_SECTION_1,
    GET_EDIT_SECTION_SUBMISSION,
    UPDATE_SECTION_1,
    UPDATE_USER_SECTION_1
} from "../../../../../queries/SubmissionQueries";
import { setFormStatus, setSuccessStatus } from '../../../../../actions/user-actions';
import { connect } from 'react-redux';
import { Form, Button, TextArea, Message, Input, Label } from "semantic-ui-react";
import { GENERIC_PROFILE } from "../../../../../queries/UserQueries";
import moment from "moment";
import sectionOneQuestions from '../../../../questions/section1_questions.json';
import { withNamespaces } from "react-i18next";

const mapStateToProps = (state, ownProps) => {
    return {
        formErrorStatus: state.custom_reducer.formErrorStatus,
        formSuccessState: state.custom_reducer.formSuccessState,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setFormStatus: (status) => {
            dispatch(setFormStatus(status))
        },
        setSuccessStatus: (status) => {
            dispatch(setSuccessStatus(status))
        }
    }
}

class ReviewSection2 extends Component {
    constructor(props) {
        super(props);
        this.props.setFormStatus({ status: false, title: '', message: '' });
        this.props.setSuccessStatus({ status: false, title: '', message: '' });
        // const proObj = this.props.location.pathname.split('/');
        this.state = {
            submissionId: this.props.location.state.submissionId,
            submissionType: this.props.location.state.submissionType,
            assessmentName: this.props.location.state.assessmentName,
            userId: this.props.location.state.userId,
            loading: true,
            spinner: false,
            saveNContinue: false,
            sectionEmpty: true,
            section: {
                id: "",
                sectionNumber: "",
                title: "",
                subTitle: "",
                description: "",
                sequence: "",
                createdAt: "",
                updatedAt: ""
            },
            // question1: {
            //     showFollowupQuestion: false,
            //     primaryQuestion:
            //         "Does your domestic law allow NGOs to carry out income generating activities (to support the social mission)?",
            //     primaryAnswer: false,
            //     secondaryQuestion: "If Yes, what kind of provisions are in place?",
            //     secondaryAnswer: "",
            //     weightFactor: "0.4",
            //     uploads: [],
            //     validated: true,
            //     feedBack: "",
            //     score: "",
            //     isScore: false
            // },
            // question2: {
            //     showFollowupQuestion: false,
            //     primaryQuestion:
            //         "Does the constitution of your organization place explicit constraints or controls on income generating activities?",
            //     primaryAnswer: "",
            //     secondaryQuestion: "",
            //     secondaryAnswer: "",
            //     weightFactor: "-0.4",
            //     uploads: [],
            //     validated: false,
            //     feedBack: "",
            //     score: "",
            //     isScore: true
            // },
            // question3: {
            //     showFollowupQuestion: false,
            //     primaryQuestion:
            //         "Does the constitution of your organization need to be amended to allow income generating activities?",
            //     primaryAnswer: "",
            //     secondaryQuestion: "",
            //     secondaryAnswer: "",
            //     weightFactor: "-0.4",
            //     uploads: [],
            //     validated: false,
            //     feedBack: "",
            //     score: "",
            //     isScore: true
            // },
            // question4: {
            //     showFollowupQuestion: false,
            //     primaryQuestion:
            //         "Is the Governing Board of your organization representative of multi-disciplinary fields?",
            //     primaryAnswer: false,
            //     secondaryQuestion: "If Yes, add all the applicable Board Members details",
            //     secondaryAnswer: "",
            //     secondaryJson: {},
            //     secondaryArray: [{ fullName: "", professionalTitle: "", roleOnTheBoard: "", typeOfExpertise: "" }],
            //     weightFactor: "0.2",
            //     uploads: [],
            //     validated: true,
            //     feedBack: "",
            //     score: "",
            //     isScore: false
            // },
            // question5: {
            //     showFollowupQuestion: false,
            //     primaryQuestion:
            //         "Does at least one Board member have knowledge of business management?",
            //     primaryAnswer: false,
            //     secondaryQuestion: "If Yes, specify the exact advisory capacity of the Board Members who have business management knowledge and any evidence of their business experience (including any academic qualification)?",
            //     secondaryAnswer: "",
            //     secondaryJson: {},
            //     weightFactor: "0.2",
            //     uploads: [],
            //     validated: true,
            //     feedBack: "",
            //     score: "",
            //     isScore: false
            // },
            // question6: {
            //     showFollowupQuestion: false,
            //     primaryQuestion:
            //         "Is there consensus or a documented agreement (i.e. meeting minutes) between your organization’s management and Board on the need to be self-sufficient financially (sustainability)?",
            //     primaryAnswer: false,
            //     secondaryQuestion: "If Yes, does your organization have a Resource Mobilization plan/strategy?",
            //     secondaryAnswer: "",
            //     secondaryJson: {},
            //     weightFactor: "0.4",
            //     uploads: [],
            //     validated: true,
            //     feedBack: "",
            //     score: "",
            //     isScore: false
            // },
            ...sectionOneQuestions,
            multiquestions: [{ fullName: "", professionalTitle: "", roleOnTheBoard: "", typeOfExpertise: "" }],
            sectionFeedBack: "",
            user: {
                firstName: "...",
                lastName: "...",
                email: "...",
                id: "...",
                createdAt: "...",
            },
            general: {
                fullName: "...",
                country: "...",
                region: "...",
                year: "...",
            },
        };
        props.setFormStatus({ status: false, title: "", message: "" });
        props.setSuccessStatus({ status: false, title: "", message: "" });
        this._getSection2();
        this._getSection1Submissions();
        this._getGenericProfile();
    }

    _getGenericProfile = () => {
        const { userId } = this.state;
        this.props.client.query({
            query: GENERIC_PROFILE,
            variables: { userId: userId }
        }).then(result => {
            this.setState({
                user: { ...result.data.getUserFullProfile.user },
                general: { ...result.data.getUserFullProfile.general },
                loading: false,
            })
        }).catch(error => {
            console.log(error);
            this.setState({ loading: false });
        });
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        setTimeout(() => {
            this.setState({
                loading: false
            });
        }, 2000);
    }

    _getSection2 = () => {
        this.props.client
            .query({
                query: GET_SECTION_TWO
            })
            .then(result => {

                this.setState({
                    section: { ...result.data.getSection1 }
                });
            })
            .catch(error => {
                console.log(error);
            });
    };

    _getSection1Submissions = () => {
        const { submissionId, submissionType } = this.state;
        this.props.client
            .query({
                query: GET_EDIT_SECTION_SUBMISSION,
                variables: { sectionId: "ck09iwgjk6ftg0b53chrw6bkf", submissionId: submissionId },
                fetchPolicy: 'network-only'
            })
            .then(result => {
                console.log(result);
                if (
                    result.data.getEditSectionSubmission === null ||
                    result.data.getEditSectionSubmission.length === 0
                ) {
                    console.log("there is nothing here");
                } else {
                    this.setState({
                        question1: result.data.getEditSectionSubmission.submission.question1 ? { ...result.data.getEditSectionSubmission.submission.question1, weightFactor: this.state.question1.weightFactor } : this.state.question1,
                        question2: result.data.getEditSectionSubmission.submission.question2 ? { ...result.data.getEditSectionSubmission.submission.question2, weightFactor: this.state.question2.weightFactor } : this.state.question2,
                        question3: result.data.getEditSectionSubmission.submission.question3 ? { ...result.data.getEditSectionSubmission.submission.question3, weightFactor: this.state.question3.weightFactor } : this.state.question3,
                        question4: result.data.getEditSectionSubmission.submission.question4 ? { ...result.data.getEditSectionSubmission.submission.question4, weightFactor: this.state.question4.weightFactor } : this.state.question4,
                        question5: result.data.getEditSectionSubmission.submission.question5 ? { ...result.data.getEditSectionSubmission.submission.question5, weightFactor: this.state.question5.weightFactor } : this.state.question5,
                        question6: result.data.getEditSectionSubmission.submission.question6 ? { ...result.data.getEditSectionSubmission.submission.question6, weightFactor: this.state.question6.weightFactor } : this.state.question6,
                        sectionFeedBack: result.data.getEditSectionSubmission.submission.sectionFeedBack ? result.data.getEditSectionSubmission.submission.sectionFeedBack : this.state.sectionFeedBack,
                        sectionEmpty: false,
                    });
                }
                console.log(this.state);
            })
            .catch(error => {
                console.log(error);
            });
    };

    _goBack = () => {
        const { submissionId, submissionType, userId, assessmentName } = this.state;
        // this.props.history.push({ pathname: "/submissions/review/section1", state: { submissionId: submissionId, submissionType: submissionType, userId: userId } });
        this.props.history.push({ pathname: "/submissions", state: { submissionId: submissionId, submissionType: submissionType, userId: userId, assessmentName: assessmentName } });
    };

    // Question 1 Stuff

    _question1Followup = state => {
        state
            ? this.setState({
                question1: {
                    ...this.state.question1,
                    showFollowupQuestion: true,
                    primaryAnswer: true,
                    validated: this.state.question1.secondaryAnswer.trim() === "" ? false : true

                }
            })
            : this.setState({
                question1: {
                    ...this.state.question1,
                    showFollowupQuestion: false,
                    primaryAnswer: false,
                    validated: true
                }
            });
    };

    _question1Onchange = event => {
        this.setState({
            question1: {
                ...this.state.question1,
                [event.target.id]: event.target.value,
                validated: (event.target.value === "" && this.state.question1.primaryAnswer === true) ? false : true
            }
        });
    };

    _composeQ1Answers = () => {
        const answers = {
            primaryQuestion:
                "Does your domestic laws allow income generating activities by NGOs?",
            primaryAnswer: this.state.question1.showFollowupQuestion,
            hasFollowup: this.state.question1.showFollowupQuestion,
            secondaryQuestion: "If Yes, what kind of provisions are in place?",
            secondaryAnswer: this.state.question1.secondaryAnswer,
            uploads: []
        };
        const submission = {
            question1: answers
        };
        this.setState({
            question1: answers
        });
        return submission;
    };

    // Question2 stuff
    _question2Onchange = event => {
        this.setState({
            question2: {
                ...this.state.question2,
                primaryAnswer: event.target.value,
                validated: event.target.value.trim() === "" ? false : true
            }
        });
    };

    // Question3 stuff
    _question3Onchange = event => {
        this.setState({
            question3: {
                ...this.state.question3,
                primaryAnswer: event.target.value,
                validated: event.target.value.trim() === "" ? false : true
            }
        });
    };

    // Question 4 Stuff

    _setupQuestion4 = () => { };

    _question4Followup = state => {
        let fields = this.state.question4.secondaryArray;
        const toArray = fields.reduce((acc, obj) => [...acc, Object.values(obj).map(y => y.trim())], []);
        const singleArr = [].concat(...toArray);
        const emptyStrings = singleArr.filter(str => str.trim().length <= 0);

        state
            ? this.setState({
                question4: { ...this.state.question4, showFollowupQuestion: true, primaryAnswer: true, validated: emptyStrings.length === 0 ? true : false }
            })
            : this.setState({
                question4: { ...this.state.question4, showFollowupQuestion: false, primaryAnswer: false, validated: true }
            });
    };

    _question4jsonOnchange = event => {
        this.setState({
            question4: {
                ...this.state.question4,
                secondaryJson: {
                    ...this.state.question4.secondaryJson,
                    [event.target.id]: event.target.value
                }
            }
        });
    };

    _question4Onchange = event => {
        this.setState({
            question4: {
                ...this.state.question4,
                [event.target.id]: event.target.value
            }
        });
    };

    // Question 5 stuff
    _question5Followup = state => {
        state
            ? this.setState({
                question5: {
                    ...this.state.question5,
                    showFollowupQuestion: true,
                    primaryAnswer: true,
                    validated: this.state.question5.secondaryAnswer.trim() === "" ? false : true
                }
            })
            : this.setState({
                question5: {
                    ...this.state.question5,
                    showFollowupQuestion: false,
                    primaryAnswer: false,
                    validated: true
                }
            });
    };

    _question5Onchange = event => {
        this.setState({
            question5: {
                ...this.state.question5,
                [event.target.id]: event.target.value
            }
        });
    };

    _question5TextOnchange = event => {
        this.setState({
            question5: {
                ...this.state.question5,
                secondaryAnswer: event.target.value,
                validated: event.target.value.trim() === "" ? false : true
            }
        });
    };

    // Question 6 stuff
    _question6Followup = state => {
        state
            ? this.setState({
                question6: {
                    ...this.state.question6,
                    showFollowupQuestion: true,
                    primaryAnswer: true,
                    // validated: false
                }
            })
            : this.setState({
                question6: {
                    ...this.state.question6,
                    showFollowupQuestion: false,
                    primaryAnswer: false,
                    // validated: true
                }
            });
    };

    _question6SecondaryFollowup = state => {
        state
            ? this.setState({
                question6: { ...this.state.question6, secondaryAnswer: true }
            })
            : this.setState({
                question6: { ...this.state.question6, secondaryAnswer: false }
            });
    };

    _next = () => {
        this.setState({
            saveNContinue: true,
        })
        this._submitSection(true);
    };

    _updateSection = () => {
        this.setState({
            spinner: true,
        })
        this._submitSection(false);
    }

    _submitSection = (flag) => {
        this.props.setFormStatus({ status: false, title: '', message: '' });
        this.props.setSuccessStatus({ status: false, title: '', message: '' });
        const submission = {
            question1: this.state.question1,
            question2: this.state.question2,
            question3: this.state.question3,
            question4: this.state.question4,
            question5: this.state.question5,
            question6: this.state.question6,
            sectionFeedBack: this.state.sectionFeedBack
        };

        this._saveSection(submission, flag);
    };

    _saveSection = (submission, flag) => {
        const { submissionId, submissionType, userId, assessmentName } = this.state;
        this.props.client.mutate({
            mutation: UPDATE_USER_SECTION_1,
            variables: { submission: submission, sectionId: "ck09iwgjk6ftg0b53chrw6bkf", submissionId: submissionId, submissionType: submissionType, userId: userId, actionType: flag }
        }).then(result => {
            this.setState({
                spinner: false,
                saveNContinue: false,
            })

            this.props.setSuccessStatus({
                status: true,
                title: "",
                message: "Saved successfully!"
            });

            if (flag) {
                this.props.history.push({ pathname: "/submissions/review/section3", state: { submissionId: submissionId, submissionType: submissionType, userId: userId, assessmentName: assessmentName } });
                // this.props.history.push({ pathname: '/users/'.concat(userId, '/review/section3'), state: { submissionId: submissionId, submissionType: submissionType } });
            }
        }).catch(error => {
            this.setState({
                spinner: false,
                saveNContinue: false,
            })
            this.props.setFormStatus({
                status: true,
                title: "Oops!",
                message:
                    "There was an error while trying to update data"

            });
        });
    };

    isEmpty(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    _handleMultiChange(i, e) {
        const { name, value } = e.target;
        let fields = this.state.question4.secondaryArray;
        fields[i][name] = value;

        const toArray = fields.reduce((acc, obj) => [...acc, Object.values(obj).map(y => y.trim())], []);
        const singleArr = [].concat(...toArray);
        const emptyStrings = singleArr.filter(str => str.trim().length <= 0);
        // const isEmpty = emptyStrings.length === 0 ? true : false;
        // // console.log(isEmpty);


        this.setState({
            question4: {
                ...this.state.question4,
                validated: emptyStrings.length === 0 ? true : false
            }
        });

        this.setState({ fields });
    }

    _addMoreClick() {
        let fields = this.state.question4.secondaryArray;
        fields.push({ fullName: "", professionalTitle: "", roleOnTheBoard: "", typeOfExpertise: "" });
        this.setState({
            question4: {
                ...this.state.question4,
                validated: false
            }
        });
        this.setState(fields);
    }

    _removeMoreClick(i) {

        let secondaryArray = this.state.question4.secondaryArray;
        secondaryArray.splice(i, 1);

        const toArray = secondaryArray.reduce((acc, obj) => [...acc, Object.values(obj).map(y => y.trim())], []);
        const singleArr = [].concat(...toArray);
        const emptyStrings = singleArr.filter(str => str.trim().length <= 0);

        this.setState({
            question4: {
                ...this.state.question4,
                validated: emptyStrings.length === 0 ? true : false
            }
        });
        this.setState({ secondaryArray });
    }

    _createMuliQuestion() {
        const { t, i18n } = this.props
        if (this.state.question4.secondaryArray != undefined) {
            return this.state.question4.secondaryArray.map((el, i) => (
                <div key={i}>
                    <br /><br />
                    <div className="ms-Grid">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm6 ms-lg10">
                                <Form.Field>
                                    <label>{t("Full Name")}</label>
                                    <TextArea
                                        onChange={this._handleMultiChange.bind(this, i)}
                                        name="fullName"
                                        id="fullName"
                                        label="Full Name"
                                        value={el.fullName || ''}
                                        placeholder={t("Full Name")}
                                        type="text"
                                        readOnly
                                    />
                                    {/* <input disabled type="text" id='fullName' name='fullName' label="Full Name" placeholder={t("Full Name")} value={el.fullName || ''} onChange={this._handleMultiChange.bind(this, i)} /> */}
                                </Form.Field>
                                <Form.Field>
                                    <label>{t("Professional Title")}</label>
                                    <TextArea
                                        onChange={this._handleMultiChange.bind(this, i)}
                                        name="professionalTitle"
                                        id="professionalTitle"
                                        label="Professional Title"
                                        value={el.professionalTitle || ''}
                                        placeholder={t("Professional Title")}
                                        type="text"
                                        readOnly
                                    />
                                    {/* <input disabled type="text" id='professionalTitle' name='professionalTitle' label="Professional Title" placeholder={t("Professional Title")} value={el.professionalTitle || ''} onChange={this._handleMultiChange.bind(this, i)} /> */}
                                </Form.Field>
                                <Form.Field>
                                    <label>{t("Role on the Board")}</label>
                                    <TextArea
                                        onChange={this._handleMultiChange.bind(this, i)}
                                        name="roleOnTheBoard"
                                        id="roleOnTheBoard"
                                        label="Role on the Board"
                                        value={el.roleOnTheBoard || ''} 
                                        className="text-area-height uk-input uk-form-width-large"
                                        placeholder={t("Role on the Board")} 
                                        type="text"
                                        readOnly
                                    />
                                    {/* <input disabled type="text" id='roleOnTheBoard' name='roleOnTheBoard' label="Role on the Board" placeholder={t("Role on the Board")} value={el.roleOnTheBoard || ''} onChange={this._handleMultiChange.bind(this, i)} /> */}
                                </Form.Field>
                                <Form.Field>
                                    <label>{t("Type of Expertise")}</label>
                                    <TextArea
                                        onChange={this._handleMultiChange.bind(this, i)}
                                        name="typeOfExpertise"
                                        id="typeOfExpertise"
                                        label="Type of Expertise"
                                        value={el.typeOfExpertise || ''}
                                        className="text-area-height uk-input uk-form-width-large"
                                        placeholder={t("Type of Expertise")}
                                        type="text"
                                        readOnly
                                    />
                                    {/* <input disabled type="text" id='typeOfExpertise' name='typeOfExpertise' label="Type of Expertise" placeholder={t("Type of Expertise")} value={el.typeOfExpertise || ''} onChange={this._handleMultiChange.bind(this, i)} /> */}
                                </Form.Field>
                            </div>

                            <br />

                            {this.state.question4.secondaryArray.length <= 1 ? (
                                null
                            ) : (
                                    null
                                    // <div className="ms-Grid-col ms-sm6 ms-lg2 uk-text-right">
                                    //     <i class="fas fa-trash-alt multiple_Field_Remove" aria-hidden="true" onClick={this._removeMoreClick.bind(this, i)}></i>                                        
                                    // </div>
                                )}

                        </div>
                    </div>
                    <hr className="multiple_Field_Seperator" />
                </div>

            ))

        } else {
            let fields = this.state.question4;
            fields["secondaryArray"] = [{ fullName: "", professionalTitle: "", roleOnTheBoard: "", typeOfExpertise: "" }];
            this.setState(fields)
        }
    }
    _pagi = (path) => {
        const { submissionId, submissionType, userId, assessmentName } = this.state;
        this.props.history.push({ pathname: '/submissions/review/'.concat(path), state: { submissionId: submissionId, submissionType: submissionType, userId: userId, assessmentName: assessmentName } });
    }

    _feedBackHandler = (event, name) => {
        this.setState({
            [name]: {
                ...this.state[name],
                feedBack: event.target.value

            }
        });
    }

    _scoreHandler = (event, name) => {
        if (event.target.value > 100 || event.target.value < 0) {
            // if (event.target.value > 10 || event.target.value < 0 || event.target.value.length > 2 || event.target.value === "00") {
            event.preventDefault();
        } else {
            this.setState({
                [name]: {
                    ...this.state[name],
                    score: event.target.value

                }
            });
        }
    }
    _sectionFeedBackHandler = event => {
        this.setState({ [event.target.id]: event.target.value });
    }

    render() {
        const { formErrorStatus, formSuccessState, t, i18n } = this.props;
        return (
            <div style={{ backgroundColor: "#fff" }} class="remove-disabled">
                <div>
                    <div class="uk-background-muted uk-margin-top uk-padding" style={{ paddingBottom: "15px", paddingTop: "15px" }}>
                        <div class="uk-grid" uk-grid>
                            <div class="uk-width-expand@m">
                                <form class=" uk-flex-inline uk-flex-middle uk-text-small">
                                    <h4 class="uk-margin-remove-bottom uk-margin-right">
                                        {" "}
                                        {this.state.general.fullName}
                                    </h4>
                                </form>
                                <h6 class="uk-margin-remove-top uk-text-left">
                                    {" "}
                                    {this.state.general.country}, {this.state.general.region}
                                </h6>
                            </div>
                            <div class="uk-width-auto@m uk-text-small">
                                <p class="uk-text-right uk-margin-remove-bottom"> {t("Submitted by")}: <strong> {this.state.user.firstName} {this.state.user.lastName} </strong> </p>
                                <p class="uk-text-right uk-margin-remove-top uk-margin-remove-bottom"> {t("Assessment name")}: <strong>{this.state.assessmentName} </strong></p>
                                {/* <p class="uk-text-right uk-margin-remove-bottom"> Assessment name: <strong> {this.state.assessmentName}</strong> </p> */}
                                <p class="uk-text-right uk-margin-remove-top uk-margin-remove-bottom">{t("Account created on")}: {moment(this.state.user.createdAt).format('DD-MMMM-YYYY')}</p>
                            </div>
                        </div>
                    </div>
                    {/* <div class="uk-background-muted" style={{ paddingRight: "40px", paddingBottom: "20px" }}>
                        <div class="uk-width-auto@m uk-text-small">

                        </div>
                    </div> */}
                </div>
                {/* <div class="uk-background-muted" style={{ paddingRight: "40px", paddingBottom: "20px" }}>
                    <div class="uk-width-auto@m uk-text-small">
                        <p class="uk-text-right uk-margin-remove-top" style={{ color: result_color }}><b>{result_status}: {score}</b></p>

                    </div>
                </div> */}
                {this.state.loading ? (
                    <SectionLoader />
                ) : (
                        <div>
                            {/* Begin Dark Header */}
                            <div className="uk-section-default uk-section" style={{ paddingTop: "10px" }}>
                                <div className="uk-container-small uk-margin-auto" style={{ marginTop: "10px" }}>
                                    <h3 className=" uk-text-light">
                                        {" "}
                                        {t("Section")} {this.state.section.sectionNumber}{" "}
                                        {/* {this.state.section.title}{" "} */}
                                    </h3>
                                </div>
                                {/* <hr /> */}
                                <div
                                    className="uk-container-small uk-margin-auto uk-margin-small-top uk-padding-small paper-read"
                                    uk-scrollspy="target: > .paper; cls:uk-animation-slide-bottom-small; delay: 200"
                                >
                                    <div className="paper uk-link-reset sectionTwo  " style={{ backgroundColor: "#008C95" }}>
                                        <div className="uk-grid uk-flex-middle">
                                            <div className="uk-width-1-6 uk-flex-first uk-text-center">
                                                <i className="far fa-comment-alt icon-xxlarge"></i>
                                            </div>
                                            <div className="uk-width-5-6">
                                                <h4 className="uk-margin-remove" style={{ color: "#ffffff" }}>
                                                    {" "}
                                                    {t(this.state.section.title)}{" "}
                                                </h4>
                                                {/* <p className="uk-margin-remove">
                          {" "}
                          {this.state.section.description}{" "}
                        </p> */}
                                            </div>
                                        </div>
                                    </div>
                                    <Form success error className="uk-margin">
                                        {/* Begin Question Group */}
                                        {/* question1 */}
                                        <div className="uk-link-reset paper uk-margin s1q1">
                                            <h4>
                                                {" "}
                                                <b>1)</b> {t("Does your domestic law allow NGOs to carry out income generating activities (to support the social mission)?")}{" "}
                                            </h4>
                                            {localStorage.RAT_LANG=='ar'? (
                                                 <Button.Group
                                                 size="large"
                                                 onChange={this._question1Onchange}
                                                 className="buttons_arabic_style"
                                             >
                                                 
                                                 
                                                 <Button disabled positive={this.state.question1.showFollowupQuestion ? false : true} onClick={() => this._question1Followup(false)}>
                                                     {t("NO")}
                                                 </Button>
                                                 {/* <Button.Or /> */}
                                                 <div class="or" data-text={localStorage.RAT_LANG=='sp'? "o" : localStorage.RAT_LANG=='fr'? "ou" : localStorage.RAT_LANG=='ar'? "أو": "or"}></div>
                                                 <Button disabled positive={this.state.question1.showFollowupQuestion ? true : false} onClick={() => this._question1Followup(true)}>
                                                     {t("YES")}
                                                 </Button>
                                             </Button.Group>
                                            ):(
                                                <Button.Group
                                                size="large"
                                                onChange={this._question1Onchange}
                                     
                                            >
                                                <Button disabled positive={this.state.question1.showFollowupQuestion ? true : false} onClick={() => this._question1Followup(true)}>
                                                    {t("YES")}
                                                </Button>
                                                {/* <Button.Or /> */}
                                                <div class="or" data-text={localStorage.RAT_LANG=='sp'? "o" : localStorage.RAT_LANG=='fr'? "ou" : localStorage.RAT_LANG=='ar'? "أو": "or"}></div>
                                                <Button disabled positive={this.state.question1.showFollowupQuestion ? false : true} onClick={() => this._question1Followup(false)}>
                                                    {t("NO")}
                                                </Button>
                                            </Button.Group>
                                            )}
                                           
                                            {this.state.question1.showFollowupQuestion ? (
                                                <div>
                                                    <div className="uk-form-label">
                                                        {t("If Yes, what kind of provisions are in place?")}
                                                    </div>
                                                    <div className="uk-margin">
                                                        <span className="uk-form-icon">
                                                            <i className="far fa-User icon-medium"></i>
                                                        </span>

                                                        <TextArea
                                                            onChange={this._question1Onchange}
                                                            name="secondaryAnswer"
                                                            id="secondaryAnswer"
                                                            value={this.state.question1.secondaryAnswer}
                                                            className="text-area-height uk-input uk-form-width-large"
                                                            placeholder={t("Describe here")}
                                                            type="text"
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                            ) : (
                                                    ""
                                                )}
                                            <div className="uk-form-label">{t("Comments/Feedback from SE Hub")}</div>
                                            <div>
                                                <span className="uk-form-icon">
                                                    <i className="far fa-User icon-medium"></i>
                                                </span>

                                                <TextArea
                                                    onChange={(e) => this._feedBackHandler(e, "question1")}
                                                    name="question1feedBack"
                                                    id="question1feedBack"
                                                    value={this.state.question1.feedBack || ""}
                                                    className="uk-textarea"
                                                    placeholder={t("Comments/Feedback here")}
                                                    type="text"
                                                />
                                            </div>
                                        </div>

                                        {/* End Question Group */}
                                        {/* Begin Question 2 */}
                                        <div className="uk-link-reset paper uk-margin">
                                            <h4 className="uk-margin-remove">
                                                {" "}
                                                <b>2)</b> {t("Does the constitution of your organization place explicit constraints or controls on income generating activities?")}
                                            </h4>
                                            <p class="uk-text-muted uk-margin-remove-top uk-margin-small-bottom">{t("Please answer and support the response with additional details.")}</p>
                                            <TextArea
                                                onChange={this._question2Onchange}
                                                name="q2primaryAnswer"
                                                id="q2primaryAnswer"
                                                value={this.state.question2.primaryAnswer}
                                                className="text-area-height uk-input uk-form-width-large"
                                                placeholder={t("Describe here")}
                                                type="text"
                                                disabled
                                            />
                                            <div className="uk-form-label">{t("Score given")}</div>
                                            <div>
                                                <span className="uk-form-icon">
                                                    <i className="far fa-User icon-medium"></i>
                                                </span>

                                                {/* <input
                                                    type="number"
                                                    placeholder="Enter score here"
                                                    id="question2score"
                                                    name="question2score"
                                                    value={this.state.question2.score || ""}
                                                    maxlength="2"
                                                    onChange={(e) => this._scoreHandler(e, "question2")}
                                                    style={{ width: "20%" }}
                                                    onKeyDown={(e) => {
                                                        if (/[\+\-\.\,]/.test(e.key) || e.key.toUpperCase() === "E") {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                /> */}
                                                <Input
                                                    style={{ width: "180px" }}
                                                    labelPosition={localStorage.RAT_LANG=='ar'? "left" : "right"} 
                                                    type="number"
                                                    placeholder={t("Enter score here")}
                                                    id="question2score"
                                                    name="question2score"
                                                    value={this.isEmpty(this.state.question2.score) ? (
                                                        null
                                                    ) : (
                                                            this.state.question2.score
                                                        )}
                                                    onChange={(e) => this._scoreHandler(e, "question2")}
                                                    onKeyDown={(e) => {
                                                        if (/[\+\-\.\,]/.test(e.key) || e.key.toUpperCase() === "E") {
                                                            e.preventDefault();
                                                        }
                                                    }}>
                                                    <input />
                                                    <Label basic>%</Label>
                                                </Input>
                                            </div>
                                            <div className="uk-form-label">{t("Comments/Feedback from SE Hub")}</div>
                                            <div>
                                                <span className="uk-form-icon">
                                                    <i className="far fa-User icon-medium"></i>
                                                </span>

                                                <TextArea
                                                    onChange={(e) => this._feedBackHandler(e, "question2")}
                                                    name="question2feedBack"
                                                    id="question2feedBack"
                                                    value={this.state.question2.feedBack || ""}
                                                    className="uk-textarea"
                                                    placeholder={t("Comments/Feedback here")}
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                        {/* End Question 2 */}
                                        {/* Begin Question 3 */}
                                        <div
                                            href="help-post-read.html"
                                            className="uk-link-reset paper uk-margin-remove-bottom"
                                        >
                                            <h4 className="uk-margin-remove">
                                                {" "}
                                                <b>3)</b> {t("Does the constitution of your organization need to be amended to allow income generating activities?")}
                                            </h4>
                                            <p class="uk-text-muted uk-margin-remove-top uk-margin-small-bottom">{t("Please answer and support the response with additional details.")}</p>
                                            <p className="uk-margin-remove">
                                                {" "}
                                                <TextArea
                                                    onChange={this._question3Onchange}
                                                    name="q3primaryAnswer"
                                                    id="q3primaryAnswer"
                                                    value={this.state.question3.primaryAnswer}
                                                    className="text-area-height uk-input uk-form-width-large"
                                                    placeholder={t("Describe here")}
                                                    type="text"
                                                    disabled
                                                />
                                            </p>
                                            <div className="uk-form-label">{t("Score given")}</div>
                                            <div>
                                                <span className="uk-form-icon">
                                                    <i className="far fa-User icon-medium"></i>
                                                </span>

                                                {/* <input
                                                    type="number"
                                                    placeholder="Enter score here"
                                                    id="question3score"
                                                    name="question3score"
                                                    value={this.state.question3.score || ""}
                                                    maxlength="2"
                                                    onChange={(e) => this._scoreHandler(e, "question3")}
                                                    style={{ width: "20%" }}
                                                    onKeyDown={(e) => {
                                                        if (/[\+\-\.\,]/.test(e.key) || e.key.toUpperCase() === "E") {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                /> */}
                                                <Input
                                                    style={{ width: "180px" }}
                                                    labelPosition={localStorage.RAT_LANG=='ar'? "left" : "right"} 
                                                    type="number"
                                                    placeholder={t("Enter score here")}
                                                    id="question3score"
                                                    name="question3score"
                                                    value={this.isEmpty(this.state.question3.score) ? (
                                                        null
                                                    ) : (
                                                            this.state.question3.score
                                                        )}
                                                    onChange={(e) => this._scoreHandler(e, "question3")}
                                                    onKeyDown={(e) => {
                                                        if (/[\+\-\.\,]/.test(e.key) || e.key.toUpperCase() === "E") {
                                                            e.preventDefault();
                                                        }
                                                    }}>
                                                    <input />
                                                    <Label basic>%</Label>
                                                </Input>
                                            </div>
                                            <div className="uk-form-label">{t("Comments/Feedback from SE Hub")}</div>
                                            <div>
                                                <span className="uk-form-icon">
                                                    <i className="far fa-User icon-medium"></i>
                                                </span>

                                                <TextArea
                                                    onChange={(e) => this._feedBackHandler(e, "question3")}
                                                    name="question3feedBack"
                                                    id="question3feedBack"
                                                    value={this.state.question3.feedBack || ""}
                                                    className="uk-textarea"
                                                    placeholder={t("Comments/Feedback here")}
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                        {/* End Question 3 */}

                                        {/* Begin Question 4 */}
                                        <div
                                            href="help-post-read.html"
                                            className="uk-link-reset paper uk-margin"
                                        >
                                            <h4 className="uk-margin-remove">
                                                {" "}
                                                <b>4)</b> {t("Is the Governing Board of your organization representative of multi-disciplinary fields?")}
                                            </h4>
                                            {localStorage.RAT_LANG=='ar'? (
                                                 <Button.Group
                                                 size="large"
                                                 onChange={this._question1Onchange}
                                                 className="buttons_arabic_style"
                                             >    
                                                 <Button disabled positive={this.state.question4.showFollowupQuestion ? false : true} onClick={() => this._question4Followup(false)}>
                                                     {t("NO")}
                                                 </Button>
                                                 {/* <Button.Or /> */}
                                                 <div class="or" data-text={localStorage.RAT_LANG=='sp'? "o" : localStorage.RAT_LANG=='fr'? "ou" : localStorage.RAT_LANG=='ar'? "أو": "or"}></div>
                                                 <Button disabled positive={this.state.question4.showFollowupQuestion ? true : false} onClick={() => this._question4Followup(true)}>
                                                     {t("YES")}
                                                 </Button>
                                             </Button.Group>
                                            ):(
                                                <Button.Group
                                                size="large"
                                                onChange={this._question1Onchange}
                                            >
                                                <Button disabled positive={this.state.question4.showFollowupQuestion ? true : false} onClick={() => this._question4Followup(true)}>
                                                    {t("YES")}
                                                </Button>
                                                {/* <Button.Or /> */}
                                                <div class="or" data-text={localStorage.RAT_LANG=='sp'? "o" : localStorage.RAT_LANG=='fr'? "ou" : localStorage.RAT_LANG=='ar'? "أو": "or"}></div>
                                                <Button disabled positive={this.state.question4.showFollowupQuestion ? false : true} onClick={() => this._question4Followup(false)}>
                                                    {t("NO")}
                                                </Button>
                                            </Button.Group>
                                            )}
                                           
                                            {this.state.question4.showFollowupQuestion ? (
                                                <div className="uk-margin">
                                                    <div class="uk-form-label">{t("If Yes, add all the applicable Board Members details")}</div>
                                                    <div>
                                                        {this._createMuliQuestion()}
                                                        <div className="uk-text-right"><button disabled onClick={this._addMoreClick.bind(this)} className="uk-button uk-button-big uk-button-primary multiple_Field_Plus" type="button">{t("Add details for another Board Member")}</button></div>
                                                    </div>
                                                </div>
                                            ) : (
                                                    " "
                                                )}
                                            <div className="uk-form-label">{t("Comments/Feedback from SE Hub")}</div>
                                            <div>
                                                <span className="uk-form-icon">
                                                    <i className="far fa-User icon-medium"></i>
                                                </span>

                                                <TextArea
                                                    onChange={(e) => this._feedBackHandler(e, "question4")}
                                                    name="question4feedBack"
                                                    id="question4feedBack"
                                                    value={this.state.question4.feedBack || ""}
                                                    className="uk-textarea"
                                                    placeholder={t("Comments/Feedback here")}
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                        {/* End Question 4 */}

                                        {/* Begin Question 5 */}
                                        <div
                                            href="help-post-read.html"
                                            className="uk-link-reset paper uk-margin-remove-bottom"
                                        >
                                            <h4 className="uk-margin-remove">
                                                <b>5)</b>{" "}
                                                {t("Does at least one Board member have knowledge of business management?")}
                                            </h4>
                                            {localStorage.RAT_LANG=='ar'? (
                                                 <Button.Group
                                                 size="large"
                                                 onChange={this._question5Onchange}
                                                 className="buttons_arabic_style"
                                             >             
                                                 <Button disabled positive={this.state.question5.showFollowupQuestion ? false : true} onClick={() => this._question5Followup(false)}>
                                                     {t("NO")}
                                                 </Button>
                                                 {/* <Button.Or /> */}
                                                 <div class="or" data-text={localStorage.RAT_LANG=='sp'? "o" : localStorage.RAT_LANG=='fr'? "ou" : localStorage.RAT_LANG=='ar'? "أو": "or"}></div>
                                                 <Button disabled positive={this.state.question5.showFollowupQuestion ? true : false} onClick={() => this._question5Followup(true)}>
                                                     {t("YES")}
                                                 </Button>
                                             </Button.Group>
                                            ):(
                                                <Button.Group
                                                size="large"
                                                onChange={this._question5Onchange} 
                                            >
                                                <Button disabled positive={this.state.question5.showFollowupQuestion ? true : false} onClick={() => this._question5Followup(true)}>
                                                    {t("YES")}
                                                </Button>
                                                {/* <Button.Or /> */}
                                                <div class="or" data-text={localStorage.RAT_LANG=='sp'? "o" : localStorage.RAT_LANG=='fr'? "ou" : localStorage.RAT_LANG=='ar'? "أو": "or"}></div>
                                                <Button disabled positive={this.state.question5.showFollowupQuestion ? false : true} onClick={() => this._question5Followup(false)}>
                                                    {t("NO")}
                                                </Button>
                                            </Button.Group>
                                            )}
                                           
                                            {this.state.question5.showFollowupQuestion ? (
                                                <div>
                                                    <div className="uk-form-label">
                                                        {t("If Yes, specify the exact advisory capacity of the Board Members who have business management knowledge and any evidence of their business experience (including any academic qualification)?")}
                                                    </div>
                                                    <div className="uk-margin">
                                                        <span className="uk-form-icon">
                                                            <i className="far fa-User icon-medium"></i>
                                                        </span>
                                                        <TextArea
                                                            onChange={this._question5TextOnchange}
                                                            name="q5secondaryAnswer"
                                                            id="q5secondaryAnswer"
                                                            value={this.state.question5.secondaryAnswer}
                                                            className="text-area-height uk-input uk-form-width-large"
                                                            placeholder={t("Describe here")}
                                                            type="text"
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                            ) : (
                                                    " "
                                                )}

                                            <div className="uk-form-label">{t("Comments/Feedback from SE Hub")}</div>
                                            <div>
                                                <span className="uk-form-icon">
                                                    <i className="far fa-User icon-medium"></i>
                                                </span>

                                                <TextArea
                                                    onChange={(e) => this._feedBackHandler(e, "question5")}
                                                    name="question5feedBack"
                                                    id="question5feedBack"
                                                    value={this.state.question5.feedBack || ""}
                                                    className="uk-textarea"
                                                    placeholder={t("Comments/Feedback here")}
                                                    type="text"
                                                />
                                            </div>
                                        </div>

                                        {/* End Question5 */}

                                        {/* Begin Question 6 */}

                                        <div
                                            href="help-post-read.html"
                                            className="uk-link-reset paper uk-margin"
                                        >
                                            <h4 className="uk-margin-remove">
                                                <b>6)</b>{" "}
                                                {t("Is there consensus or a documented agreement (i.e. meeting minutes) between your organization’s management and Board on the need to be self-sufficient financially (sustainability)?")}
                                            </h4>
                                            {localStorage.RAT_LANG=='ar'? (
                                                <Button.Group
                                                size="large"
                                                onChange={this._question6Onchange}
                                                className="buttons_arabic_style"
                                            >
                                                
                                                <Button disabled positive={this.state.question6.showFollowupQuestion ? false : true} onClick={() => this._question6Followup(false)}>
                                                    {t("NO")}
                                                </Button>
                                                {/* <Button.Or /> */}
                                                <div class="or" data-text={localStorage.RAT_LANG=='sp'? "o" : localStorage.RAT_LANG=='fr'? "ou" : localStorage.RAT_LANG=='ar'? "أو": "or"}></div>
                                                <Button disabled positive={this.state.question6.showFollowupQuestion ? true : false} onClick={() => this._question6Followup(true)}>
                                                    {t("YES")}
                                                </Button>
                                            </Button.Group>
                                            ):(
                                                <Button.Group
                                                size="large"
                                                onChange={this._question6Onchange}
                                            >
                                                <Button disabled positive={this.state.question6.showFollowupQuestion ? true : false} onClick={() => this._question6Followup(true)}>
                                                    {t("YES")}
                                                </Button>
                                                {/* <Button.Or /> */}
                                                <div class="or" data-text={localStorage.RAT_LANG=='sp'? "o" : localStorage.RAT_LANG=='fr'? "ou" : localStorage.RAT_LANG=='ar'? "أو": "or"}></div>
                                                <Button disabled positive={this.state.question6.showFollowupQuestion ? false : true} onClick={() => this._question6Followup(false)}>
                                                    {t("NO")}
                                                </Button>
                                            </Button.Group>
                                            )}
                                            
                                            {this.state.question6.showFollowupQuestion ? (
                                                <div>
                                                    <div className="uk-form-label">
                                                        {t("If Yes, does your organization have a Resource Mobilization plan/strategy?")}
                                                    </div>
                                                    <div className="uk-margin">
                                                        <span className="uk-form-icon">
                                                            <i className="far fa-User icon-medium"></i>
                                                        </span>
                                                        {localStorage.RAT_LANG=='ar'? (
                                                             <Button.Group size="large" className="buttons_arabic_style">
                                                             
                                                            
                                                             <Button
                                                                 disabled
                                                                 positive={this.state.question6.secondaryAnswer ? false : true}
                                                                 onClick={() =>
                                                                     this._question6SecondaryFollowup(false)
                                                                 }
                                                             >
                                                                 {t("NO")}
                                                             </Button>
                                                             {/* <Button.Or /> */}
                                                             <div class="or" data-text={localStorage.RAT_LANG=='sp'? "o" : localStorage.RAT_LANG=='fr'? "ou" : localStorage.RAT_LANG=='ar'? "أو": "or"}></div>
                                                             <Button
                                                                 disabled
                                                                 positive={this.state.question6.secondaryAnswer ? true : false}
                                                                 onClick={() =>
                                                                     this._question6SecondaryFollowup(true)
                                                                 }
                                                             >
                                                                 {t("YES")}
                                                             </Button>
                                                         </Button.Group>
                                                        ):(
                                                            <Button.Group size="large">
                                                            <Button
                                                                disabled
                                                                positive={this.state.question6.secondaryAnswer ? true : false}
                                                                onClick={() =>
                                                                    this._question6SecondaryFollowup(true)
                                                                }
                                                            >
                                                                {t("YES")}
                                                            </Button>
                                                            {/* <Button.Or /> */}
                                                            <div class="or" data-text={localStorage.RAT_LANG=='sp'? "o" : localStorage.RAT_LANG=='fr'? "ou" : localStorage.RAT_LANG=='ar'? "أو": "or"}></div>
                                                            <Button
                                                                disabled
                                                                positive={this.state.question6.secondaryAnswer ? false : true}
                                                                onClick={() =>
                                                                    this._question6SecondaryFollowup(false)
                                                                }
                                                            >
                                                                {t("NO")}
                                                            </Button>
                                                        </Button.Group>
                                                        )}

                                                       
                                                    </div>
                                                </div>
                                            ) : (
                                                    ""
                                                )}
                                            <div className="uk-form-label">{t("Comments/Feedback from SE Hub")}</div>
                                            <div>
                                                <span className="uk-form-icon">
                                                    <i className="far fa-User icon-medium"></i>
                                                </span>

                                                <TextArea
                                                    onChange={(e) => this._feedBackHandler(e, "question6")}
                                                    name="question6feedBack"
                                                    id="question6feedBack"
                                                    value={this.state.question6.feedBack || ""}
                                                    className="uk-textarea"
                                                    placeholder={t("Comments/Feedback here")}
                                                    type="text"
                                                />
                                            </div>
                                        </div>

                                        {/* End Question 6 */}
                                        <div
                                            href="help-post-read.html"
                                            className="uk-link-reset paper uk-margin-remove-bottom"
                                        >
                                            <h4 className="uk-margin-remove">
                                                {" "}
                                                {t("Overall section comments/feedback from SE Hub")}
                                            </h4>

                                            <div className="uk-margin">
                                                <span className="uk-form-icon">
                                                    <i className="far fa-User icon-medium"></i>
                                                </span>

                                                <TextArea
                                                    onChange={this._sectionFeedBackHandler}
                                                    name="sectionFeedBack"
                                                    id="sectionFeedBack"
                                                    value={this.state.sectionFeedBack || ""}
                                                    className="uk-textarea"
                                                    placeholder={t("Overall section comments/feedback here")}
                                                    type="text"
                                                />
                                            </div>
                                        </div>

                                        <div className="uk-margin">
                                            {(formErrorStatus.status) ? (
                                                <div className="danger_alert">{t(formErrorStatus.message)}</div>
                                            ) : ((formSuccessState.status) ? (
                                                <div className="success_alert">{t(formSuccessState.message)}</div>
                                            ) : (''))}
                                        </div>
                                        {/* <div className="uk-grid uk-margin"> */}
                                        <div className="uk-text-center uk-grid uk-grid-small uk-child-width-1-5 uk-margin-small quick-nav" uk-grid>
                                            {/* <div>
                                                <div className="uk-card">
                                                    <li><a className="uk-button uk-button-default uk-button-small uk-width-1-1" onClick={(e) => this._pagi("section1")} href="javascript:void(0)">MA Contact</a></li>
                                                </div>
                                            </div> */}
                                            <div>
                                                <div className="uk-card">
                                                    <li className="uk-active"><a className="uk-button uk-button-default uk-button-small uk-width-1-1" onClick={(e) => this._pagi("section2")} href="javascript:void(0)">{t("Operations")}</a></li>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="uk-card">
                                                    <li><a className="uk-button uk-button-default uk-button-small uk-width-1-1" onClick={(e) => this._pagi("section3")} href="javascript:void(0)">{t("Financial")}</a></li>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="uk-card">
                                                    <li><a className="uk-button uk-button-default uk-button-small uk-width-1-1" onClick={(e) => this._pagi("section4")} href="javascript:void(0)">{t("HR")}</a></li>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="uk-card">
                                                    <li><a className="uk-button uk-button-default uk-button-small uk-width-1-1" onClick={(e) => this._pagi("section5")} href="javascript:void(0)">{t("Resources")}</a></li>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="uk-card">
                                                    <li><a className="uk-button uk-button-default uk-button-small uk-width-1-1" onClick={(e) => this._pagi("section6")} href="javascript:void(0)">{t("Other")}</a></li>
                                                </div>
                                            </div>
                                        </div>

                                        {/* </div> */}

                                        <hr />
                                        <div className="uk-grid uk-text-center button_div">
                                            <div className="uk-width-1-3@m">
                                                <Button
                                                    basic
                                                    className="outline-button blueButton"
                                                    onClick={this._goBack}
                                                >
                                                    {t("Go Back")}
                                                </Button>
                                            </div>

                                            <div className="uk-width-expand@m">
                                                {!this.state.sectionEmpty ? (

                                                    <Button
                                                        basic
                                                        onClick={this._updateSection}
                                                        className="outline-button yellowButton"
                                                        loading={this.state.spinner}
                                                    >
                                                        {t("Save")}
                                                    </Button>
                                                ) : (
                                                        ""
                                                    )}
                                            </div>

                                            <div className="uk-width-1-3@m">
                                                {!this.state.sectionEmpty ? (
                                                    <div>
                                                        <Button
                                                            onClick={this._next}
                                                            className="next-button purpleButton"
                                                            loading={this.state.saveNContinue}
                                                            basic
                                                        >
                                                            {t("Save and Next")}
                                                        </Button>
                                                    </div>
                                                ) : (
                                                        <div>
                                                            <Button
                                                                onClick={this._next}
                                                                basic
                                                                className="outline-button"
                                                                loading={this.state.saveNContinue}
                                                            >
                                                                {t("Save & Continue")}
                                                            </Button>
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    )}
            </div>
        );
    }
}

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(ReviewSection2))));