import React, { Component } from "react";
import "./App.css";
import Home from "../src/components/home/home";
import { default as AdminHome } from "./screens/admin/Home";
import { default as UserHome } from './screens/generic/Home';
import PublicHome from './screens/generic/PublicHome';
import { isLoggedIn } from "./services/CacheOperations";
import Login from "./screens/common/Login";
import { createBrowserHistory } from "history";
import { withRouter, Redirect } from "react-router-dom";
import i18n from './i18n';
import { withNamespaces } from 'react-i18next';

// production/ippfrat-stag/
// /
export const hist = createBrowserHistory({ basename: '/', forceRefresh: true });

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: isLoggedIn()
    };
    this.events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress"
    ];
    this.logout = this.logout.bind(this);
    this.resetTimeout = this.resetTimeout.bind(this);

    for (var i in this.events) {
      window.addEventListener(this.events[i], this.resetTimeout);
    }

    this.setTimeout();
    // var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
    // (function(){
    // var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
    // s1.async=true;
    // s1.src='https://embed.tawk.to/5dde555443be710e1d1f585b/default';
    // s1.charset='UTF-8';
    // s1.setAttribute('crossorigin','*');
    // s0.parentNode.insertBefore(s1,s0);
    // })();
    // var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
    // (function () {
    //   var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
    //   s1.async = true;
    //   s1.src = 'https://embed.tawk.to/5e2e97488e78b86ed8ab369e/default';
    //   s1.charset = 'UTF-8';
    //   s1.setAttribute('crossorigin', '*');
    //   s0.parentNode.insertBefore(s1, s0);
    // })();

    // KMS
    // var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
    // (function(){
    // var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
    // s1.async=true;
    // s1.src='https://embed.tawk.to/5e2e9d52daaca76c6fcfff72/default';
    // s1.charset='UTF-8';
    // s1.setAttribute('crossorigin','*');
    // s0.parentNode.insertBefore(s1,s0);
    // })();
  }

  componentDidMount = () => {
    // console.log(isLoggedIn());
  }

  clearTimeout() {
    if (this.warnTimeout) clearTimeout(this.warnTimeout);

    if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
  }

  setTimeout() {
    // this.warnTimeout = setTimeout(this.warn, 16 * 1000);
    if (this.state.loggedIn) {
      this.logoutTimeout = setTimeout(this.logout, 2 * 60 * 60 * 1000);
    }
  }

  resetTimeout() {
    this.clearTimeout();
    this.setTimeout();
  }

  logout() {
    if (this.state.loggedIn) {
      setTimeout(() => {
        localStorage.removeItem('AUTH_TOKEN');
        localStorage.removeItem('FIRST_NAME');
        localStorage.removeItem('LAST_NAME');
        localStorage.removeItem('USER_TYPE');
        localStorage.removeItem('USER_NAME');
        localStorage.removeItem('USER_ID');
        localStorage.removeItem('IS_FIRST_OPEN');
        // this.props.history.push({ pathname: "/", state: { logoffMsg: "You have been logged out due to inactivity. Please login again." } });
        window.location.href = "/?logOff=true";
      }, 500)
    }
  }

  destroy() {
    this.clearTimeout();

    for (var i in this.events) {
      window.removeEventListener(this.events[i], this.resetTimeout);
    }
  }

  render() {
    const { t, i18n } = this.props;
    document.body.dir = i18n.dir();
    console.log(document)
    console.log(document.body.dir)

    return <div>
      {this.state.loggedIn ? (
        this.state.loggedIn.userType === 'ADMIN' ? (<AdminHome />) : (<UserHome />)
      ) : <UserHome />
      }</div>;
  }
}
export default withRouter(withNamespaces()(App));
// export default App;
