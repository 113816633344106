import React, { Component } from 'react'
import { Container, Grid, Divider, Header, Image, Segment } from 'semantic-ui-react'
import question_icon from '../../assets/images/question_icon.png'
import A from '../../assets/images/A.png'
import Q from '../../assets/images/Q.png'
import answer from '../../assets/images/answer.png'


import { withNamespaces } from "react-i18next";


class IPPFReadinessAssTool extends Component {
    constructor(props) {
        super(props);
        this.state = {
            questions: [
                {
                    question: ' ',
                    answer: ''
                },
                {
                    question: '',
                    answer: ''
                },
                {
                    question: ' ',
                    answer: 'answer'
                },
                {
                    question: '',
                    answer: 'answer'
                },
                {
                    question: '',
                    answer: 'answer'
                },
                {
                    question: '',
                    answer: 'answer'
                },
                {
                    question: '',
                    answer: 'answer'
                },
                {
                    question: '',
                    answer: 'answer'
                },
                {
                    question: '',
                    answer: 'answer'
                },
                {
                    question: '',
                    answer: 'answer'
                },
                {
                    question: '',
                    answer: 'answer'
                },
                {
                    question: '',
                    answer: 'answer'
                },
                {
                    question: '',
                    answer: 'answer'
                },
                {
                    question: '',
                    answer: 'answer'
                },

            ]
        }
    }

    render() {
        const { t, i18n } = this.props;
        console.log(localStorage.RAT_LANG)
        return (
            <div className="uk-section-default uk-section">
                <div className="uk-margin-auto uk-container-small">
                    {/* <h3 style={{paddingTop:"40px"}} className="">Readiness Assessment</h3> */}
                </div>
                <hr />
                <Container className="containerStyle containerMoveBottom">
                    <Segment className="HeaderBorder">
                        <Header as='h4'> {t("Frequently Asked Questions (FAQs)")} </Header>
                    </Segment>
                    <article class="uk-card uk-card-default uk-card-small uk-card-body uk-article tm-ignore-container">
                        <ul class="uk-list-divider uk-list-large uk-accordion faq-accordion" uk-accordion="true">
                            <li>
                                <a class={localStorage.RAT_LANG=='ar'? "titleFaq_AR uk-accordion-title " : "uk-accordion-title titleFaq"}  href="#">
                                    <img  src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_one") }} /> </strong>
                                </a>
                                <div class={localStorage.RAT_LANG=='ar'? ("uk-accordion-content Rowcommon_AR"):("uk-accordion-content Rowcommon")} >
                                    <img src={answer} className="faq-answer-icon" />
                                    <span> <div dangerouslySetInnerHTML={{ __html: t("a_one") }} />  </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.RAT_LANG=='ar'? "titleFaq_AR uk-accordion-title" : "uk-accordion-title titleFaq"} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_two") }} /> </strong>
                                </a>
                                <div class={localStorage.RAT_LANG=='ar'? ("uk-accordion-content Rowcommon_AR"):("uk-accordion-content Rowcommon")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span> <div dangerouslySetInnerHTML={{ __html: t("a_two") }} /> </span>
                                </div>
                            </li>
                            
                            <li>
                                <a class={localStorage.RAT_LANG=='ar'? "titleFaq_AR uk-accordion-title" : "uk-accordion-title titleFaq"} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_three") }} /> </strong>
                                </a>
                                <div class={localStorage.RAT_LANG=='ar'? ("uk-accordion-content Rowcommon_AR"):("uk-accordion-content Rowcommon")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span> <div dangerouslySetInnerHTML={{ __html: t("a_three") }} /> </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.RAT_LANG=='ar'? "titleFaq_AR uk-accordion-title" : "uk-accordion-title titleFaq"} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_four") }} />  </strong>
                                </a>
                                <div class={localStorage.RAT_LANG=='ar'? ("uk-accordion-content Rowcommon_AR"):("uk-accordion-content Rowcommon")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span> <div dangerouslySetInnerHTML={{ __html: t("a_four") }} /> </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.RAT_LANG=='ar'? "titleFaq_AR uk-accordion-title" : "uk-accordion-title titleFaq"} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_five") }} />  </strong>
                                </a>
                                <div class={localStorage.RAT_LANG=='ar'? ("uk-accordion-content Rowcommon_AR"):("uk-accordion-content Rowcommon")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span> <div dangerouslySetInnerHTML={{ __html: t("a_five") }} />  </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.RAT_LANG=='ar'? "titleFaq_AR uk-accordion-title" : "uk-accordion-title titleFaq"} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_six") }} /> </strong>
                                </a>
                                <div class={localStorage.RAT_LANG=='ar'? ("uk-accordion-content Rowcommon_AR"):("uk-accordion-content Rowcommon")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span> <div dangerouslySetInnerHTML={{ __html: t("a_six") }} /> </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.RAT_LANG=='ar'? "titleFaq_AR uk-accordion-title" : "uk-accordion-title titleFaq"} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_seven") }} /> </strong>
                                </a>
                                <div class={localStorage.RAT_LANG=='ar'? ("uk-accordion-content Rowcommon_AR"):("uk-accordion-content Rowcommon")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span> <div dangerouslySetInnerHTML={{ __html: t("a_seven") }} /> </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.RAT_LANG=='ar'? "titleFaq_AR uk-accordion-title" : "uk-accordion-title titleFaq"} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_eight") }} /> </strong>
                                </a>
                                <div class={localStorage.RAT_LANG=='ar'? ("uk-accordion-content Rowcommon_AR"):("uk-accordion-content Rowcommon")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span> <div dangerouslySetInnerHTML={{ __html: t("a_eight") }} />  </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.RAT_LANG=='ar'? "titleFaq_AR uk-accordion-title" : "uk-accordion-title titleFaq"} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_nine") }} /> </strong>
                                </a>
                                <div class={localStorage.RAT_LANG=='ar'? ("uk-accordion-content Rowcommon_AR"):("uk-accordion-content Rowcommon")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span> <div dangerouslySetInnerHTML={{ __html: t("a_nine") }} /> </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.RAT_LANG=='ar'? "titleFaq_AR uk-accordion-title" : "uk-accordion-title titleFaq"} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_ten") }} /> </strong>
                                </a>
                                <div class={localStorage.RAT_LANG=='ar'? ("uk-accordion-content Rowcommon_AR"):("uk-accordion-content Rowcommon")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span> <div dangerouslySetInnerHTML={{ __html: t("a_ten") }} /> </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.RAT_LANG=='ar'? "titleFaq_AR uk-accordion-title" : "uk-accordion-title titleFaq"} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_eleven") }} /> </strong>
                                </a>
                                <div class={localStorage.RAT_LANG=='ar'? ("uk-accordion-content Rowcommon_AR"):("uk-accordion-content Rowcommon")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span> <div dangerouslySetInnerHTML={{ __html: t("a_eleven") }} /> </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.RAT_LANG=='ar'? "titleFaq_AR uk-accordion-title" : "uk-accordion-title titleFaq"} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_twelve") }} /> </strong>
                                </a>
                                <div class={localStorage.RAT_LANG=='ar'? ("uk-accordion-content Rowcommon_AR"):("uk-accordion-content Rowcommon")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span> <div dangerouslySetInnerHTML={{ __html: t("a_twelve") }} /> </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.RAT_LANG=='ar'? "titleFaq_AR uk-accordion-title" : "uk-accordion-title titleFaq"} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong>  <div dangerouslySetInnerHTML={{ __html: t("q_thirteen") }} /> </strong>
                                </a>
                                <div class={localStorage.RAT_LANG=='ar'? ("uk-accordion-content Rowcommon_AR"):("uk-accordion-content Rowcommon")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span> <div dangerouslySetInnerHTML={{ __html: t("a_thirteen") }} /> </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.RAT_LANG=='ar'? "titleFaq_AR uk-accordion-title" : "uk-accordion-title titleFaq"}href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_fourteen") }} /> </strong>
                                </a>
                                <div class={localStorage.RAT_LANG=='ar'? ("uk-accordion-content Rowcommon_AR"):("uk-accordion-content Rowcommon")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span> <div dangerouslySetInnerHTML={{ __html: t("a_fourteen") }} /> </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.RAT_LANG=='ar'? "titleFaq_AR uk-accordion-title" : "uk-accordion-title titleFaq"} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_fifteen") }} />  </strong>
                                </a>
                                <div class={localStorage.RAT_LANG=='ar'? ("uk-accordion-content Rowcommon_AR"):("uk-accordion-content Rowcommon")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span> <div dangerouslySetInnerHTML={{ __html: t("a_fifteen") }} /> </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.RAT_LANG=='ar'? "titleFaq_AR uk-accordion-title" : "uk-accordion-title titleFaq"} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong>  <div dangerouslySetInnerHTML={{ __html: t("q_sixteen") }} />  </strong>
                                </a>
                                <div class={localStorage.RAT_LANG=='ar'? ("uk-accordion-content Rowcommon_AR"):("uk-accordion-content Rowcommon")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span> <div dangerouslySetInnerHTML={{ __html: t("a_sixteen") }} />  </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.RAT_LANG=='ar'? "titleFaq_AR uk-accordion-title" : "uk-accordion-title titleFaq"} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_seventeen") }} /> </strong>
                                </a>
                                <div class={localStorage.RAT_LANG=='ar'? ("uk-accordion-content Rowcommon_AR"):("uk-accordion-content Rowcommon")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span> <div dangerouslySetInnerHTML={{ __html: t("a_seventeen") }} /> </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.RAT_LANG=='ar'? "titleFaq_AR uk-accordion-title" : "uk-accordion-title titleFaq"} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_eighteen") }} /> </strong>
                                </a>
                                <div class={localStorage.RAT_LANG=='ar'? ("uk-accordion-content Rowcommon_AR"):("uk-accordion-content Rowcommon")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span> <div dangerouslySetInnerHTML={{ __html: t("a_eighteen") }} /> </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.RAT_LANG=='ar'? "titleFaq_AR uk-accordion-title" : "uk-accordion-title titleFaq"} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_nineteen") }} /> </strong>
                                </a>
                                <div class={localStorage.RAT_LANG=='ar'? ("uk-accordion-content Rowcommon_AR"):("uk-accordion-content Rowcommon")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                    <div dangerouslySetInnerHTML={{ __html: t("a_nineteen") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.RAT_LANG=='ar'? "titleFaq_AR uk-accordion-title" : "uk-accordion-title titleFaq"} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_twenty") }} />  </strong>
                                </a>
                                <div class={localStorage.RAT_LANG=='ar'? ("uk-accordion-content Rowcommon_AR"):("uk-accordion-content Rowcommon")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                    <div dangerouslySetInnerHTML={{ __html: t("a_twenty") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.RAT_LANG=='ar'? "titleFaq_AR uk-accordion-title" : "uk-accordion-title titleFaq"} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong>  <div dangerouslySetInnerHTML={{ __html: t("q_twenty_one") }} />  </strong>
                                </a>
                                <div class={localStorage.RAT_LANG=='ar'? ("uk-accordion-content Rowcommon_AR"):("uk-accordion-content Rowcommon")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                    <div dangerouslySetInnerHTML={{ __html: t("a_twenty_one") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.RAT_LANG=='ar'? "titleFaq_AR uk-accordion-title" : "uk-accordion-title titleFaq"} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_twenty_two") }} />  </strong>
                                </a>
                                <div class={localStorage.RAT_LANG=='ar'? ("uk-accordion-content Rowcommon_AR"):("uk-accordion-content Rowcommon")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                    <div dangerouslySetInnerHTML={{ __html: t("a_twenty_two") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.RAT_LANG=='ar'? "titleFaq_AR uk-accordion-title" : "uk-accordion-title titleFaq"} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong>  <div dangerouslySetInnerHTML={{ __html: t("q_twenty_three") }} />  </strong>
                                </a>
                                <div class={localStorage.RAT_LANG=='ar'? ("uk-accordion-content Rowcommon_AR"):("uk-accordion-content Rowcommon")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                    <div dangerouslySetInnerHTML={{ __html: t("a_twenty_three") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.RAT_LANG=='ar'? "titleFaq_AR uk-accordion-title" : "uk-accordion-title titleFaq"} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_twenty_four") }} /> </strong>
                                </a>
                                <div class={localStorage.RAT_LANG=='ar'? ("uk-accordion-content Rowcommon_AR"):("uk-accordion-content Rowcommon")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                    <div dangerouslySetInnerHTML={{ __html: t("a_twenty_four") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.RAT_LANG=='ar'? "titleFaq_AR uk-accordion-title" : "uk-accordion-title titleFaq"} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_twenty_five") }} /> </strong>
                                </a>
                                <div class={localStorage.RAT_LANG=='ar'? ("uk-accordion-content Rowcommon_AR"):("uk-accordion-content Rowcommon")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                    <div dangerouslySetInnerHTML={{ __html: t("a_twenty_five_para_one") }} />
                                        <ul> 
                                            <li style={{color: "green", fontSize:"16px"}} ><b> {t("a_twenty_five_li_one")} </b> </li> 
                                            <li style={{color: "orange", fontSize: "16px"}} ><b> {t("a_twenty_five_li_two")} </b></li> 
                                            <li style={{color: "red", fontSize:"16px" }} ><b> {t("a_twenty_five_li_three")} </b></li> </ul> 

                                    <div dangerouslySetInnerHTML={{ __html: t("a_twenty_five_para_two") }} />
                                    
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.RAT_LANG=='ar'? "titleFaq_AR uk-accordion-title" : "uk-accordion-title titleFaq"} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong>  <div dangerouslySetInnerHTML={{ __html: t("q_twenty_six") }} /> </strong>
                                </a>
                                <div class={localStorage.RAT_LANG=='ar'? ("uk-accordion-content Rowcommon_AR"):("uk-accordion-content Rowcommon")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>  <div dangerouslySetInnerHTML={{ __html: t("a_twenty_six") }} /> </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.RAT_LANG=='ar'? "titleFaq_AR uk-accordion-title" : "uk-accordion-title titleFaq"} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_twenty_seven") }} />  </strong>
                                </a>
                                <div class={localStorage.RAT_LANG=='ar'? ("uk-accordion-content Rowcommon_AR"):("uk-accordion-content Rowcommon")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span> <div dangerouslySetInnerHTML={{ __html: t("a_twenty_seven") }} /> </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.RAT_LANG=='ar'? "titleFaq_AR uk-accordion-title" : "uk-accordion-title titleFaq"} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_twenty_eight") }} /> </strong>
                                </a>
                                <div class={localStorage.RAT_LANG=='ar'? ("uk-accordion-content Rowcommon_AR"):("uk-accordion-content Rowcommon")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span> <div dangerouslySetInnerHTML={{ __html: t("a_twenty_eight") }} /> </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.RAT_LANG=='ar'? "titleFaq_AR uk-accordion-title" : "uk-accordion-title titleFaq"} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong>  <div dangerouslySetInnerHTML={{ __html: t("q_twenty_nine") }} />  </strong>
                                </a>
                                <div class={localStorage.RAT_LANG=='ar'? ("uk-accordion-content Rowcommon_AR"):("uk-accordion-content Rowcommon")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                        <div dangerouslySetInnerHTML={{ __html: t("a_twenty_nine_para_one") }} />
                                        <ul>
                                            <li><span style={{ color: "green", fontWeight:"bold", fontSize:"16px" }}> {t("a_twenty_nine_li_one")} </span> - <div dangerouslySetInnerHTML={{ __html: t("a_twenty_nine_li_one_para") }} /> </li>
                                            <li><span style={{ color: "orange", fontWeight:"bold", fontSize:"16px" }}> {t("a_twenty_nine_li_two")} </span> - <div dangerouslySetInnerHTML={{ __html: t("a_twenty_nine_li_two_para") }} /> </li>
                                            <li><span style={{ color: "red", fontWeight:"bold", fontSize:"16px" }}> {t("a_twenty_nine_li_three")} </span> - <div dangerouslySetInnerHTML={{ __html: t("a_twenty_nine_li_three_para") }} /> </li>
                                        </ul>
                                        <div dangerouslySetInnerHTML={{ __html: t("a_twenty_nine_para_two") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.RAT_LANG=='ar'? "titleFaq_AR uk-accordion-title" : "uk-accordion-title titleFaq"} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong>  <div dangerouslySetInnerHTML={{ __html: t("q_thirty") }} /> </strong>
                                </a>
                                <div class={localStorage.RAT_LANG=='ar'? ("uk-accordion-content Rowcommon_AR"):("uk-accordion-content Rowcommon")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                    <div dangerouslySetInnerHTML={{ __html: t("a_thirty_para_one") }} />                                   
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.RAT_LANG=='ar'? "titleFaq_AR uk-accordion-title" : "uk-accordion-title titleFaq"} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong>  <div dangerouslySetInnerHTML={{ __html: t("q_thirty_one") }} /> </strong>
                                </a>
                                <div class={localStorage.RAT_LANG=='ar'? ("uk-accordion-content Rowcommon_AR"):("uk-accordion-content Rowcommon")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                    <div dangerouslySetInnerHTML={{ __html: t("a_thirty_one") }} />  
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.RAT_LANG=='ar'? "titleFaq_AR uk-accordion-title" : "uk-accordion-title titleFaq"} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong>  <div dangerouslySetInnerHTML={{ __html: t("q_thirty_two") }} />   </strong>
                                </a>
                                <div class={localStorage.RAT_LANG=='ar'? ("uk-accordion-content Rowcommon_AR"):("uk-accordion-content Rowcommon")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                    <div dangerouslySetInnerHTML={{ __html: t("a_thirty_two") }} />  
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.RAT_LANG=='ar'? "titleFaq_AR uk-accordion-title" : "uk-accordion-title titleFaq"} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong>  <div dangerouslySetInnerHTML={{ __html: t("q_thirty_three") }} />   </strong>
                                </a>
                                <div class={localStorage.RAT_LANG=='ar'? ("uk-accordion-content Rowcommon_AR"):("uk-accordion-content Rowcommon")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                    <div dangerouslySetInnerHTML={{ __html: t("a_thirty_three") }} />  
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.RAT_LANG=='ar'? "titleFaq_AR uk-accordion-title" : "uk-accordion-title titleFaq"} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong>  <div dangerouslySetInnerHTML={{ __html: t("q_thirty_four") }} />  </strong>
                                </a>
                                <div class={localStorage.RAT_LANG=='ar'? ("uk-accordion-content Rowcommon_AR"):("uk-accordion-content Rowcommon")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                    <div dangerouslySetInnerHTML={{ __html: t("a_thirty_four") }} /> 
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.RAT_LANG=='ar'? "titleFaq_AR uk-accordion-title" : "uk-accordion-title titleFaq"} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong>  <div dangerouslySetInnerHTML={{ __html: t("q_thirty_five") }} />  </strong>
                                </a>
                                <div class={localStorage.RAT_LANG=='ar'? ("uk-accordion-content Rowcommon_AR"):("uk-accordion-content Rowcommon")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                    <div dangerouslySetInnerHTML={{ __html: t("a_thirty_five") }} /> 
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.RAT_LANG=='ar'? "titleFaq_AR uk-accordion-title" : "uk-accordion-title titleFaq"} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_thirty_six") }} />  </strong>
                                </a>
                                <div class={localStorage.RAT_LANG=='ar'? ("uk-accordion-content Rowcommon_AR"):("uk-accordion-content Rowcommon")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                    <div dangerouslySetInnerHTML={{ __html: t("a_thirty_six") }} /> 
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.RAT_LANG=='ar'? "titleFaq_AR uk-accordion-title" : "uk-accordion-title titleFaq"} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_thirty_seven") }} />  </strong>
                                </a>
                                <div class={localStorage.RAT_LANG=='ar'? ("uk-accordion-content Rowcommon_AR"):("uk-accordion-content Rowcommon")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                    <div dangerouslySetInnerHTML={{ __html: t("a_thirty_seven") }} /> 
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.RAT_LANG=='ar'? "titleFaq_AR uk-accordion-title" : "uk-accordion-title titleFaq"} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong>  <div dangerouslySetInnerHTML={{ __html: t("q_thirty_eight") }} />  </strong>
                                </a>
                                <div class={localStorage.RAT_LANG=='ar'? ("uk-accordion-content Rowcommon_AR"):("uk-accordion-content Rowcommon")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                    <div dangerouslySetInnerHTML={{ __html: t("a_thirty_eight") }} /> 
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.RAT_LANG=='ar'? "titleFaq_AR uk-accordion-title" : "uk-accordion-title titleFaq"} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_thirty_nine") }} /> </strong>
                                </a>
                                <div class={localStorage.RAT_LANG=='ar'? ("uk-accordion-content Rowcommon_AR"):("uk-accordion-content Rowcommon")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                    <div dangerouslySetInnerHTML={{ __html: t("a_thirty_nine") }} /> 
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.RAT_LANG=='ar'? "titleFaq_AR uk-accordion-title" : "uk-accordion-title titleFaq"} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_forty") }} />  </strong>
                                </a>
                                <div class={localStorage.RAT_LANG=='ar'? ("uk-accordion-content Rowcommon_AR"):("uk-accordion-content Rowcommon")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                    <div dangerouslySetInnerHTML={{ __html: t("a_forty") }} /> 
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.RAT_LANG=='ar'? "titleFaq_AR uk-accordion-title" : "uk-accordion-title titleFaq"} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong>  <div dangerouslySetInnerHTML={{ __html: t("q_forty_one") }} /> </strong>
                                </a>
                                <div class={localStorage.RAT_LANG=='ar'? ("uk-accordion-content Rowcommon_AR"):("uk-accordion-content Rowcommon")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                    <div dangerouslySetInnerHTML={{ __html: t("a_forty_one") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.RAT_LANG=='ar'? "titleFaq_AR uk-accordion-title" : "uk-accordion-title titleFaq"} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_forty_two") }} /> </strong>
                                </a>
                                <div class={localStorage.RAT_LANG=='ar'? ("uk-accordion-content Rowcommon_AR"):("uk-accordion-content Rowcommon")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                    <div dangerouslySetInnerHTML={{ __html: t("a_forty_two") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.RAT_LANG=='ar'? "titleFaq_AR uk-accordion-title" : "uk-accordion-title titleFaq"} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_forty_three") }} /> </strong>
                                </a>
                                <div class={localStorage.RAT_LANG=='ar'? ("uk-accordion-content Rowcommon_AR"):("uk-accordion-content Rowcommon")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                    <div dangerouslySetInnerHTML={{ __html: t("a_forty_three") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.RAT_LANG=='ar'? "titleFaq_AR uk-accordion-title" : "uk-accordion-title titleFaq"} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong>  <div dangerouslySetInnerHTML={{ __html: t("q_forty_four") }} /> </strong>
                                </a>
                                <div class={localStorage.RAT_LANG=='ar'? ("uk-accordion-content Rowcommon_AR"):("uk-accordion-content Rowcommon")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                    <div dangerouslySetInnerHTML={{ __html: t("a_forty_four") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.RAT_LANG=='ar'? "titleFaq_AR uk-accordion-title" : "uk-accordion-title titleFaq"} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_forty_five") }} /> </strong>
                                </a>
                                <div class={localStorage.RAT_LANG=='ar'? ("uk-accordion-content Rowcommon_AR"):("uk-accordion-content Rowcommon")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                    <div dangerouslySetInnerHTML={{ __html: t("a_forty_five") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.RAT_LANG=='ar'? "titleFaq_AR uk-accordion-title" : "uk-accordion-title titleFaq"} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_forty_six") }} /> </strong>
                                </a>
                                <div class={localStorage.RAT_LANG=='ar'? ("uk-accordion-content Rowcommon_AR"):("uk-accordion-content Rowcommon")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                    <div dangerouslySetInnerHTML={{ __html: t("a_forty_six") }} />
                                    </span>
                                </div>
                            </li>

                            <li>
                                <a class={localStorage.RAT_LANG=='ar'? "titleFaq_AR uk-accordion-title" : "uk-accordion-title titleFaq"} href="#">
                                    <img src={question_icon} className="faq-question-icon" />
                                    <strong> <div dangerouslySetInnerHTML={{ __html: t("q_forty_seven") }} /> </strong>
                                </a>
                                <div class={localStorage.RAT_LANG=='ar'? ("uk-accordion-content Rowcommon_AR"):("uk-accordion-content Rowcommon")}>
                                    <img src={answer} className="faq-answer-icon" />
                                    <span>
                                    <div dangerouslySetInnerHTML={{ __html: t("a_forty_seven") }} />
                                    </span>
                                </div>
                            </li>
                        </ul>
                    </article>
                </Container>

            </div>
        );
    }
}

export default withNamespaces()(IPPFReadinessAssTool)