import React, { Component } from "react";
import { GET_SINGLE_SECTION } from "../../../queries/SectionQueries";
import { withApollo } from "react-apollo";
import { withRouter } from 'react-router-dom';
import moment from 'moment';

class SingleSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      success: false,
      sectionInfo: {
        title: "...",
        description: "...",
        subTitle:"...",
        createdAt:"...",
        updatedAt:"...",
      },
      questions: [],
    };
    console.log(this.props.match.params);
    this._getSectionBasicInfo();
  }
  // This is a redirect function
  addNewSection = () => {
    console.log("addNew");
    this.props.history.push(`${this.props.match.url}new`);
  };

  _getSectionBasicInfo = () => {
    this.props.client.query({
      query: GET_SINGLE_SECTION,
      variables: { sectionId: this.props.match.params.someid }
    }).then(result => {
      console.log(result);
      this.setState({ 
        sectionInfo: {
          title: result.data.getSingleSection.title,
          description: result.data.getSingleSection.description,
          subTitle: result.data.getSingleSection.subTitle,
          createdAt: result.data.getSingleSection.createdAt,
          updatedAt: result.data.getSingleSection.updatedAt,
          loading: false,
          success: true,
        }
       })
    }).catch(error => {
      console.log(error);
      this.setState({
        loading: false,
        error: true,
      })
    });
  }

  render() {
    return (
      // <div className="admin-content-inner">
      //   <div className="uk-flex-inline uk-flex-middle">
      //     <i className="fas fa-th-list icon-large uk-margin-right"></i>
      //     <h4 className="uk-margin-remove"> Section : { this.state.sectionInfo.title } </h4>
      //     <button
      //       onClick={this.addNewSection}
      //       className="uk-button uk-button-success uk-margin-medium-left admin-btn"
      //       href="#new"
      //       uk-tooltip="title: Add a question to this section ; delay: 500 ; pos: top ;animation:	uk-animation-scale-up"
      //     >
      //       <i className="fas fa-folder"></i> Add New Question
      //     </button>
      //   </div>
      //   <div className="uk-background-default uk-margin-top uk-padding">
      //     <div className="uk-grid" uk-grid>
      //       <div className="uk-width-expand@m">
      //         <form className=" uk-flex-inline uk-flex-middle uk-text-small">
      //           <p className="uk-margin-remove-bottom uk-margin-right">
      //             {" "}
      //             Description :{" "}
      //           </p>
      //         </form>
      //       </div>
      //       <div className="uk-width-auto@m uk-text-small">
      //         <p>  Created At : { moment(this.state.sectionInfo.createdAt).format('LL') }  </p>
      //       </div>
      //     </div>
      //   </div>
      //   <div className="uk-background-default uk-position-relative uk-box-shadow-medium"> 
      //               <h5 className="uk-padding-small uk-margin-remove uk-text-bold  uk-text-left"> Questions in this section </h5> 
      //               <p className="uk-position-top-right uk-position-small">20 questions published</p> 
      //               <hr className="uk-margin-remove" /> 
      //               <div className="uk-grid uk-padding-small" uk-grid> 
      //                   <div className="uk-width-1-3@m uk-visible@m"> 
      //                       <img src="../assets/images/courses/course-1.jpg" alt="" className="uk-animation-fade" />
      //                       <ul className="uk-list uk-text-small"> 
      //                           <li> Takes this course 124+ </li>                                 
      //                           <li> 14 Lectures </li>                                 
      //                           <li> Publish time 12/12/2018</li>                                 
      //                       </ul>                             
      //                   </div>                         
      //                   <div className="uk-width-expand"> 
      //                       <div className="uk-align-right"> 
      //                           <a className="el-content uk-button uk-button-success uk-circle" href="Homepage.html"> <i className="fas fa-check"></i> approve </a> 
      //                           <a className="el-content uk-button uk-button-danger uk-circle" href="Homepage.html"> <i className="fas fa-times"></i>  Cancel  </a> 
      //                       </div>                             
      //                       <h3 className="uk-margin-top"> Build Responsive Websites </h3> 
      //                       <h5 className="uk-margin-remove"> Catagory : Web Development </h5> 
      //                       <h5 className="uk-margin-small uk-text-muted"> Course Description</h5> 
      //                       <p> Lorem ipsum dolor sit amet, est ei idque voluptua copiosae, pro detracto disputando reformidans at, ex vel suas eripuit. Vel alii zril maiorum ex, mea id sale eirsmod epicurei. Sit te possit senserit, eam alia veritus maluisset ei, id cibo vocent ocurreret per. Te qui doming doctus referrentur, usu debet tamquam et. Sea ut nullam aperiam, mei cu tollit salutatus delicatissimi. His appareat perfecto intellegat te. </p> 
      //                   </div>                         
      //               </div>                     
      //               <hr/> 
      //               <div className="uk-grid uk-padding-small" uk-grid> 
      //                   <div className="uk-width-1-3@m uk-visible@m"> 
      //                       <img src="../assets/images/courses/course-1.jpg" alt="" className="uk-animation-fade" />
      //                       <ul className="uk-list uk-text-small"> 
      //                           <li> 
      //                               Takes this course 124+ 
      //                           </li>                                 
      //                           <li> 
      //                               14 Lectures 
      //                           </li>                                 
      //                           <li> 
      //                               Publish time 12/12/2018
      //                           </li>                                 
      //                       </ul>                             
      //                   </div>                         
      //                   <div className="uk-width-expand"> 
      //                       <div className="uk-align-right"> 
      //                           <a className="el-content uk-button uk-button-success uk-circle" href="Homepage.html"> <i className="fas fa-check"></i> Accept </a> 
      //                           <a className="el-content uk-button uk-button-danger uk-circle" href="Homepage.html"> <i className="fas fa-times"></i> Delete  </a> 
      //                       </div>                             
      //                       <h3 className="uk-margin-top"> Build Responsive Websites </h3> 
      //                       <h5 className="uk-margin-remove"> Catagory : Web Development </h5> 
      //                       <h5 className="uk-margin-small uk-text-muted"> Course Description</h5> 
      //                       <p> Lorem ipsum dolor sit amet, est ei idque voluptua copiosae, pro detracto disputando reformidans at, ex vel suas eripuit. Vel alii zril maiorum ex, mea id sale eirmod epicurei. Sit te possit senserit, eam alia veritus maluisset ei, id cibo vocent ocurreret per. Te qui doming doctus referrentur, usu debet tamquam et. Sea ut nullam aperiam, mei cu tollit salutatus delicatissimi. His appareat perfecto intellegat te. </p> 
      //                   </div>                         
      //               </div>                     
      //               <hr />
      //               <div className="uk-grid uk-padding-small" uk-grid> 
      //                   <div className="uk-width-1-3@m uk-visible@m"> 
      //                       <img src="../assets/images/courses/course-1.jpg" alt="" className="uk-animation-fade" />
      //                       <ul className="uk-list uk-text-small"> 
      //                           <li> Takes this course 124+ </li>                                 
      //                           <li> 14 Lectures </li>                                 
      //                           <li> Publish time 12/12/2018</li>                                 
      //                       </ul>                             
      //                   </div>                         
      //                   <div className="uk-width-expand"> 
      //                       <div className="uk-align-right"> 
      //                           <a className="el-content uk-button uk-button-success uk-circle" href="Homepage.html"> <i className="fas fa-check"></i> Accept </a> 
      //                           <a className="el-content uk-button uk-button-danger uk-circle" href="Homepage.html"> <i className="fas fa-times"></i> Delete  </a> 
      //                       </div>                             
      //                       <h3 className="uk-margin-top"> Build Responsive Websites   </h3> 
      //                       <h5 className="uk-margin-remove"> Catagory : Web Development </h5> 
      //                       <h5 className="uk-margin-small uk-text-muted"> Course Description</h5> 
      //                       <p> Lorem ipsum dolor sit amet, est ei idque voluptua copiosae, pro detracto disputando reformidans at, ex vel suas eripuit. Vel alii zril maiorum ex, mea id sale eirmod epicurei. Sit te possit senserit, eam alia veritus maluisset ei, id cibo vocent ocurreret per. Te qui doming doctus referrentur, usu debet tamquam et. Sea ut nullam aperiam, mei cu tollit salutatus delicatissimi. His appareat perfecto intellegat te. </p> 
      //                   </div>                         
      //               </div>                     
      //           </div>                 
      //           <div className="uk-padding uk-background-default"> 
      //               <ul className="uk-pagination uk-flex-center uk-margin-medium"> 
      //                   <li className="uk-active"> 
      //                       <span>1</span> 
      //                   </li>                         
      //                   <li> 
      //                       <a href="#">2</a> 
      //                   </li>                         
      //                   <li> 
      //                       <a href="#">3</a> 
      //                   </li>                         
      //                   <li> 
      //                       <a href="#">4</a> 
      //                   </li>                         
      //                   <li> 
      //                       <a href="#">5</a> 
      //                   </li>                         
      //                   <li> 
      //                       <a href="#"><i className="fas fa-ellipsis-h uk-margin-small-top"></i></a> 
      //                   </li>                         
      //                   <li> 
      //                       <a href="#">12</a> 
      //                   </li>                         
      //               </ul>                     
      //               <p>  You are showing 1 to 12 entries</p> 
      //           </div> 
      // </div>

      <div></div>
    );
  }
}

export default withRouter(withApollo(SingleSection));
