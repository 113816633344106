import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';

class Navbar extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (

            <div>

                {/* <div className="side-nav uk-animation-slide-left-medium" id="side-nav">

                    <span className="uk-animation-fade tm-mobile-close-icon" uk-toggle="target: #side-nav; cls: side-nav-active"> <i className="fas fa-times icon-large"></i></span>
                    <div id="side-nav">
                        <div className="side-nav-bg"></div>
                        <div className="uk-navbar-left uk-visible@s">
                            <a className="uk-logo" href="Homepage.html"> <i className="fas fa-graduation-cap"></i> </a>
                        </div>
                        <ul>
                            <li>
                                <a href="#"> <i className="fas fa-play"></i> </a>
                                <div className="side-menu-slide">
                                    <div className="side-menu-slide-content">
                                        <ul data-simplebar>
                                            <li>
                                                <a href="Browse_all_webdevelopment.html"> <i className="far fa-credit-card icon-medium"></i> Web Development </a>
                                            </li>
                                            <li>
                                                <a href="Browse_all_webdevelopment.html"> <i className="fas fa-briefcase icon-medium"></i>  Business </a>
                                            </li>
                                            <li>
                                                <a href="Browse_all_webdevelopment.html"> <i className="fas fa-box icon-medium"></i>    IT & Software </a>
                                            </li>
                                            <li>
                                                <a href="Browse_all_webdevelopment.html"> <i className="fas fa-pencil-ruler icon-medium"></i>    Office Productivity </a>
                                            </li>
                                            <li>
                                                <a href="Browse_all_webdevelopment.html"> <i className="fas fa-brain icon-medium"></i>   Personal Development </a>
                                            </li>
                                            <li>
                                                <a href="Browse_all_webdevelopment.html"> <i className="fas fa-bullhorn icon-medium"></i>  Marketing </a>
                                            </li>
                                            <li>
                                                <a href="Browse_all_webdevelopment.html"> <i className="far fa-life-ring icon-medium"></i>  Life Style  </a>
                                            </li>
                                            <li>
                                                <a href="Browse_all_webdevelopment.html"> <i className="fas fa-camera icon-medium"></i>  Photography  </a>
                                            </li>
                                            <li>
                                                <a href="Browse_all_webdevelopment.html"> <i className="fas fa-briefcase-medical icon-medium"></i>   Health & Fitness </a>
                                            </li>
                                            <li>
                                                <a href="Browse_all_webdevelopment.html"> <i className="fas fa-music icon-medium"></i>   Music </a>
                                            </li>
                                            <li>
                                                <a href="Browse_all_webdevelopment.html"> <i className="fas fa-shopping-bag icon-medium"></i>  Ecommerce  </a>
                                            </li>
                                            <li>
                                                <a href="Browse_all_webdevelopment.html"> <i className="fas fa-utensils icon-medium"></i>  Food & cooking </a>
                                            </li>
                                            <li>
                                                <a href="Browse_all_webdevelopment.html"> <i className="fas fa-universal-access icon-medium"></i>  Teaching & academy </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <a href="books.html" uk-tooltip="title: Books ; delay: 500 ; pos: right ;animation:	uk-animation-scale-up"> <i className="fas fa-book-open"></i> </a>
                            </li>
                            <li>
                                <a href="#"> <i className="fas fa-code"></i> </a>
                                <div className="side-menu-slide">
                                    <div className="side-menu-slide-content">
                                        <ul>
                                            <li>
                                                <a href="Scripts.html"> <i className="fas fa-code icon-medium"></i> Browse All Scripts </a>
                                            </li>
                                            <li>
                                                <a href="Scripts_single_page.html"> <i className="fab fa-wordpress icon-medium"></i>   Cms Plugins </a>
                                            </li>
                                            <li>
                                                <a href="Scripts_single_page.html"> <i className="fab fa-php icon-medium"></i>   PHP Scripts </a>
                                            </li>
                                            <li>
                                                <a href="Scripts_single_page.html"> <i className="fab fa-wordpress-simple icon-medium"></i> Cms themes </a>
                                            </li>
                                            <li>
                                                <a href="Scripts_single_page.html"> <i className="fab fa-html5 icon-medium"></i> Html templates  </a>
                                            </li>
                                            <li>
                                                <a href="Scripts_single_page.html"> <i className="fab fa-android icon-medium"></i>  Apps Source codes </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <a href="Discussion.html" className="active" uk-tooltip="title: Discussion ; delay: 500 ; pos: right ;animation:	uk-animation-scale-up"> <i className="fas fa-comment-alt"></i> </a>
                            </li>
                            <li>
                                <a href="Blog.html" className="active" uk-tooltip="title: Blogs ; delay: 500 ; pos: right ;animation:	uk-animation-scale-up"> <i className="fas fa-file-alt"></i> </a>
                            </li>
                        </ul>
                    </div>
                </div> */}
            </div>
        );
    }

}

export default Navbar;