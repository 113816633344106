import gql from "graphql-tag";
import GraphQLJSON, { GraphQLJSONObject } from "graphql-type-json";

export const ADD_SUBMISSION = gql`
  mutation AddSubmission(
    $fullName: String
    $country: String
    $region: String
    $year: Int
    $contactPersonFullName: String
    $contactPersonEmail: String
    $contactPersonSkype: String
    $assessmentName: String
  ) {
    addSubmission(
      fullName: $fullName
      country: $country
      region: $region
      year: $year
      contactPersonFullName: $contactPersonFullName
      contactPersonEmail: $contactPersonEmail
      contactPersonSkype: $contactPersonSkype,
      assessmentName: $assessmentName
    ) {
      id
    }
  }
`;

export const GET_GENERAL_INFO = gql`
  query {
    getGeneralInfo {
      id
      country
      fullName
      year
      region
      contactPersonFullName
      contactPersonEmail
      contactPersonSkype
      assessmentName
    }
  }
`;
export const SUBMIT_SECTION_1 = gql`
  mutation SubmistSection1($submission: JSON, $sectionId: String, $actionType: Boolean) {
    submitSection1(
      sectionId: $sectionId
      submission: $submission
      actionType: $actionType
    ) {
      id
    }
  }
`;

export const GET_SECTION_1 = gql`
  query ($sectionId: String) {
    getSectionSubmission(sectionId: $sectionId) {
      id
      submissionID
      addedBy
      createdAt
      updatedAt
      sectionId
      submission
      sectionName
      sectionNumber
    }
  }
`;

export const GET_SECTION_II_SUBMISSIONS = gql`
query {
  getSectionSubmission(sectionId: "ck0xh4o71pbyx0b17v0fmtjb4") {
    id
    submissionID
    addedBy
    createdAt
    updatedAt
    sectionId
    submission
    sectionName
    sectionNumber
  }
}
`;

export const GET_SUBMISSIONS_BY_SECTION = gql`
  query GetUserSectionSubmission($userId: String, $sectionId: String) {
    getSubmissionForUser(sectionId: $sectionId, userId: $userId) {
      id
      submissionID
      addedBy
      createdAt
      updatedAt
      sectionId
      submission
      sectionName
      sectionNumber
    }
  }
`;

export const GET_ALL_SUBMMISSIONS = gql`
  query {
  getAllSubmissionsByUser{
    section{
      title
      sectionNumber
      id
    },
    submission,
    updatedAt
  }
}
`
export const GET_SUBMITTED_LIST = gql`
  query GetSubmittedList($userId: String!) {
  getAllSubmissionsForUser(userId: $userId){
    section{
      title
      sectionNumber
      id
    },
    submission,
    updatedAt
  }
}
`

export const GET_USER_SCORE = gql`
  query GetUserScore($userId: String!, $submissionId: String) {
    getUserScore(userId: $userId, submissionId: $submissionId)
}
`
export const GET_SUBMISSION = gql`
  query GetSubmission($submissionId: String!, $submissionType: String!) {
    getSubmission(submissionId: $submissionId, submissionType: $submissionType)
}
`

export const GET_SUBMISSION_LIST = gql`
  query { getAllSubmissions }
`

export const ADD_NEW_ASSESSMENT = gql`
  mutation { addNewAssessment }
`;

export const GET_ALL_USER_ASSESSMENT = gql`
  query GetAllUserAssessment($userId: String!) {
    getAllUserAssessment(userId: $userId)
}
`
export const GET_SUBMISSIONS = gql`
  query {
    getSubmissions
}
`

export const GET_EDIT_GENERAL_INFO = gql`
  query ($submissionId: String!, $submissionType: String!) {
    getEditGeneralInfo(submissionId: $submissionId, submissionType: $submissionType)
  }
`;

export const UPDATE_SUBMISSION = gql`
  mutation UpdateSubmission(
    $submissionId: String!
    $submissionType: String!
    $fullName: String
    $country: String
    $region: String
    $year: Int
    $contactPersonFullName: String
    $contactPersonEmail: String
    $contactPersonSkype: String
    $assessmentName: String
  ) {
    updateSubmission(
      submissionId: $submissionId
      submissionType: $submissionType
      fullName: $fullName
      country: $country
      region: $region
      year: $year
      contactPersonFullName: $contactPersonFullName
      contactPersonEmail: $contactPersonEmail
      contactPersonSkype: $contactPersonSkype,
      assessmentName: $assessmentName
    )
  }
`;


export const GET_EDIT_SECTION_SUBMISSION = gql`
  query ($sectionId: String!, $submissionId: String!) {
    getEditSectionSubmission(sectionId: $sectionId, submissionId: $submissionId) {
      id
      submissionID
      addedBy
      createdAt
      updatedAt
      sectionId
      submission
      sectionName
      sectionNumber
    }
  }
`;

export const UPDATE_SECTION_1 = gql`
  mutation UpdateSection1($submission: JSON, $sectionId: String!, $submissionId: String!, $submissionType: String, $status: SubmissionStatus, $actionType: Boolean) {
    updateSection1(
      sectionId: $sectionId
      submission: $submission
      submissionId: $submissionId
      submissionType: $submissionType
      status: $status
      actionType: $actionType
    ) {
      id
    }
  }
`;

export const UPDATE_USER_SECTION_1 = gql`
  mutation UpdateUserSection1($submission: JSON, $sectionId: String!, $submissionId: String!, $submissionType: String, $userId: String!, $status: SubmissionStatus, $actionType: Boolean) {
    updateUserSection1(
      userId: $userId
      sectionId: $sectionId
      submission: $submission
      submissionId: $submissionId
      submissionType: $submissionType
      status: $status 
      actionType: $actionType     
    ) {
      id
    }
  }
`;

export const GET_ALL_EDIT_SUBMMISSIONS = gql`
  query ($submissionId: String!) {
  getAllEditSubmissionsByUser(submissionId: $submissionId){
    section{
      title
      sectionNumber
      id
    },
    submission,
    updatedAt
  }
}
`

export const GET_VIEW_GENERAL_INFO = gql`
  query ($submissionId: String!, $submissionType: String!, $userId: String) {
    getViewGeneralInfo(submissionId: $submissionId, submissionType: $submissionType, userId: $userId)
  }
`;

export const DOWNLOAD_PDF = gql`
  query ($submissionId: String!, $submissionType: String!) {
    downloadPDF(submissionId: $submissionId, submissionType: $submissionType)
}
`

export const DOWNLOAD_USER_PDF = gql`
  query ($submissionId: String!, $submissionType: String!, $lang: Lang) {
    downloadUserPDF(submissionId: $submissionId, submissionType: $submissionType, lang: $lang)
}
`

export const DELETE_SUBMMISSION = gql`
  mutation DeleteSubmission(
    $submissionId: String!
    $submissionType: String!
  ) {
    deleteSubmission(
      submissionId: $submissionId
      submissionType: $submissionType
    )
  }
`;

export const DELETE_USER_SUBMMISSION = gql`
  mutation DeleteUserSubmission(
    $submissionId: String!
    $submissionType: String!
  ) {
    deleteUserSubmission(
      submissionId: $submissionId
      submissionType: $submissionType
    )
  }
`;

export const REOPEN_SUBMMISSION = gql`
  mutation ReopenSubmission(
    $submissionId: String!
    $submissionType: String!
  ) {
    reopenSubmission(
      submissionId: $submissionId
      submissionType: $submissionType
    )
  }
`;