import React, { Component } from 'react';
import Login from '../common/Login';

class Reg extends Component {
    render(){
        return(
            <div>
            
            <div class="uk-section-default uk-section">
            <div class="uk-width-2-3@m uk-width-1-2@s uk-margin-auto  border-radius-6 ">
                <div class="uk-grid uk-child-width-1-2@m uk-background-grey uk-grid-collapse" uk-grid>
                    <div class="uk-text-middle uk-margin-auto-vertical uk-text-center uk-padding-small uk-animation-scale-up">
                        <p> <i class="fas fa-certificate uk-text-white" style={styles.graduationIcon}></i> </p>
                        <h3 class="uk-text-white uk-margin-small"> Readiness Assessment Tool </h3>
                        {/* <h5 class="uk-margin-small uk-text-muted uk-text-bold uk-text-nowrap"> Readiness Assessment Tool </h5> */}
                    </div>
                    <div>
                        <div class="uk-card-default uk-padding uk-card-small">
                                
                            <div id="login" class="tabcontent tab-default-open animation: uk-animation-slide-right-medium"> 
                                <Login/>
                            </div>

                                                   
                            
                        </div>                         
                    </div>
                </div>
            </div>
        </div>
            </div>
        )
    }
}

const styles = {
    darkBack: {
        backgroundColor:'rgba(0, 0, 0, 0.52)',
        backgroundBlendMode:'color-burn',
    },
    poweredBy: {
        bottom: '8px',
        right: '60px',

    },
    graduationIcon:{
        fontSize: '60px',
    }
}

export default Reg;